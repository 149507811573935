<template>
  <fieldset>
    <legend>{{label}}</legend>
    <template v-for="(display, v) in options" v-bind:key="'checkbox-' + v">
      <div><input ref="checkboxinput" :id="`${idRoot}${v}`" type="checkbox" :value="v" v-model="cValue"><label :for="`${idRoot}${v}`">{{display}}</label></div>
    </template>
  </fieldset>
</template>

<script>
import mixin from './Mixin';
export default {
  mixins: [ mixin ],
  name: 'FormCheckboxSet',
  data() {
    return {
      cValue: this.modelValue,
    };
  },
  watch: {
    cValue() {
      this.$emit( 'update:modelValue', this.cValue );
    },
  },
  props: {
    idRoot: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: false,
      default: () => {
        return [];
      },
    },
    options: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';
  fieldset {
    legend {
      margin: 10px 0;
      display: block;
      color: #262e37;
    }
    input {
      // padding: 10px;
      // border: 1px solid #95989c;
      // color: #262e37;
      // border-radius: 2px;
      // width: calc(100% - 20px);
    }
    label {
      color: #262e37;
    }
  }

  ._darkMode fieldset {
    legend {
      color: $hugr-colours-grey;
    }
    label {
      color: #FFF;
    }
  }
</style>
