<template>
  <div>
    <div v-if="report" class="Report">
      <!-- <LinkButton type="transparent" size="small" :icon="['solid', 'arrow-left']" :to="'/reports'">Back to reports</LinkButton> -->
      <ReportInfo :reportVersion="report.currentVersion._id"
        :trail="[{name: 'Home', path: '/dashboard'}, {name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), path: `/${$hugrConfig.reportRouterReplacement}s`}]" />

      <Body class="Report_Body" :withMenu="true">
        <div class="Report_Body_SheetNotice" size="small"
          v-if="report.spreadsheets.length==0&&showSheetNotice&&!disableSpreadsheetPrompt">
          <Notice>
            <h3 v-translate>Create a tracking spreadsheet?</h3>
            <p>{{ $gettext( 'Adding a spreadsheet to your %{projectString} helps you to track progress and locate issues.', { projectString: $hugrConfig.projectString } ) }}</p>
            <p v-translate>Choose the type of report template that you would like from the list below.</p>
            <AriaListBox idRoot="sst_" label="Template" :labelVisible="false" v-model="new_template"
              :options="templates" :extra="templateDescs" />

            <div class="Report_Body_SheetNotice_Actions">
              <Button type="secondary" @click.prevent="newSheet">{{$gettext('Create sheet')}}</Button>
              <Button @click="dismissSheet">{{$gettext('Dismiss')}}</Button>
            </div>
          </Notice>
        </div>

        <div class="Report_Body_All" v-if="report.pages.length || showReportWithoutPage">
          <div ref="reportActions" class="Report_Body_All_Actions">
            <div class="Report_Body_All_Actions_Actions __grid" v-if="report.spreadsheets.length==0">
              <Button class="__grid_column_2" type="secondary" :icon="['solid', 'folder-plus']"
                @click="$refs.issueinstancemodal.show()">{{$gettext('New issue')}}</Button>
              <Search class="__grid_column_10" :label="$gettext('Search all issues')" v-model="searchall" :inUse="searchall!=''" :results="searchIssues.length" />
            </div>
            <div class="Report_Body_All_Actions_Actions __grid" v-else>
              <Button class="__grid_column_2" type="secondary" :icon="['solid', 'folder-plus']"
                @click="$refs.issueinstancemodal.show()">{{$gettext('New issue')}}</Button>
              <LinkButton class="__grid_column_2" type="secondary" :icon="['solid', 'table']"
                :to="`/reports/spreadsheet/${report.identifier}/${report.currentVersion.version}`">
                {{$gettext('Spreadsheet')}}</LinkButton>
              <Search class="__grid_column_8" label="Search all issues" v-model="searchall" :inUse="searchall!=''" :results="searchIssues.length"/>
            </div>
          </div>

          <div v-if="searchall!=''">
            <h3 v-translate>Search results</h3>
            <div v-if="searchIssues">
              <ClearList v-if="searchIssues.length" class="sections">
                <IssueLi v-for="instance in searchIssues" :title="instance.reason" :identifier="instance.identifier"
                  :section="instance.section" :status="parseStatus(instance.status)" :flag="parseStatus(instance.flag)"
                  :barColour="parseColour(instance.flag)" :instances="instance.others.length+1"
                  :to="`/issue/${instance.identifier}`" :key="instance.identifier" />
              </ClearList>
              <Empty v-else :text="$gettext(`Can't find any issues like that.`)"
                :button="{size: 'regular', type: 'secondary', icon: ['solid', 'folder-plus'], text: $gettext('Add an issue')}"
                @buttonClick="$refs.issueinstancemodal.show()" />
            </div>
            <Loader v-else />
          </div>
          <div v-else>
            <div class="Report_Body_All_Details">
              <div class="_flex-3 _flex-col _flex-grow-3">
                <div class="Report_Body_All_Details_Exec_Desc" v-if="productSettings || ( report.productDescription && report.productDescription != '' )">
                  <h3>{{ $gettext( '%{projectString} details', { projectString: $hugrConfig.projectStringCap } ) }}</h3>
                  <p>{{ report.productDescription }}</p>
                  <Button size="micro" type="border"
                    v-if="!allDetails && ( report.productUrl!='' || report.productStatementUrl!='' || productSettings )"
                    @click="openAllDetails()" ref="allDetailsBtn">{{
                    $gettext('View more')
                    }}</Button>&nbsp;
                </div>
                <div v-if="allDetails" ref="allDetails" class="Report_Body_All_Details_More"
                  tabindex="-1">
                  <dl>
                    <div v-if="report.productUrl!='' || productSettings" class="Report_Body_All_Details_More_Item _inline">
                      <dt>{{ $gettext('URL') }}:</dt>
                      <dd>
                        <span v-if="report.productUrl!=''"><a :href="`${productUrlParsed}`">{{ report.productUrl }}</a></span>
                        <span v-else>{{ $gettext('None') }}</span>
                      </dd>
                    </div>
                    <div v-if="report.productStatementUrl!='' || productSettings" class="Report_Body_All_Details_More_Item _inline">
                      <dt>{{ $gettext('Statement URL') }}:</dt>
                      <dd>
                        <span v-if="report.productStatementUrl!=''"><a :href="`${statementUrlParsed}`">{{ report.productStatementUrl }}</a></span>
                        <span v-else>{{ $gettext('None') }}</span>
                      </dd>
                    </div>
                    <div v-if="productSettings" class="Report_Body_All_Details_More_Item _inline">
                      <dt>{{ $gettext('Product risk') }}:</dt>
                      <dd>
                        <span>{{ riskString }}</span>
                        <span v-if="report.productRiskDescription!=''">: {{ report.productRiskDescription }}</span>
                      </dd>
                    </div>
                    <div v-if="productSettings" class="Report_Body_All_Details_More_Item _inline">
                      <dt>{{ $gettext('Disproportionate burden') }}:</dt>
                      <dd>
                        <span>{{ disproportionateBurdenString }}</span>
                        <span v-if="report.disproportionateBurdenDescription!=''">: {{
                          report.disproportionateBurdenDescription }}</span>
                      </dd>
                    </div>
                    <div v-if="productSettings" class="Report_Body_All_Details_More_Item _inline">
                      <dt>{{ $gettext('Product accessibility statement') }}:</dt>
                      <dd>
                        <span>{{ accessibilityStatementString }}</span>
                        <span v-if="report.accessibilityStatementNotes">: {{ report.accessibilityStatementNotes
                          }}</span>
                      </dd>
                    </div>
                  </dl>

                  <Button size="micro" type="border" @click="closeAllDetails">{{ $gettext('View less') }}</Button>&nbsp;
                </div>
                <div class="Report_Body_All_Details_Exec">
                  <h3 v-translate>Executive Summary</h3>
                  <div class="Report_Body_All_Details_Exec_Buttons">
                    <Button v-if="!editingExecSummary" class="Report_Body_All_Exec_Buttons_EditBtn" type="transparent"
                      size="micro" :icon="['regular', 'edit']" @click="editExecSummary">{{ $gettext('Edit') }}</Button>
                    <Button v-if="!editingExecSummary" class="Report_Body_All_Exec_Buttons_EditBtn" type="transparent"
                      size="micro" :icon="['regular', 'edit']"
                      @click="$refs.editrenderdetails.show(report.currentVersion._id, report.currentVersion.createdOverride, report.currentVersion.publishedOverride, report.currentVersion.keyIssues, issuesMarkedAsKeyIssues);">{{
                      $gettext('Dates and key issues') }}</Button>
                  </div>

                  <form v-if="editingExecSummary" class="Report_Body_All_Details_Exec_Form">
                    <FormTextArea v-model="report.currentVersion.execSummaryTmp" idRoot="reportexec"
                      label="Executive Summary" :labelVisible="false" />
                    <div class="Report_Body_All_Details_Exec_Form_Actions">
                      <Button size="small" type="error" @click="restoreExecSummary">{{ $gettext('Return to default') }}</Button>&nbsp;
                      <Button size="small" @click="cancelExecSummary">{{ $gettext('Cancel') }}</Button>&nbsp;
                      <Button size="small" type="secondary" @click="saveExecSummary">{{ $gettext('Save') }}</Button>
                    </div>
                  </form>
                  <vue-markdown v-else v-highlight :html="false">{{ report.currentVersion.execSummary }}</vue-markdown>
                  <br/>
                  <Button size="micro" type="border" @click="$refs.pagecompliancemodal.show(pageComplianceList)" ref="pageComplianceBtn">{{
                  $gettext('View page compliance')
                  }}</Button>
                  <br />
                  <br />
                  <ProgressBar id="reportprogress" label="Report Progress: " :value="report.currentVersion.progress"/>
                </div>
              </div>
              <div class="_flex" v-if="showPeopleOnReports && people.length>1">
                <div class="Report_Body_All_Details_People">
                  <h3 v-translate>People</h3>
                  <ul>
                    <li v-for="person in people" :key="`person${person[0]}`" class="Report_Body_All_Details_People_Person">
                      <router-link :to="`/userprofile/${person[1]}`" class="Report_Body_All_Details_Person_Name">{{ person[0] }}</router-link>
                      <span class="Report_Body_All_Details_People_Person_Details">
                        <span v-if="person[2]&&person[2]!=''" class="Report_Body_All_Details_People_Person_Details_Role">{{ person[2] }}</span>
                        <template v-if="person[2]&&person[2]!=''&&lastEngagement[person[1]]">&nbsp;-&nbsp;</template>
                        <span v-if="lastEngagement[person[1]]" class="Report_Body_All_Details_People_Person_Details_LastEngagement">{{ moment(lastEngagement[person[1]]).fromNow() }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- <CardSet class="Report_Body_All_UserJourneys"
              :title="(report.currentVersion.userJourneys&&report.currentVersion.userJourneys.length)?
                $gettext('User Journeys')+` (${report.currentVersion.userJourneys.length})` :
                $gettext('User Journeys')"
              hlevel="3"
              :button="{icon:['solid', 'users-cog', 'after'], text: $gettext('Add a journey')}"
              @buttonClick="$refs.userjourneymodal.show()">

              <div v-if="report.currentVersion.userJourneys && report.currentVersion.userJourneys.length">
                <DataTable
                  class="Report_Body_All_UserJourneys_Table"
                  :headers="[
                    'Name',
                    'Importance',
                    'Description',
                    'Comprised of',
                    'Progress',
                    'Actions'
                  ]"
                >
                  <template v-for="userJourney in report.currentVersion.userJourneys" :key="userJourney._id">
                    <tr v-if="userJourney.title!='_devHide'">
                      <td>{{ userJourney.title }}</td>
                      <td>{{ userJourney.importance }}</td>
                      <td>{{ userJourney.description }}</td>
                      <td>
                        <span>
                          <span>{{ userJourney.items.filter(i => i.type=='page').length }} pages</span>,
                          <span>{{ userJourney.items.filter(i => i.type=='component').length }} components</span>
                        </span>
                      </td>
                      <td><ProgressBar :id="`journeyprogress-${userJourney._id}`" label="Journey Progress" :hideLabel="true" :value="userJourney.progress"/></td>
                      <td>
                        <Button size="micro" @click="$refs.edituserjourneymodal.show(userJourney._id)">Edit Journey</Button>&nbsp;
                        <Button size="micro" type="serious" @click="retireJourney(userJourney._id)">Remove Journey</Button>
                      </td>
                    </tr>
                  </template>
                </DataTable>
              </div>
              <Empty v-else :text="$gettext('There are no user journeys on this report')"
                :button="{size: 'regular', type: 'secondary', icon: ['regular', 'file'], text: $gettext('Add a journey')}"
                @buttonClick="$refs.userjourneymodal.show()" />
            </CardSet> -->

            <h3 v-translate>Issues</h3>
            <!-- <p></p> -->
            <TabList :tabs="tabs" />

            <!-- <TabPanel ref="simplified" class="Report_Body_Simplified" id="simplified-issues">
              <ClearList v-if="report.spreadsheets[0]" class="Report_Body_Simplified_Accordion">
                <template v-for="section of rowSplit(report.spreadsheets[0].rows)">
                  <AccordionLi class="Report_Body_Simplified_Accordion_Item" :key="section.title" @open="() => { openSections.push(section.title) }" @close="() => { openSections.filter( s => s!=section.title) }">
                    {{section.title}}
                    <ClearList v-if="openSections.indexOf(section.title)>=0" class="Report_Body_Simplified_Accordion_Item_SubList">
                      <li v-for="(row,index) of section.rows" v-bind:key="'row-' + index" class="Report_Body_Simplified_Accordion_Item_SubList_Item">{{row.template.ref}}: {{row.template.header}}</li>
                    </ClearList>
                  </AccordionLi>
                </template>
              </ClearList>
              <div v-else>
                <h4>This isn't fully set up yet.</h4>
                <p>Please select the report template you would like to use below.</p>
                <FormSelect idRoot="sst_" label="Template" v-model="new_template" v-bind:options="templates" :key="'templates'+templates.length"/>
                <Button @click.prevent="newSheet">New sheet</Button>
              </div>
            </TabPanel> -->

            <TabPanel ref="all" class="Report_Body_All" id="all-issues" default="open">
              <FilterBar ref="filterBar" class="Report_Body_All_Filters" @clear="clearAllFilters">
                <FilterOpt :title="$gettext('Issue status')" @update="(v) => { this.filters.status = v }"
                  @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="{
                    'reported': $gettext('Reported'),
                    'in-progress': $gettext('In Progress'),
                    'retest': $gettext('To Retest')
                  }" :extra="{
                    'reported': $gettext('Issues that are reported and action is required.'),
                    'in-progress': $gettext('Issues where a fix is in progress.'),
                    'retest': $gettext('Issues that have been fixed and require retesting')
                  }" />
                <FilterOpt :title="$gettext('Issue severity')" @update="(v) => { this.filters.severity = v }"
                  @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="{
                    '0': $gettext('Advisory'),
                    '1': $gettext('Low'),
                    '2': $gettext('Medium'),
                    '3': $gettext('High'),
                    '4': $gettext('Critical'),
                  }" :extra="{
                    '0': $gettext(`Issues that should be addressed to improve the user experience`),
                    '1': $gettext(`There may be a minor impact for some users`),
                    '2': $gettext(`Some people may have difficulty using the product`),
                    '3': $gettext(`Issues that cause severe difficulties for some groups of people`),
                    '4': $gettext(`Issues that make the product unusable`),
                  }" />
                <FilterOpt :title="$gettext('Issue priority')" @update="(v) => { this.filters.priority = v }"
                  @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="{
                    '0': $gettext('Lowest'),
                    '1': $gettext('Low'),
                    '2': $gettext('Medium'),
                    '3': $gettext('High'),
                    '4': $gettext('Highest'),
                  }" :extra="{
                    '0': $gettext(`Issues that should be fixed when convenient`),
                    '1': $gettext(`Issues that require a fix in the long term`),
                    '2': $gettext(`Issues that require a in the short term`),
                    '3': $gettext(`Issues that require a fix urgently`),
                    '4': $gettext(`Issues that require a fix immediately`),
                  }" />
                <FilterOpt :title="$gettext('Assignee')" v-if="report.team"
                  @update="(v) => { this.filters.assignee = v }" @open="(current) => { $refs.filterBar.setCurrent(current) }"
                  :options="teamMembers" />
                <FilterOpt :title="$gettext('Reporter')" @update="(v) => { this.filters.reporter = v }"
                  @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="reporters" />
                <FilterOpt :title="$gettext('QA status')" @update="(v) => { this.filters.flag = v }"
                  @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="{
                    'unchecked': $gettext('Unchecked'),
                    'to-check': $gettext('To Check'),
                    'checked': $gettext('Checked'),
                    'change-required': $gettext('Change Required'),
                    'second-opinion': $gettext('Second Opinion')
                  }" :extra="{
                    'unchecked': $gettext(`Issue hasn't been checked`),
                    'to-check': $gettext('Issue needs to be checked'),
                    'checked': $gettext('Issue has been checked and confirmed'),
                    'change-required': $gettext('A change is required before the issue can be confirmed'),
                    'second-opinion': $gettext('Issue has been checked but a second opinion is required')
                  }" />
                <!-- <FilterSort
                  @open="() => { $refs.filterBar.closeAll() }"
                  :options="{
                    'date-oldest': 'Date added (oldest first)',
                    'date-newest': 'Date added (newest first)',
                    'instances-low': 'Instances (low to high)',
                    'instances-high': 'Instances (high to low)',
                    'issuetemplate': 'Issue template'
                  }"
                /> -->
              </FilterBar>
              <div v-if="sections">
                <h4 class="Report_Body_IssueCountH" aria-live="polite">{{$gettext('Showing')}} {{ openIssuesToShow.length }} {{$gettext('issues with')}} {{ countInstances(openIssuesToShow) }} {{$gettext('instances')}}</h4>
                <ClearList v-if="openIssuesToShow.length" class="sections">
                  <IssueLi v-for="instance in openIssuesToShow" :title="instance.reason"
                    :identifier="instance.identifier" :section="instance.section" :status="parseStatus(instance.status)"
                    :flag="parseStatus(instance.flag)" :barColour="parseColour(instance.flag)"
                    :instances="instance.others.length+1" :to="`/issue/${instance.identifier}`"
                    :key="instance.identifier" />
                </ClearList>
                <Empty v-else :text="$gettext('No issues to show')"
                  :button="{size: 'regular', type: 'secondary', icon: ['solid', 'folder-plus'], text: $gettext('Add an issue')}"
                  @buttonClick="$refs.issueinstancemodal.show()" />
              </div>
            </TabPanel>

            <TabPanel ref="closed" class="Report_Body_Closed" id="closed-issues">
              <div v-if="sections">
                <h4 class="Report_Body_IssueCountH">{{$gettext('Showing')}} {{ closedIssues.length }} {{$gettext('issues with')}} {{ countInstances(closedIssues) }} {{$gettext('instances')}}</h4>
                <ClearList v-if="closedIssues.length" class="sections">
                  <IssueLi v-for="instance in closedIssues" :title="instance.reason" :identifier="instance.identifier"
                    :section="instance.section" :status="parseStatus(instance.status)"
                    :flag="parseStatus(instance.flag)" :barColour="parseColour(instance.flag)"
                    :instances="instance.others.length+1" :to="`/issue/${instance.identifier}`"
                    :key="instance.identifier" />
                </ClearList>
                <Empty v-else :text="$gettext('No closed issues')" />
              </div>
            </TabPanel>

            <h3 v-translate>Activity Log</h3>
            <Comments :reference="report._id" :team="teamId" :fileUploadEnabled="true" :reportID="report._id"/>
          </div>
        </div>
        <Empty v-else :text="$gettext('There are no pages on this report')"
          :button="{size: 'regular', type: 'secondary', icon: ['regular', 'file'], text: $gettext('Add a page')}"
          @buttonClick="$refs.pagemodal.show()" />
      </Body>
      <EditPageModal ref="editpagemodal" @edited="$apollo.queries.areport.refetch()" />
      <EditComponentModal :report="report.currentVersion._id" ref="editcomponentmodal"
        @edited="$apollo.queries.areport.refetch()" />

      <PageModal :report="report.currentVersion._id" :components="report.components" ref="pagemodal" @added="$apollo.queries.areport.refetch()" />

      <IssueInstanceModal :report="report.currentVersion._id" ref="issueinstancemodal"
        @success="()=> {$apollo.queries.areport.refetch(); $apollo.queries.sections.refetch();}" />
      <EditRenderDetailsModal
        @success="$apollo.queries.areport.refetch();"
        ref="editrenderdetails" :data="[]" />

      <UserJourneyModal :reportVersion="report.currentVersion._id" ref="userjourneymodal" @success="()=> {$apollo.queries.areport.refetch(); $apollo.queries.sections.refetch();}" />
      <EditUserJourneyModal ref="edituserjourneymodal" @success="()=> {$apollo.queries.areport.refetch(); $apollo.queries.sections.refetch();}" />

      <PageComplianceModal ref="pagecompliancemodal" />
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import moment from 'moment';

import EditPageModal from '@/modals/Page/Edit';
import EditComponentModal from '@/modals/Component/Edit';

import PageModal from '@/modals/Page/Create';

import EditRenderDetailsModal from '@/modals/RenderDetails/Edit';

import IssueInstanceModal from '@/modals/IssueInstance/Create';

import AriaListBox from '@/components/Aria/ListBox.vue';

import UIFilterBar from '@/components/UI/FilterBar.vue';
import UIFilterOpt from '@/components/UI/FilterOpt.vue';

import FormTextArea from '@/components/Form/TextArea.vue';

import Comments from '@/components/Comments/Index';

import UserJourneyModal from '@/modals/UserJourney/Create';
import EditUserJourneyModal from '@/modals/UserJourney/Edit';

import PageComplianceModal from '@/modals/Page/Compliance';

import ProgressBar from '@/components/UI/ProgressBar';
import config from '../../../config';

export default {
  name: 'ReportsViewView',
  mounted() {
    window.addEventListener( 'scroll', this.scroll );
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            productDetails
            displayAllProductDetails
            disableSpreadsheetPrompt
            showReportWithoutPage
            showPeopleOnReports
          }
        }
      `,
    } ).then( res => {
      this.productSettings = res.data.settings.productDetails;
      this.allDetails = res.data.settings.displayAllProductDetails;
      this.disableSpreadsheetPrompt = res.data.settings.disableSpreadsheetPrompt;
      this.showReportWithoutPage = res.data.settings.showReportWithoutPage;
      this.showPeopleOnReports = res.data.settings.showPeopleOnReports;
    } );

    this.$apollo.query( {
      query: gql`
        {
          templates: SpreadsheetTemplates {
            _id,
            name,
            description,
            published,
            promoted
          }
        }
      `,
    } ).then( res => {
      const templates = {};
      for( const template of res.data.templates ) {
        if( template.published ) {
          this.templateDescs[template._id] = template.description;
          if( template.promoted ) {
            this.promoted = template;
          } else {
            templates[template._id] = template.name;
          }
        }
      }
      this.new_template = this.promoted._id;
      const p = {};
      // eslint-disable-next-line no-unused-expressions
      p[this.promoted._id] = `${this.promoted.name} (recommended)`;
      this.templates = {
        ...p,
        ...templates,
      };
    } );

    if( this.$apollo.queries.sections ) {
      this.$apollo.queries.sections.refetch();
    }
  },
  beforeUnmount() {
    window.removeEventListener( 'scroll', this.scroll );
  },
  data() {
    // let pageView = this.$session.get('report.page.view');
    // if(typeof pageView =='undefined') {
    //   pageView = 'table';
    // }
    // let componentView = this.$session.get('report.component.view');
    // if(typeof componentView =='undefined') {
    //   componentView = 'table';
    // }
    const tabs = [
      // {
      //   text: "Report overview",
      //   controls: "simplified-issues",
      //   action: () => {
      //     this.$refs.all.close();
      //     this.$refs.closed.close();
      //     this.$refs.simplified.open();
      //   }
      // },
      {
        text: "All issues",
        controls: "all-issues",
        action: () => {
          //this.$refs.simplified.close();
          this.$refs.closed.close();
          this.$refs.all.open();

          this.resetAllFilters();

          this.$apollo.queries.areport.refetch();
        },
      },
      {
        text: "Closed issues",
        controls: "closed-issues",
        action: () => {
          //this.$refs.simplified.close();
          this.$refs.all.close();
          this.$refs.closed.open();

          this.$apollo.queries.areport.refetch();
        },
      },
    ];

    return {
      productSettings: false,
      allDetails: false,
      disableSpreadsheetPrompt: false,
      showReportWithoutPage: false,
      showPeopleOnReports: false,

      identifier: this.$route.params.identifier.replace( '%20', ' ' ),
      version: this.$route.params.version,
      searchTimeOut: '',
      report: false,
      owner: false, //TODO remove?
      editor: true, //TODO remove?

      pinActions: true,

      editingExecSummary: false,
      execSummaryTmp: '',

      filters: {
        status: [],
        flag: [],
        severity: [],
        priority: [],
        assignee: [],
        reporter: [],
      },
      sections: [
        {
          identifier: 'Loading',
          title: 'Loading',
          subsections: [
            {
              identifier: 'Loading',
              title: 'Loading',
              templates: [
                {
                  instances: [
                    {
                      _id: '0',
                      identifier: 'Loading 1',
                      others: [],
                      primary: true,
                      reason: 'Loading',
                      status: 'Loading',
                    },
                    {
                      _id: '0',
                      identifier: 'Loading 2',
                      others: [],
                      primary: true,
                      reason: 'Loading',
                      status: 'Loading',
                    },
                    {
                      _id: '0',
                      identifier: 'Loading 3',
                      others: [],
                      primary: true,
                      reason: 'Loading',
                      status: 'Loading',
                    },
                  ],
                  template: {
                    _id: '0',
                    identifier: 'Loading 4',
                    title: 'Loading',
                  },
                },
              ],
            },
          ],
        },
      ],

      searchall: '',
      searchIssues: false,

      tabs,

      templates: {},
      templateDescs: {},
      showSheetNotice: true,

      new_template: 0,

      openSections: [],
      // pageView,
      // componentView

      lastEngagement: {},
    };
  },
  watch: {
    $route() {
      if( this.$route.name == 'ReportsView' ) {
        this.identifier = this.$route.params.identifier?.replace( '%20', ' ' );
        this.version = this.$route.params.version;
        this.$apollo.queries.areport.refetch();
      }
    },
    areport() {
      this.report = { ...this.areport };
      this.report.currentVersion = { ...this.report.currentVersion };
      if ( this.report.customer ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation refreshCustomerReportAccessTimestamp($customer: ObjectID!) {
                result: refreshCustomerReportAccessTimestamp(customer: $customer) 
              }
            `,
            variables: {
              customer: this.report.customer._id,
            },
          } );
      }
      if( this.report.currentVersion ) {

        this.$apollo.addSmartQuery( 'sections', {
          query: gql`
            query Sections($version: ObjectID!) {
              sections: Sections(reportVersion: $version) {
                identifier,
                title,
                subsections {
                  identifier,
                  title,
                  templates {
                    template {
                      _id
                      identifier,
                      title
                    },
                    instances {
                      _id
                      identifier,
                      status,
                      flag,
                      reason,
                      primary,
                      priority,
                      isAKeyIssueInstance,

                      reporter {
                        _id
                        name
                      }
                      assignee {
                        _id
                      }
                      template {
                        _id
                        severity
                      }
                      others {
                        _id
                      }
                    }
                  }
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables() {
            return {
              version: this.report.currentVersion._id,
            };
          },
        } );
      }
    },
    searchall() {
      clearTimeout( this.searchTimeOut );
      if( this.searchall == '' ) {
        this.searchIssues = false;
      } else if( this.report.currentVersion ) {
          this.searchTimeOut = setTimeout( () => {
          this.$apollo.query( {
            query: gql`
              query SearchIssuesFromReport($rV: ObjectID!, $term: String) {
                search: SearchIssuesFromReport(reportVersion: $rV, term: $term) {
                  _id
                  identifier,
                  status,
                  flag,
                  reason,
                  primary,
                  others {
                    _id
                  }
                }
              }
            `,
            variables: {
              rV: this.report.currentVersion._id,
              term: this.searchall,
            },
          } ).then( res => {
            const ret = [];
            for( const instance of res.data.search ) {
              if( instance.primary ) {
                ret.push( {
                  ...instance,
                  section: ``,
                } );
              }
            }
            this.searchIssues = ret;
          } ).catch( () => {
            this.$alerts.error( 'Something went wrong!', 'search went bad' ); //TODO
          } );
          }, 1000 );
        }
    },
  },
  apollo: {
    dismissReportVideo: {
      query: gql`
        query GetUserSetting($id: ObjectID!, $key: String!) {
          dismissReportVideo: GetUserSetting(id: $id, key: $key)
        }
      `,
      variables() {
        return {
          id: this.user.id,
          key: 'dismissReportVideo',
        };
      },
    },
    areport: {
      query() {
        const reportFragment = gql`
          fragment reportFragment on Report {
            _id,
            identifier,
            title,

            productAliases,
            productDescription,
            productUrl,
            productStatementUrl,

            productRisk,
            productRiskDescription,

            disproportionateBurden,
            disproportionateBurdenDescription,

            accessibilityStatement,
            accessibilityStatementNotes,
            
            archived,
            team {
              _id,
              managers {
                _id,
                name,
                permissionGroup {
                  _id
                  name
                }
              }
              users {
                _id,
                name,
                permissionGroup {
                  _id
                  name
                }
              }
              contacts {
                _id,
                name,
                permissionGroup {
                  _id
                  name
                }
              }
            }
            portfolio {
              _id
              identifier
              name
              teams {
                _id
                managers {
                  _id,
                  name,
                  permissionGroup {
                    _id
                    name
                  }
                }
                users {
                  _id,
                  name,
                  permissionGroup {
                    _id
                    name
                  }
                }
                contacts {
                  _id,
                  name,
                  permissionGroup {
                    _id
                    name
                  }
                }
              }
            }
            customer {
              _id
              name
            }
            owner {
              _id
              name,
              email,
              permissionGroup {
                _id
                name
              }
            }
            collaborators {
              _id
              name,
              email,
              permissionGroup {
                _id
                name
              }
            }
            currentVersion {
              _id,
              published,
              publishedDate,
              version,
              execSummary,
              execSummaryTmp,
              createdOverride,
              publishedOverride,
              keyIssues,

              progress,

              userJourneys {
                _id
                title
                description
                importance
                progress,

                items {
                  type
                }
              }
            }
            trueVersion {
              _id
            }
            pages {
              _id,
              host,
              path,
              name,
              title,
              issues {
                _id,
                template {
                  severity
                }
              },
              components {
                _id
                issues {
                  _id,
                  template {
                    severity
                  }
                },
              }
              screenshot {
                _id
              }
            }
            components {
              _id,
              identifier,
              description,
              futurePagesAdded,
              pages {
                _id,
                name
              }
              issues {
                _id
              }
              screenshot {
                _id
              }
            }

            spreadsheets {
              _id
            #   number,
            #   title,
            #   rows {
            #     newSection
            #     template {
            #       _id
            #       section
            #       ref
            #       header
            #       details
            #     }
            #     columns {
            #       id,
            #       header,
            #       type,

            #       cell {
            #         _id
            #         row
            #         column
            #         type
            #         items {
            #           done,
            #           text,
            #           instance,
            #         }
            #       }
            #     }
            #   }
            }
          }`;
        if( typeof this.version == 'undefined' ) {
          return gql`
            query Report($ident: String!) {
              areport: Report(identifier: $ident) {
                ... reportFragment
              }
            }
            ${reportFragment}
          `;
        }

          return gql`
            query Report($ident: String!, $version: String!) {
              areport: ReportWithVersion(identifier: $ident, version: $version) {
                ... reportFragment
              }
            }
            ${reportFragment}
          `;

      },
      variables() {
        return {
          ident: this.identifier,
          version: this.version,
        };
      },
    },
  },
  methods: {
    ...mapActions( [ 'setReport' ] ),
    scroll( e ) {
      if( this.pinActions ) {
        const fromTop = window.scrollY;
        if( fromTop >= 150 ) {
          this.$refs.reportActions.classList.add( '_pinned' );
        } else {
          this.$refs.reportActions.classList.remove( '_pinned' );
        }
      }
    },
    openAllDetails() {
      this.allDetails = true;
      setTimeout( () => {
        this.$refs.allDetails.focus();
      }, 300 );
    },
    closeAllDetails() {
      this.allDetails = false;
      setTimeout( () => {
        this.$refs.allDetailsBtn.focus();
      }, 300 );
    },
    countInstances( issues ) {
      let count = 0;
      for( const i of issues ) {
        count += 1 + i.others.length;
      }

      return count;
    },
    editExecSummary() {
      this.editingExecSummary = true;
      this.execSummaryTmp = this.report.currentVersion.execSummaryTmp;
    },
    cancelExecSummary() {
      this.editingExecSummary = false;
      this.report.currentVersion.execSummaryTmp = this.execSummaryTmp;
    },
    saveExecSummary() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateExecSummary($id: ObjectID!, $execSummary: String!) {
            res: updateExecSummary(id: $id, execSummary: $execSummary) {
              _id,
              execSummary,
              execSummaryTmp
            }
          }
        `,
        variables: {
          id: this.report.currentVersion._id,
          execSummary: this.report.currentVersion.execSummaryTmp,
        },
      } ).then( res => {
        this.report.currentVersion.execSummary = res.data.res.execSummary;
        this.report.currentVersion.execSummaryTmp = res.data.res.execSummaryTmp;
        this.editingExecSummary = false;
        this.$alerts.success( 'executive summary updated', 'report version executive summary has been updated.' );
      } ).catch( () => {
        this.$alerts.error( 'Something went wrong', 'Executive summary not updated, please try again or report a bug.' );
      } );
    },
    restoreExecSummary() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation restoreExecSummary($id: ObjectID!) {
            res: restoreExecSummary(id: $id) {
              _id,
              execSummary,
              execSummaryTmp
            }
          }
        `,
        variables: {
          id: this.report.currentVersion._id,
        },
      } ).then( res => {
        this.report.currentVersion.execSummary = res.data.res.execSummary;
        this.report.currentVersion.execSummaryTmp = res.data.res.execSummaryTmp;
        this.editingExecSummary = false;
        this.$alerts.success( 'executive summary restored', 'report version executive summary has been restored to default.' );
      } ).catch( () => {
        this.$alerts.error( 'Something went wrong', 'Executive summary not restored, please try again or report a bug.' );
      } );
    },

    clearAllFilters() {
      this.filters = {
        status: [],
        flag: [],
        severity: [],
        priority: [],
        assignee: [],
        reporter: [],
      };
    },
    doDismiss() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setUserSetting($id: ObjectID!, $key: String!, $value: String!) {
            res: setUserSetting(id: $id, key: $key, value: $value)
          }
        `,
        variables: {
          id: this.user.id,
          key: 'dismissReportVideo',
          value: 'true',
        },
      } ).then( () => {
        this.$apollo.queries.dismissReportVideo.refetch();
      } );
    },
    deletePage( page ) {
      if( !page.issues.length && !page.components.length ) {
        this.$confirm.simple( 'This cannot be undone' ).then( result => {
          if( result ) {
            this.doDeletePage( page._id );
          }
        } );
      } else {
        this.$alerts.error( 'Can\'t Delete Page', 'Page cannot be deleted as is has issues or components associated' );
      }
    },
    doDeletePage( page ) {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: String!) {
          removePage(id: $id)
        }`,
        // Parameters
        variables: {
          id: page,
        },
      } ).then( () => {
        this.$alerts.success( 'Success!', 'Page deleted' );
        this.changed();
      } );
    },
    deleteComponent( component ) {
      if( !component.issues.length ) {
        this.$confirm.simple( 'This cannot be undone' ).then( result => {
          if( result ) {
            this.doDeleteComponent( component._id );
          }
        } );
      } else {
        this.$alerts.error( 'Can\'t Delete Component', 'Component cannot be deleted as is has issues associated' );
      }
    },
    doDeleteComponent( component ) {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: String!) {
          removeComponent(id: $id)
        }`,
        // Parameters
        variables: {
          id: component,
        },
      } ).then( () => {
        this.$alerts.success( 'Success!', 'Component deleted' );
        this.changed();
      } );
    },

    pageViewToggle( v ) {
      this.$session.set( 'report.page.view', v );
      this.pageView = v;
    },
    componentViewToggle( v ) {
      this.$session.set( 'report.component.view', v );
      this.componentView = v;
    },

    rowSplit( rows ) {
      const sections = [];
      let currSection;
      for( const row of rows ) {
        if( !currSection || ( currSection && currSection.title != row.template.section ) ) {
          if( currSection ) { sections.push( currSection ) }
          currSection = {
            title: row.template.section,
            open: false,
            rows: [],
          };
        }
        currSection.rows.push( row );
      }
      sections.push( currSection );

      return sections;
    },
    newSheet() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation addSpreadsheetPage($page: SpreadsheetPageInput!) {
            addSpreadsheetPage(page: $page) {
              _id
            }
          }
        `,
        variables: {
          page: {
            reportVersion: this.report.currentVersion._id,
            template: this.new_template,
            title: 'Sheet 1',
            number: 0,
          },
        },
      } ).then( () => {
        setTimeout( () => {
          this.$router.push( `/${config.reportRouterReplacement}s/spreadsheet/${this.report.identifier}/${this.report.currentVersion.version}` );
        }, 100 );
      } ).catch( () => {

      } );
    },
    dismissSheet() {
      this.showSheetNotice = false;
    },
    parseStatus( status ) {
      if( status ) {
        return status.replace( '-', ' ' );
      }

        return '';

    },
    parseColour( flag ) {
      switch( flag ) {
        case 'change-required': {
          return 'red';
        }
        case 'second-opinion': {
          return 'yellow';
        }
        case 'checked': {
          return 'green';
        }
        default: {
          return 'grey';
        }
      }
    },
    getLastEngagement( user ) {
      this.$apollo.query( {
        query: gql`
          query LastEngagement($user: ObjectID!, $report: ObjectID!) {
            LastEngagement(user: $user, report: $report) {
              timestamp
            }
          }
        `,
        variables: {
          user,
          report: this.report._id,
        },
      } ).then( res => {
        if( res.data.LastEngagement ) {
          this.lastEngagement[user] = res.data.LastEngagement.timestamp;
        } else {
          this.lastEngagement[user] = false;
        }
      } );
    },
    retireJourney( id ) {
      this.$confirm.simple().then( result => {
          if( result ) {
            this.$apollo.mutate( {
              mutation: gql`
                mutation retireUserJourney($id: ObjectID!) {
                  retireUserJourney(id: $id) {
                    _id
                  }
                }
              `,
              variables: {
                id,
              },
            } ).then( () => {
              this.$apollo.queries.areport.refetch();
            } ).catch( () => {
              //TODO error
            } );
          }
        } );
    },
    moment,
  },
  computed: {
    ...mapState( [ 'user' ] ),
    issuesMarkedAsKeyIssues() {
      const ret = [];
      for ( const issue of this.openIssues ) {
        if( issue.isAKeyIssueInstance ) {
          ret.push( issue );
        }
      }

      return ret;
    },
    productUrlParsed() {
      if( this.report.productUrl ) {
        let url = `${this.report.productUrl}`;
        url = url.toLowerCase();

        if ( url.indexOf( 'https://' ) >= 0 || url.indexOf( 'http://' ) >= 0 ) {
          return url;
        }

        return `https://${url}`;
      }

      return '';
    },
    statementUrlParsed() {
      if( this.report.productStatementUrl ) {
        let url = `${this.report.productStatementUrl}`;
        url = url.toLowerCase();

        if ( url.indexOf( 'https://' ) >= 0 || url.indexOf( 'http://' ) >= 0 ) {
          return url;
        }

        return `https://${url}`;
      }

      return '';
    },
    riskString() {
      let ret = 'Unknown';
      switch( this.report.productRisk ) {
        case '0': {
          ret = 'low';

          break;
        }
        case '1': {
          ret = 'medium';

          break;
        }
        case '2': {
          ret = 'high';

          break;
        }
      }

      return this.$gettext( ret );
    },
    disproportionateBurdenString() {
      let ret = 'Unknown';
      switch( this.report.disproportionateBurden ) {
        case '0': {
          ret = 'none';

          break;
        }
        case '1': {
          ret = 'being considered';

          break;
        }
        case '2': {
          ret = 'made';

          break;
        }
        case '3': {
          ret = 'expired';

          break;
        }
      }

      return this.$gettext( ret );
    },
    accessibilityStatementString() {
      let ret = 'Unknown';
      switch( this.report.accessibilityStatement ) {
        case '0': {
          ret = 'Unknown';

          break;
        }
        case '1': {
          ret = 'partially - mostly unmet';

          break;
        }
        case '2': {
          ret = 'partally - mostly met';

          break;
        }
        case '3': {
          ret = 'met';

          break;
        }
        case '4': {
          ret = 'exceeded';

          break;
        }
      }

      return `${this.$gettext( 'compliance' )} ${this.$gettext( ret )}`;
    },
    teamId() {
      if( this.report.portfolio && this.report.portfolio.teams[0] ) {
        return this.report.portfolio.teams[0]._id;
      } else if( this.report.team ) {
        return this.report.team._id;
      }

      return false;

    },
    people() {
      const ret = [];
      ret.push( [ this.report.owner.name, this.report.owner._id , this.report.owner.permissionGroup?.name ] );

      for( const person of this.report.collaborators ) {
        ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
      }

      if( this.report.team ) {
        for( const person of this.report.team.managers ) {
          if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
            ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
          }
        }

        for( const person of this.report.team.users ) {
          if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
            ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
          }
        }

        for( const person of this.report.team.contacts ) {
          if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
            ret.push( [ `${person.name} (${this.$gettext( 'Contact' )})`, person._id, person.permissionGroup?.name ] );
          }
        }
      } else if( this.report.portfolio ) {
        for( const team of this.report.portfolio.teams ) {
          for( const person of team.managers ) {
            if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
              ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
            }
          }

          for( const person of team.users ) {
            if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
              ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
            }
          }

          for( const person of team.contacts ) {
            if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
              ret.push( [ `${person.name} (${this.$gettext( 'Contact' )})`, person._id, person.permissionGroup?.name ] );
            }
          }
        }
      }

      for( const p of ret ) {
        this.getLastEngagement( p[1] );
      }

      return ret;
    },
    isManager() {
      return this.report.team.managers.map( user => user._id ).indexOf( this.user.id ) >= 0;
    },
    openIssuesToShow() {
      let ret = this.openIssues;

      if( this.filters.status.length ) {
        ret = ret.filter( i => {
          return this.filters.status.indexOf( i.status ) >= 0;
        } );
      }

      if( this.filters.flag.length ) {
        ret = ret.filter( i => {
          return this.filters.flag.indexOf( i.flag ) >= 0;
        } );
      }

      if( this.filters.severity.length ) {
        ret = ret.filter( i => {
          return this.filters.severity.indexOf( i.template.severity.toString() ) >= 0;
        } );
      }

      if( this.filters.priority.length ) {
        ret = ret.filter( i => {
          return this.filters.priority.indexOf( i.priority.toString() ) >= 0;
        } );
      }

      if( this.filters.assignee.length ) {
        ret = ret.filter( i => {
          return i.assignee && this.filters.assignee.indexOf( i.assignee._id ) >= 0;
        } );
      }

      if( this.filters.reporter.length ) {
        ret = ret.filter( i => {
          return this.filters.reporter.indexOf( i.reporter._id ) >= 0;
        } );
      }

      return ret;
    },
    openIssues() {
      const ret = [];
      if( this.sections ) {
        for( const section of this.sections ) {
          for( const subsection of section.subsections ) {
            for( const template of subsection.templates ) {
              for( const instance of template.instances ) {
                if( instance.primary && ( instance.status.indexOf( 'closed' ) == -1 ) ) {
                  ret.push( {
                    ...instance,
                    section: `${section.title} > ${subsection.title}`,
                  } );
                }
              }
            }
          }
        }
      }

      return ret;
    },
    closedIssues() {
      const ret = [];
      if( this.sections ) {
        for( const section of this.sections ) {
          for( const subsection of section.subsections ) {
            for( const template of subsection.templates ) {
              for( const instance of template.instances ) {
                if( instance.primary && ( instance.status.indexOf( 'closed' ) >= 0 ) ) {
                  ret.push( {
                    ...instance,
                    section: `${section.title} > ${subsection.title}`,
                  } );
                }
              }
            }
          }
        }
      }

      return ret;
    },
    reporters() {
      const res = {};
      for( const issue of this.openIssues ) {
        if( issue.reporter && !Object.prototype.hasOwnProperty.call( res, issue.reporter._id ) ) {
          res[issue.reporter._id] = issue.reporter.name;
        }
      }
      for( const issue of this.closedIssues ) {
        if( issue.reporter && !Object.prototype.hasOwnProperty.call( res, issue.reporter._id ) ) {
          res[issue.reporter._id] = issue.reporter.name;
        }
      }

      return res;
    },
    teamMembers() {
      const res = {};
      for( const person of this.report.team.users ) {
        res[person._id] = person.name;
      }
      //add all assignees of issue incase of changed team
      for( const issue of this.openIssues ) {
        if( issue.assignee && !Object.prototype.hasOwnProperty.call( res, issue.assignee._id ) ) {
          res[issue.assignee._id] = issue.assignee.name;
        }
      }

      return res;
    },
    pageComplianceList() {
      const out = [];
      for( const page of this.report.pages ) {
        let pageCompliance = 'Pass';
        let componentCompliance = 'Pass';
        for( const issue of page.issues ) {
          if( issue.template.severity != '0' ) {
            pageCompliance = 'Fail';
          } else if( pageCompliance == 'Pass' ) {
            pageCompliance = 'Pass with advisories';
          }
        }
        for( const component of page.components ) {
          for( const issue of component.issues ) {
            if( issue.template.severity != '0' ) {
              componentCompliance = 'Fail';
            } else if( componentCompliance == 'Pass' ) {
              componentCompliance = 'Pass with advisories';
            }
          }
        }
        out.push( {
          name: page.name,
          title: page.title,
          url: `${page.host}${page.path}`,
          compliance: pageCompliance,
          componentCompliance,
        } );
      }

      return out;
    },
  },
  components: {
    EditPageModal,
    EditComponentModal,

    PageModal,

    EditRenderDetailsModal,

    IssueInstanceModal,

    AriaListBox,

    FilterBar: UIFilterBar,
    FilterOpt: UIFilterOpt,
    // FilterSort: UIFilterSort,

    FormTextArea,

    Comments,

    UserJourneyModal,
    EditUserJourneyModal,

    PageComplianceModal,

    ProgressBar,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';
@import '@/assets/styles/variables/_mixins';

.Report_Body_SheetNotice_Actions {
  > * {
    @include gap-polyfill(0.5rem);
    margin-top: 1rem;
  }
}

.Report_Body_All_Exec_Form_Actions {
  > * {
    @include gap-polyfill(0.5rem);
    margin-top: 1rem;
  }
}

._flex {
  flex: 1;
  display: flex;
}

._flex-2 {
  flex: 2;
  display: flex;
}

._flex-3 {
  flex: 3;
  display: flex;
}

._flex-col {
  flex-direction: column;
}

._flex-grow {
  flex-grow: 1;
}

._flex-grow-2 {
  flex-grow: 2;
}

._flex-grow-3 {
  flex-grow: 3;
}

.Report {
  .userJourney {
    border: 1px solid $hugr-colours-grey;
    border-radius: 5px;
    margin: 0.5rem 0;
    padding: 0.75rem;
  }
  &_Welcome {
    margin-bottom: 25px !important;
    &_Video {
      display: inline-block;
      vertical-align: top;
      // width: 50%;
    }
    &_Content {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      padding: 0 20px;
      padding-left: 50px;
      box-sizing: border-box;
      h3 {
        margin-top: 0;
      }
    }
  }

  &_Body {
    &_IssueCountH {
      margin: 0;
      font-size: 10pt;
      position: relative;
      top: -40px;
    }

    &_All {

      &_Actions {
        &._pinned {
          position: fixed;
          top: 65px;
          background: linear-gradient(180deg, white 60%, transparent);
          padding: 40px 0 60px;
          z-index: 999;
          width: calc(100% - 250px - 150px);
        }
      }

      &_Filters {
        position: relative;
        top: -51px;
      }

      &_Details {
        display: flex;
        margin-top: 50px;
        &_Exec {
          flex: 1;
          position: relative;
          padding-right: 20px;
          &_Buttons {
            position: absolute;
            right: 20px;
            top: 20px;
            &_EditBtn {

            }
          }
        }
        &_Desc {
          flex: 1;
          padding-right: 20px;
          &_Opts {
            position: absolute;
            margin-top: -48px;
            right: 50px;
          }
        }
        &_People {
          flex: 1;
          border-left: 1px solid $hugr-colours-grey;
          padding: 0 20px;
          ul {
            padding: 0;
            list-style: none;
            max-width: 250px;
            li {
              margin-bottom: 5px;
              a {
                display: inline-block;
                max-width: 250px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 0.9em;
              }
            }
          }

          &_Person {
            &_Name {

            }
            &_Details {
              font-size: 0.8em;
              display: block;
              margin-top: -6px;
              &_Role {
              }
              &_LastEngagement {

              }
            }
          }
        }
        &_More {
          flex: 1;
          padding-right: 20px;

          &_Item {
            margin-bottom: 10px;

            &._inline {
              dd, dt {
                display: inline;
                margin: 0;
              }
              dd {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    &_SheetNotice {
      margin-bottom: 20px;
      &_Actions {
        margin-top: 20px;
      }
    }

    &_Simplified {
      &_Accordion {
        &_Item {
          &_SubList {
            margin-top: 15px !important;
            border-top: 1px solid $hugr-colours-grey;
          }
        }
      }
    }
  }
}

._darkMode .Report {
  &_Body {
    &_All {
      &_Actions {
        &._pinned {
          background: linear-gradient(180deg, $hugr-colours-primary 60%, transparent);
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .Report {
    &_Welcome {
      &_Video {

      }
      &_Content {
        width: 100%;
        padding: 0 5px;
      }
    }
  }
}
  // #settings {
  //   float: right;
  //   position: relative;
  //   top: -54px;
  // }
  //
  // .componentpages {
  //   height: 115px;
  //   overflow: hidden;
  //   overflow-y: auto;
  // }
  //
  // #report-metrics {
  //   .metric {
  //     display: inline-block;
  //     vertical-align: top;
  //     margin-right: 100px;
  //   }
  // }
  //
  // .cardLink {
  //   max-width: 100%;
  //   white-space: nowrap;
  //   display: inline-block;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }
  //
  // .tableLink {
  //   max-width: 600px;
  //   white-space: nowrap;
  //   display: inline-block;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }
</style>
