<template>
  <Modal
    ref="modal"
    :title="$gettext('Edit contact')"
    
    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submitMutation"

    @close="reset()"
    :ready="contact">
    <FormInput ref="name" idRoot="contact_" :label="$gettext('Name')" v-model="contact.name" :validation="['not-empty']"/>
    <FormInput ref="email" idRoot="contact_" :label="$gettext('Email')" v-model="contact.email" :validation="['not-empty', 'email']"/>
    <FormInput idRoot="contact_" :label="$gettext('Phone number')" v-model="contact.phoneNumber" />
    <!-- <FormSelect idRoot="contact_" :label="$gettext('Role')" :value="contact.role" v-model="contact.role" v-bind:options="{
        'manager': $gettext('Manager'),
        'owner': $gettext('Owner'),
        'admin': $gettext('Admin')
      }" /> -->
    <FormSelect v-if="hasPermission('SetUserPermissionGroup', 'Any') || ( hasPermission('SetUserPermissionGroup', 'WithinGroupCategories') && Object.keys(niceList).length > 0 )" :key="'permission-key'" idRoot="permissiongroup_" :label="$gettext('Permission Group')" v-model="selectedGroup" :options="niceList" hintText="This will become their permission group if an account is made using this email."/>
    <FormTextArea idRoot="contact_" :label="$gettext('Public Notes')" v-model="contact.notes" />
  </Modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import gql from 'graphql-tag';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import FormSelect from '@/components/Form/Select';

export default {
  name: 'EditContactModal',
  data() {
    return {
      contact: null,
      permissionGroups: false,
      selectedGroup: false,
      submitting: false,
    };
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    permissionGroups: {
      query: gql`
        query permissionGroups {
          permissionGroups: PermissionGroups {
            _id,
            name,
            categories {
              category {
                _id
              },
              hierarchy
            }
          }
        }
      `,
    },
  },
  methods: {
    submitMutation() {
    this.$hugrvalidate( [ 'name', 'email' ], this ).then( ( { success } ) => {
      if( success ) {
        this.submitting = true;
        let contactInput = false;

        contactInput = {
            name: this.contact.name,
            email: this.contact.email,
            phoneNumber: this.contact.phoneNumber,
            notes: this.contact.notes,
            role: this.contact.role,
        };

        if( this.selectedGroup ) {
          contactInput = {
            ...contactInput,
            permissionGroup: this.selectedGroup,
          };
        }

        this.$apollo.mutate( {
          mutation: gql`
            mutation ($id: ObjectID!, $contact: ContactInput!) {
              updateContact(id: $id, contact: $contact) {
                _id
              }
            }
          `,
          variables: {
            id: this.contact._id,
            contact: contactInput,
          },
        } ).then( res => {
          this.submitting = false;
          if ( res.data.updateContact ) {
            this.reset();
            this.$alerts.success( 'Contact edited', `Contact has been edited.` );
            this.$emit( 'success' );
          }
        } ).catch( error => {
          this.submitting = false;
          this.$alerts.coded( 'E053', 'F3001', '', error ); //see notifications spreadsheet
        } );
      }
    } );
    },
    show( contact ) {
      let id = contact;

      if( typeof contact == 'object' ) {
        id = contact._id;
      }

      this.$apollo.query( {
        query: gql`
          query User($id: ObjectID!) {
            user: User(id: $id) {
              _id,
              name,
              email,
              phoneNumber
              archived
              notes
              permissionGroup {
                _id,
                name
              }
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.contact = { ...res.data.user };
        if( this.contact.permissionGroup ) {
          this.selectedGroup = this.contact.permissionGroup._id;
        }
        setTimeout( () => {
          this.$refs.modal.show();
        }, 100 );
      } ).catch( error => {
        this.$alerts.coded( 'E018', 'F3902', '', error ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.email = '';

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission' ] ),
    niceList() {
      const list = {};
      this.permissionGroups.forEach( group => {
        let hierarchyMatch = false;
        group.categories.forEach( pairTwo => {
          this.user.permissionGroup?.categories.forEach( pair => {
            if ( ( pair.category._id == pairTwo.category._id && pair.hierarchy > pairTwo.hierarchy ) ) {
              hierarchyMatch = true;
            }
        } );
      } );

      if ( this.hasPermission( 'SetUserPermissionGroup', 'Any' ) || ( this.hasPermission( 'SetUserPermissionGroup', 'WithinGroupCategories' ) && hierarchyMatch ) ) {
        list[group._id] = group.name;
      }

      } );
      
      return list;
    },
    chosenGroup() {
      return this.permissionGroups.filter( group => {
        return ( ( group._id == this.selectedGroup ) );
      } )[0];
    },
  },
  components: {
    FormInput,
    FormTextArea,
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
