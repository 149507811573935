<template>
  <div class="AccountSettings_AboutMe">
    <Avatar :uid="user.id" class="AccountSettings_AboutMe_Avatar" size="giant"/>
    <h3 class="AccountSettings_AboutMe_Welcome">Welcome, {{ myName }}</h3>
  </div>
  <div class="__grid">
      <Well title="Update your info" class="QuickLinks __grid_column_6">
        <p>Update your name or email</p>
        <LinkButton type="secondary" size="small" to="/account/personal-info">Manage your personal info</LinkButton>
      </Well>
      <Well title="Security recommendations" class="__grid_column_6">
        <p>Make sure your account is secure and up to date</p>
        <LinkButton type="secondary" size="small" to="/account/security">Manage your security</LinkButton>
      </Well>
    </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  import Avatar from '@/components/Helpers/Avatar.vue';

  const store = useStore();
  const user = computed( () => store.getters.user );

  const myName = computed( () => user.value.displayname || user.value.name );
</script>

<style lang="scss" scoped>

  .AccountSettings {
    &_AboutMe {
      &_Welcome {
        font-size: 2em;
      }
    }
  }
</style>
