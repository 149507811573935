import Confirmation from './components/Confirmation';
import { reactive } from 'vue';

import md5 from 'md5';

const confPlugin = {
  install( app ) {
    app.component( 'hugr-confirm', Confirmation );

    const conf = reactive( {
      fire: false,
      title: 'Are you sure?',
      message: '',
      actioned: false,
      result: false,
      inputMessage: '',
      input: false,
      password: false,
    } );
    // eslint-disable-next-line no-param-reassign -- Legacy, TODO: remove
    app.config.globalProperties.$fireConf = conf;

    const doConfirm = () => {
      setTimeout( () => {
        document.getElementById( 'conf' ).focus();
      }, 100 );

      return new Promise( resolve => {
        ( function pollConfirm() {
          if( conf.actioned ) {
            return resolve( conf.result );
          }
          setTimeout( pollConfirm, 100 );
        }() );
      } );
    };

    const doTextualConfirm = () => {
      return new Promise( resolve => {
        ( function pollConfirm() {
          if ( conf.actioned ) {
            return resolve( [ conf.result, conf.inputMessage ] );
          }
          setTimeout( pollConfirm, 100 );
        }() );
      } );
    };

    const doPasswordConfirm = () => {
      return new Promise( resolve => {
        ( function pollConfirm() {
          if ( conf.actioned ) {
            return resolve( [ conf.result, md5( `${conf.inputMessage.replace( '&nbsp;', '' ).trim()}:alienFORGERY` ) ] );
          }
          setTimeout( pollConfirm, 100 );
        }() );
      } );
    };

    const confirm = {
      simple( msg, positive = 'Yes', negative = 'No' ) {
        conf.password = false;
        conf.input = false;
        conf.fire = true;
        conf.title = 'Are you sure?';
        conf.message = msg;
        conf.actioned = false;
        conf.result = false;
        conf.positive = positive;
        conf.negative = negative;

        return doConfirm();
      },

      input( title = 'Are you sure?', prefilledMessage = '', positive = 'Yes', negative = 'No' ) {
        conf.password = false;
        conf.input = true;
        conf.fire = true;
        conf.title = title;
        conf.actioned = false;
        conf.result = false;
        conf.positive = positive;
        conf.negative = negative;
        conf.message = '';
        conf.inputMessage = prefilledMessage;

        return doTextualConfirm();
      },

      make( title, msg, positive = 'Yes', negative = 'No' ) {
        conf.password = false;
        conf.input = false;
        conf.fire = true;
        conf.title = title;
        conf.message = msg;
        conf.actioned = false;
        conf.result = false;
        conf.positive = positive;
        conf.negative = negative;

        return doConfirm();
      },

      password( title = 'We need to make sure', message = 'Please confirm your password', positive = 'Confirm', negative = 'Cancel' ) {
        conf.password = true;
        conf.input = false;
        conf.fire = true;
        conf.title = title;
        conf.message = message;
        conf.actioned = false;
        conf.result = false;
        conf.positive = positive;
        conf.negative = negative;
        conf.inputMessage = '';

        return doPasswordConfirm();
      },

      yes() {
        conf.actioned = true;
        conf.result = true;
      },
      no() {
        conf.actioned = true;
        conf.result = false;
      },
    };

    // eslint-disable-next-line no-param-reassign -- Legacy, TODO: remove
    app.config.globalProperties.$confirm = confirm;
    app.provide( 'confirm', confirm );

  },
};

export default confPlugin;
