<template>
    <li role="menuitem" tabindex="-1"
      ref="item"
      @click.prevent="sendActivation"
      @keyup.enter="sendActivation"
      @keyup.space="sendActivation"
      @keyup.esc.prevent="$parent.close()"
      @keydown.up="goUp"
      @keydown.down="goDown"
      @keydown.tab.prevent="goDown">
      <slot></slot>
    </li>
</template>

<script>
export default {
  name: 'UIContextItem',
  inject: [ 'signal' ],
  methods: {
    sendActivation( e ) {
      this.$emit( 'activate', e, this.signal.value );
      this.$parent.close();
    },
    goUp( e ) {
      const next = this.$refs.item.previousElementSibling;
      if( next ) {
        next.focus();
      } else {
        const last = this.$refs.item.parentElement.children.length - 1;
        this.$refs.item.parentElement.children[last].focus();
      }
    },
    goDown( e ) {
      const next = this.$refs.item.nextElementSibling;
      if( next ) {
        next.focus();
      } else {
        this.$refs.item.parentElement.children[0].focus();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

  li {
    display: block;
    padding: 5px 25px;
    font-size: 9pt;
    border-radius: 5px;
    &:focus, &:hover {
      outline: none;
      background: #e5ebf2;
      cursor: default;
    }
  }

  ._darkMode li {
    &:focus, &:hover {
      background: darken($hugr-colours-primary, 10%);
    }
  }
</style>
