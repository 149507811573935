<template>
  <div id="conf-cont" v-if="conf.fire&&!conf.actioned">
    <div id="conf" tabindex="-1" ref="confbox">
      <h1>{{ conf.title }}</h1>
      <p v-if="conf.message">{{ conf.message }}</p>
      <div v-if="conf.input">
        <Box ref="boxedit" v-model="comment" :nosend="true"></Box>
      </div>
      <div v-else-if="conf.password">
        <FormInput label="password" type="password" idRoot="password" v-model="conf.inputMessage" />
      </div>
      <div class="actions">
        <Button @click="no">{{ conf.negative }}</Button>
        <Button v-if="!conf.input || conf.inputMessage != comment" type="secondary" @click="yes">{{ conf.positive }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Box from '@/components/Comments/Box.vue';
import FormInput from '@/components/Form/Input.vue';

export default {
  name: 'ConfirmationPlugin',
  mounted() {
    document.addEventListener( 'keyup', this.tab );
  },
  beforeUnmount() {
    document.removeEventListener( 'keyup', this.tab );
  },
  watch: {
    conf: {
      handler() {
        this.comment = `${this.$fireConf.inputMessage}&nbsp;`;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      conf: this.$fireConf,
      comment: this.$fireConf.inputMessage,
    };
  },
  methods: {
    yes() {
      this.conf.inputMessage = this.comment;
      this.$confirm.yes();
    },
    no() {
      this.$confirm.no();
    },
    tab( e ) { //IT'S A TARP
      if( e.keyCode == 9 && this.$refs.confbox ) {
        if( !this.$refs.confbox.querySelectorAll( ":focus" ).length && document.activeElement != this.$refs.confbox ) {
          this.$refs.confbox.focus();
        }
      }
    },
  },
  components: {
    Box,
    FormInput,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

#conf-cont {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100vh;
  background: rgba(38, 46, 55, 0.5);
  #conf {
    z-index: 9999999;
    min-width: 500px;
    width: 50%;
    max-height: calc(100vh - 150px);
    margin: auto;
    background: white;
    overflow-y: scroll;
    overflow-y: overlay;
    border: 1px solid #c6cdd7;
    border-radius: 3px;

    position: absolute;
    transform: translateY(-50%) translateX(-50%);
    top: 50%;
    left: 50%;

    padding: 20px;

    .actions {
      text-align: right;
      button {
        margin-left: 16px;
      }
    }
  }
}

._darkMode #conf-cont {
  #conf {
    background: $hugr-colours-primary;

  }
}
</style>
