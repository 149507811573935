<template>
  <Modal
    ref="modal"
    :title="$gettext('New Spreadsheet Template')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding template..."

    :submitText="$gettext('Add template')"
    @submit="submitTemplate">
    <FormInput idRoot="template_" :label="$gettext('Name')" v-model="name" />
    <FormInput idRoot="template_" :label="$gettext('Description')" v-model="description" />
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';

export default {
  name: 'SpreadsheetTemplateForm',
  data() {
    return {
      name: '',
      description: '',
      submitting: false,
    };
  },
  methods: {
    submitTemplate() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation addSpreadsheetTemplate($name: String!, $description: String) {
            ss: addSpreadsheetTemplate(name: $name, description: $description) {
              _id
            }
          }
        `,
        variables: {
          name: this.name,
          description: this.description,
        },
      } ).then( res => {
        this.submitting = false;
        this.$emit( 'success', res.data.ss._id );
        this.name = '';
        this.description = '';
        this.$alerts.success( 'Spreadsheet template added' );
      } ).catch( error => {
        this.submitting = false;
        this.$alerts.coded( 'E040', 'F2101', '', error ); //see notifications spreadsheet
      } );
    },
    show( ) {
      this.$refs.modal.show();
    },
    reset() {
      this.name = '';
      this.description = '';

      this.$refs.modal.reset();
    },
  },
  components: {
    FormInput,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
