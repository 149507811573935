import * as types from './mutation-types';

export default {
  [types.SET_USER]( state, payload ) {
    state.user = payload;
  },
  [types.SET_DISPLAYNAME]( state, payload ) {
    state.user.displayname = payload;
  },
  [types.SET_SETTINGS]( state, payload ) {
    state.user.settings = payload;
  },
  [types.SET_REPORT]( state, payload ) {
    state.report = payload;
  },
  [types.SET_FIRST_USE]( state, payload ) {
    state.firstUse = payload;
  },
};
