<template>
  <span :class="['FormInput', hasError?'_hasError':'']">
    <label :class="['FormInput_Label', !this.labelVisible?'a11yhide':'']" :for="id">
      <span class="FormInput_Label_Main">{{ label }}</span>
      <span v-if="required" class="FormInput_Label_Required">Required</span>
    </label>
    <input  :id="id"
            :value="modelValue"
            :type="type"
            :autocomplete="autocomplete"
            :placeholder="placeholder"
            ref="mainInput"
            @input="$emit('update:modelValue', $event.target.value)"
            @keyup="$emit('keyup', $event)"
            @blur="$emit('blur', $event)"
            :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : ''].join(' ')"
            :disabled="disabled"
            tabindex="0"
            :class="['FormInput_Input', type=='number'?'_number':'', hasError?'_hasError':'']"
          />
    <DescribedBy v-if="hintText" :id="id+'-hint'" type="hint">{{ hintText }}</DescribedBy>
    <DescribedBy v-if="hasError" :id="id+'-error'" type="error">{{ errorMessage }}</DescribedBy>
  </span>
</template>

<script>
import mixin from './Mixin';
import DescribedBy from './DescribedBy.vue';

export default {
  mixins: [ mixin ],
  name: 'FormInput',
  data() {
    return {
      uid: this.$uuid.generate(),
    };
  },
  methods: {
    focus() {
      this.$refs['mainInput'].focus();
    },
    setValue( v ) {
      this.$refs.mainInput.value = v;
      this.$emit( 'update:modelValue', v );
    },
  },
  computed: {
    id() {
      return `${this.idRoot + this.uid}-${this.label.replace( / /g, '' ).toLowerCase().trim()}`;
    },
    required() {
      return ( this.validation && this.validation.indexOf( 'not-empty' ) >= 0 );
    },
  },
  components: {
    DescribedBy,
  },
  props: {
    modelValue: {
      type: String,
    },
    idRoot: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    autocomplete: {
      type: String,
      required: false,
      default: "on",
    },
    labelVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    hintText: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .FormInput {
    display: block;
    margin-bottom: 16px;

    &_Label {
      display: block;
      position: relative;
      width: 100%;
      font-size: 0.9em;
      padding-bottom: 4px;
      &_Main {
        font-weight: bold;
      }
      &_Required {
        position: absolute;
        right: 0;
      }
    }

    &_Input {
      padding: 10px;
      background: $hugr-colours-input-surface;
      border: none;
      border-bottom: 3px solid $hugr-colours-input-border;
      width: calc(100% - 20px);
      &._number {
        width: 42px;
      }

      &._hasError {
        border-bottom: 3px solid $hugr-colours-red;
      }
    }
  }

  ._darkMode .FormInput {
    &_Label {
      color: $hugr-colours-grey;
    }
    &_Input {
      color: $hugr-colours-grey;
      background: $hugr-colours-dark-input-surface;
      border-bottom: 3px solid $hugr-colours-dark-input-border;
      &:disabled {
        background: darken($hugr-colours-primary, 10%);
        color: lighten($hugr-colours-grey, 10%);
      }

      &._hasError {
        border-bottom: 3px solid lighten( $hugr-colours-red, 20% );
      }
    }
  }
</style>
