<template>
  <Body title="Import Users" class="Import">
    <DataTable :headers="[
              $gettext('Num'),
              $gettext('Identifier'),
              $gettext('Name'),
              $gettext('Customer'),
              $gettext('Ignore'),
              ''
            ]">
      <tr v-for="(team, i) in parsedData" :key="i">
        <td>{{ i+1 }}</td>
        <td>
          {{ team.identifier }}
          <span v-if="team.duplicate || team.exists">
            &nbsp;(
            <span v-if="team.duplicate">duplicate</span>&nbsp;
            <span v-if="team.exists">Already exists</span>
            )
          </span>
        </td>
        <td>{{ team.name }}</td>
        <td>
          {{ team.customer }}
          (<span v-if="team.newCustomer">Creating new</span>
          <span v-else>Using old</span>)

          <span v-if="team.customerMade">Created</span>
          <span v-else-if="team.customerSkipped">Skipped</span>
        </td>
        <td><input aria-label="Ignore" type="checkbox" v-model="team.ignore"/></td>
        <td>
          <span v-if="team.made">Created</span>
        </td>
      </tr>
    </DataTable>
    <Button @click="importData()">Import</Button>
    <div class="Import_Settings">
      <div>
        <label for="order">Order</label>&nbsp;
        <input v-model="order" id="order" />
        &nbsp;&nbsp;
        <label for="splitter">Splitter</label>&nbsp;
        <select id="splitter" v-model="splitter">
          <option value="0">Comma</option>
          <option value="1">Tab</option>
        </select>
      </div>
      <br />
      <div>
        <label for="csv">Data</label>
        <textarea id="csv" v-model="csv"></textarea>
      </div>
      <Button @click="parse()">Parse</Button>
    </div>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

export default {
  name: "ImportTeams",
  data() {
    return {
      csv: '',
      splitter: 0,
      splitters: [ ",", "\n" ],
      order: "identifier, name, customer",

      parsedData: false,
    };
  },
  methods: {
    parse() {
      const lines = this.csv.split( "\n" );
      const ret = [];

      for( const line of lines ) {
        if( line.trim() == "" ) continue;

        const components = line.split( this.splitters[this.splitter] );
        const newLine = {};
        for( const i in this.parsedOrder ) {
          if( components.hasOwnProperty( i ) ) {
            newLine[this.parsedOrder[i]] = components[i].trim();
          }
        }
        ret.push( newLine );
      }

      const done = [];

      for( const i in ret ) {
        if( ret.hasOwnProperty( i ) ) {
          const item = ret[i];
          if( done.indexOf( item.identifier ) >= 0 ) {
            item.duplicate = true;
            item.ignore = true;
          } else {
            item.duplicate = false;
            item.ignore = false;
          }
          done.push( item.identifier );

          this.checkCustomer( item, i );
          this.checkTeam( item, i );
        }
      }

      this.parsedData = ret;
    },
    checkCustomer( team, i ) {
      if( team.customer ) {
        this.$apollo.query( {
          query: gql`
            query CustomerFromName($name: String!) {
              customer: CustomerFromName(name: $name) {
                _id,
                name
              }
            }
          `,
          variables: {
            name: team.customer,
          },
        } ).then( res => {
          if( res.data.customer ) {
            this.parsedData[i].customerId = res.data.customer._id;
            this.parsedData[i].newCustomer = false;
          } else {
            this.parsedData[i].newCustomer = true;
          }
        } ).catch( err => {
          this.$catchError( err );
          //TODO error
        } );
      }
    },
    checkTeam( team, i ) {
      if( team.identifier ) {
        this.$apollo.query( {
          query: gql`
            query Team($identifier: String!) {
              team: Team(identifier: $identifier) {
                _id,
                name
              }
            }
          `,
          variables: {
            identifier: team.identifier,
          },
        } ).then( res => {
          if( res.data.team ) {
            this.parsedData[i].exists = true;
            this.parsedData[i].ignore = true;
          } else {
            this.parsedData[i].exists = false;
          }
        } ).catch( () => {
          //TODO error
        } );
      }
    },
    importData() {
      this.createCustomers();
      this.importTeams();
    },
    createCustomers() {
      const customers = {};
      for( const team of this.parsedData ) {
        if( team.customerId ) {
          team.customerSkipped = true;
        } else if( customers.hasOwnProperty( team.customer ) ) {
          team.customerId = customers[team.customer];
          team.customerSkipped = true;
        } else {
          this.$apollo.mutate( {
            mutation: gql`
              mutation addCustomer($customer: CustomerInput!) {
                customer: addCustomer(customer: $customer) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              customer: {
                name: team.customer,
              },
            },
          } ).then( res => {
            customers[team.customer] = res.data.customer._id;
            team.customerId = res.data.customer._id;
            team.customerMade = true;
          } ).catch( () => {
            //TODO
          } );
        }
      }
    },
    importTeams() {
      if( this.parsedData.map( t => !!t.customerId ).indexOf( false ) >= 0 ) {
        setTimeout( () => {
          this.importTeams();
        }, 100 );
      } else {
        for( const team of this.parsedData ) {
          if( !team.ignore ) {
            const teamInput = {
              identifier: team.identifier,
              name: team.name,
              users: [],
              managers: [],
              customer: team.customerId,
              contact: this.user.id,
              activeIndefinitely: true,
            };
            this.$apollo.mutate( {
              mutation: gql`
                mutation addTeam($team: TeamInput!) {
                  team: addTeam(team: $team) {
                    _id,
                    name
                  }
                }
              `,
              variables: {
                team: teamInput,
              },
            } ).then( res => {
              team.made = true;
            } ).catch( err => {
              this.$catchError( err );
              //TODO
            } );
          }
        }
      }
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    parsedOrder() {
      const ret = [];
      for( const i of this.order.split( ',' ) ) {
        ret.push( i.trim() );
      }

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
  .Import {
    &_Settings {
      textarea {
        width: 100%;
        height: 200px;
      }
    }
  }
</style>
