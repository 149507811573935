<template>
  <div class="CopyBox">
    <span class="CopyBox_Inner __micro">{{  text }}</span>
    <Button class="CopyBox_Button" size="small" type="icon" :icon="['solid', 'copy']" @click="copy" title="Copy">Copy</Button>
  </div>
</template>

<script setup>
  import { toRefs, inject } from 'vue';

  const props = defineProps( {
    text: String,
    singleLine: Boolean,
  } );

  const alerts = inject( 'alerts' );

  const { text, singleLine } = toRefs( props );

  const copy = () => {
    navigator.clipboard.writeText( text.value );
    alerts.success( 'Copied to clipboard' );
  };
</script>

<style lang="scss" scoped>
  .CopyBox {
    background: #00000040;
    padding: 8px;
    overflow: hidden;
    &_Inner {
      overflow: hidden;
      width: 95%;
      display: inline-block;
    }
    &_Button {
      display: inline-block;
      position: absolute;
      top: 0px;
    }
  }
</style>
