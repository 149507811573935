/* eslint-disable no-empty */
<template>
  <Body :title="$gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } )" :trail="trail">
    <div class="Reports_Actions __grid">
      <Button
        class="__grid_column_4"
        type="secondary"
        :icon="['solid', 'plus']"
        @click="$refs.reportmodal.show()"
        v-show="!hasPermission( 'Restrictions','CanNotCreateProducts')"
        >{{ $gettext( 'New %{projectString}', { projectString: $hugrConfig.projectString } ) }}</Button
      >
      <Search
        :class="hasPermission( 'Restrictions','CanNotCreateProducts')?'__grid_column_12':'__grid_column_8'"
        :label="$gettext( 'Search all %{projectString}s', { projectString: $hugrConfig.projectString } )"
        v-model="search"
        :inUse="search!=''"
      />
    </div>
    <div class="HONotice" v-if="$hugrConfig.hoMissingProductBannerURL != ''">
      <p>Don't see a product that you think you should have access to? Let us know by filling out this <a :href="$hugrConfig.hoMissingProductBannerURL" target="_blank">form<span class="a11yhide">(opens in a new tab)</span></a>.</p>
    </div>
    <TabList
      :tabs="tabs"
      :key="`tablist`"
      v-if="search == ''"
      :lastIndex="tabs.map(t => t.method).indexOf(getMethod)"
    />
    <h3 v-else v-translate>Search results:</h3>

    <AriaListBox :class="['Reports_Sort', search!=''?'_search':'']" v-model="reportsOrder" idRoot="order_" label="Sort" :inline="true" :bold="true"
      :options="{
        'alphabetical': $gettext('Alphabetically'),
        'created-newest': $gettext('By created date (newest first)'),
        'created-oldest': $gettext('By created date (oldest first)')
      }"
    />

    <div v-if="filter" class="Reports_Filter">
      <span class="Reports_Filter_Label">Filter</span>

      <span class="Reports_Filter_Item">
        <span v-if="filter[0]=='customer'">Customer: {{ filter[2] }}</span>
        <span v-else-if="filter[0]=='team'">Team: {{ filter[2] }}</span>
        <span v-else-if="filter[0]=='portfolio'">Portfolio: {{ filter[2] }}</span>
        <span v-else-if="filter[0]=='owner'">Owner: {{ filter[2] }}</span>
        <span v-else>Unknown</span>
        <button aria-label="clear filter" title="clear filter" @click="clearFilter()"><Icon type="solid" icon="times"></Icon></button>
      </span>
    </div>

    <div id="ReportsPanel" class="Reports_Panel">
      <div v-if="reports?.reports?.length">
        <Loader v-if="$apollo.queries.reports.loading" />
        <DataTable v-else
          class="Reports_Panel_Table"
          :headers="headers"
          :noMinHeight="true"
        >
          <tr
            v-for="(report, key) in reports.reports"
            :key="`${report.identifier}-${key}`"
          >
            <td class="Reports_Panel_Table_Title">
              <router-link class="Reports_Link" :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.identifier}`">{{
                report.title
              }}</router-link>
            </td>
            <td class="Reports_Panel_Table_Identifier">
              <span>{{ report.identifier }}</span>
            </td>
            <td v-if="showInProgress" class="Reports_Panel_Table_Progress">
              <ProgressBar v-if="report.currentVersion" type="simple" id="reportprogress" label="Progress: " :value="report.currentVersion.progress" :hideLabel="true" />
              <span v-else>loading</span>
            </td>
            <td>
              <CompliancePill v-if="report.currentVersion" size="small" :progress="report.currentVersion.progress" :isCompliant="report.currentVersion.isCompliant" :issues="report.issueCount"/>
              <span v-else>loading</span>
            </td>
            <td>
              <span v-if="report.pages">{{ report.pages.length }}</span>
              <span v-else>loading</span>
            </td>
            <td v-if="!noTeam">
              <span v-if="report.customer"><a href="#" role="button" @click="setFilter( ['customer', report.customer._id, report.customer.name] )">{{ report.customer.name }}</a></span>
              <span v-else-if="lazyLoading">loading</span>
              <span v-else v-translate>No customer</span>
            </td>
            <td>
              <span v-if="report.team"><a href="#" role="button" @click="setFilter( ['team', report.team._id, report.team.name] )">{{ report.team.name }}</a></span>
              <span v-else-if="lazyLoading">loading</span>
              <span v-else v-translate>No team</span>
            </td>
            <td>
              <span v-if="report.currentVersion">{{
                report.currentVersion.version
              }}</span>
              <span v-else v-translate>None</span>
            </td>
            <td v-if="!publishDisabled">
              <template v-if="report.currentVersion">
                <DateTime
                  v-if="report.currentVersion.published"
                  :timestamp="report.currentVersion.publishedDate"
                  format="fromNow"
                />
                <span v-else v-translate>Not published</span>
              </template>
              <span v-else v-translate>N/A</span>
            </td>
            <td>
              <template v-if="report.archived">
                Archived
              </template>
              <span v-else v-translate>Not Archived</span>
            </td>
            <td>
              <template v-if="report.created">
                <DateTime :timestamp="report.created" format="fromNow"/>
              </template>
              <span v-else v-translate>Unknown</span>
            </td>
          </tr>
        </DataTable>
        <DataTablePaginator :key="`${getMethod}-${search}-${reportsPage}`" :currentPage="reportsPage" :pages="reports.pages" @select="(page) => { reportsPage = page } " />
        <DataTablePageSize :tableName="'Reports'" @selected="(p) => { reportsPageSize = p; reportsPage = 0; } " />
      </div>
      <Loader v-else-if="$apollo.queries.reports.loading" />
      <Empty
        v-else-if="!hasPermission( 'Restrictions','CanNotCreateProducts')"
        :text="$gettext( 'No %{projectString}s to show', { projectString: $hugrConfig.projectString } )"
        :button="{
          size: 'regular',
          type: 'secondary',
          icon: ['solid', 'folder-plus'],
          text: $gettext( 'Add a %{projectString}', { projectString: $hugrConfig.projectString } ),
        }"
        @buttonClick="$refs.reportmodal.show()"
      />
      <Empty
        v-else
        :text="$gettext( 'No %{projectString}s to show', { projectString: $hugrConfig.projectString } )"
      />
    </div>

    <ReportModal
      ref="reportmodal"
      @success="$apollo.queries.reports.refetch()"
      @reportidentifier="navigate"
    />
  </Body>
</template>

<script>
import gql from "graphql-tag";
import { mapState } from "vuex";
import ReportModal from "@/modals/Report/Create";
import UIDataTablePaginator from '@/components/UI/DataTablePaginator';
import UIDataTablePageSize from '@/components/UI/DataTablePageSize';
import AriaListBox from '@/components/Aria/ListBox';
import { mapGetters } from 'vuex';
import CompliancePill from "../../components/CompliancePill.vue";
import ProgressBar from "../../components/UI/ProgressBar.vue";

import config from '../../../config';

export default {
  name: "ReportsView",
  mounted() {
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            disablePublishAndShare
            portfolios
            showInProgress
          }
        }
      `,
    } ).then( res => {
      this.publishDisabled = res.data.settings.disablePublishAndShare;
      this.portfoliosEnabled = res.data.settings.portfolios;
      this.showInProgress = res.data.settings.showInProgress;
    } );

    this.$apollo.mutate( {
        mutation: gql`
          mutation ($page: String) {
            sendNavigation(page: $page)
          }
        `,
        variables: {
          page: 'Assessments',
        },
    } );
  },
  watch: {
    search() {
      this.reportsPage = 0;
      if( this.search != '' ) this.filter = null;
    },
    reportsOrder() {
      this.updateURL();
    },
    reports() {
      if( !this.reports || !this.reports.reports || this.reports.reports.length == 0 ) return;
      const reportsArray = this.reports.reports?.map( r => r._id );
      if( typeof reportsArray == 'undefined' ) return;

      this.lazyLoading = true;

      this.$apollo.query( {
        query: gql`
          query ReportsFromArray($reports: [ObjectID]) {
            reports: ReportsFromArray(reports: $reports) {
              _id
              issueCount
              archived
              customer {
                _id
                name
              }
              owner {
                _id
                name
                email
              }
              team {
                _id
                name
              }
              currentVersion {
                _id
                version
                published
                publishedDate
                isCompliant
                progress
              }
              pages {
                _id
              }
            }
          }
        `,
        variables: {
          reports: reportsArray,
        },
      } ).then( res => {
        this.lazyLoading = false;
        if( !this.reports && this.reports.reports ) return;
        for( const index in res.data.reports ) {
          if( !res.data.reports.hasOwnProperty( index ) ) continue;
          const report = res.data.reports[ index ];

          const pIndex = this.reports.reports.map( r => r._id ).indexOf( report._id );

          this.reports.reports[ pIndex ].issueCount = report.issueCount;
          this.reports.reports[ pIndex ].customer = report.customer;
          this.reports.reports[ pIndex ].owner = report.owner;
          this.reports.reports[ pIndex ].team = report.team;
          this.reports.reports[ pIndex ].pages = report.pages;
          this.reports.reports[ pIndex ].archived = report.archived;
          this.reports.reports[ pIndex ].currentVersion = report.currentVersion;
        }
      } ).catch( error => this.$alerts.generic( error ) );
    },
  },
  apollo: {
    reportsCount: {
      query: gql`
        query {
          reportsCount: ReportsCount {
            owned
            ownedNew
            all
            allNew
            collaborator
            collaboratorNew
            team
            teamNew
            portfolio
            portfolioNew
            archive
            archiveNew
          }
        }
      `,
    },
    reports: {
      query() {
        this.reports = []; //this ensures the loading screen is shown and it doesn't look broke
        const pageFragment = gql`
          fragment pageFragment on ReportPage {
            reports {
              _id
              identifier
              title
              created
            }
            hasMore
            pages
          }
        `;
        if( this.search != "" ) {
          return gql`
            query ReportsSearchPage($search: String!, $page: Int!, $size: Int!, $order: String) {
              reports: ReportsSearchPage(search: $search, page: $page, size: $size, order: $order) {
                ... pageFragment
              }
            }
            ${pageFragment}
          `;
        }

        return gql`
          query ReportsPage($method: String!, $archived: Boolean!, $page: Int!, $size: Int!, $order: String, $filter: [String]) {
            reports: ReportsPage(method: $method, archived: $archived, page: $page, size: $size, order: $order, filter: $filter) {
              ... pageFragment
            }
          }
          ${pageFragment}
        `;

      },
      variables() {
        return {
          method: this.getMethod,
          archived: this.getArchived,
          page: this.reportsPage,
          size: this.reportsPageSize,

          search: this.search,

          order: this.reportsOrder,

          filter: this.filter,
        };
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      result() {
        this.initialLoad = false;
      },
    },
  },
  data() {
    let view = this.$session.get( "reports.view" );
    if ( typeof view == "undefined" ) {
      view = "table";
    }

    const trail = [
      {
        name: this.$gettext( 'Home' ),
        path: '/dashboard',
      },
      {
        name: this.$gettext( '%{projectString}s', { projectString: this.$hugrConfig.projectStringCap } ),
        path: `/${config.reportRouterReplacement}s`,
      },
    ];

    const tabs = [];
    tabs.push( {
      text: this.$gettext( "All %{projectString}s", { projectString: this.$hugrConfig.projectString } ),
      method: 'all',
      controls: "ReportsPanel",
      action: () => {
        this.getMethod = 'all';
        this.getArchived = false;
        this.reportsPage = 0;
        this.$apollo.queries.reports.refetch();
        this.updateURL();
      },
      pill: () => {
        if( this.$apollo.queries.reportsCount.loading ) return [];

        if( this.reportsCount.allNew > 0 ) {
          return [ this.reportsCount.all, `${this.reportsCount.allNew} new` ];
        }

          return [ this.reportsCount.all ];

      },
    } );

    tabs.push( {
      text: this.$gettext( "My %{projectString}s", { projectString: this.$hugrConfig.projectString } ),
      method: 'owned',
      controls: "ReportsPanel",
      hidden: () => ( !this.hasPermission( 'ReportTabs', 'Mine' ) ),
      action: () => {
        this.getMethod = 'owned';
        this.getArchived = false;
        this.reportsPage = 0;
        this.$apollo.queries.reports.refetch();
        this.updateURL();
      },
      pill: () => {
        if( this.$apollo.queries.reportsCount.loading ) return [];

        if( this.reportsCount.ownedNew > 0 ) {
          return [ this.reportsCount.owned, `${this.reportsCount.ownedNew} new` ];
        }

          return [ this.reportsCount.owned ];
      },
    } );

    tabs.push( {
      text: this.$gettext( "%{projectString}s shared with me", { projectString: this.$hugrConfig.projectStringCap } ),
      method: 'collaborator',
      controls: "ReportsPanel",
      hidden: () => ( !this.hasPermission( 'ReportTabs', 'SharedWithMe' ) ),
      action: () => {
        this.getMethod = 'collaborator';
        this.getArchived = false;
        this.reportsPage = 0;
        this.$apollo.queries.reports.refetch();
        this.updateURL();
      },
      pill: () => {
        if( this.$apollo.queries.reportsCount.loading ) return [];

        if( this.reportsCount.collaboratorNew > 0 ) {
          return [ this.reportsCount.collaborator, `${this.reportsCount.collaboratorNew} new` ];
        }

          return [ this.reportsCount.collaborator ];

      },
    } );

    tabs.push( {
      text: this.$gettext( "%{projectString}s on my team", { projectString: this.$hugrConfig.projectStringCap } ),
      method: 'team',
      controls: "ReportsPanel",
      hidden: () => ( this.user.teams.length <= 0 || !this.hasPermission( 'ReportTabs', 'OnMyTeam' ) ),
      action: () => {
        this.getMethod = 'team';
        this.getArchived = false;
        this.reportsPage = 0;
        this.$apollo.queries.reports.refetch();
        this.updateURL();
      },
      pill: () => {
        if( this.$apollo.queries.reportsCount.loading ) return [];

        if( this.reportsCount.teamNew > 0 ) {
          return [ this.reportsCount.team, `${this.reportsCount.teamNew} new` ];
        }

          return [ this.reportsCount.team ];

      },
    } );

    tabs.push( {
      text: this.$gettext( "%{projectString}s on my portfolio", { projectString: this.$hugrConfig.projectStringCap } ),
      method: 'portfolio',
      controls: "ReportsPanel",
      hidden: () => ( this.portfoliosEnabled == false || !this.hasPermission( 'ReportTabs', 'OnMyPortfolio' ) ),
      action: () => {
        this.getMethod = 'portfolio';
        this.getArchived = false;
        this.reportsPage = 0;
        this.$apollo.queries.reports.refetch();
        this.updateURL();
      },
      pill: () => {
        if( this.$apollo.queries.reportsCount.loading ) return [];

        if( this.reportsCount.portfolioNew > 0 ) {
          return [ this.reportsCount.portfolio, `${this.reportsCount.portfolioNew} new` ];
        }

        return [ this.reportsCount.portfolio ];

      },
    } );

    tabs.push( {
      text: this.$gettext( "Archive" ),
      method: 'archive',
      controls: "ReportsPanel",
      hidden: () => ( !this.hasPermission( 'ReportTabs', 'Archive' ) ),
      action: () => {
        this.getMethod = 'all';
        this.getArchived = true;
        this.reportsPage = 0;
        this.$apollo.queries.reports.refetch();
        this.updateURL();
      },
      pill: () => {
        if( this.$apollo.queries.reportsCount.loading ) return [];

        if( this.reportsCount.archiveNew > 0 ) {
          return [ this.reportsCount.archive, `${this.reportsCount.archiveNew} new` ];
        }

          return [ this.reportsCount.archive ];

      },
    } );

    tabs.push( {
      text: this.$gettext( "Admin All" ),
      method: 'adminall',
      controls: "ReportsPanel",
      hidden: () => ( !this.hasPermission( 'QualityOfLife', 'AdminAll' ) ),
      action: () => {
        this.getMethod = 'adminall';
        this.getArchived = false;
        this.reportsPage = 0;
        this.$apollo.queries.reports.refetch();
        this.updateURL();
      },
      pill: () => {
        return [];
      },
    } );

    const getMethod = this.$route.params.method || 'all';
    const reportsOrder = this.$route.params.order || 'alphabetical';
    let filter = null;
    if( this.$route.params.filtertype && this.$route.params.filter ) {
      filter = [ this.$route.params.filtertype, this.$route.params.filter, 'Loading' ];
      switch( this.$route.params.filtertype ) {
        case 'team': {
          this.$apollo.query( {
            query: gql`
              query Team( $id: ObjectID ) {
                team: Team( id: $id ) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              id: filter[1],
            },
          } ).then( res => {
            filter[2] = res.data.team.name;
          } );

          break;
        }
        case 'customer': {
          this.$apollo.query( {
            query: gql`
              query Customer( $id: ObjectID! ) {
                customer: Customer( id: $id ) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              id: filter[1],
            },
          } ).then( res => {
            filter[2] = res.data.customer.name;
          } );

          break;
        }
        case 'portfolio': {
          this.$apollo.query( {
            query: gql`
              query Portfolio( $id: ObjectID! ) {
                portfolio: Portfolio( id: $id ) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              id: filter[1],
            },
          } ).then( res => {
            filter[2] = res.data.portfolio.name;
          } );

          break;
        }
        case 'owner': {
          this.$apollo.query( {
            query: gql`
              query User( $id: ObjectID! ) {
                user: User( id: $id ) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              id: filter[1],
            },
          } ).then( res => {
            filter[2] = res.data.user.name;
          } );

          break;
        }
        default: {
          filter[2] = 'Unknown';
        }
      }
    }

    let reportsPageSize = 25;
    if( localStorage.getItem( `TablePageSize_Reports` ) ) reportsPageSize = parseInt( localStorage.getItem( `TablePageSize_Reports` ) );

    return {
      publishDisabled: false,
      portfoliosEnabled: false,
      showInProgress: false,
      reports: [],
      view,
      search: "",
      currentTab: 0,
      tabs,
      trail,

      initialLoad: true,
      lazyLoading: false,

      getMethod,
      getArchived: false,
      reportsPage: 0,
      reportsPageSize,

      reportsOrder,
      filter,
    };
  },
  methods: {
    updateURL() {
      const filterPath = this.filter ? `/${this.filter[0]}/${this.filter[1]}` : '';
      this.$router.push( `/${config.reportRouterReplacement}s/find/${this.getMethod}/${this.reportsOrder}${filterPath}` );
    },
    navigate( route ) {
      this.$router.push( `/${config.reportRouterReplacement}s/view/${route}` );
    },
    toggle( filter ) {
      this.show[filter] = !this.show[filter];
    },
    viewToggle( v ) {
      this.$session.set( "reports.view", v );
      this.view = v;
    },
    setFilter( filter ) {
      this.search = '';
      this.filter = filter;
      this.$apollo.queries.reports.refetch();
      this.updateURL();
    },
    clearFilter() {
      this.setFilter( null );
      this.updateURL();
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    ...mapState( [ "user" ] ),
    noTeam() {
      return this.user.teams.length <= 0;
    },
    headers() {
      const ret = [];
      ret.push( this.$gettext( 'Title' ) );
      ret.push( this.$gettext( 'Identifier' ) );
      if( this.showInProgress ) ret.push( this.$gettext( 'Test status' ) );
      ret.push( this.$gettext( 'Compliance' ) );
      ret.push( this.$gettext( 'Pages' ) );
      if( !this.noTeam ) ret.push( this.$gettext( 'Customer' ) );
      ret.push( this.$gettext( 'Team' ) );
      ret.push( this.$gettext( 'Version' ) );
      if( !this.publishDisabled ) ret.push( this.$gettext( 'Published' ) );
      ret.push( this.$gettext( 'Archived' ) );
      ret.push( this.$gettext( 'Created' ) );

      return ret;
    },
  },
  components: {
    // ReportForm,
    ReportModal,
    DataTablePaginator: UIDataTablePaginator,
    DataTablePageSize: UIDataTablePageSize,
    AriaListBox,
    CompliancePill,
    ProgressBar,
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';
td.Reports_Panel_Table_Title a.Reports_Link {
  text-decoration: underline;
}
.a11yhide {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.HONotice {
  width: 100%;
  padding: 8px;
  background: $dig-blue;
  min-height: 25px;
  vertical-align: center;
  color: white;
  border-radius: 4px;
  text-align: center;
  a {
    color: $dig-orange;
  }
  p {
    margin: 0px;
  }
}

.Reports {
  position: relative;
  &_Actions {
    position: absolute;
    top: 30px;
    right: 48px;
    max-width: 60%;
  }
  &_Sort {
    width: 210px;
    margin-top: -16px;
    margin-bottom: 16px;
    &._search {
      // margin-top: -80px;
    }
  }
  &_Filter{
    display: inline-block;
    position: absolute;
    left: 300px;
    margin-top: -40px;
    &_Label {
      vertical-align: middle;
      color: #262e37;
      margin-right: 8px;
    }
    &_Item {
      background: lighten($dig-blue, 70%);
      padding: 4px;
      border-radius: 4px;
      font-size: 0.8em;

      button {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        margin-left: 8px;
        vertical-align: middle;
      }
    }
  }
  &_Panel {
    &_Table {
      &_Title {
        a {
          max-width: 20vw;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &_Identifier {
        // min-width: 200px;
        span {
          display: inline-block;
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &_Progress {
        width: 160px;
      }
      &_Info {
        width: 35px;
        font-size: 1em;
      }
    }
  }
}

._darkMode .Reports {
  &_Filter {
    &_Item {
      color: #000;
    }
  }
}
</style>
