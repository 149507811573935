<template>
  <ul class="Breadcrumbs">
    <template v-for="(item, key) of trail" :key="item.name">
      <li class="Breadcrumbs_Item">
        <router-link v-if="item.path" :to="item.path" v-translate>{{item.name}}</router-link>
        <span v-else v-translate>{{item.name}}</span>
      </li>
      <Icon :key="key" v-if="key!=(trail.length-1)" type="solid" icon="chevron-right" class="Breadcrumbs_Icon"/>
    </template>
  </ul>
</template>

<script>
export default {
  name: 'UIBreadCrumbs',
  props: {
    trail: {
      type: Array,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Breadcrumbs {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.8em;
    &_Item {
      display: inline-block;
      vertical-align: top;
      a {
        color: lighten($hugr-colours-primary, 10%);
        text-decoration: none;
        &:focus, &:hover, &.focus {
          text-decoration: underline;
        }
      }
      span {
        color: lighten($hugr-colours-primary, 10%);
      }
    }
    &_Icon {
      color: $hugr-colours-grey;
      margin: 0 10px;
    }
  }

  ._darkMode .Breadcrumbs {
    &_Item {
      a {
        color: $hugr-colours-grey;
      }
      span {
        color: $hugr-colours-grey;
      }
    }
  }
</style>
