<template>
  <Body :title="$gettext(`Edit`) + ` ${section.title}`">
    <form @submit.prevent="updateStatementSection">
      <FormFieldSet :legend="$gettext('Edit')">
        <FormInput ref="title" idRoot="section_" :validation="['not-empty']" :label="$gettext('Title')" v-model="section.title" />
        <FormTextArea ref="body" idRoot="section_" :validation="['not-empty']" :taller="true" :label="$gettext('Body')" v-model="section.body" />
        <FormInput ref="order" idRoot="section_" :validation="['positive-integer']" type="number" :label="$gettext('Order')" v-model="section.order" />
        <Button type="primary" htype="submit">{{$gettext('Save')}}</Button>
      </FormFieldSet>
    </form>
  </Body>
</template>

<script>
import gql from 'graphql-tag';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import Body from '@/components/UI/Body.vue';

export default {
  name: 'EditAccessibilityStatementSection',
  data() {
    return {
      section: false,
      id: this.$route.params.id,
    };
  },
  apollo: {
    asection: {
      query: gql`
        query AccessibilityStatementSection($id: ObjectID!) {
          asection: AccessibilityStatementSection(id: $id) {
            _id
            title
            body
            enabled
            order
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  watch: {
    $route( to ) {
      if( this.$route.name == 'EditAccessibilityStatementSection' ) {
        this.id = to.params.id;
        this.$apollo.queries.asection.refetch();
      }
    },
    asection() {
      this.section = { ...this.asection };
    },
  },
  methods: {
    updateStatementSection() {
      const validations = [ 'title', 'body', 'order' ];

      this.$hugrvalidate( validations, this ).then( ( { success, errors } ) => {
        if ( success ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation updateAccessibilityStatementSection($id: ObjectID!, $accessibilityStatementSection: AccessibilityStatementSectionInput!) {
                rs: updateAccessibilityStatementSection(id: $id, accessibilityStatementSection: $accessibilityStatementSection) {
                  _id
                }
              }
            `,
            variables: {
              id: this.id,
              accessibilityStatementSection: {
                title: this.section.title,
                body: this.section.body,
                enabled: this.section.enabled,
                order: Number( this.section.order ) || 1,
              },
            },
          } ).then( () => {
            this.$alerts.success( 'Statement section updated', `The accessibility statement section has been updated successfully.` );
            this.$router.push( { path: '/accessibility-statement/statement-sections' } );
          } ).catch( error => {
            this.$alerts.generic( error );
          } );
        }
    } );
    },
  },
  components: {
    FormInput,
    FormFieldSet,
    FormTextArea,
    Body,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
