<template>
  <div>
    <div v-if="disabled">
      <p>{{ $gettext( 'This functionality is disabled, please speak to an administrator' ) }}</p>
    </div>
    <div v-else-if="!hasAccess">
      <p>{{ $gettext( "You don't have access to this %{project}", { project: $hugrConfig.projectString } ) }}</p>
    </div>
    <div class="Render" v-else-if="report&&sections">
      <div class="Render_TopBar" v-if="!undecorated">
        <ReportInfo v-if="report.hasWriteAccess" class="Render_TopBar_Info" :reportVersion="report.currentVersion._id" :slim="true" :back="true"/>
        <div v-else class="Render_TopBar_Bar"></div>
        <ButtonSet class="Render_TopBar_Buttons">
          <Button v-if="report.hasWriteAccess" size="small" direction="horizontal" @click="$refs.statementsharemodal.show( report )">{{$gettext('Share')}}</Button>
          <Button size="small" direction="horizontal" @click="doPrint()">{{$gettext('Print')}}</Button>
          <!-- <Button v-if="report.hasWriteAccess" size="small" direction="horizontal" @click="doCopy()">Copy</Button> -->
          <Button v-if="report.hasWriteAccess" size="small" direction="horizontal" @click="$refs.editrenderdetails.showExtended( report.currentVersion._id, report.currentVersion.createdOverride, report.currentVersion.publishedOverride, report.currentVersion.keyIssues, issuesMarkedAsKeyIssues, report.currentVersion.accessibilityStatementOverrides, 'dateCreatedOverride' );">{{$gettext('Edit details')}}</Button>
        </ButtonSet>
      </div>

      <Notice size="small" v-if="hasChangeRequired&&report.hasWriteAccess" type="error">
        {{$gettext('Some issues require changes for QA')}}
      </Notice>
      <Notice size="small" v-else-if="(hasUnchecked||hasToCheck||hasSecondOpinion)&&report.hasWriteAccess" type="alert">
        {{$gettext('Not all issues have been QA approved')}}
      </Notice>
      <Notice size="small" v-else-if="!publicLink && report.hasWriteAccess" type="alert">
        {{$gettext('This statement is not publicly available.')}}
      </Notice>

      <Loader v-if="doingLoading" />
      <div v-else :class="['Report', sidebar&&!undecorated?'_sidebaropen':'']" ref="report" tabindex="-1">
        <div class="Report_SideBar" ref="sidebar" v-if="!undecorated">
          <Button v-show="sidebar" id="closeSidebar" type="icon" :icon="['solid', 'arrow-left']" @click="() => { sidebar = false }">{{$gettext('Hide document overview')}}</Button>
          <Button v-show="!sidebar" id="openSidebar" type="icon" :icon="['solid', 'arrow-right']" @click="() => { sidebar = true }">{{$gettext('Show document overview')}}</Button>
          <ul v-show="sidebar" id="sidebarContents">
            <li>
              <a href="#title" @focus="(e) => scrollTo(e, 'title')" @click="(e) => moveTo(e, 'title')" class="_anchor _h1">{{report.identifier}}: {{report.title}}</a>
            </li>
            <ul v-show="sidebarO['title']||typeof sidebarO['title']=='undefined'">
              <li>
                <ul>
                  <template v-for="( section, i ) in statementSections" :key="`sidebar-${section._id}`">
                    <li>
                      <a :href="`#statementSection-${section._id}`" @focus="(e) => scrollTo(e, `statementSection-${section._id}`)" @click="(e) => moveTo(e, `statementSection-${section._id}`)" class="_anchor _h3">{{ getReplacement( i, false, false, false ) }}</a>
                    </li>
                  </template>
                </ul>
              </li>
            </ul>
          </ul>
        </div>

        <Page class="Report_Page Report_Page_FirstPage" ref="titlepage" :key="`report-title-${reportTicker}`">
          <img class="Report_Page_FirstPage_Logo" src="../../assets/images/dig-inclusion-white.png" alt="Dig Inclusion"/>
          <div class="Report_Page_FirstPage_Title">
            <h1 id="title" tabindex="-1" >{{report.title}}</h1>
            <span>#{{report.identifier}}</span>
          </div>
          <p class="Report_Page_FirstPage_Version">{{$gettext('Version ')}}&nbsp;{{report.currentVersion.version}}</p>
          <p class="Report_Page_FirstPage_Dates">
            <span v-if="report.currentVersion.createdOverride == '' || !report.currentVersion.createdOverride">
              {{createdDate}}
            </span>
            <span v-else>
              {{report.currentVersion.createdOverride}}
            </span> -
            <span v-if="report.currentVersion.publishedOverride == '' || !report.currentVersion.publishedOverride">
              {{publishedDate}}
            </span>
            <span v-else>
              {{report.currentVersion.publishedOverride}}
            </span>
          </p>
          <dl class="Report_Page_FirstPage_Details">
            <div class="Report_Page_FirstPage_Details_Item">
              <dt>Purpose of the product:</dt>
              <dd>{{  report.productDescription }}</dd>
            </div>
            <div class="Report_Page_FirstPage_Details_Item _inline">
              <dt v-translate>Prepared by:</dt>
              <dd>
                <ul class="inline-list">
                  <li>{{report.owner.name}}</li>
                  <li v-for="user in report.collaborators" v-bind:key="'user-' + user.name">{{user.name}}</li>
                </ul>
              </dd>
            </div>
            <div class="Report_Page_FirstPage_Details_Item _inline">
              <dt v-translate>Published:</dt>
              <dd>{{ publishedDate }}</dd>
            </div>
          </dl>
        </Page>
        <Page :ref="`statement`" class="Report_Page Report_Page_NewSection" :key="`report-statement-${reportTicker}`">
        <div v-if="!report.currentVersion.published">
          <span>{{ $gettext( 'No statement can be generated as this %{project} is currently unpublished.', { project: $hugrConfig.projectString } ) }} </span>
        </div>
        <div v-else-if="!statementSections || statementSections.length == 0">
          <span>No accessibility statement sections set up yet.</span>
        </div>
        <div v-else-if="$apollo.queries.report.loading">
          <span>Generating statement, please wait.</span>
          <Loader />
        </div>
        <div v-else>
          <template v-for="(statementSection, i ) in statementSections" :key="`statementSection-${statementSection.title}-${statementSection.body}`">
            <div class="statementSection">
              <div :id="`statementSection-${statementSection._id}`" :ref="`statementSection-${statementSection._id}`">
                  <vue-markdown>
                    {{ getReplacement( i, false, showReplacementLinks, true ) }}
                  </vue-markdown>
              </div>
              <div>
                <vue-markdown>
                  {{ getReplacement( i, true, showReplacementLinks ) }}
                </vue-markdown>
              </div>
            </div>
        </template>
        </div>
        </Page>
      </div>
    </div>
    <Loader v-else />
    <EditRenderDetailsModal v-on:success="$apollo.queries.report.refetch()" ref="editrenderdetails" :data="[]"/>
    <ShareModal v-on:html="downloadHTMLStatement()" v-on:print="doPrint()" ref="statementsharemodal"/>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AriaListBox from '@/components/Aria/ListBox';

import EditRenderDetailsModal from '@/modals/RenderDetails/Edit';
import ShareModal from '@/modals/Share';

import Page from '@/components/ReportRender/Page';

import * as moment from 'moment';
import config from '../../../config';

export default {
  name: 'ReportsRenderView',
  mounted() {
    window.addEventListener( 'scroll', this.scroll );
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            disableAccessibilityStatement
          }
        }
      `,
    } ).then( res => {
      this.disabled = res.data.settings.disableAccessibilityStatement;
    } );
    this.reportTicker++;

  },
  updated() {
    setTimeout( () => {
      this.addListeners();
    }, 500 );
  },
  beforeUnmount() {
    window.removeEventListener( 'scroll', this.scroll );
  },
  data() {
    return {
      disabled: false,
      doingLoading: false,

      identifier: this.$route.params.identifier?.replace( '%20', ' ' ),
      version: this.$route.params.version,
      showingVersion: '0.0',

      shareId: this.$route.params.shareId,

      solutionsUri: 'https://solutions.diginclusion.com/#/solutions/',

      report: false,
      sections: false,

      currentNumber: 0,
      hidePasses: false,
      showAllTeamSections: false,

      sidebar: true,
      sidebarO: [],

      scrollTimeout: false,
      reportSections: false,

      passedIssues: [],
      hasAccess: true,

      undecorated: this.$route.name == 'ReportsRenderUndecorated',

      statementSections: false,
      processedStatementSections: [ ],
      showReplacementLinks: true,

      detailToFocus: 'none',
      detailTicker: 0,
      reportTicker: 0,
    };
  },
  watch: {
    sections( to, from ) {
      if ( this.sections ) {
        this.processedStatementSections = [];

        this.statementSections.forEach( section => {
          const sectionBits = [];
          sectionBits.push( [ this.processReplacements( section.title, true ), this.processReplacements( section.title, false ) ] );
          sectionBits.push( [ this.processReplacements( section.body, true ), this.processReplacements( section.body, false ) ] );
          this.processedStatementSections.push( sectionBits );
        } );
      }
    },
    $route( to, from ) {
      this.identifier = this.$route.params.identifier?.replace( '%20', ' ' );
      this.version = this.$route.params.version;
      this.shareId = this.$route.params.shareId;
      this.$apollo.queries.report.refetch();
    },
    report( to, from ) {
      if( !this.report || typeof this.report == 'undefined' ) {
        this.hasAccess = false;
      } else if( this.report.currentVersion ) {
        this.hasAccess = true;
        this.showingVersion = this.report.currentVersion.version;
        this.reportTicker++;
        
        this.processedStatementSections = [];

        this.statementSections.forEach( section => {
          const sectionBits = [];
          sectionBits.push( [ this.processReplacements( section.title, true ), this.processReplacements( section.title, false ) ] );
          sectionBits.push( [ this.processReplacements( section.body, true ), this.processReplacements( section.body, false ) ] );
          this.processedStatementSections.push( sectionBits );
        } );

        this.$apollo.addSmartQuery( 'sections', {
          query: gql`
            query Sections($version: ObjectID!, $showAllTeamSections: Boolean) {
              sections: Sections(reportVersion: $version, showAllTeamSections: $showAllTeamSections) {
                identifier,
                title,
                subsections {
                  identifier,
                  title,
                  templates {
                    template {
                      _id,
                      identifier,
                      title,
                      description,
                      severity,
                      affected,
                      impact
                      #extra
                      criteria {
                        _id
                        identifier,
                        criterion,
                        title,
                        level
                      }
                      solutions {
                        _id
                        identifier,
                        title
                      }
                    },
                    instances {
                      _id
                      identifier,
                      evidence,
                      reason,
                      primary,
                      status,
                      flag,
                      timestamp,
                      isAKeyIssueInstance,
                      solutions {
                        _id
                        identifier
                      },
                      page {
                        _id
                        name
                      }
                      component {
                        _id
                        identifier
                      }
                      others {
                        _id
                        page {
                          _id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables() {
            return {
              version: this.report.currentVersion._id,
              showAllTeamSections: this.showAllTeamSections,
            };
          },
        } );
      }
    },
  },
  apollo: {
    report: {
      query() {
        const reportFragment = gql`
          fragment renderReportFragment on Report {
            _id,
            identifier,
            title,
            archived,
            hasWriteAccess,
            productDescription,
            template,
            publicLink,
            linkString,
            spreadsheets {
              template {
                name
              }
            }
            team {
              _id
              name
            }
            owner {
              _id
              name
            }
            customer {
              _id
              name
            }
            collaborators {
              _id
              name
            }
            currentVersion {
              _id
              published,
              publishedDate,
              firstPublishDate,
              created,
              version,
              execSummary,
              createdOverride,
              publishedOverride,
              keyIssues,
              accessibilityStatementOverrides {
                mainURL,
                purpose,
                platform,
                customer,
                reportName,
                wcagVersion,
                wcagStandard,
                contactEmail,
                contactPhone,
                contactPerson,
                complianceStatus,
                statementDate,
                lastReviewDate,
                latestTestDate,
                testConductor,
                reportURL,
                disproportionateBurden
              }
            }
            versions {
              _id,
              version
              published
              publishedDate
            }
            pages {
              _id,
              host,
              path,
              name,
              title,
              issues {
                _id,
                template {
                  severity
                }
              },
              components {
                _id
                issues {
                  _id,
                  template {
                    severity
                  }
                },
              }
            }
          }`;
        if( typeof this.shareId != 'undefined' ) {
          return gql`
            query ReportExt($shareId: String!) {
              report: ReportExt(id: $shareId) {
                ... renderReportFragment
              }
            }
            ${reportFragment}
          `;
        } else if( typeof this.version == 'undefined' ) {
          return gql`
            query Report($ident: String!) {
              report: Report(identifier: $ident) {
                ... renderReportFragment
              }
            }
            ${reportFragment}
          `;
        }

          return gql`
            query Report($ident: String!, $version: String!) {
              report: ReportWithVersion(identifier: $ident, version: $version) {
                ... renderReportFragment
              }
            }
            ${reportFragment}
          `;

      },
      variables() {
        return {
          ident: this.identifier,
          version: this.version,
          shareId: this.shareId,
        };
      },
      fetchPolicy: 'no-cache',
    },
    reportSections: gql`
      {
        reportSections: ReportSections {
          _id
          shortName
          fullName
          summary
          order
        }
      }
    `,
    statementSections: gql`
      {
        statementSections: ActiveAccessibilityStatementSections {
          _id
          title
          body
          order
          enabled
        }
      }
    `,
  },
  methods: {
    scroll( e ) {
      clearTimeout( this.scrollTimeout );

      //offset stuff
      const pageOffset = this.$refs.titlepage.$el.getBoundingClientRect().top - 40;
      if( pageOffset > 65 ) {
        this.$refs.sidebar.style.top = `${pageOffset}px`;
      } else {
        this.$refs.sidebar.style.top = `65px`;
      }

      //scrollspy stuff
      this.scrollTimeout = setTimeout( () => {
        const items = document.getElementsByClassName( '_anchor' );
        for( const item of items ) {
          const sItem = document.getElementById( item.getAttribute( 'href' ).replace( '#', '' ) );
          let sItemOffset = 0;
          if ( sItem ) {
            sItemOffset = sItem.offsetTop + sItem.offsetParent.offsetTop;
          }
          if( sItemOffset > window.scrollY && sItemOffset <= window.scrollY + window.innerHeight ) {
            this.makeCurrent( item );
          }
        }
      }, 100 );
    },
    makeCurrent( item ) {
      const items = document.getElementsByClassName( '_anchor' );
      for( const item of items ) {
        item.classList.remove( '_active' );
        item.removeAttribute( 'aria-current' );
      }
      item.classList.add( '_active' );
      item.setAttribute( 'aria-current', 'location' );
      item.scrollIntoViewIfNeeded();
    },
    scrollTo( e, id ) {
      e.preventDefault();
      const el = document.getElementById( id );
      el.scrollIntoViewIfNeeded();
    },
    moveTo( e, id ) {
      e.preventDefault();
      const el = document.getElementById( id );
      el.focus();
    },
    downloadHTMLStatement() {
      this.showReplacementLinks = false;
      this.reportTicker++;

      setTimeout( () => {
        let statement = this.$refs.report.outerHTML;
        let allCSS = [ ...document.styleSheets ];

        allCSS = allCSS.map( styleSheet => {
          try {
            return [ ...styleSheet.cssRules ].map( rule => rule.cssText ).join( "" );
          } catch ( e ) {
            console.log( "Access to stylesheet %s is denied. Ignoring…", styleSheet.href );
          }
        } ).filter( Boolean ).join( "\n" );

        statement = statement.replace( `"openSidebar"`, `"openSidebar" onclick="document.getElementById('closeSidebar').style.display = 'inline-block'; document.getElementById('openSidebar').style.display = 'none'; document.getElementById('sidebarContents').style.display = 'block';" ` );
        statement = statement.replace( `"closeSidebar"`, `"closeSidebar" onclick="document.getElementById('openSidebar').style.display = 'inline-block'; document.getElementById('closeSidebar').style.display = 'none'; document.getElementById('sidebarContents').style.display = 'none';" ` );

        const finalHTML = `<!DOCTYPE html><html><head><style>${allCSS}</style></head><body>${statement}</body></html>`;
        const content = `data:text/html;charset=utf-8,${encodeURIComponent( finalHTML )}`;
        const link = document.createElement( 'a' );
        link.setAttribute( 'download', `${this.report.identifier}-statement.html` );
        link.setAttribute( 'href', content );
        link.click();
      
        this.showReplacementLinks = true;
        this.reportTicker++;

      }, 400 );
    },
    doPrint() {
      window.print();
    },
    doCopy() {
      this.$refs.report.focus();
      const el = this.$refs.report;
      const { body } = document;
      let range, sel;
      if ( document.createRange && window.getSelection ) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
              range.selectNodeContents( el );
              sel.addRange( range );
          } catch ( e ) {
              range.selectNode( el );
              sel.addRange( range );
          }
      } else if ( body.createTextRange ) {
          range = body.createTextRange();
          range.moveToElementText( el );
          range.select();
          range.execCommand( "Copy" );
      }
      document.execCommand( 'copy' );
    },
    sectionResult( sections ) {
      let count = 0,
          advisoryCount = 0;
      for( const section of sections ) {
        if( section.template.severity != 0 && this.hasOpenInstances( section.instances ) ) {
          count++;
        } else if( section.template.severity == 0 && this.hasOpenInstances( section.instances ) ) {
          advisoryCount++;
        }
      }

      if( count ) {
        return 'Fail';
      } else if( advisoryCount ) {
        return 'Pass (with advisories)';
      }

        return 'Pass';

    },
    wholeSectionResult( section ) {
      let result = 'Pass';
      for( const subsection of section.subsections ) {
        const r = this.sectionResult( subsection.templates );
        if( r == 'Fail' ) {
          result = r;
        } else if( result != 'Fail' && r == 'Pass (with advisories)' ) {
          result = r;
        }
      }

      return result;
    },
    openDetailsModalFocus( focusID ) {
      this.$refs.editrenderdetails.showExtended( this.report.currentVersion._id, this.report.currentVersion.createdOverride, this.report.currentVersion.publishedOverride, this.report.currentVersion.keyIssues, this.issuesMarkedAsKeyIssues, this.report.currentVersion.accessibilityStatementOverrides, focusID );
    },
    getReplacement( index, body = true, link = true, header = false ) {
      const ret = this.processedStatementSections[ index ][body ? 1 : 0][link ? 0 : 1 ];

      if ( header ) {
        return `<h2>${ret}</h2>`;
      }

      return ret;
    },
    processReplacements( input, link = true ) {
      let ret = input;

      if ( !this.report.hasWriteAccess ) {
        // eslint-disable-next-line no-param-reassign
        link = false;
      }

      if ( ret.indexOf( "{{keyIssues}}" ) >= 0 ) {
        let replacement = "<h3>Key issues identified:</h3>";
        this.processedKeyIssues.forEach( issue => {
          replacement += `<h4>${issue[0]}</h4>`;

          replacement += `</p>${issue[1]}</p>`;
        } );
        // eslint-disable-next-line no-template-curly-in-string

        if ( this.processedKeyIssues.length == 0 ) {
          replacement = "No key issues have been identified.";
        }
        
        ret = ret.replaceAll( "{{keyIssues}}", replacement );
      }

      if ( ret.indexOf( "{{openIssues}}" ) >= 0 ) {
        let replacement = "<ul>";
        this.allInstancesByTemplates.forEach( template => {
          replacement += `<li>${template[0].template}<ul>`;
          
          template.forEach( issue => {
            replacement += `<li><a target="_blank" href="${config.rootUrl}/${config.reportRouterReplacement}s/${this.report.identifier}/${this.version}/issues/${issue.identifier}">${issue.identifier}</a></li>`;
          } );

          replacement += `</ul></li>`;
        } );
        replacement += `</ul>`;
        // eslint-disable-next-line no-template-curly-in-string

        if ( this.allInstances.length == 0 ) {
          replacement = "No issues are currently present.";
        }

        ret = ret.replaceAll( "{{openIssues}}", replacement );
      }

      while ( ret.indexOf( "{{servicePurpose}}" ) >= 0 ) {
        let replacement = "is to provide a service";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.purpose && this.report.currentVersion.accessibilityStatementOverrides?.purpose != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.purpose;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for service purpose field." data-clicktarget="purpose" id="purpose-${this.detailTicker}">${replacement}</a>`;
        }
        
        ret = ret.replaceAll( "{{servicePurpose}}", replacement );
      }

      while ( ret.indexOf( "{{mainURL}}" ) >= 0 ) {
        let replacement = "https://example.com";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.mainURL && this.report.currentVersion.accessibilityStatementOverrides?.mainURL != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.mainURL;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for main URL field." data-clicktarget="mainURL" id="mainURL-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{mainURL}}", replacement );
      }

      while ( ret.indexOf( "{{platform}}" ) >= 0 ) {
        let replacement = "website";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.platform && this.report.currentVersion.accessibilityStatementOverrides?.platform != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.platform;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for platform field." data-clicktarget="platform" id="platform-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{platform}}", replacement );
      }

      while ( ret.indexOf( "{{disproportionateBurden}}" ) >= 0 ) {
        let replacement = "No claims for disproportionate burden have been made.";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.disproportionateBurden && this.report.currentVersion.accessibilityStatementOverrides?.disproportionateBurden != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.disproportionateBurden;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for disproportionate burden field." data-clicktarget="disproportionateBurden" id="disproportionateBurden-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{disproportionateBurden}}", replacement );
      }

      while ( ret.indexOf( "{{customer}}" ) >= 0 ) {
        let replacement = this.report.customer?.name || "ACME Corporation";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.customer && this.report.currentVersion.accessibilityStatementOverrides?.customer != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.customer;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for ${this.$gettext( 'Customer' )} field." data-clicktarget="customer" id="customer-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{customer}}", replacement );
      }

      while ( ret.indexOf( "{{reportName}}" ) >= 0 ) {
        let replacement = this.report.title || "Service";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.reportName && this.report.currentVersion.accessibilityStatementOverrides?.reportName != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.reportName;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for name field." data-clicktarget="reportName" id="reportName-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{reportName}}", replacement );
      }

      while ( ret.indexOf( "{{wcagVersion}}" ) >= 0 ) {
        let replacement = this.highestWCAG;
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.wcagVersion && this.report.currentVersion.accessibilityStatementOverrides?.wcagVersion != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.wcagVersion;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for wcag version field." data-clicktarget="wcagVersion" id="wcagVersion-${this.detailTicker}">${replacement}</a>`;
        }
        ret = ret.replaceAll( "{{wcagVersion}}", replacement );
      }

      while ( ret.indexOf( "{{wcagStandard}}" ) >= 0 ) {
        let replacement = this.highestConformity;
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.wcagStandard && this.report.currentVersion.accessibilityStatementOverrides?.wcagStandard != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.wcagStandard;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for wcag standard field." data-clicktarget="wcagStandard" id="wcagStandard-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{wcagStandard}}", replacement );
      }

      while ( ret.indexOf( "{{contactEmail}}" ) >= 0 ) {
        let replacement = "John@Smith.com";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.contactEmail && this.report.currentVersion.accessibilityStatementOverrides?.contactEmail != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.contactEmail;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for contact email field." data-clicktarget="contactEmail" id="contactEmail-${this.detailTicker}">${replacement}</a>`;
        }
        ret = ret.replaceAll( "{{contactEmail}}", replacement );
      }

      while ( ret.indexOf( "{{contactPhone}}" ) >= 0 ) {
        let replacement = "+44 0001 00001";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.contactPhone && this.report.currentVersion.accessibilityStatementOverrides?.contactPhone != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.contactPhone;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for contact phone field." data-clicktarget="contactPhone" id="contactPhone-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{contactPhone}}", replacement );
      }

      while ( ret.indexOf( "{{contactPerson}}" ) >= 0 ) {
        let replacement = "Mr. John Smith";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.contactPerson && this.report.currentVersion.accessibilityStatementOverrides?.contactPerson != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.contactPerson;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for contact name field." data-clicktarget="contactPerson" id="contactPerson-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{contactPerson}}", replacement );
      }

      while ( ret.indexOf( "{{complianceStatus}}" ) >= 0 ) {
        let replacement = this.allInstances.length > 0 ? "is not compliant" : "is compliant";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.complianceStatus && this.report.currentVersion.accessibilityStatementOverrides?.complianceStatus != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.complianceStatus;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for compliance status field." data-clicktarget="complianceStatus" id="complianceStatus-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{complianceStatus}}", replacement );
      }

      while ( ret.indexOf( "{{statementDate}}" ) >= 0 ) {
        let replacement = this.report.currentVersion.firstPublishDate;
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.statementDate && this.report.currentVersion.accessibilityStatementOverrides?.statementDate != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.statementDate;

          if ( moment( this.report.currentVersion.accessibilityStatementOverrides?.statementDate, true ).isValid() ) {
            replacement = this.formatDate( replacement );
          }
        } else {
          replacement = this.formatDate( replacement );
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for statement date field." data-clicktarget="statementDate" id="statementDate-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{statementDate}}", replacement );
      }

      while ( ret.indexOf( "{{lastReviewDate}}" ) >= 0 ) {
        let replacement = this.report.currentVersion.publishedDate;
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.lastReviewDate && this.report.currentVersion.accessibilityStatementOverrides?.lastReviewDate != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.lastReviewDate;

          if ( moment( this.report.currentVersion.accessibilityStatementOverrides?.lastReviewDate, true ).isValid() ) {
            replacement = this.formatDate( replacement );
          }
        } else {
          replacement = this.formatDate( replacement );
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for last review date field." data-clicktarget="lastReviewDate" id="lastReviewDate-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{lastReviewDate}}", replacement );
      }

      while ( ret.indexOf( "{{latestTestDate}}" ) >= 0 ) {
        let replacement = this.latestIssueInstanceTime;
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.latestTestDate && this.report.currentVersion.accessibilityStatementOverrides?.latestTestDate != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.latestTestDate;

          if ( moment( this.report.currentVersion.accessibilityStatementOverrides?.latestTestDate, true ).isValid() ) {
            replacement = this.formatDate( replacement );
          }
        } else {
          replacement = this.formatDate( replacement );
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for latest test date field." data-clicktarget="latestTestDate" id="latestTestDate-${this.detailTicker}">${replacement}</a>`;
        }
        ret = ret.replaceAll( "{{latestTestDate}}", replacement );
      }

      while ( ret.indexOf( "{{testConductor}}" ) >= 0 ) {
        let replacement = this.report.owner.name || "Mr. John Smith";
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.testConductor && this.report.currentVersion.accessibilityStatementOverrides?.testConductor != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.testConductor;
        }

        if ( link ) {
          replacement = `<a class="ClickableField" href="javascript:;" aria-label="Change override for tester name field." data-clicktarget="testConductor" id="testConductor-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{testConductor}}", replacement );
      }

      while ( ret.indexOf( "{{reportURL}}" ) >= 0 ) {
        let replacement = config.rootUrl + this.$route.fullPath.substring( 0, this.$route.fullPath.length - 9 );
        this.detailTicker++;

        if ( this.report.currentVersion.accessibilityStatementOverrides?.reportURL && this.report.currentVersion.accessibilityStatementOverrides?.reportURL != "" ) {
          replacement = this.report.currentVersion.accessibilityStatementOverrides?.reportURL;
        }

        if ( link ) {
          replacement = `<a href="${replacement}" target="_blank" aria-label="Visit full report in new tab." id="reportURL-${this.detailTicker}">${replacement}</a>`;
        }

        ret = ret.replaceAll( "{{reportURL}}", replacement );
      }

      return ret;
    },
    addListeners() {
      const matches = document.querySelectorAll( "a[data-clicktarget]" );

      matches.forEach( item => {
        item.removeEventListener( "click", () => this.openDetailsModalFocus( `${item.dataset.clicktarget}Override` ), false );
      } );
      
      this.$nextTick( () => {
        const matches = document.querySelectorAll( "a[data-clicktarget]" );

        matches.forEach( item => {
          item.addEventListener( "click", () => this.openDetailsModalFocus( `${item.dataset.clicktarget}Override` ), false );
        } );
      } );
    },
    hasOpenInstances( instances ) { //remove todo's as they don't count unless there's a primary
      const ninst = instances.filter( i => i.identifier.indexOf( 'todo' ) < 0 ).filter( i => i.status.indexOf( 'closed' ) != 0 );

      return ninst.length > 0;
    },
    changeVersion() {
      this.doingLoading = true;
      this.$apollo.query( {
        query: gql`
          query ReportWithVersion($ident: String!, $version: String!) {
            report: ReportWithVersion(identifier: $ident, version: $version) {
              _id,
              currentVersion {
                _id
                published,
                publishedDate,
                created,
                version,
                execSummary,
                createdOverride,
                publishedOverride,
                keyIssues
              }
            }
          }
        `,
        variables: {
          ident: this.report.identifier,
          version: this.showingVersion,
        },
      } ).then( () => {
        this.doingLoading = false;
      } ).catch( () => {
        this.$alerts.error( `Couldn't load version`, `Something went wrong when loading this version, please try again.` );
      } );
    },
    formatDate( input ) {
      return moment( input ).format( 'Do MMMM YYYY' );
    },
    hasBeenRendered( t, id ) {
      const i = document.getElementById( id );
      if( i ) {
        return false;
      }

        return true;

    },
  },
  computed: {
    sectionMeta() {
      if ( !this.reportSections ) return {};

      const meta = {};

      const clone = Array.from( this.reportSections );

      const ordered = clone.sort( ( a, b ) => (
        a.order - b.order
      ) );

      ordered.forEach( section => {
        meta[section.shortName] = {
          full: section.fullName,
          summary: section.summary,
        };
      } );

      return meta;
    },

    latestIssueInstanceTime() {
      const initial = moment().subtract( 10,'y' );
      let highest = initial;

      this.allInstances.forEach( instance => {
        if ( instance.timestamp ) {
          if ( moment( instance.timestamp ).isAfter( moment( highest ) ) ) {
            highest = instance.timestamp;
          }
        }
      } );

      if ( highest == initial ) {
        return "Unknown";
      }

      return moment( highest ).format( 'Do MMMM YYYY' );
    },

    highestWCAG() {
      let wcag = '2.2';

      switch( this.report.template ) {
        case 'aaa':
        case 'default': {
          wcag = '2.1';
          
          break;
        }
        case 'wcag22aa':
        case 'wcag22aaa':
        default: {
          wcag = '2.2';

          break;
        }
      }

      return wcag;
    },

    // highestWCAG() {
    //   let main = 2;
    //   let minor = 1;

    //   if ( !this.report.spreadsheets ) return `${main}.${minor}`;

    //   this.report.spreadsheets.forEach( spreadsheet => {
    //     if ( spreadsheet.template.name.toLowerCase().indexOf( "wcag" ) >= 0 ) {
    //       const split = spreadsheet.template.name.toLowerCase().split( "wcag" );
    //       const numberSplit = split[1].split( "." );

    //       if ( main < parseInt( numberSplit[0].trim() ) ) {
    //         main = parseInt( numberSplit[0].trim() );
    //       }

    //       if ( minor < parseInt( numberSplit[1].substring( 0,2 ).trim() ) ) {
    //         minor = parseInt( numberSplit[1].substring( 0,2 ).trim() );
    //       }
    //     }
    //   } );

    //   return `${main}.${minor}`;
    // },

    highestConformity() {
      let conformity = "AA";

      if ( !this.report.spreadsheets ) return conformity;

      this.report.spreadsheets.forEach( spreadsheet => {
        if ( spreadsheet.template.name.indexOf( " AAA" ) >= 0 ) {
          conformity = "AAA";
        }
      } );

      return conformity;
    },

    pageNumber() {
      //TODO make better
      // eslint-disable-next-line
      return this.currentNumber++;
    },
    createdDate() {
      return moment( this.report.currentVersion.created ).format( 'Do MMMM YYYY' );
    },
    publishedDate() {
      if( this.report.currentVersion.publishedDate ) {
        return moment( this.report.currentVersion.publishedDate ).format( 'Do MMMM YYYY' );
      }

        return 'Not published';

    },
    issuesMarkedAsKeyIssues() {
      const ret = [];
      for ( const issue of this.allInstances ) {
        if( issue.isAKeyIssueInstance ) {
          ret.push( issue );
        }
      }

      return ret;
    },
    processedKeyIssues() {
      const ret = [];
      for ( const keyIssue of this.report.currentVersion.keyIssues ) {
        const split = keyIssue.split( '<keyIssueBodySeparator>' );
        ret.push( split );
      }

      return ret;
    },
    allInstances() { //open only, this isn't used here?
      const ret = [];
      if( this.sections ) {
        for( const section of this.sections ) {
          for( const subsection of section.subsections ) {
            for( const template of subsection.templates ) {
              for( const instance of template.instances ) {
                if( instance.primary && ( instance.status.indexOf( 'closed' ) == -1 ) ) {
                  ret.push( {
                    ...instance,
                    section: `${section.title} > ${subsection.title}`,
                  } );
                }
              }
            }
          }
        }
      }

      return ret;
    },
    allInstancesByTemplates() { //open only, this isn't used here?
      const ret = [];
      if( this.sections ) {
        for( const section of this.sections ) {
          for( const subsection of section.subsections ) {
            // eslint-disable-next-line max-depth
            for( const template of subsection.templates ) {
              const templateInstances = [];
              // eslint-disable-next-line max-depth
              for( const instance of template.instances ) {
                // eslint-disable-next-line max-depth
                if( instance.primary && ( instance.status.indexOf( 'closed' ) == -1 ) ) {
                  templateInstances.push( {
                    ...instance,
                    section: `${section.title} > ${subsection.title}`,
                    template: `${template.template.identifier}: ${template.template.title}`,
                  } );
                }
              }
              // eslint-disable-next-line max-depth
              if ( templateInstances.length > 0 ) {
                ret.push( templateInstances );
              }
            }
          }
        }
      }

      return ret;
    },

    hasUnchecked() {
      return this.allInstances.filter( i => i.flag == 'unchecked' ).length;
    },
    hasToCheck() {
      return this.allInstances.filter( i => i.flag == 'tocheck' ).length;
    },
    hasChecked() {
      return this.allInstances.filter( i => i.flag == 'checked' ).length;
    },
    hasChangeRequired() {
      return this.allInstances.filter( i => i.flag == 'change-required' ).length;
    },
    hasSecondOpinion() {
      return this.allInstances.filter( i => i.flag == 'second-opinion' ).length;
    },

    versionOpts() {
      const opts = {};
      for( const version of this.report.versions ) {
        opts[version.version] = version.version;
      }

      return opts;
    },
    versionOptsExt() {
      const opts = {};
      for( const version of this.report.versions ) {
        if( version.published ) {
          opts[version.version] = `published ${moment( version.publishedDate ).format( 'Do MMMM YYYY' )}`;
        } else {
          opts[version.version] = 'Not published';
        }
      }

      return opts;
    },
    pageComplianceList() {
      const out = [];
      for( const page of this.report.pages ) {
        let pageCompliance = 'Pass';
        let componentCompliance = 'Pass';
        for( const issue of page.issues ) {
          if( issue.template.severity != '0' ) {
            pageCompliance = 'Fail';
          } else if( pageCompliance == 'Pass' ) {
            pageCompliance = 'Pass with advisories';
          }
        }
        for( const component of page.components ) {
          for( const issue of component.issues ) {
            if( issue.template.severity != '0' ) {
              componentCompliance = 'Fail';
            } else if( componentCompliance == 'Pass' ) {
              componentCompliance = 'Pass with advisories';
            }
          }
        }
        out.push( {
          name: page.name,
          title: page.title,
          url: `${page.host}${page.path}`,
          compliance: pageCompliance,
          componentCompliance,
        } );
      }

      return out;
    },
  },
  components: {
    Page,
    EditRenderDetailsModal,
    ShareModal,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_mixins.scss';

  .ClickableField {
    color: #405DEC;
    background: #FFF4FF;
  }

  .statementSection {
    margin-bottom: 16px;
  }

  .Render {
    &_TopBar {
      position: relative;

      &_Info {

      }
      &_Bar {
        background: #FFF;
        width: 100%;
        height: 55px;
        position: relative;
        padding: 0 50px;
      }
      &_Buttons {
        position: absolute;
        right: 50px;
        top: 7px;

        button {
          margin-left: 8px;
        }

        &_VersionSelect {
          display: inline-block;
          width: 200px;
          margin-left: 8px;
          vertical-align: middle;
        }
      }
    }
  }
  .Report {
    background: #e5ebf2;
    padding-top: 20px;
    &_SideBar {
      position: fixed;
      left: 0;
      padding: 16px;
      width: 20%;
      overflow: hidden;
      height: calc(100vh - 100px);
      
      @include vertical-scroll;

      ul {
        list-style: none;
        margin: 0;
        margin-left: 8px;
        padding: 0;
        a {
          color: $hugr-colours-primary;
          text-decoration: none;
          padding: 2px 0px;
          display: block;

          &._active {
            color: $hugr-colours-tertiary;
          }
        }
      }
    }
    &._sidebaropen {
      .page {
        position: relative;
        left: 150px;
      }
    }
    &_Page {
      position: relative;
      &_ExecSummary {
        &_Main {
          margin-top: 20px;
        }
      }
      &_FirstPage {
        background: $dig-blue !important;
        color: #FFFFFF !important;
        position: relative;
        &_Logo {
          width: 40%;
        }
        // &_Project {
        //   display: inline-block;
        //   width: 100%;
        //   font-size: 2em;
        //   // border-bottom: 2px solid;
        //   // margin-top: 50px;
        //   // margin-bottom:0;
        // }
        &_Title {
          margin: 16px 0;
          margin-top: 300px;
          h1 {
            font-size: 3em;
            margin: 0;
          }
          span {
            font-size: 0.8em;
            position: relative;
            top: -10px;
          }
        }
        &_Dates {
          margin-top: 0;
        }

        &_Details {
          &_Item {
            margin: 10px 0;

            &._inline {
              dd, dt {
                display: inline;
                margin: 0;
              }
              dd {
                margin-left: 10px;
              }
            }
          }
        }
      }

      &_Affected {
        &_User {
          &_Image {
            display: inline-block;
            vertical-align: top;
            width: 49%;
            margin-top: 20px;
          }
          &_Details {
            display: inline-block;
            vertical-align: top;
            width: 49%;
            margin-left: 2%;
            margin-top: 20px;
            p {
              margin-top: 0;
            }
          }
        }
      }

      &_NewSection {
        &_Details {
          > div {
            > * {
              display: inline-block;
              vertical-align: top;
            }
          }
        }
      }

      h3 {
        background: #262E37;
        color: #FFF;
        display: inline-block;
        width: 100%;
        padding: 15px 2cm;
        position: relative;
        left: -2cm;

        margin-bottom: 0;
      }

      h4 {
        background: #d3d5d7;
        color: #000;
        display: inline-block;
        width: 100%;
        padding: 5px 2cm;
        position: relative;
        left: -2cm;

        margin-top: 0;
      }
    }

    table {
      max-width:800px !important;
    }

    ul.inline-list {
      padding: 0;
      margin: 0;
      list-style: none;
      display: inline;
      li {
        display: inline;
        &:after {
          content: ', ';
        }
        &:last-child:after {
          content: '';
        }
      }
    }
  }

  ._darkMode {
    .Render {

    }

    .Report {
      background: darken(#e5ebf2, 80%);

      &_SideBar {
        ul {
          a {
            color: $hugr-colours-grey;
            &._active {
              color: $hugr-colours-secondary;
            }
          }
        }
      }
      &_Page {
        background: #000;
      }
    }

    .page {
      background: #000;
    }
  }

  .PageCompliance {
    // margin: 18px;
    // width: calc(100% - 32px);
    table-layout: fixed;
    &_Head {
      &_Compliance {
        width: 200px;
      }
    }

    &_Body {
      &_Row {
        &_Page {
          &_Name {
            margin: 2px 0;
          }
          &_Details {
            font-size: 0.8em;
            margin-top: 0;
            margin-bottom: 2px;

            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        &_Compliance {
          &._red {
            background: lighten($hugr-colours-red, 50%);
          }
          &._green {
            background: lighten($hugr-colours-green, 50%);
          }

          &_Result {
            margin: 2px 0;
          }

          &_Details {
            font-size: 0.8em;
            margin-top: 0;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  ._darkMode .PageCompliance {
    &_Body {
      &_Row {
        &_Compliance {
          &._red {
            background: darken($hugr-colours-red, 20%);
          }
          &._green {
            background: darken($hugr-colours-green, 20%);
          }
        }
      }
    }
  }

  @media print {
    .reportbtns {
      display: none !important;
    }
    .Report {
      position: relative !important;
      background: #FFF !important;

      &_SideBar {
        display: none !important;
      }

      .page {
        position: unset !important;
        left: 0 !important;
        background: #FFF !important;
        color: black;
      }

      &_Page {

        &_FirstPage {
          color: black !important;
        }
      }
      
    }

    .notice {
      display: none !important;
    }
  }
</style>
