<template>

  <Body class="ReportSections">
    <div>
      <DataTable class="ReportSections_Table" :headers="[$gettext('Short name'), $gettext('Full name'), $gettext('Summary'), $gettext('Actions')]">
        <tr v-for="section in orderedSections" :key="section._id">
          <td>{{section.shortName}}</td>
          <td>{{section.fullName}}</td>
          <td>{{section.summary}}</td>
          <td class="ReportSections_Actions">
            <LinkButton v-if="hasPermission( 'ReportSections', 'Update' )" type="primary" size="micro" :to="`/templates/report-sections/${section._id}`">{{$gettext('Edit')}}</LinkButton>
            &nbsp;
            <Button v-if="hasPermission( 'ReportSections', 'Delete' )" type="serious" size="micro" @click="rmReportSection(section._id)">{{$gettext('Delete')}}</Button>
          </td>
        </tr>
      </DataTable>
    </div>

    <ReportSectionForm v-if="hasPermission( 'ReportSections', 'Create' )" @success="$apollo.queries.reportSections.refetch()" />

    <br />
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

import ReportSectionForm from '@/forms/ReportSection';

export default {
  name: 'TemplatesReportSectionsView',
  data() {
    return {
      reportSections: [],
    };
  },
  apollo: {
    reportSections: gql`
      {
        reportSections: ReportSections {
          _id
          shortName
          fullName
          summary
          order
        }
      }
    `,
  },
  methods: {
    rmReportSection( id ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation removeReportSection($id: ObjectID!) {
            removeReportSection(id: $id)
          }
        `,
        variables: {
          id,
        },
      } ).then( () => {
        this.$apollo.queries.reportSections.refetch();
      } ).catch( error => {
        this.$alerts.generic( error );
      } );
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    orderedSections() {
      const clone = Array.from( this.reportSections );

      return clone.sort( ( a,b ) => (
        a.order - b.order
      ) );
    },
  },
  components: {
    ReportSectionForm,
  },
};
</script>

<style lang="scss" scoped>
  .ReportSections {
    &_Table {
      width: 100%;
    }

    &_Actions {
      display: flex;
      justify-content: space-between;
    }
  }
</style>
