<template>
  <Body title="Import Users" class="Import">
    <DataTable :headers="[
              $gettext('Num'),
              $gettext('Identifier'),
              $gettext('Name'),
              $gettext('Team'),
              $gettext('Customer'),
              $gettext('Ignore'),
              ''
            ]">
      <tr v-for="(portfolio, i) in parsedData" :key="i">
        <td>{{ i+1 }}</td>
        <td>
          <input aria-label="identifier" v-model="portfolio.identifier" @change="doChecks()"/>
          <span v-if="portfolio.duplicate || portfolio.exists">
            &nbsp;(
            <span v-if="portfolio.cantAdd">can't add</span>&nbsp;
            <span v-if="portfolio.duplicate">duplicate</span>&nbsp;
            <span v-if="portfolio.exists">Already exists</span>
            )
          </span>
        </td>
        <td>
          <input aria-label="name" v-model="portfolio.name" />
        </td>
        <td>
          {{ portfolio.team }}
          <span v-if="!portfolio.teamExists">Add team first</span>
          <span v-if="portfolio.teamAdded">Added</span>
        </td>
        <td>
          {{ portfolio.customer }}
          <span v-if="!portfolio.customerExists">Add customer first</span>
        </td>
        <td><input aria-label="Ignore" type="checkbox" v-model="portfolio.ignore"/></td>
        <td>
          <span v-if="portfolio.made">Created</span>
        </td>
      </tr>
    </DataTable>
    <Button @click="importData()">Import</Button>
    <div class="Import_Settings">
      <div>
        <label for="order">Order</label>&nbsp;
        <input v-model="order" id="order" />
        &nbsp;&nbsp;
        <label for="splitter">Splitter</label>&nbsp;
        <select id="splitter" v-model="splitter">
          <option value="0">Comma</option>
          <option value="1">Tab</option>
        </select>
      </div>
      <br />
      <div>
        <label for="csv">Data</label>
        <textarea id="csv" v-model="csv"></textarea>
      </div>
      <Button @click="parse()">Parse</Button>
    </div>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

export default {
  name: "ImportTeams",
  data() {
    return {
      csv: '',
      splitter: 0,
      splitters: [ ",", "\n" ],
      order: "identifier, name, team, customer",

      parsedData: false,
    };
  },
  methods: {
    parse() {
      const lines = this.csv.split( "\n" );
      const ret = [];

      for( const line of lines ) {
        if( line.trim() == "" ) continue;

        const components = line.split( this.splitters[this.splitter] );
        const newLine = {};
        for( const i in this.parsedOrder ) {
          if( components.hasOwnProperty( i ) ) {
            newLine[this.parsedOrder[i]] = components[i].trim();
          }
        }
        ret.push( newLine );
      }

      this.parsedData = ret;

      this.doChecks();
    },
    doChecks() {
      const done = [];
      const ret = this.parsedData;
      for( const i in ret ) {
        if( ret.hasOwnProperty( i ) ) {
          const item = ret[i];

          //no identifier
          if( item.identifier.trim() == "" ) {
            item.identifier = item.name.split( " " ).map( w => w[0] ).join( "" ).toUpperCase();
          }

          //no name
          if( item.name.trim() == "" ) {
            item.name = item.identifier;
          }

          if( done.indexOf( item.identifier ) >= 0 ) {
            item.duplicate = true;
            item.ignore = true;
          } else {
            item.duplicate = false;
            item.ignore = false;
          }

          if( item.identifier.trim() == "" ) {
            item.ignore = true;
            item.cantAdd = true;
          } else {
            item.cantAdd = false;
          }

          done.push( item.identifier );

          this.checkPortfolio( item, i );
          this.checkCustomer( item, i );
          this.checkTeam( item, i );
        }
      }
    },
    checkPortfolio( portfolio, i ) {
      if( portfolio.identifier ) {
        this.$apollo.query( {
          query: gql`
            query Portfolio($identifier: String!) {
              portfolio: Portfolio(identifier: $identifier) {
                _id,
                name
              }
            }
          `,
          variables: {
            identifier: portfolio.identifier,
          },
        } ).then( res => {
          if( res.data.portfolio ) {
            this.parsedData[i].portfolioId = res.data.portfolio._id;
            this.parsedData[i].exists = true;
            this.parsedData[i].ignore = true;
          } else {
            this.parsedData[i].exists = false;
          }
        } ).catch( () => {
          //TODO error
        } );
      }
    },
    checkCustomer( portfolio, i ) {
      this.$apollo.query( {
        query: gql`
          query CustomerFromName($name: String!) {
            customer: CustomerFromName(name: $name) {
              _id,
              name
            }
          }
        `,
        variables: {
          name: portfolio.customer,
        },
      } ).then( res => {
        if( res.data.customer ) {
          this.parsedData[i].customerExists = true;
          this.parsedData[i].customerId = res.data.customer._id;
        } else {
          this.parsedData[i].customerExists = false;
          this.parsedData[i].ignore = true;
        }
      } ).catch( () => {
        //TODO error
      } );
    },
    checkTeam( portfolio, i ) {
      this.$apollo.query( {
        query: gql`
          query Team($identifier: String!) {
            team: Team(identifier: $identifier) {
              _id,
              name
            }
          }
        `,
        variables: {
          identifier: portfolio.team,
        },
      } ).then( res => {
        if( res.data.team ) {
          this.parsedData[i].teamExists = true;
          this.parsedData[i].teamId = res.data.team._id;
        } else {
          this.parsedData[i].teamExists = false;
        }
      } ).catch( () => {
        //TODO error
      } );
    },
    importData() {
      this.importPortfolios();
      this.addTeams();
    },
    importPortfolios() {
      for( const portfolio of this.parsedData ) {
        if( !portfolio.ignore ) {
          const portfolioInput = {
            identifier: portfolio.identifier,
            name: portfolio.name,
            customer: portfolio.customerId,
          };
          this.$apollo.mutate( {
            mutation: gql`
              mutation addPortfolio($portfolio: PortfolioInput!) {
                portfolio: addPortfolio(portfolio: $portfolio) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              portfolio: portfolioInput,
            },
          } ).then( res => {
            if( res.data.portfolio ) {
              portfolio.made = true;
              portfolio.portfolioId = res.data.portfolio._id;
            }
          } ).catch( err => {
            this.$catchError( err );
            //TODO
          } );
        }
      }
    },
    addTeams() {
      if( this.parsedData.map( p => !!p.portfolioId || p.cantAdd ).indexOf( false ) >= 0 ) {
        setTimeout( () => {
          this.addTeams();
        }, 100 );
      } else {
        for( const portfolio of this.parsedData ) {
          if( !portfolio.cantAdd || !portfolio.teamExists ) {
            const variables = {
              portfolio: portfolio.portfolioId,
              team: portfolio.teamId,
            };
            this.$apollo.mutate( {
              mutation: gql`
                mutation addTeamToPortfolio($portfolio: ObjectID!, $team: ObjectID!) {
                  portfolio: addTeamToPortfolio(portfolio: $portfolio, team: $team) {
                    _id,
                    name
                  }
                }
              `,
              variables,
            } ).then( res => {
              if( res.data.portfolio ) {
                portfolio.teamAdded = true;
              }
            } ).catch( err => {
              this.$catchError( err );
              //TODO
            } );
          }
        }
      }
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    parsedOrder() {
      const ret = [];
      for( const i of this.order.split( ',' ) ) {
        ret.push( i.trim() );
      }

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
  .Import {
    &_Settings {
      textarea {
        width: 100%;
        height: 200px;
      }
    }
  }
</style>
