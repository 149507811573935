<template>
  <Modal
    ref="modal"
    :title="title"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Component..."

    submitText="Add component"

    @submit="submitComponent"
    @close="reset()">
    <Notice type="info" size="micro">{{$gettext('Components are elements that are duplicated across multiple pages with exactly the same characteristics, code and colours.')}}</Notice>
    <div v-if="addToExisting&&!doAddToExisting" class="ComponentModal_AddToExisting">
      <Button size="small" @click="triggerAddToExisting()">Show existing components</Button>
    </div>
    <template v-if="doAddToExisting && existingComponents">
      <ul v-if="existingComponents.length" class="ComponentModal_ExistingComponents">
        <li v-for="component in existingComponents" :key="component._id" class="ComponentModal_ExistingComponents_Item">
          {{ component.identifier }}
          <Button size="micro" :aria-label="`Add this page to component: ${component.identifier}`" @click="mutateAddToExisting( component._id )">Add</Button>
        </li>
      </ul>
      <Notice type="alert" size="small" v-else>{{ $gettext('No existing components, add one below') }}</Notice>
    </template>
    <template v-if="!doAddToExisting || doAddToExisting&&existingComponents.length==0">
      <FormInput ref="identifier" idRoot="component_" :label="$gettext('Unique Name')" v-model="identifier" :hintText="$gettext('This must be unique to the project')" :validation="['not-empty', 'max-70-chars']"/>
      <FormInput idRoot="component_" :label="$gettext('Description')" v-model="description"/>
      <AriaSearchSelectList v-if="pages.length" v-model:items="pages"/>
      <p v-else v-translate>No pages</p>
      <AriaSearchSelect ref="pageselect" :label="$gettext('Add a Page')" :dropdown="true" idRoot="page" :gqlOptions="['PAGES_BY_REPORT', report]" @selected="addPage"/>
      <Button v-if="!gotPages || ( gotPages && pages.length<numberOfPages )" size="micro" @click="addAllCurrentPages()">{{$gettext('Add all pages to this component')}}</Button><br />
      <template v-if="gotPages">
        <input type="checkbox" v-model="addFuturePages" id="addFuturePages" />&nbsp;<label for="addFuturePages">Add all future pages as they are made</label>
      </template>
      <FormImage ref="ssImage" idRoot="page_" :label="$gettext('Screenshot')" v-model="screenshot"/>
    </template>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import FormImage from '@/components/Form/Image';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';
import AriaSearchSelectList from '@/components/Aria/SearchSelectList';

export default {
  name: 'ComponentModal',
  data() {
    return {
      title: 'New Component',
      identifier: '',
      description: '',
      pages: [],
      addFuturePages: false,
      // elements: [],
      screenshot: '',
      submitting: false,

      gotPages: false,
      numberOfPages: 0,
      
      addToExisting: false,
      pageToAdd: null,
      doAddToExisting: false,
      existingComponents: false,
    };
  },
  methods: {
    addAllCurrentPages() {
      this.$apollo.query( {
        query: gql`query Options($func: String!, $param: String) {
          options: Options(function: $func, param: $param) {
            value,
            display,
            search
          }
        }`,
        variables: {
          func: 'PAGES_BY_REPORT',
          param: this.report,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        const fetchedpages = res.data.options;
        fetchedpages.forEach( page => {
          this.addPage( page.value );
        } );
        this.gotPages = true;
        this.numberOfPages = fetchedpages.length;
      } ).catch( () => {
        this.$alerts.coded( 'F2904', 'E086' ); //see notifications spreadsheet
      } );
    },
    addPage( page ) {
      if( this.pages.map( p => p._id ).indexOf( page ) < 0 ) {
        this.$apollo.query( {
          query: gql`
            query Page($id: ObjectID!) {
              page: Page(id: $id) {
                _id,
                name
              }
            }
          `,
          variables: {
            id: page,
          },
        } ).then( res => {
          this.pages.push( res.data.page );
        } ).catch( () => {
          this.$alerts.coded( 'E046', 'F2401' ); //see notifications spreadsheet
        } );
      }
    },
    // rmPage: function (page) {
    //   this.pages.remove(page);
    // },
    submitComponent() {
      this.$hugrvalidate( [ 'identifier' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.$apollo.mutate( {
            mutation: gql`mutation addComponent($component: ComponentInput!) {
              component: addComponent(component: $component) {
                _id,
                identifier,
                description,
                pages {
                  _id,
                  name
                }
              }
            }`,
            variables: {
              component: {
                reportVersion: this.report,
                identifier: this.identifier,
                description: this.description,
                pages: this.pages.map( page => page._id ),
                // elements: this.elements,
                screenshot: this.screenshot,
                futurePagesAdded: this.addFuturePages,
              },
            },
          } ).then( res => {
            this.identifier = '';
            this.description = '';
            this.pages = [];
            // this.elements = [];
            this.$refs.ssImage.clear();
            this.screenshot = '';

            this.$emit( 'added', res.data.component );
            this.$alerts.success( 'Component added' );
          } ).catch( err => {
            if( err.message == 'component name is not unique' ) {
              this.$refs.identifier.addError( 'Component name must be unique' );
            } else {
              this.$alerts.coded( 'E047', 'F2402' ); //see notifications spreadsheet
            }
          } );
        }
      } );
    },

    setAddToExisting( page ) {
      this.addToExisting = true;
      this.pageToAdd = page;
    },
    triggerAddToExisting() {
      this.title = 'Add to existing component';
      this.doAddToExisting = true;

      this.$apollo.query( {
        query: gql`
          query ComponentsFromVersion($id: ObjectID!) {
            components: ComponentsFromVersion(id: $id) {
              _id,
              identifier,
            }
          }
        `,
        variables: {
          id: this.report,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.existingComponents = res.data.components;
      } ).catch( () => {
        this.$alerts.coded( 'TODO', 'TODO' ); //see notifications spreadsheet
      } );
    },
    mutateAddToExisting( component ) {
      this.$apollo.mutate( {
        mutation: gql`mutation addPageToComponent($page: ObjectID!, $component: ObjectID!) {
          component: addPageToComponent(page: $page, component: $component) {
            _id,
          }
        }`,
        variables: {
          page: this.pageToAdd,
          component,
        },
      } ).then( res => {
        this.$emit( 'added', res.data.component );
        this.$alerts.success( 'Page added to component' );
        this.reset();
      } ).catch( () => {
        this.$alerts.coded( 'TODO', 'TODO' ); //see notifications spreadsheet
      } );
    },

    show() {
      this.reset();
      this.$refs.modal.show();
      setTimeout( () => {
        this.$refs['pageselect'].refetch();
      }, 100 );
    },
    reset() {
      this.pages = [];
      this.addFuturePages = false;
      this.gotPages = false;
      this.numberOfPages = 0;
      this.addToExisting = false;
      this.pageToAdd = null;
      this.doAddToExisting = false;
      this.existingComponents = false;
      this.$refs.modal.reset();
    },
  },
  props: {
    report: {
      type: String,
      required: true,
    },
  },
  components: {
    FormInput,
    FormImage,
    AriaSearchSelect,
    AriaSearchSelectList,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';
  .padded {
      margin-left: 5px;
  }
  .pageDisclaimer {
      margin-top: 5px;
      font-weight: bold;
  }

  .ComponentModal {
    &_AddToExisting {
      text-align: center;
      border-bottom: 1px solid $hugr-colours-grey;
      margin: 16px 0;
      padding: 16px;
    }

    &_ExistingComponents {
      list-style: none;
      margin: 0;
      padding: 0;
      &_Item {
        padding: 8px;
        border-left: 5px solid $hugr-colours-grey;
        background: lighten( $hugr-colours-grey, 13% );
        margin: 16px 0;
        button {
          float: right;
          top: -4px;
        }
      }
    }
  }

  ._darkMode .ComponentModal {
    &_ExistingComponents{
      &_Item {
        background: darken( $hugr-colours-primary, 10% );
      }
    }
  }
</style>
