<template>
  <Modal
    ref="modal"
    :title="( editedUser&&editedUser.contactOnly?$gettext( 'Edit contact:' ):$gettext( 'Edit user:' ) ) + ` ${editedUser.name} (${editedUser.email})`"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submitUser"
    
    @close="reset()"
    :ready="editedUser">
    <FormInput ref="name" idRoot="user_" :label="$gettext('Name')" v-model="editedUser.name" :validation="['not-empty']"/>
    <FormInput ref="phone" idRoot="user_" :label="$gettext('Phone Number')" v-model="editedUser.phoneNumber" />
    <FormInput ref="email" idRoot="user_" :label="$gettext('Email')" v-model="editedUser.email" :validation="['not-empty', 'email']" :disabled="!hasPermission('Users','Update')" :hintText="$gettext('Be careful when changing email as this will change their login information')" />
    <FormSelect v-if="!editedUser.contactOnly&&hasPermission('SetUserPermissionGroup', 'SetLegacyACL')" idRoot="user_" :label="$gettext('User Type')" v-model="editedUser.aclevel" :options="{
        0: $gettext('Admin'),
        1: $gettext('Tester'),
        2: $gettext('Customer')
      }"/>
    <FormSelect v-if="hasPermission('SetUserPermissionGroup', 'Any') || ( hasPermission('SetUserPermissionGroup', 'WithinGroupCategories') && Object.keys(niceList).length > 0 )" :key="'permission-key'" ref="perms" idRoot="permissiongroup_" :label="$gettext('Permission Group')" v-model="selectedGroup" :options="niceList"/>
    <FormTextArea idRoot="user_" :label="$gettext('Public Notes')" v-model="editedUser.notes" />
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters } from 'vuex';

import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';
import FormTextArea from '@/components/Form/TextArea';

export default {
  name: 'EditUserModal',
  data() {
    return {
      editedUser: false,
      permissionGroups: false,
      selectedGroup: false,
      submitting: false,
    };
  },
  apollo: {
    permissionGroups: {
      query: gql`
        query permissionGroups {
          permissionGroups: PermissionGroups {
            _id,
            name,
            categories {
              category {
                _id
              },
              hierarchy
            }
          }
        }
      `,
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission' ] ),
    niceList() {
      const list = {};
      this.permissionGroups.forEach( group => {
        let hierarchyMatch = false;
        group.categories.forEach( pairTwo => {
          this.user.permissionGroup?.categories.forEach( pair => {
            if ( ( pair.category._id == pairTwo.category._id && pair.hierarchy > pairTwo.hierarchy ) ) {
              hierarchyMatch = true;
            }
        } );
      } );

      if ( this.hasPermission( 'SetUserPermissionGroup', 'Any' ) || ( this.hasPermission( 'SetUserPermissionGroup', 'WithinGroupCategories' ) && hierarchyMatch ) ) {
        list[group._id] = group.name;
      }

      } );
      
      return list;
    },
    chosenGroup() {
      return this.permissionGroups.filter( group => {
        return ( ( group._id == this.selectedGroup ) );
      } )[0];
    },
  },
  methods: {
    submitUser() {
      const validations = [ 'name', 'email', 'perms' ];
      this.$hugrvalidate( validations, this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation editUser($id: ObjectID!, $email: String!, $name: String!, $aclevel: Int, $permissionGroup: ObjectID, $notes: String, $phoneNumber: String) {
                resultUser: editUser(id: $id, email: $email, name: $name, aclevel: $aclevel, permissionGroup: $permissionGroup, notes: $notes, phoneNumber: $phoneNumber  ) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              id: this.editedUser._id,
              email: this.editedUser.email,
              phoneNumber: this.editedUser.phoneNumber,
              name: this.editedUser.name,
              notes: this.editedUser.notes,
              aclevel: this.editedUser.aclevel ? parseInt( this.editedUser.aclevel ) : null,
              permissionGroup: this.selectedGroup ? this.selectedGroup : null,
            },
          } ).then( res => {
            this.submitting = false;
            this.reset();
            this.$alerts.success( 'User updated!', `User ${res.data.resultUser.name} has been updated successfully` );
            this.$emit( 'success', res.data.resultUser._id );
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.coded( 'E068', 'F3901', '', error ); //see notifications spreadsheet
          } );
        }
      } );
    },
    show( id ) {
      this.$apollo.query( {
        query: gql`
          query User($id: ObjectID!) {
            editedUser: User(id: $id) {
              _id,
              name,
              email,
              phoneNumber,
              aclevel,
              notes,
              contactOnly,
              permissionGroup {
                _id,
                name
              }
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.editedUser = { ...res.data.editedUser };
        if( typeof this.editedUser.aclevel == 'undefined' ) this.editedUser.aclevel = 2;

        if( this.editedUser.permissionGroup ) this.selectedGroup = this.editedUser.permissionGroup._id;
        else this.selectedGroup = false;

        setTimeout( () => { this.$refs.modal.show() }, 100 );

      } ).catch( () => { this.$alerts.coded( 'E018', 'F3902' ) } );
    },
    reset() {
      this.editedUser = false;
      this.$refs.modal.reset();
    },
  },
  components: {
    FormInput,
    FormTextArea,
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
