<template>
  <form @submit.prevent="submit">
    <fieldset>
      <legend v-translate>Add Criterion</legend>
      <FormInput idRoot="criterion_" :label="$gettext('identifier')" v-model="identifier" />
      <FormInput idRoot="criterion_" :label="$gettext('criterion')" v-model="criterion" />
      <FormInput idRoot="criterion_" :label="$gettext('title')" v-model="title" />
      <FormInput idRoot="criterion_" :label="$gettext('level')" v-model="level" />
      <FormInput idRoot="criterion_" :label="$gettext('description')" v-model="description" />
      <FormInput idRoot="criterion_" :label="$gettext('url')" v-model="url" />
      <button htype="submit">{{$gettext('Add')}}</button>
    </fieldset>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import FormContainer from '@/components/Form/Container';
import FormInput from '@/components/Form/Input';

export default {
  name: 'CriterionForm',
  data() {
    return {
      identifier: '',
      criterion: '',
      title: '',
      level: '',
      description: '',
      url: '',
    };
  },
  methods: {
    ...mapActions( [ 'jwtemit' ] ),
    submit() {
      this.jwtemit( [ 'PUT_CRITERION', {
        guideline: this.guideline,
        identifier: this.identifier,
        criterion: this.criterion,
        title: this.title,
        level: this.level,
        description: this.description,
        url: this.url,
      } ] );
    },
  },
  props: {
    guideline: String,
  },
  components: {
    FormInput,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
