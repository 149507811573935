/**
 * Localisation setup
 */
import config from '../../config';
import { createGettext } from 'vue3-gettext';
import translations from '../../translations/translations.json';

const availableLanguages = {};
const allowedLocales = config.allowedLocales.split( ',' );
if( allowedLocales.indexOf( 'all' ) >= 0 || allowedLocales.indexOf( 'en_GB' ) >= 0 ) {
  availableLanguages['en_GB'] = 'British English';
}
if( allowedLocales.indexOf( 'all' ) >= 0 || allowedLocales.indexOf( 'et' ) >= 0 ) {
  availableLanguages['et'] = 'Estonian';
}
if( allowedLocales.indexOf( 'all' ) >= 0 || allowedLocales.indexOf( 'en_ho' ) >= 0 ) {
  availableLanguages['en_ho'] = 'Home Office English';
}

export default createGettext( {
  availableLanguages,
  defaultLanguage: config.locale,
  translations,
  silent: true,
} );
