<template>
  <Modal ref="modal" :title="$gettext('Add a card')" class="PaymentMethodModal" @close="reset()">
    <form @submit="submit">
      <FieldSet :legend="$gettext('Card details')">
        <FormInput ref="cardnumber" idRoot="card_" :label="$gettext('Card number')" v-model="cardNumber" placeholder="**** **** **** ****" :validation="[{
            regex: /^[0-9 ]{16,19}$/,
            message: $gettext('Not a valid card number')
          },
          'not-empty']"
          @keyup="cardFormat()"
          @blur="cardFormat()"
        />

        <fieldset :class="['PaymentMethodModal_Expiry', expErr!=''?'_error':'']">
          <legend>{{$gettext('Expiry')}}</legend>
          <span class="PaymentMethodModal_Expiry_Inp _month">
            <label for="cardExpMonth">{{$gettext('Month')}}</label>
            <input id="cardExpMonth" ref="expmonth" type="number" limit="2" placeholder="mm" v-model="expMonth" class="_month" :aria-describedby="expErr!=''?'expError':false" @keyup="mmFormat" @keydown="mmFormat"/>
          </span>
          <span class="PaymentMethodModal_Expiry_Inp _year">
            <label for="cardExpYear">{{$gettext('Year')}}</label>
            <input id="cardExpYear" ref="expyear" type="number" limit="2" placeholder="YY" v-model="expYear" class="_year" :aria-describedby="expErr!=''?'expError':false" @keyup="yyFormat" @keydown="yyFormat"/>
          </span>
          <span id="expError" class="_error" v-if="expErr!=''">{{ expErr }}</span>
        </fieldset>

        <FormInput ref="cvc" idRoot="card_" type="number" :label="$gettext('CVC')" v-model="cvc" placeholder="123" :validation="[{
              regex: /^[0-9]{3}$/,
              message: $gettext('CVC must be 3 numbers')
            },
            'not-empty']"/>

        <FormInput ref="name" idRoot="card_" :label="$gettext('Cardholder name')" v-model="cardHolderName" :validation="['not-empty']"/>

      </FieldSet>
      <FieldSet :legend="$gettext('Billing address')">
        <FormInput ref="addressline1" idRoot="add_" :label="$gettext('Line 1')" v-model="addLine1" placeholder="1 test place" :validation="['not-empty']" />
        <!-- <FormInput ref="addressline2" idRoot="add_" label="Line 2" v-model="addLine2" /> -->

        <!-- <FormInput ref="addresscity" idRoot="add_" label="City" v-model="addCity" /> -->
        <!-- <FormInput ref="addresscounty" idRoot="add_" label="County" v-model="addCounty" /> -->

        <FormInput ref="addresspostcode" idRoot="add_" :label="$gettext('Post code')" v-model="addPostcode" :validation="['postcode', 'not-empty']" />

        <!-- country: String -->

      </FieldSet>

      <Notice v-if="error!=''" aria-live="polite" size="small" type="error">{{ error }}</Notice>

      <div class="actions">
        <Button @click.prevent="reset">{{$gettext('Cancel')}}</Button>
        <Button type="secondary" htype="submit">{{$gettext('Add')}}</Button>
      </div>
    </form>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import FormFieldSet from '@/components/Form/FieldSet';

import moment from "moment";

export default {
  name: 'PaymentMethodModal',
  data() {
    return {
      cardNumber: '',
      cardHolderName: '',
      cvc: '',
      expMonth: '',
      expYear: '',

      expErr: '',

      addLine1: '',
      addLine2: '',
      addCity: '',
      addCounty: '',
      addPostcode: '',
      addCountry: '',

      error: '',
    };
  },
  methods: {
    submit() {
      this.expErr = '';

      const testNotEmpty = this.expMonth != '' || this.expYear != '';
      const testFormat = moment( `${this.expMonth}${this.expYear}`, "MMYY" ).isValid();
      const testFuture = moment() < moment( `${this.expMonth}${this.expYear}`, "MMYY" ).add( 1, 'months' );

      if( !testNotEmpty ) {
        this.expErr = 'This cannot be empty';
      } else if( !testFormat ) {
        this.expErr = 'Must be format mm-yy';
      } else if( !testFuture ) {
        this.expErr = 'This card has expired';
      }
      this.$hugrvalidate( [ 'cardnumber', 'cvc', 'name', 'addressline1', 'addresspostcode' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.doSubmit();
        }
      } );
    },
    doSubmit() {
      this.$apollo.mutate( {
        mutation: gql`mutation addPaymentMethod($card: stripeCardInput!, $billing: stripeBillingInput!) {
          card: addPaymentMethod(card: $card, billing: $billing) {
            id,
          }
        }`,
        variables: {
          card: {
            cardNumber: this.cardNumber,
            expMonth: parseInt( this.expMonth ),
            expYear: parseInt( this.expYear ),
            cvc: parseInt( this.cvc ),
            cardHolderName: this.cardHolderName,
          },
          billing: {
            lineOne: this.addLine1,
            postCode: this.addPostcode,
          },
        },
      } ).then( res => {
        this.$emit( 'new' );
        this.reset();
      } ).catch( () => {
        this.error = 'Something went wrong adding your card, please check your details and try again.';
      } );
    },
    show() {
      this.$refs.modal.show();
    },
    reset() {
      this.cardNumber = '';
      this.cardHolderName = '';
      this.cvc = '';
      this.expMonth = '';
      this.expYear = '';

      this.expErr = '';

      this.addLine1 = '';
      this.addLine2 = '';
      this.addCity = '';
      this.addCounty = '';
      this.addPostcode = '';
      this.addCountry = '';

      this.error = '';

      this.$refs.modal.reset();
    },

    cardFormat() {
      const strippedNum = this.cardNumber.replace( /[ -.]/g, '' ).trim();
      const num = strippedNum.match( /.{1,4}/g );
      this.cardNumber = num.join( ' ' );
    },

    mmFormat( e ) {
      if( this.expMonth.length == 2 && e.keyCode != 8 ) {
        this.$refs.expyear.focus();
      }
    },
    yyFormat( e ) {
      // const valid = [0,1,2,3,4,5,6,7,8,9];
      if( e.keyCode == 8 && this.expYear == '' ) {
        this.$refs.expmonth.focus();
      }
    },
  },
  computed: {
  },
  props: {
  },
  components: {
    FormInput,
    FieldSet: FormFieldSet,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/assets/styles/variables/_colours.scss';

  .PaymentMethodModal {
    fieldset {
      margin: 0 0 32px;
    }
    &_Expiry {
      padding: 0;
      margin: 0 !important;
      border: 0;
      box-shadow: none;
      legend {
        margin: 10px 0;
        display: block;
        color: $hugr-colours-primary;
        padding: 0;
        margin-bottom: 22px;
        border: 0;
      }
      &_Inp {
        position: relative;
        label {
          position: absolute;
          font-size: 0.6em;
          top: -22px;
        }
        input {
          border: 1px solid #95989c;
          padding: 10px;
          width: 50px;
          border-radius: 2px;
          &._month {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
          }
          &._year {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      &._error {
        .PaymentMethodModal_Expiry_Inp {
          input {
            border: 1px solid red;
            &._month {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-right: 0;
            }
            &._year {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
        span._error {
          display: block;
          font-size: 9pt;
          color: red;
        }
      }
    }
  }

  ._darkMode .PaymentMethodModal {
    &_Expiry {
      box-shadow: none;
      legend {
        background: transparent;
        color: $hugr-colours-grey;
      }
      &_Inp {
        input {
          background: $hugr-colours-primary;
          color: #FFF;
        }
      }
      &._error {
        .PaymentMethodModal_Expiry_Inp {
          input {
            border: 1px solid #f77373;
            &._month {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-right: 0;
            }
            &._year {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
        span._error {
          display: block;
          font-size: 9pt;
          color: #f77373;
        }
      }
    }
  }
</style>
