<template>
  <ul v-if="reports.length" class="OnDeck">
    <template v-for="report in reports" v-bind:key="report.identifier">
      <div class="OnDeck_Block">
      <li>
        <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.identifier}`" :class="['OnDeck_Item', pinnedAssessments.indexOf( report._id ) >= 0?'_pinned':'' ]">
          <div class="OnDeck_Item_Container">
            <!-- TODO: <div class="OnDeck_Item_Container_Updated">
              <h5 class="OnDeck_Item_Container_Title_Title" v-translate>Last updated:</h5>
              <span class="OnDeck_Item_Container_Title_Identifier">{{report.identifier}}</span>
            </div> -->
            <div class="OnDeck_Item_Container_Title">
              <Icon class="OnDeck_Item_Container_Title_Pin" v-if="pinnedAssessments.indexOf( report._id ) >= 0" type="solid" icon="thumbtack" title="Pinned to dashboard" aria-label="Pinned to dashboard"/>
              <h5 :class="['OnDeck_Item_Container_Title_Title']">{{report.title}}</h5>
              <span class="OnDeck_Item_Container_Title_Identifier">{{report.identifier}}</span>
            </div>
            <div v-if="this.hasPermission( 'QualityOfLife', 'PinToDashboard' )" class="OnDeck_Item_Container_Opts">
              <!-- <Button type="icon" :icon="['solid', 'ellipsis-vertical']">Options</Button> -->
              <ButtonSet label="Options" :dropdown="true" type="icon" :icon="['solid', 'ellipsis-vertical']">
                <Button v-if="pinnedAssessments.indexOf( report._id ) == -1" size="small" type="transparent" @click.prevent.stop="pinAssessment( report._id )">{{$gettext('Pin to dashboard')}}</Button>
                <Button v-else size="small" type="transparent" @click.prevent.stop="unPinAssessment( report._id )">{{$gettext('Unpin from dashboard')}}</Button>

                <!-- <Button size="small" type="transparent">{{$gettext('Remove from dashboard')}}</Button> -->
              </ButtonSet>
            </div>
            <div class="OnDeck_Item_Container_Status">
              <CompliancePill class="OnDeck_Item_Container_Status_Pill" :progress="report.currentVersion.progress" :isCompliant="report.currentVersion.isCompliant" :issues="report.issueCount" />
            </div>
            <div class="OnDeck_Item_Container_Progress" v-if="showInProgress">
              <ProgressBar class="OnDeck_Item_Container_Progress_Bar" id="reportprogress" label="Progress: " type="simple" :value="report.currentVersion.progress" :hideLabel="true" :direction="'row'"/>
            </div>
          </div>
        </router-link>
      </li>
    </div>
    </template>
  </ul>
  <p v-else-if="!$apollo.queries.reports.loading" class="OnDeck_Empty">
    {{ $gettext( "No %{projectString}s opened in the last 7 days!", { projectString: $hugrConfig.projectString } ) }}

  </p>
  <p v-else class="OnDeck_Empty" v-translate>Loading...</p>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters } from 'vuex';
import ProgressBar from '@/components/UI/ProgressBar.vue';
import CompliancePill from '@/components/CompliancePill.vue';

export default {
  name: 'OnDeck',
  created() {
    this.getPinned();
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            showInProgress
          }
        }
      `,
    } ).then( res => {
      this.showInProgress = res.data.settings.showInProgress;
    } );
  },
  data() {
    return {
      reports: false,
      pinnedAssessments: [],

      showInProgress: false,
    };
  },
  apollo: {
    reports: {
      query: gql`
        {
          reports: ReportsOnDeck {
            _id,
            title,
            identifier,
            issueCount,
            currentVersion {
              progress,
              isCompliant,

            }
          }
        }
      `,
    },
  },
  methods: {
    getPinned() {
      this.$apollo.query( {
        query: gql`
          query GetUserSetting($id: ObjectID!, $key: String!) {
            pinnedAssessments: GetUserSetting(id: $id, key: $key)
          }
        `,
        variables: {
          id: this.user.id,
          key: 'pinnedAssessments',
        },
      } ).then( res => {
        if( res.data.pinnedAssessments != 'undefined' ) {
          this.pinnedAssessments = JSON.parse( res.data.pinnedAssessments );
          this.getPinnedAssessments();
        }
      } ).catch( error => {
        this.$alerts.coded( 'E002', 'F6001', '', error ); //see notifications spreadsheet
      } );
    },
    getPinnedAssessments() {
      this.$apollo.query( {
        query: gql`
          query ReportsFromArray($reports: [ObjectID]) {
            reports: ReportsFromArray(reports: $reports) {
              _id,
              title,
              identifier,
              issueCount,
              currentVersion {
                progress,
                isCompliant,

              }
            }
          }
        `,
        variables: {
          reports: this.pinnedAssessments,
        },
      } ).then( res => {
        if( !Array.isArray( this.reports ) ) this.reports = res.data.reports;
        else this.reports = [ ...res.data.reports, ...this.reports.filter( r => this.pinnedAssessments.indexOf( r._id ) == -1 ) ];
      } ).catch( error => {
        this.$alerts.coded( 'E089', 'F6002', '', error ); //see notifications spreadsheet
      } );
    },
    pinAssessment( id ) {
      if( this.pinnedAssessments.indexOf( id ) == -1 ) this.pinnedAssessments.push( id );
      this.$apollo.mutate( {
        mutation: gql`
          mutation setUserSetting($id: ObjectID!, $key: String!, $value: String!) {
            res: setUserSetting(id: $id, key: $key, value: $value)
          }
        `,
        variables: {
          id: this.user.id,
          key: 'pinnedAssessments',
          value: JSON.stringify( this.pinnedAssessments ),
        },
      } ).catch( error => {
        this.$alerts.coded( 'E085', 'F6003', '', error ); //see notifications spreadsheet
      } );
    },
    unPinAssessment( id ) {
      const pindex = this.pinnedAssessments.indexOf( id );
      if( pindex >= 0 ) this.pinnedAssessments.splice( pindex, 1 );
      this.$apollo.mutate( {
        mutation: gql`
          mutation setUserSetting($id: ObjectID!, $key: String!, $value: String!) {
            res: setUserSetting(id: $id, key: $key, value: $value)
          }
        `,
        variables: {
          id: this.user.id,
          key: 'pinnedAssessments',
          value: JSON.stringify( this.pinnedAssessments ),
        },
      } ).catch( error => {
        this.$alerts.coded( 'E085', 'F6004', '', error ); //see notifications spreadsheet
      } );
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission' ] ),
  },
  components: {
    ProgressBar,
    CompliancePill,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';

  .OnDeck {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px 20px;
    
    &_Empty {
      font-weight: bold;
      text-align: center;
      padding: 50px;
    }

    &_Block {
      width: 260px;
      flex: 1 1 auto
    }

    &_Item {
      display: block;
      color: $hugr-colours-primary;
      text-decoration: none;

      padding: 16px;
      background-color: $hugr-colours-card-background;
      // width: 275px;
      // max-width: 275px;

      &:focus, &:hover {
        background: lighten($hugr-colours-grey, 10%);
      }

      &._pinned {
        background-color: lighten( $dig-blue, 70% );

        &:focus, &:hover {
          background: lighten( $dig-blue, 65% );
        }
      }

      &_Container {
        // display: flex;
        // flex-direction: column;
        // row-gap: 16px;
        position: relative;
        height: 100%;

        &_Title {
          line-height: 8px;
          margin-bottom: 32px;
          // margin-top: 16px;

          &_Pin {
            position: absolute;
            top: -8px;
            left: -10px;
          }
          &_Title {
            font-size: 1.4em;
            color: $hugr-colours-new-primary;
            line-height: 40px;
            font-weight: bold;

            margin-top: 0px;
            margin-bottom: 0px;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &_Identifier {
            font-size: 1em;
            color: $hugr-colours-new-grey;
            font-weight: bold;
          }
        }

        &_Opts {
          position: absolute;
          top: 0px;
          right: -20px;

          button {
            color: black;
          }
        }

        &_Progress {
          display: flex;
          flex-direction: column;
          width: 100%;

          &_Title {
            line-height: 30px;
            font-size: 18px;
            font-weight: 700;
            color: $hugr-colours-new-primary;
          }

          &_Bar {
            width: 100%;
          }

        }

        &_Status {
          // display: flex;
          // flex-direction: column;
          margin-bottom: 16px;

          &_Title {
            line-height: 30px;
            font-size: 18px;
            font-weight: 700;
            color: $hugr-colours-new-primary;
          }

          &_Pill {
            max-width: 25%;
          }
        }
      }

      &_Title {
        font-weight: bold;
        margin: 0;
        position: relative;
        display: block;
      }

      &_Identifier {
        font-size: 0.6em;
      }
    }
  }

  ._darkMode .OnDeck{
    &_Item {
      background: lighten( $hugr-colours-primary, 10% );
      &_Container {
        &_Title {
          &_Title {
            color: #FFF;
          }
          &_Identifier {
            color: $hugr-colours-grey;
          }
        }
      }

      &:focus, &:hover {
        background: darken($hugr-colours-primary, 10%);
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .OnDeck {
      justify-content: left;
    }
  }
</style>
