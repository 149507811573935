<template>
  <Modal ref="modal" :title="'Groups in category'" >
    <div>
      <ul>
        <li v-for="(group, i) in groups" v-bind:key="group._id+'-list-'+i">{{group.name}}, with hierarchy level {{getCategoryHierarchy(group.categories)}}</li>
      </ul>
    </div>
  </Modal>
</template>

<script>

export default {
  name: 'GroupsInPermissionCategoryModal',
  data() {
    return {
      
    };
  },
  methods: {
    getCategoryHierarchy( categories ) {
      let level = 0;

      categories.forEach( cat => {
        if ( cat.category._id == this.categoryID ) {
          level = cat.hierarchy;
        }
      } );

      return level;
    },
    show() {
      this.$refs.modal.show();
    },
    reset() {
      this.$refs.modal.reset();
    },
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
    categoryID: {
      type: String,
      default: '',
    },
  },
  components: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .pill {
    background-color: $hugr-colours-secondary;
    border-radius: 16px;
    padding-right: 4px;
    padding-left: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    font-size: 0.8em;
    margin-right: 5px;
    color:black;
  }

</style>
