<template>
  <div :class="['TabList']">
    <div class="TabList_Inner" role="tablist">
      <template v-for="(tab, key) of filteredTabs()" :key="tab.text">
        <button   class="TabList_Inner_Tab"
                  :ref="`tab-${key}`"
                  role="tab"
                  :aria-selected="selected[tab.text]?true:false"
                  :aria-controls="tab.controls"
                  :tabindex="selected[tab.text]?0:-1"
                  @click.prevent="activate(tab.text, tab.action)"
                  @keydown.left="goLeft(key)"
                  @keydown.right="goRight(key)">

                  <span class="_tabText">{{tab.text}}</span>

                  <template v-for="(element, keyIndex) of pill(tab)" :key="`tab-${keyIndex}-pill`">
                    <span class="pillContainer">{{pill(tab)[keyIndex]}}</span>
                  </template>
                  </button>
      </template>
    </div>
  </div>
</template>

<script>

// interface SelectedDictionary {
//   [id:string] : boolean
// }

// interface Tab {
//   text: string;
//   controls: string;
//   action: Function;
//   hidden?: Function;
//   pill?: Function;
// }

export default {
  name: 'UITabList',
  created() {
    this.parseTabs();
  },
  mounted() {
    this.goTo( this.lastIndex, false );
  },
  data() {
    return {
      selected: {},
    };
  },
  watch: {
    tabs() {
      this.parseTabs();
    },
  },
  methods: {
    filteredTabs() {
      return this.tabs.filter( tab => {
        if ( tab.hidden ) {
          return !tab.hidden();
        }

        return true;
      } );
    },
    activate( text, fn ) {
      for( const select of Object.keys( this.selected ) ) {
        this.selected[select] = false;
      }
      for ( const i in this.tabs ) {
        if ( this.tabs[i].text == text ) {
          this.$emit( 'tabChanged',i );
        }
      }
      this.selected[text] = true;
      this.$forceUpdate();
      fn();
    },
    pill( tab ) {
      if( tab.pill && typeof tab.pill == 'function' ) return tab.pill();
    },
    goLeft( key ) {
      let goto;
      if( key === 0 ) {
        goto = ( this.tabs.length - 1 );
      } else {
        goto = key - 1;
      }
      this.goTo( goto );
    },
    goRight( key ) {
      let goto;
      if( key === ( this.tabs.length - 1 ) ) {
        goto = 0;
      } else {
        goto = key + 1;
      }
      this.goTo( goto );
    },
    goTo( key, focus = true ) {
      if( typeof this.tabs[key] != 'undefined' ) {
        this.activate( this.tabs[key].text, this.tabs[key].action );
        const tabs = this.$refs[`tab-${key}`];
        const tabEl = tabs[0];
        //@ts-ignore focus does exist
        if( focus ) tabEl.focus();
      }
    },
    parseTabs() {
      let hasSelection = false;
      for( const tab of this.tabs ) {
        if( typeof this.selected[tab.text] == 'undefined' ) {
          this.selected[tab.text] = false;
        } else if( this.selected[tab.text] ) {
          hasSelection = true;
        }
      }

      if( !hasSelection && typeof this.tabs[0] != 'undefined' ) {
        this.selected[this.tabs[0].text] = true;
      }
    },
  },
  props: [ 'tabs', 'lastIndex' ],
  emits: [ 'tabChanged' ],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  @import '@/assets/styles/variables/_fonts.scss';
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_mixins.scss';
  .pillContainer {
    background-color: $hugr-colours-secondary;
    border-radius: 16px;
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    font-size: 0.8em;
    margin-right: 5px;
    color:black;
  }
  .TabList {
    width: 100%;
    margin-bottom: 40px;
    padding-bottom: 5px;
    @include horizontal-scroll;
    
    &_Inner {
      width: auto;
      height: 47px;
      overflow-y: visible;
      border-bottom: 4px solid $hugr-colours-grey;
      white-space: nowrap;
      position: relative;
      z-index: 1;

      &_Tab {
        background: transparent;
        border: none;
        padding: 15px 20px 14px;
        color: lighten($hugr-colours-primary, 20%);
        border-top: 1px solid transparent;

        position: relative;
        bottom: -2px;
        z-index: 2;
        cursor: pointer;
        // border-bottom: 1px solid $hugr-colours-grey;

        ._tabText {
          transition: background-color .5s ease-in-out 0s, color .5s ease-in-out 0s;
          border-radius: 10px;
          padding: 5px;
          font-weight: bold;
        }

        &:focus, &:hover {
          color: $hugr-colours-primary;
          ._tabText {
            background-color: lighten($hugr-colours-grey, 10%);
          }
        }

        &:focus {
          outline: none;
          border-top: 1px solid $dig-blue;
        }

        &[aria-selected="true"] {
          color: $hugr-colours-primary;
          border-bottom: 4px solid $dig-blue;
        }
      }
    }
  }

  ._darkMode .TabList {
    &_Inner {
      &_Tab {
        ._tabText {
          color: #FFF;

        }
        &:focus, &:hover {
          ._tabText {
            background-color: darken($hugr-colours-primary, 10%);
          }
        }
      }
    }
  }
</style>
