<template>
  <Modal
    ref="modal"
    :title="$gettext('Add a team member')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding user..."

    :submitText="$gettext('Add user to team')"
    @submit="submitUser"

    @close="reset()">
    <FormInput ref="email" idRoot="team_" :label="$gettext('Email')" v-model="email" :validation="['not-empty', 'email']"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import FormInput from '@/components/Form/Input';

export default {
  name: 'TeamMemberModal',
  data() {
    return {
      email: '',
      submitting: false,
    };
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  methods: {
    submitUser() {
      this.$hugrvalidate( [ 'email' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          if( this.team.domainrestriction && this.team.domainrestriction.toUpperCase() != this.email.split( '@' )[1].toUpperCase() ) {
            this.$confirm.simple( 'This person is outside of your specified domain restriction, are you sure you wish to invite them?' ).then( result => {
              if( result ) {
                this.submitMutation();
              }
            } );
          } else {
            this.submitMutation();
          }
        }
      } );
    },
    submitMutation() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation ($email: String!, $team: ObjectID!, $sender: String!) {
            inviteTeamUser(email: $email, team: $team, sender: $sender)
          }
        `,
        variables: {
          email: this.email,
          team: this.team._id,
          sender: this.user.name,
        },
      } ).then( res => {
        this.submitting = false;
        if ( res.data.inviteTeamUser ) {
          this.reset();
          this.$alerts.success( 'Team member added!', `User has been added to team, they will receive an email.` );
          this.$emit( 'success' );
        } else {
          this.$alerts.error( "Error", "Couldn't add user, have you hit your users limit?" );
        }
      } ).catch( error => {
        this.submitting = false;
        this.$alerts.coded( `E091`, `F6001`, '', error );
      } );
    },
    show() {
      this.$refs.modal.show();
    },
    reset() {
      this.email = '';

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  components: {
    FormInput,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
