<template>
  <Modal
    ref="modal"
    :title="$gettext('Edit Issue Template')"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submit"

    @close="reset()"
    :ready="template">
    <FormInput idRoot="template_" :label="$gettext('Identifier')" v-model="template.identifier"/>
    <FormInput idRoot="template_" :label="$gettext('Title')" v-model="template.title"/>
    <FormTextArea ref="description" idRoot="template_" :label="$gettext('Description')" v-model="template.description"/>
    <FormInput idRoot="template_" :label="$gettext('Metadata tags')" :hintText='$gettext(`Comma separated e.g: "image,input,custom"`)' v-model="tags"/>
    <FormInput idRoot="template_" :label="$gettext('Section')" v-model="template.section"/>
    <FormSelect idRoot="template_" :label="$gettext('Severity')" v-model="template.severity" :options="{
      0: $gettext('Advisory'),
      1: $gettext('Low'),
      2: $gettext('Medium'),
      3: $gettext('High'),
      4: $gettext('Critical')
      }" />
    <FormCheckboxSet idRoot="template" :label="$gettext('Affected users')" v-model="template.affected" :options="{
        'blind': $gettext('Blind'),
        'low-vision': $gettext('Low Vision'),
        'cognitively-impaired': $gettext('Cognitively Impaired'),
        'deaf': $gettext('Deaf'),
        'motor-impaired': $gettext('Motor Impaired')
      }" />
    <FormTextArea ref="impact" idRoot="template_" :label="$gettext('Impact')" v-model="template.impact"/>

    <ul v-if="template.criteria.length" aria-live="polite">
      <li v-for="criterion in template.criteria" v-bind:key="criterion.identifier">
        {{criterion.criterion}} {{criterion.title}} ({{$gettext('Level')}} {{criterion.level}}) [{{criterion.identifier}}]
        &nbsp;
        <button @click.prevent="rmCriterion(criterion)">{{$gettext('Remove')}}</button>
      </li>
    </ul>
    <p v-else v-translate>No criteria, add one</p>
    <AriaSearchSelect :label="$gettext('Add a criterion')" :dropdown="true" idRoot="criteria" :gqlOptions="['CRITERIA']" @selected="addCriterion"/>

    <ul v-if="template.solutions.length" aria-live="polite">
      <li v-for="solution in template.solutions" v-bind:key="solution.identifier">
        {{solution.identifier}}: {{solution.title}}
        &nbsp;
        <button @click.prevent="rmSolution(solution)">{{$gettext('Remove')}}</button>
      </li>
    </ul>
    <p v-else v-translate>No solutions, add one</p>
    <AriaSearchSelect :label="$gettext('Add a solution')" :dropdown="true" idRoot="solution" :gqlOptions="['SOLUTIONS']" @selected="addSolution"/>

    <fieldset>
      <legend v-translate>Reason Templates</legend>
      <div v-for="(reasonTemplate, i) in template.reasonTemplates" v-bind:key="'reasonTemplate-' + i">
        <textarea v-model="template.reasonTemplates[i]"></textarea>
        <button @click.prevent="template.reasonTemplates.splice(i, 1)">{{$gettext('remove')}}</button>
      </div>
      <button @click.prevent="template.reasonTemplates.push('')" type="button">{{$gettext('Add one')}}</button>
    </fieldset>

    <fieldset>
      <legend v-translate>For report type</legend>
      <div>
        <input type="checkbox" v-model="template.reportTypes" value="default" id="default"/>
        <label for="default">Accessibility - Web WCAG 2.1 AA</label>
      </div>
      <div>
        <input type="checkbox" v-model="template.reportTypes" value="aaa" id="aaa"/>
        <label for="aaa">Accessibility - Web WCAG 2.1 AAA</label>
      </div>
      <div>
        <input type="checkbox" v-model="template.reportTypes" value="mobios" id="mobios"/>
        <label for="mobios">Accessibility - Mobile - iOS</label>
      </div>
      <div>
        <input type="checkbox" v-model="template.reportTypes" value="mobandroid" id="mobandroid"/>
        <label for="mobandroid">Accessibility - Mobile - Android</label>
      </div>
      <div>
        <input type="checkbox" v-model="template.reportTypes" value="mobweb" id="mobweb"/>
        <label for="mobweb">Accessibility - Mobile - Web</label>
      </div>
      <div>
        <input type="checkbox" v-model="template.reportTypes" value="mobmixed" id="mobmixed"/>
        <label for="mobmixed">Accessibility - Mobile - Mixed</label>
      </div>

      <div>
        <input type="checkbox" v-model="template.reportTypes" value="uxdesktop" id="uxdesktop"/>
        <label for="uxdesktop">UX - Desktop</label>
      </div>
      <div>
        <input type="checkbox" v-model="template.reportTypes" value="uxmob" id="uxmob"/>
        <label for="uxdesktop">UX - Mobile</label>
      </div>

      <div>
        <input type="checkbox" v-model="template.reportTypes" value="other" id="other"/>
        <label for="other" v-translate>Other</label>
      </div>
    </fieldset>

    <FormSelect idRoot="report_" :label="$gettext('Team')" v-model="template.team" :options="{
        '0': $gettext('Just me'),
        ...teams
      }"/>

    <div>
      <input type="checkbox" id="private" v-model="template.private"><label for="private">{{$gettext('Private')}}</label>
    </div>
    <div>
      <input type="checkbox" id="locked" v-model="template.locked"><label for="locked">{{$gettext('Locked')}}</label>
    </div>
    <div>
      <input type="checkbox" id="retired" v-model="template.retired"><label for="retired">{{$gettext('Retired')}}</label>
    </div>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';

import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';
import FormTextArea from '@/components/Form/TextArea';
import FormCheckboxSet from '@/components/Form/CheckboxSet';

export default {
  name: 'EditIssueTemplateModal',
  data() {
    return {
      template: false,
      tags: '',
      submitting: false,
    };
  },
  apollo: {
    teams: {
      query: gql`
        {
          teams: Teams {
            _id
            name
          }
        }
      `,
      update: data => {
        const opts = {};
        for( const team of data.teams ) {
          opts[team._id] = team.name;
        }

        return opts;
      },
    },
  },
  methods: {
    submit() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateIssueTemplate($id: ObjectID!, $template: IssueTemplateInput!) {
            template: updateIssueTemplate(id: $id, template: $template) {
              _id
              identifier
            }
          }
        `,
        variables: {
          id: this.template._id,
          template: {
            identifier: this.template.identifier,
            title: this.template.title,
            description: this.template.description,
            section: this.template.section,
            severity: parseInt( this.template.severity ),
            affected: this.template.affected,
            impact: this.template.impact,
            extra: this.template.extra,
            metadata: this.tags.split( ',' ).map( t => t.trim() ),
            criteria: this.template.criteria.map( criterion => criterion._id ),
            solutions: this.template.solutions.map( solution => solution._id ),
            reasonTemplates: this.template.reasonTemplates,

            reportTypes: this.template.reportTypes,

            private: this.template.private,
            locked: this.template.locked,
            retired: this.template.retired,

            team: this.template.team == '0' ? null : this.template.team,
          },
        },
      } ).then( res => {
        this.submitting = false;
        this.$emit( 'success', res.data.template._id );
        this.$alerts.success( 'Issue template updated!', `The issue template ${res.data.template.identifier} has been updated successfully` );
        this.reset();
      } ).catch( error => {
        this.submitting = false;
        this.$alerts.coded( 'E056', 'F3201', '', error ); //see notifications spreadsheet
      } );
    },
    addCriterion( criterion ) {
      this.$apollo.query( {
        query: gql`
          query Criterion($id: ObjectID!) {
            criterion: Criterion(id: $id) {
              _id,
              criterion,
              title,
              level,
              identifier
            }
          }
        `,
        variables: {
          id: criterion,
        },
      } ).then( res => {
        this.template.criteria.push( res.data.criterion );
      } ).catch( () => {
        this.$alerts.coded( 'E057', 'F3202', criterion ); //see notifications spreadsheet
      } );
    },
    rmCriterion( criterion ) {
      this.template.criteria.remove( criterion );
    },

    addSolution( solution ) {
      this.$apollo.query( {
        query: gql`
          query Solution($id: ObjectID!) {
            solution: Solution(id: $id) {
              _id,
              identifier,
              title
            }
          }
        `,
        variables: {
          id: solution,
        },
      } ).then( res => {
        this.template.solutions.push( res.data.solution );
      } ).catch( () => {
        this.$alerts.coded( 'E058', 'F3203', solution ); //see notifications spreadsheet
      } );
    },
    rmSolution( solution ) {
      this.template.solutions.remove( solution );
    },

    show( id ) {
      this.$apollo.query( {
        query: gql`
          query IssueTemplate($id: ObjectID!) {
            template: IssueTemplate(id: $id) {
              _id,
              identifier,
              title,
              description,
              severity,
              affected,
              impact,
              extra,
              metadata,
              criteria {
                _id,
                criterion,
                title,
                level,
                identifier
              },
              solutions {
                _id,
                identifier,
                title
              },
              section,
              reasonTemplates

              reportTypes

              locked,
              private,
              retired,

              team {
                _id
              }
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.template = { ...res.data.template };
        this.tags = this.template.metadata.join();
        if( this.template.team ) {
          this.template.team = this.template.team._id;
        } else {
          this.template.team = '0';
        }

        //Uncaught TypeError: Cannot add property 0, object is not extensible??
        this.template.criteria = Object.assign( [], this.template.criteria );
        this.template.solutions = Object.assign( [], this.template.solutions );

        this.$refs.modal.show();
      } ).catch( () => {
        this.$alerts.coded( 'E059', 'F3204', id ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.template.identifier = '';
      this.template.title = '';
      this.template.description = '';
      this.template.section = '';
      this.template.severity = 0;
      this.template.affected = [];
      this.template.impact = '';
      this.template.metadata = [];
      this.tags = '';
      this.template.extra = '';
      this.template.criteria = [];
      this.template.solutions = [];
      this.template.reasonTemplates = [];

      this.template.reportTypes = [];

      this.template.locked = false;
      this.template.private = false;
      this.template.retired = false;
      this.template.team = 0;

      this.$refs.modal.reset();
    },
  },
  components: {
    FormInput,
    FormSelect,
    FormTextArea,
    FormCheckboxSet,
    AriaSearchSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
