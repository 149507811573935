<template>
  <div :class="[ 'FilterBar', border?'_border':'' ]">
    <div class="FilterBar_Inner">
      <div class="FilterBar_Inner_IntroText">Filter:</div>
      <slot :signal="openFilter"></slot>
      <button class="FilterBar_Inner_ClearBtn" @click="clear">Clear all filters</Button>
    </div>
    <!-- <div class="FilterBar_ActiveFilters">
      <Pill v-for="filter of activeFilters" :key="filter" :text="filter" size="small"/>
    </div> -->
  </div>
</template>

<script>
import { ref, provide } from 'vue';
// import Pill from '@/components/UI/Pill';

export default {
  name: 'UIFilterBar',
  setup() {
    const openFilter = ref( false );
    provide( 'signal', openFilter );

    return {
      openFilter,
    };
  },
  // computed: {
  //   activeFilters() {
  //     const active = [];
  //     for( const filterGroup of Object.keys( this.filters ) ) {
  //       if( !this.filters.hasOwnProperty( filterGroup ) ) continue;

  //       for( const filter of this.filters[filterGroup] ) {
  //         if( [ 'severity', 'priority' ].indexOf( filterGroup ) >= 0 ) {
  //           active.push( `${filter} ${filterGroup}` );
  //         } else {
  //           active.push( `${filterGroup}: ${filter}` );
  //         }
  //       }
  //     }
      
  //     return active;
  //   },
  // },
  methods: {
    setCurrent( current ) {
      this.openFilter = current;
    },

    clear() {
      this.$emit( 'clear' );
      for( const slot of this.$slots.default() ) {
        if( slot.type.name == 'UIFilterOpt' && slot.type.methods && slot.type.methods.doUnshowOpts ) {
          slot.type.methods.doUnshowOpts();
          slot.type.methods.ghostClear();
        }
      }
    },
  },
  props: {
    border: {
      type: Boolean,
      default: true,
    },
    clearBtn: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  // components: {
  //   Pill,
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .FilterBar {
    padding: 8px;

    &._border {
      border-top: 1px solid $hugr-colours-grey;
      border-bottom: 1px solid $hugr-colours-grey;
    }

    &_Inner {
      position: relative;
      display: flex;
      gap: 8px;

      &_IntroText {
        // border-right: 1px solid $hugr-colours-grey;
        padding: 8px 0;
        color: $hugr-colours-primary;
        transition: background-color .5s ease-in-out 0s, color .5s ease-in-out 0s;
        font-weight: bold;
        font-size: 0.9em;
      }

      &_ClearBtn {
        background: white;
        border: none;
        padding: 10px;
        position: absolute;
        right: 0;
        transition: background-color .5s ease-in-out 0s, color .5s ease-in-out 0s;
        font-weight: bold;

        &:hover, &:focus {
          background: lighten($hugr-colours-grey, 10%);
          cursor: pointer;
        }
      }

      > * {
        display: inline-block;
        vertical-align: top;
      }
    }
    &_ActiveFilters {
      display: flex;
      gap: 8px;
    }
  }

  ._darkMode .FilterBar {
    &_Inner {
      &_IntroText {
        color: #FFF;
      }

      &_ClearBtn {
        background: $hugr-colours-primary;
        color: #FFF;
        &:hover, &:focus {
          background: darken($hugr-colours-primary, 10%);
        }
      }
    }
  }
</style>
