const validatePlugin = {
  install( app ) {
    app.config.globalProperties.$hugrvalidate = async ( refs, context, extra, forceFail ) => {
      let success = true;
      let focussed = false;
      const errors = {};
      for( const ref of refs ) {

        if( context.$refs.hasOwnProperty( ref ) && "reset" in context.$refs[ref] ) {
          context.$refs[ref].reset();
        }

        let result;
        if( context.$refs.hasOwnProperty( ref ) && "validate" in context.$refs[ref] ) {
          if( typeof extra != 'undefined' && extra[ref] ) {
            result = context.$refs[ref].validate( extra[ref] );
          } else {
            result = context.$refs[ref].validate();
          }
        } else {
          result = {
            success: true,
          };
        }
        success = success && result.success;
        if( !result.success ) {
          if( !focussed ) {
            context.$refs[ref].focus(); //focus first failure
            focussed = true;
          }
          errors[ref] = result.messages;
        }
      }

      if( forceFail ) {
        success = false;
      }

      if( success ) {
        return {
          success,
        };
      }

        return {
          success,
          errors,
        };

    };
  },
};

export default validatePlugin;
