<template>
  <Body title="Solutions">
    <div class="Issues_Actions __grid">
      <Button v-if="hasPermission('Solutions', 'Create')" class="__grid_column_2" type="secondary" :icon="['solid', 'plus']" @click="$refs.solutionmodal.show()">{{$gettext('Add a solution')}}</Button>
      <Search :class="[hasPermission('Solutions', 'Create')?'__grid_column_10':'__grid_column_12']" :label="$gettext('Search all solutions')" v-model="search" :inUse="search!=''" :results="searchSolutions.length"/>
    </div>
    <TabList
      class="Solutions_Tablist"
      :tabs="tabs"
      :key="`tablist`"
    />
        <CardSet v-if="searchSolutions" :title="$gettext('Search Results')">
                <DataTablePageSize :tableName="'Solutions'" @selected="(p) => { size = p } " />
      <Loader v-if="loading" />
      <DataTable v-else
          class="Reports_Panel_Table" tablekey="solutionscat" :customisable="true" @changed="$forceUpdate()"
          :headers="[
            $gettext('Identifier'),
            $gettext('Title'),
            $gettext('Metadata'),
            { name: $gettext('Owner'), default: false },
            { name: $gettext('Team'), default: false },
            { name: $gettext('Private')+'?', default: false },
            { name: $gettext('Locked')+'?', default: false },
            { name: '', freeze: true },
          ]"
        >
        <template v-for="(solution, i) in searchSolutions" :key="solution._id">
          <tr v-if="solution.identifier!=''">
            <td v-if="$tableColumnVisible('solutionscat', 'identifier')">{{ solution.identifier }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'title')">{{ solution.title }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'metadata')">
              <span v-if="solution.metadata.length > 0 && solution.metadata[0] != ''">
                <span v-bind:key="solution.identifier+ '-tag-' + key" class="pill" v-for="(tag,key) in solution.metadata">
                  {{ solution.metadata[key] }}
                </span>
              </span>
              <span v-else v-translate>
                None
              </span>
            </td>
            <td v-if="$tableColumnVisible('solutionscat', 'owner')">{{ solution.owner?solution.owner.name:$gettext('None') }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'team')">{{ solution.team?solution.team.name:$gettext('None') }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'private?')">{{ solution.private?$gettext('Yes'):$gettext('No') }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'locked?')">{{ solution.locked?$gettext('Yes'):$gettext('No') }}</td>
            <td>
              <Button size="micro" @click="$refs.solutiondetailsmodal.show(solution._id)">{{ $gettext('View details') }}</Button>
              &nbsp;
              <Button
                v-if="hasPermission('Solutions', 'Update') || solution.canEdit"
                @click="editSolution(solution._id, i)"
                size="micro">
                  {{$gettext('Edit')}}
              </Button>
            </td>
          </tr>
        </template>
      </DataTable>
    </CardSet>

    <CardSet v-else :title="$gettext('All solutions')">
      <Loader v-if="$apollo.queries.solutionsPage.loading" />
      <Loader v-else-if="loading"/>
      <DataTable
          class="Reports_Panel_Table" tablekey="solutionscat" :customisable="true" @changed="$forceUpdate()"
          :headers="[
            $gettext('Identifier'),
            $gettext('Title'),
            $gettext('Metadata'),
            { name: $gettext('Owner'), default: false },
            { name: $gettext('Team'), default: false },
            { name: $gettext('Private')+'?', default: false },
            { name: $gettext('Locked')+'?', default: false },
            { name: '', freeze: true },
          ]"
          v-else
        >
        <template v-for="(solution, i) in solutionsPage.solutions" :key="solution._id">
          <tr v-if="solution.identifier!=''">
            <td v-if="$tableColumnVisible('solutionscat', 'identifier')">{{ solution.identifier }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'title')">{{ solution.title }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'metadata')">
              <span v-if="solution.metadata.length > 0 && solution.metadata[0] != ''">
                <span v-bind:key="solution.identifier+ '-tag-' + key" class="pill" v-for="(tag,key) in solution.metadata">
                  {{solution.metadata[key]}}
                </span>
              </span>
              <span v-else v-translate>
                None
              </span>
            </td>
            <td v-if="$tableColumnVisible('solutionscat', 'owner')">{{ solution.owner?solution.owner.name:$gettext('None') }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'team')">{{ solution.team?solution.team.name:$gettext('None') }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'private?')">{{ solution.private?$gettext('Yes'):$gettext('No') }}</td>
            <td v-if="$tableColumnVisible('solutionscat', 'locked?')">{{ solution.locked?$gettext('Yes'):$gettext('No') }}</td>
            <td>
              <Button size="micro" @click="$refs.solutiondetailsmodal.show(solution._id)">{{ $gettext('View details') }}</Button>
              &nbsp;
              <Button
                v-if="hasPermission('Solutions', 'Update') || solution.canEdit"
                @click="editSolution(solution._id, i)"
                size="micro">
                  {{$gettext('Edit')}}
              </Button>
            </td>
          </tr>
        </template>
      </DataTable>
      <DataTablePaginator :currentPage="page" :pages="solutionsPage.pages" @select="(p) => { page = p } " />
      <DataTablePageSize :tableName="'Solutions'" @selected="(p) => { size = p; page = 0; } " />
    </CardSet>
    <SolutionModal ref="solutionmodal" @success="$apollo.queries.solutionsPage.refetch()" />
    <EditSolutionModal ref="editsolutionmodal" @success="replaceSolution" />
    <SolutionDetailsModal ref="solutiondetailsmodal"/>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters } from 'vuex';

import SolutionModal from '@/modals/Solution/Create';
import SolutionDetailsModal from '@/modals/Solution/Details';
import EditSolutionModal from '@/modals/Solution/Edit';
import UIDataTablePaginator from '@/components/UI/DataTablePaginator';
import UIDataTablePageSize from '@/components/UI/DataTablePageSize';

export default {
  name: 'SolutionsView',
  data() {
    const tabs = [];

    tabs.push( {
      text: this.$gettext( "Common solutions" ),
      controls: "TemplatesPanel",
      action: () => {
        this.method = 'dig';
      },
      pill: () => {
        return [];
      },
    } );

    // tabs.push({
    //   text: this.$gettext("Dig Mobile templates"),
    //   controls: "TemplatesPanel",
    //   action: () => {
    //     this.method = 'digmob'
    //   },
    //   pill: () => {
    //     return []
    //   },
    // });

    // tabs.push({
    //   text: this.$gettext("Dig other templates"),
    //   controls: "TemplatesPanel",
    //   action: () => {
    //      this.method = 'digother'
    //   },
    //   pill: () => {
    //     return []
    //   },
    // });

    tabs.push( {
      text: this.$gettext( "My solutions" ),
      controls: "TemplatesPanel",
      action: () => {
         this.method = 'mine';
      },
      pill: () => {
        return [];
      },
    } );

    tabs.push( {
      text: this.$gettext( "Solutions on my teams" ),
      controls: "TemplatesPanel",
      action: () => {
         this.method = 'team';
      },
      pill: () => {
        return [];
      },
    } );

    tabs.push( {
      text: this.$gettext( "Community solutions" ),
      controls: "TemplatesPanel",
      action: () => {
         this.method = 'community';
      },
      pill: () => {
        return [];
      },
    } );

    return {
      solutionsPage: false,
      editedSolutionIndex: false,
      page: 0,
      size: 25,

      loading: false,
      typingTimeout: '',
      search: '',
      searchSolutions: false,

      method: 'dig',
      tabs,
    };
  },
  watch: {
    search() {
      clearTimeout( this.typingTimeout );
      this.loading = true;
      if( this.search == '' ) {
        this.loading = false;
        this.searchSolutions = false;
      } else {
        this.typingTimeout = setTimeout( () => {
        this.$apollo.query( {
          query: gql`
            query SearchSolutions($term: String!) {
              solutions: SearchSolutions(term: $term) {
                _id,
                identifier,
                title,
                metadata,
                owner {
                  _id,
                  name
                }
                team {
                  _id
                  name
                }
                private
                locked
                canEdit
              }
            }
          `,
          variables: {
            term: this.search,
          },
        } ).then( res => {
          this.loading = false;
          this.searchSolutions = res.data.solutions;
        } ).catch( () => {
          this.$alerts.error( 'Something went wrong!', 'search went bad' ); //TODO
        } );
        }
      , 1000 );
    }
    },
  },
  apollo: {
    solutionsPage: {
      query: gql`
        query SolutionsPage($method: String, $page: Int!, $size: Int!){
          solutionsPage: SolutionsPage(method: $method, page: $page, size: $size) {
            solutions {
              _id,
              identifier,
              title,
              metadata,
              owner {
                _id,
                name
              }
              team {
                _id
                name
              }
              private
              locked
              canEdit
            },
            hasMore,
            pages
          }
        }
      `,
      variables() {
        return {
          method: this.method,
          page: this.page,
          size: this.size,
        };
      },
      update( data ) {
        return { ...data.solutionsPage };
      },
    },
  },
  methods: {
    editSolution( id, index ) {
      this.editedSolutionIndex = index;
      this.$refs.editsolutionmodal.show( id );
    },
    replaceSolution( identifier ) {
      this.$apollo.query( {
        query: gql`
          query Solution($id: ObjectID) {
            solution: Solution(id: $id) {
              _id,
              identifier,
              title,
              overview,
              code,
              fix,
              metadata,
              issueTemplates {
                _id
                identifier
                title
              }

              owner {
                _id,
                name
              }
              team {
                _id
                name
              }
              private
              locked

              canEdit
            }
          }
        `,
        variables: {
          id: identifier,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        if ( this.search == '' ) {
          this.solutionsPage.solutions[this.editedSolutionIndex] = res.data.solution;
        } else {
          this.searchSolutions[this.editedSolutionIndex] = res.data.solution;
        }
        this.$forceUpdate();
      } );
    },
    showMore() {
      this.loading = true;
      this.page++;

      this.$apollo.queries.solutionsPage.fetchMore( {
        variables: {
          page: this.page,
          size: this.size,
        },
        updateQuery: ( pResult, { fetchMoreResult } ) => {
          this.loading = false;

          return {
            solutionsPage: {
              __typename: pResult.solutionsPage.__typename,
              solutions: [ ...pResult.solutionsPage.solutions, ...fetchMoreResult.solutionsPage.solutions ],
              hasMore: fetchMoreResult.solutionsPage.hasMore,
            },
          };
        },
      } );
    },
    showAll() {
      this.loading = true;
      this.$apollo.query( {
        query: gql`
        {
          solutions: Solutions {
            _id,
            identifier,
            title,
            metadata,
            issueTemplates {
                _id
                identifier
                title
              }
            owner {
              _id,
              name
            }
            team {
              _id
              name
            }
            private
            locked
            canEdit
          }
        }
        `,
      } ).then( res => {
        this.loading = false;
        this.solutionsPage = {
          solutions: res.data.solutions,
          hasMore: false,
        };
      } );
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    ...mapState( [ 'user' ] ),
  },
  components: {
    SolutionModal,
    EditSolutionModal,
    SolutionDetailsModal,
    DataTablePaginator: UIDataTablePaginator,
    DataTablePageSize: UIDataTablePageSize,
  },
};
</script>

<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';

  .details {
    > div {
      display: inline-block;
      margin-right: 25px;
      dt {
        display: inline-block;
        font-weight: bold;
      }
      dd {
        display: inline-block;
        margin-left: 5px;
      }
    }
  }

  .pill {
    background-color: $hugr-colours-secondary;
    border-radius: 16px;
    padding-right: 4px;
    padding-left: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    font-size: 0.8em;
    margin-right: 5px;
    color:black;
  }

  .editbtn {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .actions {
    padding: 10px 0;
    button {
      margin-right: 10px;
    }
  }

  .Solutions {
    &_Tablist {
      margin-top: 50px;
      padding-bottom: 0 !important;
    }
  }

  @media screen and (max-width: 700px) {
    table {
      display: block;
      tr {
        display: block;
        width: 100%;
        th {
          display: block;
        }
        td {
          display: block;
        }
      }
    }
  }
</style>
