<template>
  <Body :title="$gettext('Guidelines')">
    <div v-if="guidelines.length">
      <Well v-for="guideline in guidelines" v-bind:key="guideline._id">
        <h3 v-translate>Details</h3>
        <p>{{$gettext('Name:')}} {{ guideline.name }}</p>
        <p>{{$gettext('Version:')}} {{ guideline.version }}</p>
        <p>{{$gettext('URL:')}} {{ guideline.url }}</p>
        <button @click="remove(guideline)">{{$gettext('Delete')}}</button>
        <h3 v-translate>Criterion</h3>
        <ul>
          <li v-for="criterion in criteria[guideline._id]" v-bind:key="criterion.title">
            {{ criterion.criterion }} {{ criterion.title }} (Level {{ criterion.level }}) <button @click="rmCriterion(criterion._id, guideline._id)">{{$gettext('Delete')}}</button>
          </li>
        </ul>
        <CriterionForm v-bind:guideline="guideline._id"/>
      </Well>
      <!-- <GuidelineForm /> -->
    </div>
    <Loader v-else />
  </Body>
</template>

<script>
import gql from 'graphql-tag';

import CriterionForm from '@/forms/Criterion';

export default {
  name: 'GuidelinesView',
  data() {
    return {
      guidelines: false,
      criteria: {},
    };
  },
  mounted() {
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Guidelines',
      },
    } );
  },
  apollo: {
    guidelines: {
      query: gql`
        {
          guidelines: Guidelines {
            _id,
            name,
            version,
            url,

            criteria {
              _id
            }
          }
        }
      `,
    },
  },
  // sockets: {
  //   RETURN_GUIDELINES: function(data) {
  //     this.guidelines = data;
  //     this.$socket.emit('GET_CRITERIA', data[0]._id); //TODO for all
  //   },
  //   RETURN_CRITERIA: function(data) {
  //     this.criteria[data.gid] = data.criteria;
  //     this.$forceUpdate();
  //   }
  // },
  methods: {
    // ...mapActions(['jwtemit']),
    // remove: function(g) {
    //   this.jwtemit(['RM_GUIDELINE', g._id]);
    // },
    // rmCriterion: function(id, gid) {
    //   this.jwtemit(['RM_CRITERION', {id: id, gid: gid}]);
    // }
  },
  components: {
    /* GuidelineForm, */
    CriterionForm,
  },
};
</script>
