<template>
  <Modal
    ref="modal"
    :title="$gettext('Edit user journey')"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submit"

    @close="reset()"
    :ready="userJourney">
    <FormInput idRoot="userJourney_" :label="$gettext('Title')" v-model="userJourney.title" ref="title" :validation="['not-empty']"/>
    <FormSelect idRoot="userJourney_" :label="$gettext('Importance')" v-model="userJourney.importance" :options="{'Essential': 'Essential', 'Secondary': 'Secondary', 'Tertiary': 'Tertiary'}"  />
    <FormTextArea idRoot="userJourney_" :label="$gettext('Description')" v-model="userJourney.description" />

    <h3>Pages and components</h3>
    <AriaSearchSelectList v-if="pagesAndComponents.length" v-model:items="pagesAndComponents" :ordered="true" :moveable="true" />
    <p v-else v-translate>No pages or components</p>

    <AriaSearchSelect ref="pageselect" :label="$gettext('Add a Page')" :dropdown="true" idRoot="page" :gqlOptions="['PAGES_BY_REPORT', userJourney.reportVersion._id]" @selected="addPage"/>

    <AriaSearchSelect ref="componentselect" :label="$gettext('Add a Component')" :dropdown="true" idRoot="component" :gqlOptions="['COMPONENTS_BY_REPORT', userJourney.reportVersion._id]" @selected="addComponent"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import FormSelect from '@/components/Form/Select';
import AriaSearchSelect from '@/components/Aria/SearchSelect2';
import AriaSearchSelectList from '@/components/Aria/SearchSelectList';

export default {
  name: 'EditUserJourneyModal',
  data() {
    return {
      id: null,
      userJourney: null,
      pagesAndComponents: [],

      stagedPages: [],
      stagedComponents: [],

      submitting: false,
    };
  },
  methods: {
    submit() {
      this.$hugrvalidate( [ 'title' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitMutation();
        }
      } );
    },
    submitMutation() {
      this.submitting = true;
      const items = this.pagesAndComponents.map( i => {
        return {
          _id: i._id,
          type: i.type,
        };
      } );
      this.$apollo.mutate( {
        mutation: gql`
          mutation ($id: ObjectID!, $userJourney: UserJourneyInput!) {
            updateUserJourney(id: $id, userJourney: $userJourney) {
              _id
            }
          }
        `,
        variables: {
          id: this.userJourney._id,
          userJourney: {
            title: this.userJourney.title,
            description: this.userJourney.description,
            importance: this.userJourney.importance,
            items,
          },
        },
      } ).then( res => {
        this.submitting = false;
        if ( res.data.updateUserJourney ) {
          this.reset();
          this.$alerts.success( 'User journey updated', `Your user journey has been updated.` );
          this.$emit( 'success' );
        }
      } ).catch( err => {
        this.submitting = false;
        if( err.message == 'user journey title is not unique' ) {
          this.$refs.title.addError( 'User Journey must be unique' );
        } else {
          this.$alerts.coded( 'E069', 'F4001' ); //see notifications spreadsheet
        }
      } );
    },
    show( id ) {
      this.reset();
      this.id = id;
      this.$apollo.query( {
        query: gql`
          query UserJourney($id: ObjectID!) {
            userJourney: UserJourney(id: $id) {
              _id
              title
              description
              importance,
              reportVersion {
                _id
              }

              items {
                type
                item {
                  ... on Page {
                    _id
                    name
                  }
                  ... on Component {
                    _id
                    identifier
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: this.id,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.pagesAndComponents = [];
        for( const item of res.data.userJourney.items ) {
          if( item.item ) {
            if( item.type == 'page' ) {
              this.pagesAndComponents.push( {
                _id: item.item._id,
                name: item.item.name,
                type: item.type,
              } );
            }
            if( item.type == 'component' ) {
              this.pagesAndComponents.push( {
                _id: item.item._id,
                name: item.item.identifier,
                type: item.type,
              } );
            }
          }
        }
        this.userJourney = { ...res.data.userJourney };
        for( const page of this.stagedPages ) {
          this.addPage( page );
        }
        for( const component of this.stagedComponents ) {
          this.addComponent( component );
        }
        this.$refs.modal.show();
      } ).catch( () => {
        this.$alerts.coded( 'E070', 'F4002' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.userJourney = null;

      this.pagesAndComponents = [];

      this.stagedPages = [];

      this.$refs.modal.reset();
    },

    addPage( page ) {
      if( this.pagesAndComponents.map( p => p._id ).indexOf( page ) < 0 ) {
        this.$apollo.query( {
          query: gql`
            query Page($id: ObjectID!) {
              page: Page(id: $id) {
                _id,
                name
              }
            }
          `,
          variables: {
            id: page,
          },
        } ).then( res => {
          this.pagesAndComponents.push( {
            ...res.data.page,
            type: 'page',
          } );
        } ).catch( () => {
          this.$alerts.coded( 'E046', 'F4003' ); //see notifications spreadsheet
        } );
      }
    },
    preAddPage( page ) {
      this.stagedPages.push( page );
    },
    addComponent( component ) {
      if( this.pagesAndComponents.map( c => c._id ).indexOf( component ) < 0 ) {
        this.$apollo.query( {
          query: gql`
            query Component($id: ObjectID!) {
              component: Component(id: $id) {
                _id,
                identifier
              }
            }
          `,
          variables: {
            id: component,
          },
        } ).then( res => {
          this.pagesAndComponents.push( {
            _id: res.data.component._id,
            name: res.data.component.identifier,
            type: 'component',
          } );
        } ).catch( () => {
          this.$alerts.coded( 'E052', 'F4004' ); //see notifications spreadsheet
        } );
      }
    },
    preAddComponent( component ) {
      this.stagedComponents.push( component );
    },
  },
  components: {
    FormInput,
    FormTextArea,
    FormSelect,
    AriaSearchSelect,
    AriaSearchSelectList,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .actions button {
    margin-top: 1rem;
  }

</style>
