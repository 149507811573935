<template>
  <span :class="['Tooltip', tooltipOpen?'_open':'a11yhide', flipLeft?'_flipLeft':'', flipDown?'_flipDown':'', `_closeness-${closeness}`, wider?'_wider':'']" ref="tooltip">
    <h5 v-if="title!=''" class="Tooltip_Title">{{ title }}</h5>
    <div v-if="Array.isArray(text)">
      <dl v-if="text.join().indexOf('Changed on:') >= 0 || text.join().indexOf('Changed by:') >= 0 || text.join().indexOf('Previous value:') >= 0">
      <p class="Tooltip_Text" v-for="line in text" :key="line+title">
        <span v-if="line.indexOf('Changed on:') == 0 || line.indexOf('Changed by:') == 0 || line.indexOf('Previous value:') == 0">
          <dt class="Tooltip_Text_Bold">{{ line.split(':')[0] }}:</dt>
          <dd>
            {{ moment(line.split(':').slice(1).join(':')).isValid()?moment(line.split(':').slice(1).join(':')).format('Do MMMM YYYY'):line.split(':').slice(1).join(':').replaceAll('undefined', 'Not set') }}
          </dd>
        </span>
        <span v-else>
          {{ line }}
        </span>
      </p>
      </dl>
    </div>
    <span v-else class="Tooltip_Text">{{ text }}</span>
  </span>
</template>

<script setup>
  import { toRefs, ref, onUpdated, onMounted } from 'vue';
  import moment from 'moment';

  const props = defineProps( {
    text: String,
    title: {
      type: String,
      default: '',
    },
    wider: {
      type: Boolean,
      default: false,
    },
    closeness: {
      type: String,
      default: 'normal',
    },
  } );

  const tooltip = ref( null );

  const { text, title } = toRefs( props );

  const tooltipOpen = ref( false );
  const flipLeft = ref( false );
  const flipDown = ref( false );

  const checkBounds = () => {
    const bounds = tooltip.value.getBoundingClientRect();

    const leftEdge = bounds.x;
    if( leftEdge < 0 ) flipLeft.value = false;

    const rightEdge = bounds.x + bounds.width;
    if( rightEdge > window.innerWidth - 50 ) flipLeft.value = true;

    const topEdge = bounds.y;
    if( topEdge < 0 ) flipDown.value = true;
  };

  onUpdated( () => checkBounds() );
  onMounted( () => checkBounds() );

  const showTooltip = () => {
    tooltipOpen.value = true;
    checkBounds();
  };

  const hideTooltip = () => {
    tooltipOpen.value = false;
  };

  defineExpose( {
    showTooltip,
    hideTooltip,
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Tooltip {
    display: block;
    position: absolute;
    background: lighten( $hugr-colours-grey, 10% );
    min-width: 200px;
    max-width: 300px;
    padding: 8px;
    text-align: left;
    border-radius: 8px;
    color: black;

    bottom: 44px;
    left: -16px;
    
    font-size: 1em;

    z-index: 99999;

    &._closeness-close {
      bottom: 32px;
    }

    &._closeness-medium {
      bottom: 64px;
    }

    &._closeness-far {
      bottom: 100px;
    }

    &._closeness-groupSummary {
      bottom: 140px;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid lighten( $hugr-colours-grey, 10% );
      position: absolute;
      bottom: -10px;
      left: 21px;
    }

    &_Title {
      margin: 0;
      font-weight: bold;
      font-size: 1em;
    }
    &_Text {
      font-size: 0.9em;
      font-weight: normal;
      margin: 4px;

      &_Bold {
        font-weight: bold;
      }

      dd {
        display: inline;
        margin-left: 4px;
      }

      dt {
        display: inline;
      }
    }
  }

  ._flipLeft.Tooltip {
    left: unset;
    right: -16px;

    &:after {
      left: unset;
      right: 21px;
    }
  }

  ._wider {
    width: 300px;
  }

  // ._flipDown.Tooltip {
  //   top: 42px;
    
  //   &:after {
  //     display: none;
  //   }

  //   &:before {
  //     content: '';
  //     width: 0;
  //     height: 0;
  //     border-left: 10px solid transparent;
  //     border-right: 10px solid transparent;
  //     border-top: 10px solid lighten( $hugr-colours-grey, 10% );
  //     position: absolute;
  //     // bottom: -10px;
  //     // left: 21px;
  //   }
  // }

  ._darkMode .Tooltip {
    &_Title {
      color: #000 !important;
    }
  }
</style>
