<template>
  <div
    :class="[ 'SlidePanel', visible?'_showing':'' ]"
    ref="pane"
    v-if="active"
    tabindex="-1"
    role="dialog"
    aria-labelledby="todo"
    aria-modal="true">
    <!-- v-click-away="clickAway"> -->
    <div class="SlidePanel_Inner">
      <div class="SlidePanel_Inner_PreHead">
        <a v-if="showBack" class="SlidePanel_Inner_PreHead_Back" href="#" @click="emit( 'back' )">
          <Icon type="solid" icon="arrow-left" />&nbsp;
          <span>Back</span>
        </a>
        <Button class="SlidePanel_Inner_PreHead_Close" type="icon" :icon="['solid', 'times']" @click="reset()">close</Button>
      </div>
      <div class="SlidePanel_Inner_Head">
        <h2 class="SlidePanel_Inner_Head_Title">{{ title }}</h2>
      </div>
      <div class="SlidePanel_Inner_Body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, toRefs } from 'vue';

  const pane = ref( null );

  const emit = defineEmits( [ 'close', 'back' ] );

  const props = defineProps( {
    title: {
      type: String,
      required: true,
    },
    showBack: {
      type: Boolean,
      defaul: false,
    },
  } );

  const {
    title,
  } = toRefs( props );

  const active = ref( false );
  const visible = ref( false );

  let activatingElement;

  const show = () => {
    activatingElement = document.activeElement;
    active.value = true;
    setTimeout( () => {
      visible.value = true; //delay for animation
      pane.value.focus();
    }, 100 );
  };

  const reset = () => {
    activatingElement?.focus();
    visible.value = false;
    setTimeout( () => {
      active.value = true; //delay for animation
    }, 100 );
    emit( 'close' );
  };

  defineExpose( {
    show,
    reset,
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_header.scss';
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_sizes.scss';

  $panelWidth: $slidePanel-panelWidth;
  $fullWidth: $slidePanel-fullWidth;

  .SlidePanel {
    position: fixed;
    z-index: 9999;
    right: calc( -100px - $panelWidth );
    top: calc( $hugr-header-regular-height + 1px );
    height: calc( 100% - $hugr-header-regular-height );
    width: $panelWidth;
    border-left: 1px solid $hugr-colours-grey;
    background: white;
    padding: 16px;
    transition: all .5s ease-in-out 0s;

    &._showing {
      right: 0px;
    }

    &_Inner {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 160px);

      &_PreHead {
        &_Close {
          position: absolute;
          right: 0;
          top: 0;
          color: black;
        }
      }

      &_Head {
        &_Title {
          font-size: 1.2em;
          font-weight: 500;
          margin-bottom: 8px;
        }
      }

      &_Body {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      
    }
  }

  ._darkMode .SlidePanel {
    background: darken( $hugr-colours-primary, 5% );
    border-left: 1px solid darken( $hugr-colours-primary, 10% );

    &_Inner {
      &_PreHead {
        &_Close {
          color: white !important;
        }
      }
    }
  }

  @media screen and (max-width: $fullWidth) {
    .SlidePanel {
      width: 100%;
      box-sizing: border-box;
    }
  }
</style>
