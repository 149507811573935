<template>
  <Modal
    ref="modal"
    :title="$gettext('Add authenticator app')"
    class="AuthenticatorModal"
    
    :showActions="true"
    submitText="Verify"

    @submit="verify()"
    @close="reset()">
    <div class="AuthenticatorModal_Top">
      <vue-qrcode :value="auth" class="AuthenticatorModal_Top_QR"/>
      <div class="AuthenticatorModal_Top_Text">
        <div>
          <p v-translate>Open your chosen authenticator application, such as Microsoft or Google Authenticator on your mobile device and add a new account by scanning the QR code.</p>
          <p v-translate>If you are unable to scan the QR code you can copy the following code into your authenticator app</p>
          <p>{{ base32Split }}</p>
        </div>
      </div>
    </div>
    <FormFieldSet legend="Verify authenticator">
      <FormInput idRoot="verify_" label="Name" v-model="name" />
      <FormInput idRoot="verify_" label="Code" v-model="totp" />
    </FormFieldSet>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import FormInput from '@/components/Form/Input';
import FormFieldSet from '@/components/Form/FieldSet';

import VueQrcode from 'vue-qrcode';

export default {
  name: 'AuthenticatorModal',
  created() {
  },
  data() {
    return {
      name: '',
      base32: '',
      recAuthUrl: false,
      totp: '',
      verified: false,
    };
  },
  methods: {
    show() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation addAuthenticator {
            auth: addAuthenticator {
              otpAuthUrl,
              base32
            }
          }
        `,
      } ).then( res => {
        this.name = decodeURIComponent( res.data.auth.otpAuthUrl.replace( 'otpauth://totp/', '' ).split( '?' )[0] );
        this.recAuthUrl = res.data.auth.otpAuthUrl;
        this.base32 = res.data.auth.base32;
        this.$refs.modal.show();
      } ).catch( () => {
        this.$alerts.coded( 'E041', 'F2201' ); //see notifications spreadsheet
      } );
    },
    verify() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation verifyAuthenticator($name: String, $token: String) {
            verified: verifyAuthenticator(name: $name, token: $token)
          }
        `,
        variables: {
          name: this.name,
          token: this.totp,
        },
      } ).then( res => {
        this.verified = res.data.verified;
        if( this.verified ) {
          this.$alerts.success( 'Authenticator added', `Authenticator has been set up successfully` );
          this.$emit( 'success' );
          this.reset();
        } else {
          //code not valid
          this.$alerts.coded( 'E043', 'F2203' ); //see notifications spreadsheet
        }
      } ).catch( () => {
        this.$alerts.coded( 'E042', 'F2202' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.totp = '';
      this.$refs.modal.reset();
    },
  },
  computed: {
    auth() {
      if( this.recAuthUrl ) {
        return `otpauth://totp/Hugr:${encodeURIComponent( this.name )}?secret=${this.base32}&issuer=Hugr`;
      }

        return false;

    },
    base32Split() {
      return [ ...this.base32 ].map( ( d, i ) => ( ( i ) % 4 == 0 ? ` ${d}` : d ) ).join( '' ).trim();
    },
  },
  props: {
  },
  components: {
    FormInput,
    FormFieldSet,

    VueQrcode,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .AuthenticatorModal {
    &_Top {
      & > * {
        display: inline-block;
        vertical-align: top;
      }
      &_QR {
        width: 40%;
      }
      &_Text {
        width: 60%;
        > div {
          padding-left: 20px;
        }
      }
    }
  }
</style>
