<template>
  <div class="CommentBox">
    <Avatar :uid="user.id" type="round" size="small"/>
    <div class="CommentBox_Right">
      <div ref="textbox" :class="[ 'CommentBox_Right_Input', hasValue?'_open':'' ]" role="textbox" contenteditable="true" tabindex="0" :aria-label="label"
        v-on:input="$emit('update:modelValue', $event.target.innerHTML); checkInput(); checkUserTag($event, doUserSearch)"
        
        @keydown.left="checkReactivation" @keydown.right="checkReactivation" @keydown.backspace="checkReactivation" @click="checkReactivation"
        @keydown.enter="doComplete">
      </div>
      <span class="CommentBox_Right_Placeholder" v-if="!hasValue">{{ label }}</span>
      <div class="CommentBox_Right_Footer">
        <span v-if="message">{{  message }}</span>
        <span v-else-if="replyTo">Replying to {{ replyToName }}</span>
        <span v-else>Use @ to mention someone</span>
        <span class="CommentBox_Right_Footer_Buttons">
          <span v-if="fileUpload">
            <Button v-if="fileUpload" type="icon" :icon="['solid', 'paperclip']" size="micro" @click="$refs.fileselect.click()">Attach files</Button>
            <input v-show="false" type="file" multiple name="fileSelect" id="fileSelect" ref="fileselect" @change="(e) => $emit('fileschanged', e)" :key="fileSelectKey">
          </span>
          <Button v-if="cancellable" class="CommentBox_Right_Footer_Buttons_Cancel" type="primary" size="micro" @click="cancel">Cancel</Button>
          <Button v-if="replyTo!=null" class="CommentBox_Right_Footer_Buttons_Cancel" type="primary" size="micro" @click="clearReplyTo(true)">Cancel</Button>
          <Button v-if="!nosend" class="CommentBox_Right_Footer_Buttons_Send" type="tertiary" size="micro" :icon="['regular', 'paper-plane', 'after']" @click="submit">{{$gettext(verb)}}</Button>
        </span>
      </div>
      <UserAutocomplete :visible="tagInit && ( returnedUsers.length||team )" @complete="completeSuggestion" ref="autocomplete" :users="returnedUsers" :team="team" v-model:term="term" @focusItem="focusSuggestion"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import gql from 'graphql-tag';
import UserAutocomplete from './UserAutocomplete';
import Avatar from '@/components/Helpers/Avatar';

export default {
  name: 'CommentBox',
  mounted() {
    this.parseValue( this.$props.modelValue );
  },
  data() {
    return {
      returnedUsers: [],
      selectedUser: null,

      userTimeout: false,

      tagInit: false,
      term: '',

      replyTo: null,
      replyToName: null,

      hasValue: false,
    };
  },
  watch: {
    modelValue() {
      if ( this.$props.modelValue == '' ) {
        this.$refs.textbox.innerHTML = '';
      }
    },
  },
  methods: {
    doComplete() {
      if( this.tagInit ) {
        this.$refs.autocomplete.completeOnCurrent();
      }
    },
    getReplyTo() {
      return this.replyTo;
    },
    setReplyTo( uid, name ) {
      this.replyTo = uid;
      this.replyToName = name;
    },
    clearReplyTo( clearbox = false ) {
      this.replyTo = null;
      this.replyToName = null;

      if( clearbox ) {
        this.setValue( '' );
      }
    },
    activeTagHandler( e ) {
      const attag = [ ...this.$refs.textbox.getElementsByTagName( 'span' ) ].filter( s => s.getAttribute( 'data-active' ) == 'true' )[0];
      if( e.key == 'ArrowUp' || e.key == 'ArrowDown' ) {
        e.preventDefault();
        if( this.$refs.autocomplete ) this.$refs.autocomplete.cycleActive( e );
      }
      if( e.key == 'ArrowRight' || e.key == 'Enter' ) {
        if( attag.getAttribute( 'data-id' ) ) {
          e.preventDefault();
          this.stopTagging();
          this.focus();
        }
      }
      if( e.key == ' ' ) {
        if( attag && attag.getAttribute( 'data-id' ) && attag.getAttribute( 'data-id' ) != '' ) {
          this.stopTagging();
          this.focus();
        }
      }
      if( e.key == 'Escape' ) this.clearCurrentTag();
      if( e.key == 'Backspace' ) {
        if( attag.innerText.trim() == "@" ) {
          this.clearCurrentTag();
        } else {
          setTimeout( () => {
            if( attag.innerText[0] != "@" ) this.clearCurrentTag();
          }, 100 );
        }
      }
      this.term = attag.innerHTML.replace( '@', '' );
    },
    cancel() {
      this.$emit( 'cancel' );
    },
    submit() {
      this.$emit( 'submitComment' );
    },
    focus() {
      this.$refs.textbox.focus();
      document.execCommand( 'selectAll', false, null );
      document.getSelection().collapseToEnd();
    },
    setValue( v ) {
      this.parseValue( v );
      this.$emit( 'update:modelValue', v );
    },
    parseValue( v ) {
      this.$refs.textbox.innerHTML = v;
      this.checkInput();
    },
    checkInput() {
      if( this.$refs.textbox.innerHTML != '' ) this.hasValue = true;
      else this.hasValue = false;
    },
    showAutoComplete( members, searchString ) {
      this.returnedUsers = members;
      this.searchString = searchString;
      if( this.$refs.autocomplete ) {
        this.$refs.autocomplete.$forceUpdate();
      }
    },
    cycleActive( e ) {
      this.$refs.autocomplete.cycleActive( e );
    },
    doUserSearch( searchString ) {
      if( searchString != '' && searchString != undefined ) {
      this.$apollo.query( {
        query: gql`
          query SearchTeamMembers($searchString: String!, $team: ObjectID) {
            members: SearchTeamMembers(searchString: $searchString, team: $team) {
              name
              _id
            }
          }
        `,
        variables: {
          searchString,
          team: this.team ? this.team : null,
        },
      } ).then( res => {
        const { members } = res.data;
        this.showAutoComplete( members, searchString );
      } ).catch( () => {
        this.$alerts.coded( 'E017', 'F701' ); //see notifications spreadsheet
      } );
      }
    },
    completeSuggestion( id ) {
      if ( this.$refs.autocomplete.selected !== null && this.searchString ) {
        this.$apollo.query( {
          query: gql`
            query user($id: ObjectID!) {
              user: User(id: $id) {
                name
                _id
              }
            }
          `,
          variables: {
            id,
          },
        } ).then( res => {
          const { user } = res.data;

          const attag = [ ...this.$refs.textbox.getElementsByTagName( 'span' ) ].filter( s => s.getAttribute( 'data-active' ) == 'true' )[0];
          attag.innerHTML = `@${user.name}`;
          attag.setAttribute( 'data-id', user._id );
          attag.setAttribute( 'data-name', user.name );
          this.$emit( 'update:modelValue', this.$refs.textbox.innerHTML );

          this.focus();
          this.stopTagging();

          this.showAutoComplete( [], null );

          this.doUserSearch(); //incase a new contact was made, probably should be smarter about this.

        } ).catch( () => {
          this.$alerts.coded( 'E018', 'F702' ); //see notifications spreadsheet
        } );
      }
    },
    focusSuggestion( user ) {
      const attag = [ ...this.$refs.textbox.getElementsByTagName( 'span' ) ].filter( s => s.getAttribute( 'data-active' ) == 'true' )[0];

      attag.innerHTML = `@${user.name}`;

      attag.setAttribute( 'data-id', user._id );
      attag.setAttribute( 'data-name', user.name );
      
      const range = document.createRange();
      range.selectNode( attag );
      range.collapse();

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange( range );
      this.$refs.textbox.focus();

      this.$emit( 'update:modelValue', this.$refs.textbox.innerHTML );
    },
    checkUserTag( e, search ) {
      const attag = [ ...this.$refs.textbox.getElementsByTagName( 'span' ) ].filter( s => s.getAttribute( 'data-active' ) == 'true' )[0];
      if( e.inputType == 'deleteContentBackward' ) {
        //check still has active tag
        if( !attag ) this.stopTagging();
      } else if( this.tagInit ) {
        clearTimeout( this.userTimeout );
        this.userTimeout = setTimeout( () => {
          search( attag.innerHTML.replace( '@', '' ) );
        }, 500 );
      } else if( e.data == '@' ) {
        if( this.$refs.textbox.innerHTML.trim() == '@' || this.$refs.textbox.innerHTML.indexOf( ' @' ) >= 0 ) this.startTagging();
      }
    },
    startTagging() {
      this.tagInit = true;

      let hasSpace = true;
      if( this.$refs.textbox.innerHTML.trim() == '@' ) hasSpace = false;

      if( hasSpace ) {
        this.setValue(
          this.$refs.textbox.innerHTML.replace(
            ` @`,
            ` <span class="attag" data-active="true">@</span>&nbsp;`,
          ),
        );
      } else {
        this.setValue(
          this.$refs.textbox.innerHTML.replace(
            `@`,
            `<span class="attag" data-active="true">@</span>&nbsp;`,
          ),
        );
      }

      const attag = [ ...this.$refs.textbox.getElementsByTagName( 'span' ) ].filter( s => s.getAttribute( 'data-active' ) == 'true' )[0];
      const range = document.createRange();
      range.selectNode( attag );
      range.collapse();

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange( range );
      this.$refs.textbox.focus();

      document.addEventListener( 'keyup', this.activeTagHandler );
    },
    stopTagging() {
      this.tagInit = false;

      //TODO clear font stuff here

      //check it has an id
      const attag = [ ...this.$refs.textbox.getElementsByTagName( 'span' ) ].filter( s => s.getAttribute( 'data-active' ) == 'true' )[0];
      if( attag ) {
        if( !attag.getAttribute( 'data-id' ) || attag.getAttribute( 'data-id' ) == '' ) {
          attag.classList.add( '_fail' );
        }
        attag.setAttribute( 'data-active', 'false' );
        const correctInnerText = `@${attag.getAttribute( 'data-name' )}`;
        if( attag.innerText != correctInnerText ) attag.innerText = correctInnerText;

        document.removeEventListener( 'keyup', this.activeTagHandler );
      }
    },
    clearCurrentTag() {
      const attag = [ ...this.$refs.textbox.getElementsByTagName( 'span' ) ].filter( s => s.getAttribute( 'data-active' ) == 'true' )[0];
      this.stopTagging();
      this.setValue(
        this.$refs.textbox.innerHTML.replace(
          attag.outerHTML,
          '',
        ),
      );
      this.focus();
    },
    checkReactivation( e ) {
      if( e.code == 'Backspace' && this.tagInit ) return;

      const selection = document.getSelection();
      if( selection.focusNode.parentElement.classList.contains( 'attag' ) ) {
        const attag = selection.focusNode.parentElement;
        attag.classList.remove( '_fail' );
        attag.setAttribute( 'data-active', true );
        this.tagInit = true;
        document.addEventListener( 'keyup', this.activeTagHandler );
      } else {
        const attag = [ ...this.$refs.textbox.getElementsByTagName( 'span' ) ].filter( s => s.getAttribute( 'data-active' ) == 'true' )[0];
        if ( attag ) {
          this.stopTagging();
        }
      }
  },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  props: {
    modelValue: {
      default: "",
    },
    team: {
      default: false,
    },
    label: {
      type: String,
      default: "Add a comment",
    },
    verb: {
      type: String,
      default: 'Send',
    },
    message: {
      type: String,
      default: '',
    },
    cancellable: {
      type: Boolean,
      default: () => false,
    },
    fileUpload: {
      type: Boolean,
      default: () => false,
    },
    nosend: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    UserAutocomplete,
    Avatar,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';

  .CommentBox {
    // border: 1px solid lighten( $hugr-colours-primary, 50% );
    // background: lighten( $hugr-colours-grey, 15% );
    // border-radius: 3px;
    width: 100%;
    // margin-bottom: 16px;

    display: flex;
    gap: 4px;

    &_Right {
      flex-grow: 1;
      &_Input {
        border: 1px solid lighten( $hugr-colours-primary, 50% );
        border-radius: 3px;
        padding: 8px;

        font-size: 0.9em;
        line-height: 1.4em;

        // &:focus-within {
        //   border: 1px solid lighten( $hugr-colours-tertiary, 20% );
        // }

        &:focus {
          border: 1px solid lighten( $hugr-colours-tertiary, 20% );
          min-height: 64px;

          .CommentBox_Right_Placeholder {
            display: none;
          }
        }
      }
      &_Placeholder {
        font-size: 0.8em;
        color: lighten( $hugr-colours-primary, 10% );
        position: absolute;
        margin-top: -27px;
        margin-left: 8px;
        pointer-events: none;
      }
      &_Footer {
        position: relative;
        // border-top: 1px solid lighten( $hugr-colours-primary, 50% );
        padding: 4px;
        height: 27px;
        span {
          font-size: 0.8em;
          color: lighten( $hugr-colours-primary, 20% );
          display: inline-block;
          margin: 6px 0;
        }
        &_Buttons {
          position: absolute;
          right: 4px;
          top: -2px;
          button {
            margin-left: 8px;
          }
        }
      }
    }
  }

  ._darkMode .CommentBox {
    // background: lighten( $hugr-colours-primary, 20% );
    &_Right {
      &_Footer {
        span {
          color: darken( white, 25% );
        }
        &_Buttons {
          button {
            color: darken( white, 25% ) !important;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  @import '@/assets/styles/variables/_colours.scss';
  .attag {
    background: lighten( $hugr-colours-tertiary, 70% );
    color: $hugr-colours-tertiary;
    text-decoration: none;
    border-radius: 4px;
    padding: 2px;
    white-space: nowrap;
    &._fail {
      background: lighten( $hugr-colours-red, 50% );
      color: #000;
    }
    &._lite {
      background: transparent;
      padding: 0;
    }
  }

  ._darkMode .attag {
    &._lite {
      color: $hugr-colours-secondary;
    }
  }
</style>
