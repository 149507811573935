<template>
  <p>Info about you and your preferences</p>
  <template v-if="me">
    <Well title="Basic Info">
      <dl class="DataList">
        <div>
          <dt>Name</dt>
          <dd>
            <FormInput v-if="editingName" label="Name" :labelVisible="false" v-model="name" ref="nameInput" idRoot="name"/>
            <span v-else>{{ name }}</span>

            <div class="_actions">
              <template v-if="editingName">
                <Button type="secondary" size="small" @click="saveName()">Save</Button>
                <Button type="border" size="small" @click="cancelName()">Cancel</Button>
              </template>
              <template v-else>
                <Button type="border" size="small" @click="changeName()" ref="changeNameButton">{{$gettext('Change name')}}</Button>
              </template>
            </div>
          </dd>
        </div>
        <div>
          <dt>Email</dt>
          <dd>
            <FormInput v-if="editingEmail" label="Email" :labelVisible="false" v-model="email" ref="emailInput" idRoot="email" />
            <span v-else>{{ me.email }}</span>

            <div class="_actions">
              <template v-if="editingEmail">
                <Button type="secondary" size="small" @click="saveEmail()">Save</Button>
                <Button type="border" size="small" @click="cancelEmail()">Cancel</Button>
              </template>
              <Button v-else type="border" size="small" @click="changeEmail()" ref="changeEmailButton">{{$gettext('Change email')}}</Button>
            </div>
          </dd>
        </div>
        <div>
          <dt>Language</dt>
          <dd>
            <FormSelect idRoot="language?" :label="$gettext('Select a language')" :labelVisible="false" v-model="$language.current" :options="$language.available" @change="saveLanguage()"/>
          </dd>
        </div>
      </dl>
    </Well>
  </template>
  <ConfirmEmailChangeModal ref="confirmemailchangemodal" @success="cancelEmail()"/>
</template>

<script setup>
  import { computed, ref, inject, watch } from 'vue';
  import { useQuery, useMutation, useLazyQuery } from '@vue/apollo-composable';
  import { useGettext } from 'vue3-gettext';
  import gql from 'graphql-tag';

  import FormSelect from '@/components/Form/Select';
  import FormInput from '@/components/Form/Input';

  import ConfirmEmailChangeModal from '@/modals/ConfirmEmailChange';

  const gettext = useGettext();
  const alerts = inject( 'alerts' );
  const confirm = inject( 'confirm' );
  
  const { result: meResult, refetch: refetchMe, onResult: meDone } = useQuery( gql`
    query {
      me: WhoAmI {
        _id,
        name,
        displayname,
        email,
        aclevel,
        teams {
          _id,
          name,
          domainrestriction
        }
      }
    }`, null, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only', //needed to trigger onResult first time
    },
  );

  const me = computed( () => meResult.value?.me ?? {} );

  const name = ref( "" );
  const email = ref( "" );

  const sidebarVars = ref( { id: me.value._id, key: 'showSpreadsheetInSidebar' } );

  const { load: loadSidebar, refetch: updateSidebar, onResult: onSidebar } = useLazyQuery( gql`
    query GetUserSetting($id: ObjectID!, $key: String!) {
      showSpreadsheetInSidebar: GetUserSetting(id: $id, key: $key)
    }
  `,
  sidebarVars,
  {
    fetchPolicy: 'no-cache',
  } );

  const showSpreadsheetInSidebar = ref( false );

  onSidebar( ( { data } ) => {
    const value = data.showSpreadsheetInSidebar;
    if( value == 'undefined' || value == 'false' ) {
      showSpreadsheetInSidebar.value = false;
    } else {
      showSpreadsheetInSidebar.value = true;
    }
  } );

  meDone( ( { data } ) => {
    name.value = data?.me.displayname && data?.me.displayname != '' ? data?.me.displayname : data?.me.name;
    email.value = data?.me.email;

    sidebarVars.value.id = data?.me._id;
    setTimeout( () => {
      // eslint-disable-next-line no-unused-expressions
      loadSidebar() || updateSidebar();
    }, 100 );
  } );

  const { mutate: setSidebar, onError: setSidebarError, onDone: setSidebarDone } = useMutation( gql`
    mutation setUserSetting($id: ObjectID!, $key: String!, $value: String!) {
      res: setUserSetting(id: $id, key: $key, value: $value)
    }
  ` );

  const toggleSidebar = () => {
    showSpreadsheetInSidebar.value = !showSpreadsheetInSidebar.value;
    setSidebar( {
      id: me.value._id,
      key: 'showSpreadsheetInSidebar',
      value: showSpreadsheetInSidebar.value ? 'true' : 'false',
    } );
  };

  const editingName = ref( false );
  const nameInput = ref( null );
  const changeNameButton = ref( null );

  const changeName = () => {
    editingName.value = true;
    setTimeout( () => {
      nameInput.value.focus();
    }, 100 );
  };

  const cancelName = () => {
    editingName.value = false;
    refetchMe();
    setTimeout( () => {
      changeNameButton.value.focus();
    }, 100 );
  };

  const { mutate: changeDisplayName, onError: changeDisplayNameError, onDone: changeDisplayNameDone } = useMutation( gql`
    mutation changeDisplayName($name: String) {
      done: changeDisplayName(name: $name)
    }
  ` );

  changeDisplayNameError( () => {
    alerts.coded( 'E030', 'FXXXX' ); //TODO
  } );

  changeDisplayNameDone( result => {
    cancelName();
    if( result.data.done ) {
      refetchMe();
      alerts.success( 'Display name changed!', `Your display name has been changed successfully` );
    } else {
      alerts.error( 'Display name not changed', `Something went wrong, please try again later. If the problem persists, contact us.` );
    }
  } );

  const saveName = () => {
    changeDisplayName( {
      name: name.value,
    } );
  };

  const resetName = () => {
    name.value = '';
    saveName();
  };

  const editingEmail = ref( false );
  const emailInput = ref( null );
  const changeEmailButton = ref( null );
  const confirmemailchangemodal = ref( null );

  const changeEmail = () => {
    editingEmail.value = true;
    setTimeout( () => {
      emailInput.value.focus();
    }, 100 );
  };

  const cancelEmail = () => {
    editingEmail.value = false;
    refetchMe();
    setTimeout( () => {
      changeEmailButton.value.focus();
    }, 100 );
  };

  const { mutate: initiateEmailChange, onError: initiateEmailChangeError, onDone: initiateChangeEmailDone } = useMutation( gql`
    mutation initiateChangeEmail($email: String!, $password: String!) {
      done: initiateChangeEmail(email: $email, password: $password)
    }
  ` );

  initiateEmailChangeError( () => {
    alerts.coded( 'E027', 'FXXX' ); //TODO
  } );

  initiateChangeEmailDone( result => {
    if( result.data.done ) {
      alerts.success( 'Request initiated!', `Please check your new email and enter the code sent to you.` );
      confirmemailchangemodal.value.show( email );
    } else {
      alerts.error( 'Problem with initiating request', `Have you used an incorrect password? Please try again.` );
    }
  } );

  const saveEmailConfirmation = () => {
    confirm.password().then( result => {
      if( result[0] ) {
        initiateEmailChange( {
          email: email.value,
          password: result[1],
        } );
      } else {
        cancelEmail();
      }
    } );
  };

  const saveEmail = () => {
    const restrictedTeams = [];
    let restrictedTeamsString = '';
    for( const team of me.value.teams ) {
      if ( team.domainrestriction && team.domainrestriction.toUpperCase() != email.value.split( '@' )[1].toUpperCase() ) {
        restrictedTeams.push( team );
        restrictedTeamsString += `${team.name}, `;
      }
    }
    if( restrictedTeams.length > 0 ) {
    confirm.simple( `The email you wish to change to clashes with ${restrictedTeams.length} teams that have enabled domain restriction. You will be forced to quit these teams and will have to be re-invited to them if you're not the only manager in them. Teams: ${restrictedTeamsString} Proceed?` ).then( result => {
      if ( result ) {
        saveEmailConfirmation();
      } else {
        cancelEmail();
      }
    } );
    } else {
      saveEmailConfirmation();
    }
  };

  const { mutate: setLanguage, onError: setLanguageError, onDone: setLanguageDone } = useMutation( gql`
    mutation SetUserSetting($setUserSettingId: ObjectID!, $key: String!, $value: String!) {
      result: setUserSetting(id: $setUserSettingId, key: $key, value: $value)
    }
  ` );

  setLanguageDone( () => {
    alerts.success( 'Language changed' );
    refetchMe();
  } );

  setLanguageError( () => {
    alerts.coded( 'ETODO', 'FTODO' ); //XXX
  } );

  const saveLanguage = () => {
    const langOpts = {
      setUserSettingId: me.value._id,
      key: "LanguageChoice",
      value: gettext.current,
    };
    setLanguage( langOpts );
  };

</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .DataList {
    > div {
      padding: 16px;
      border-bottom: 1px solid $hugr-colours-grey;
      &:last-child {
        border-bottom: none;
      }
      dt {
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 20%;
      }
      dd {
        display: inline-block;
        vertical-align: top;
        margin-left: 0;
        width: 80%;
        position: relative;

        ._actions {
          display: inline-block;
          right: 0;
          position: absolute;
          top: -9px;

          button {
            margin-left: 8px;
          }
        }

        .forminput {
          margin-bottom: 0;
          top: -10px;
          position: absolute;
          max-width: 300px;
          width: calc( 100% - 100px);
        }
      }
    }
  }

  ._darkMode {
    .DataList {
      > div {
        border-bottom: 1px solid darken( $hugr-colours-grey, 40% );
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
</style>
