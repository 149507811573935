<template>
  <div :class="['TextArea', hasError?'with-error':'']">
    <label :class="['TextArea_Label', labelVisible?'_a11yhide':'']" :for="id">
      <span class="TextArea_Label_Main">{{ label }}</span>
      <span v-if="required" class="TextArea_Label_Required">Required</span>
    </label>
    <textarea   ref="textarea"
                :class="['TextArea_Input', hasError?'_hasError':'', taller?'_taller':'']"
                :id="id"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : '']"
                :disabled="disabled"></textarea>
    <DescribedBy v-if="hintText" :id="id+'-hint'" type="hint">{{ hintText }}</DescribedBy>
    <DescribedBy v-if="hasError" :id="id+'-error'" type="error">{{ errorMessage }}</DescribedBy>
  
  </div>
</template>

<script>
import mixin from './Mixin';
import DescribedBy from './DescribedBy.vue';

export default {
  mixins: [ mixin ],
  name: 'FormTextArea',
  data() {
    return {
      uid: this.$uuid.generate(),
    };
  },
  methods: {
    focus() {
      this.$refs.textarea.focus();
    },
  },
  computed: {
    id() {
      return `${this.idRoot + this.uid}-${this.label.replace( / /g, '' ).toLowerCase().trim()}`;
    },
    required() {
      return ( this.validation && this.validation.indexOf( 'not-empty' ) >= 0 );
    },
  },
  components: {
    DescribedBy,
  },
  props: {
    idRoot: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: false,
    },
    labelVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    hintText: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    taller: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .TextArea {
    margin-bottom: 16px;
    &_Label {
      display: block;
      position: relative;
      width: 100%;
      font-size: 0.9em;
      padding-bottom: 4px;
      &_Main {
        font-weight: bold;
      }
      &_Required {
        position: absolute;
        right: 0;
      }
    }

    &_Input {
      background: $hugr-colours-input-surface;
      padding: 4px;
      border: none;
      border-bottom: 3px solid $hugr-colours-input-border;
      margin-bottom: 16px;
      width: 100%;
      height: 100px;
      box-sizing: border-box;
      margin-bottom: 0;
      resize: vertical;

      &._hasError {
        border-bottom: 3px solid $hugr-colours-red;
      }

      &._taller {
        height: 200px;
      }
    }
  }

  ._darkMode .TextArea {
    &_Input {
      color: $hugr-colours-grey;
      background: $hugr-colours-dark-input-surface;
      border-bottom: 3px solid $hugr-colours-dark-input-border;

      &._hasError {
        border-bottom: 3px solid lighten( $hugr-colours-red, 20% );
      }
    }
  }
</style>
