<template>
  <Body title="JiraCallback">
    <div v-if="!success" v-translate>
      Gathering data and making calls to servers. Just bear with me for a moment!
    </div>
    <div v-else v-translate>
      Successfully linked! All the managers on your team can now export to Jira on behalf of the linked account from reports associated with your team.
      Please be aware that linked Jira sessions expire in 90 days of inactivity, or expire absolutely in 1 year requiring you to relink.
    </div>
  </Body>
</template>

<script>

import UIBody from '@/components/UI/Body';
import gql from 'graphql-tag';

export default {
  name: 'JiraCallbackView',
  created() {
    let search = window.location.search.substring( 1 );

    search = search.split( "&" );

    this.code = search[1].substring( 5 );
    this.state = search[0].substring( 6 );

    if ( search[0].substring( 5 ).toLowerCase() == "code=" ) {
      this.code = search[0].substring( 5 );
      this.state = search[1].substring( 6 );
    }

    // eslint-disable-next-line prefer-destructuring
    this.team = this.state.split( "-..-" )[0];

    this.$apollo.mutate( {
      mutation: gql`
        mutation grabJiraTokens($id: ObjectID!, $code: String!) {
          result: grabJiraTokens(id: $id, code: $code)
        }
      `,
      variables: {
        code: this.code,
        id: this.team,
      },
    } ).then( res => {
      if ( res.data.result ) {
        this.success = true;
        window.history.replaceState( {},document.title,'/' );
        this.$router.replace( `/callback` );
      }
    } );

  },
  data() {
    return {
      accessToken: "",
      data: false,
      success: false,
    };
  },
  methods: {

  },
  components: {
    Body: UIBody,
  },
};
</script>
