<template>
  <Modal
    ref="modal"
    :stepsInHeader="true"
    :currentStep="currentStep"
    :totalSteps="2"

    :title="$gettext(titles[currentStep-1])"
    :subtitle="$gettext(subtitles[currentStep-1])"

    :showActions="true"
    :noBack="true"
    :canContinue="currentStep==2"
    submitText="Save"
    emptyText="Activate your key"

    @submit="registerKey()"
    @close="reset()">
    <form>
      <div v-if="alreadyRegistered">
        <Notice type="warning" aria-live="polite">{{$gettext('Security key already registered')}}</Notice>
      </div>
      <div v-else-if="credential" aria-live="polite">
        <!-- <Notice size="micro">{{$gettext('Found a key!')}}</Notice> -->
        <FormInput idRoot="key_" :label="$gettext('Name')" v-model="keyName" />
      </div>
      <div v-else-if="canRegister">
        <p v-translate>Please plug your key in and click the button.</p>
      </div>
      <div v-else>
        <Notice type="warning">{{$gettext('Security keys are not supported in this browser, please try in a browser that supports it')}}</Notice>
      </div>
    </form>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';

import FormInput from '@/components/Form/Input';

export default {
  name: 'SecurityKeyModal',
  created() {
  },
  data() {
    return {
      keyName: '',
      canRegister: true,
      challenge: false,

      credential: false,

      currentStep: 1,

      titles: [
        'Add a security key',
        'Name your key',
      ],
      subtitles: [
        'Plug in and activate the security key you would like to use.',
        'Give your key a memorable name.',
      ],
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
      if( navigator.credentials && navigator.credentials.create ) {

        // const toArrayBuffer = (str) => {
        //   const arr = str.split(',');
        //   const ab = new ArrayBuffer(arr.length);
        //   const view = new Uint8Array(ab);
        //   for (let i = 0; i < arr.length; ++i) {
        //       view[i] = parseInt(arr[i]);
        //   }
        //   return ab;
        // };

        this.$apollo.query( {
          query: gql`
            {
              challenge: GenerateChallenge
            }
          `,
        } ).then( res => {
          const { challenge } = res.data;
          const publicKey = {
            challenge: Uint8Array.from( challenge, c => c.charCodeAt( 0 ) ),
            rp: {
              name: "Hugr",
              id: this.$hugrConfig.appId,
            },
            user: {
              id: Uint8Array.from( this.user.id, c => c.charCodeAt( 0 ) ),
              name: this.user.email,
              displayName: this.user.displayName || this.user.name,
            },
            pubKeyCredParams: [ { alg: -7, type: "public-key" } ],
            authenticatorSelection: {
              authenticatorAttachment: "cross-platform",
            },
            timeout: 60000,
            attestation: "direct",
          };
          navigator.credentials.create( {
            publicKey,
          } ).then( credential => {
            this.currentStep = 2;
            this.credential = credential;
          } ).catch( () => {
            this.$alerts.coded( 'E082', 'F4802' ); //see notifications spreadsheet
          } );
        } ).catch( () => {
          this.$alerts.coded( 'E081', 'F4801' ); //see notifications spreadsheet
        } );
      } else {
        this.canRegister = false;
      }
    },
    registerKey() {

      const clientDataJSON = new Uint8Array( this.credential.response.clientDataJSON ).toString( 'utf8' );
      const attestationObject = new Uint8Array( this.credential.response.attestationObject ).toString( 'utf8' );
      this.$apollo.mutate( {
          mutation: gql`
            mutation registerSecurityKey($name: String!, $id: String!, $clientDataJSON: String!, $attestationObject: String!) {
              keyres: registerSecurityKey(name:$name, id: $id, clientDataJSON: $clientDataJSON, attestationObject: $attestationObject) {
                success
                reason
              }
            }
          `,
          variables: {
            name: this.keyName,
            id: this.credential.id,
            clientDataJSON,
            attestationObject,
          },
        } ).then( res => {
        if( res.data.keyres.success ) {
          this.$alerts.success( 'Security key added', `Security key has been set up successfully` );
          this.$emit( 'success' );
          this.reset();
        } else {
          this.$alerts.coded( 'E083', 'F4804' ); //see notifications spreadsheet
        }
      } ).catch( () => {
        this.$alerts.coded( 'E083', 'F4803' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.keyName = '';
      this.canRegister = true;
      this.challenge = false;
      this.registrationResponse = false;
      this.alreadyRegistered = false;
      this.currentStep = 1;
      this.credential = false;
      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  props: {
  },
  components: {
    FormInput,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
