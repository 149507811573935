<template>
  <form @submit.prevent="submitPermissionGroup">
    <FormFieldSet :legend="$gettext('New Permission Group')">
      <FormInput idRoot="Permission Group_" :label="$gettext('Name')" v-model="name" />
      <Button id="Permission Group" type="primary" htype="submit">{{$gettext('Add Permission Group')}}</Button>
    </FormFieldSet>
  </form>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';

export default {
  name: 'PermissionGroupForm',
  data() {
    return {
      name: '',
    };
  },
  methods: {
    submitPermissionGroup() {
      if( this.name != '' ) {
        this.$apollo.mutate( {
          mutation: gql`
            mutation addPermissionGroup($permissionGroup: PermissionGroupInput!) {
              permissionGroup: addPermissionGroup(permissionGroup: $permissionGroup) {
                _id,
                name
              }
            }
          `,
          variables: {
            permissionGroup: {
              name: this.name,
            },
          },
        } ).then( () => {
          this.$emit( 'success' );
          this.$alerts.success( 'Success', 'Group successfully created.' );
          this.name = '';
        } ).catch( () => {
          this.$alerts.coded( 'E036', 'F1801' ); //see notifications spreadsheet
        } );
      } else {
        //give the group a name
        this.$alerts.coded( 'E037', 'F1802' ); //see notifications spreadsheet
      }
    },
  },
  components: {
    FormInput,
    FormFieldSet,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
