<template>
  <span class="FormImage">
    <label :for="idRoot+'fileselect'">{{ label }}</label>
    <div :class="['FormImage_Droparea', dragOver?'_dragOver':'']" tabindex="0" @paste="onPaste" @dragenter.prevent="dropCheck" @dragover.prevent="dropCheck" @dragleave.prevent="dropCancel" @dragend.prevent="dropCancel" @drop.prevent="onDrop">
      <span v-if="!isPopulated" class="FormImage_Droparea_Msg">
        <template v-if="msg">{{msg}}</template>
        <template v-else>Drag and drop or paste an image here</template>
      </span>
      <span class="FormImage_Droparea_Imgselectmsg" aria-live="polite" ref="msg"></span>
      <img class="FormImage_Droparea_Display _hidden" ref="display" alt="screenshot" />
    </div>
    <input :id="idRoot+'fsin'" ref="textIn" class="FormImage_TextIn" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" />

    <input class="FormImage_ImgInp" type="file" name="fileSelect" :id="idRoot+'fileSelect'" ref="fileselect" @change="onPick">
    <!-- <span v-if="hintText" class="hint">{{ hintText }}</span> -->
  </span>
</template>

<script>
import mixin from './Mixin';
export default {
  mixins: [ mixin ],
  name: 'FormImage',
  data() {
    return {
      selected: false,
      isPopulated: false,
      value: this.modelValue,
      dragOver: false,
    };
  },
  computed: {
    id() {
      return `${this.idRoot}-${this.label.replace( / /g, '' ).toLowerCase().trim()}`;
    },
  },
  methods: {
    onPaste( evt ) {
      if( evt.clipboardData.items[0].type.match( 'image/*' ) ) {
        const file = evt.clipboardData.items[0].getAsFile();
        const reader = new FileReader();
        reader.addEventListener( "load", () => {
          this.$refs.display.setAttribute( 'src', reader.result );
          this.$refs.display.classList.remove( '_hidden' );
          this.$refs.textIn.value = reader.result;
          this.$emit( 'update:modelValue', reader.result );
          this.populated();
        }, false );
        reader.readAsDataURL( file );
      } else {
        //TODO: why is this here?
      }
    },
    dropCheck() {
      this.dragOver = true;
    },
    dropCancel() {
      this.dragOver = false;
    },
    onDrop( e ) {
      this.dragOver = false;
      if( e.dataTransfer.files[0].type.match( 'image/*' ) ) {
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.addEventListener( "load", () => {
          this.$refs.display.setAttribute( 'src', reader.result );
          this.$refs.display.classList.remove( '_hidden' );
          this.$refs.textIn.value = reader.result;
          this.$emit( 'update:modelValue', reader.result );
          this.populated();
        }, false );
        reader.readAsDataURL( file );
      } else {
        //TODO: why is this here?
      }
    },
    onPick() {
      const reader = new FileReader();
      reader.addEventListener( "load", () => {
        this.$refs.display.setAttribute( 'src', reader.result );
        this.$refs.display.classList.remove( '_hidden' );
        this.$refs.textIn.value = reader.result;
        this.$emit( 'update:modelValue', reader.result );
        this.populated();
        setTimeout( () => { //because focus is being moved away for some Reason
          this.$refs.fileselect.focus();
        }, 100 );
      }, false );
      reader.readAsDataURL( this.$refs.fileselect.files[0] );
    },
    populated() {
      this.isPopulated = true;
      this.$refs.msg.innerText = 'An image has been selected';
    },
    clear() {
      this.$refs.fileselect.value = null;
      this.$refs.display.setAttribute( 'src', '' );
      this.$refs.display.classList.add( '_hidden' );

      this.isPopulated = false;
      this.$refs.msg.innerText = 'Drag and drop or paste an image here';
    },
  },
  props: {
    idRoot: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    msg: {
      default: false,
    },
    modelValue: {
      default: "",
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_buttons.scss';

  .FormImage {
    label {
      margin: 10px 0;
      display: block;
      color: #262e37;
    }
    &_Droparea {
      width: 100%;
      border: 1px dashed $hugr-colours-primary;
      background: lighten( $hugr-colours-grey, 15% );
      padding: 10px 0;
      text-align: center;
      color: $hugr-colours-primary;

      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;

      &._dragOver {
        border: 3px solid $hugr-colours-secondary;
      }

      &_Msg {
        padding: 40px 0;
        display: block;
      }

      &_Imgselectmsg {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;
      }

      &_Display {
        &._hidden {
          display: none;
        }
      }

      img {
        max-height: 300px;
      }
    }
    &_TextIn {
      display: none;
    }

    &_ImgInp {
      margin-top: 5px;
      &::-webkit-file-upload-button {
        font-family: $hugr-button-all-font;
        background: $hugr-colours-secondary;
        border: 1px solid $hugr-colours-secondary;
        border-radius: 5px;
        padding: 5px;
        font-size: 1em;
        cursor: pointer;

        transition: background-color .5s ease-in-out 0s,
              color .5s ease-in-out 0s,
              border-radius 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
        &:hover {
          background: darken($hugr-colours-secondary, 10%);
          border-radius: 10px;
        }
      }
    }
  }

  ._darkMode .FormImage {
    label {
      color: $hugr-colours-grey
    }
    &_Droparea {
      color: $hugr-colours-grey;
      background: lighten( $hugr-colours-primary, 10% );
    }
  }
</style>
