<template>
    <div v-if="items">
        <ol v-if="ordered" aria-live="polite" class="Items">
            <li v-for="(item, i) in items" :key="`item-${item.name}`" :class="['Items_Item', '_padded']">
                <span v-if="boldName" class="Items_Item_Name_Bold">{{ displayItems[i].name }}</span>
                <span v-else class="Items_Item_Name">{{ displayItems[i].name }}</span>
                <span v-if="displayItems[i].type" class="Items_Item_Type">{{ displayItems[i].type }}</span>
                <span class="Items_Item_Actions">
                    <span v-if="moveable" class="Items_Item_Actions_Move">
                        <Button v-show="i!=0"
                        size="micro"
                        type="icon"
                        :icon="['solid', 'chevron-up']"
                        class="Items_Item_Actions_Move_Up"
                        @click="(e) => { moveItemUp(item, e) }">{{$gettext('Move up')}} {{ displayItems[i].name }}</Button>
                        <Button v-show="i<(items.length-1)"
                        size="micro"
                        type="icon"
                        :icon="['solid', 'chevron-down']"
                        class="Items_Item_Actions_Move_Down"
                        @click="(e) => { moveItemDown(item, e) }">{{$gettext('Move down')}} {{ displayItems[i].name }}</Button>
                    </span>
                    <Button size="micro" type="serious" :icon="['solid', 'times']" class="Items_Item_Actions_Remove" @click="rmItem(item)">{{$gettext('Remove')}}</Button>
                </span>
            </li>
        </ol>
        <ul v-else aria-live="polite" :class="['Items', '_unordered']">
            <li v-for="(item, i) in items" v-bind:key="'item-' + item.name" :class="['Items_Item', '_padded']">
                <span v-if="boldName" class="Items_Item_Name_Bold">{{ displayItems[i].name }}</span>
                <span v-else class="Items_Item_Name">{{ displayItems[i].name }}</span>
                <span class="Items_Item_Type">{{ displayItems[i].type }}</span>
                <span class="Items_Item_Actions">
                    <span v-if="moveable" class="Items_Item_Actions_Move">
                        <Button v-show="i!=0"
                        size="micro"
                        type="icon"
                        :icon="['solid', 'chevron-up']"
                        class="Items_Item_Actions_Move_Up"
                        @click="(e) => { moveItemUp(item, e) }">{{$gettext('Move up')}} {{ displayItems[i].name }}</Button>
                        <Button v-show="i<(items.length-1)"
                        size="micro"
                        type="icon"
                        :icon="['solid', 'chevron-down']"
                        class="Items_Item_Actions_Move_Down"
                        @click="(e) => { moveItemDown(item, e) }">{{$gettext('Move down')}} {{ displayItems[i].name }}</Button>
                    </span>
                    <Button size="micro" type="serious" :icon="['solid', 'times']" class="Items_Item_Actions_Remove" @click="rmItem(item)">{{$gettext('Remove')}}</Button>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    setup() {

    },
    methods: {
        rmItem( item ) {
            this.items.remove( item );
            this.$emit( 'update:items', this.items );
        },
        moveItemUp( item, e ) {
            e.preventDefault();
            //get current array position
            let oldIndex = 0;

            if( typeof this.items[0] == 'string' ) {
                oldIndex = this.items.indexOf( item );
            }

            if( this.items[0]._id ) {
                oldIndex = this.items.map( i => i._id ).indexOf( item._id );
            }

            this.items.move( oldIndex, oldIndex - 1 );
            this.$emit( 'update:items', this.items );
        },
        moveItemDown( item, e ) {
            e.preventDefault();
            //get current array position
            let oldIndex = 0;

            if( typeof this.items[0] == 'string' ) {
                oldIndex = this.items.indexOf( item );
            }

            if( this.items[0]._id ) {
                oldIndex = this.items.map( i => i._id ).indexOf( item._id );
            }

            this.items.move( oldIndex, oldIndex + 1 );
            this.$emit( 'update:items', this.items );
        },
    },
    computed: {
        displayItems() {
            if( this.overrideDisplayItems ) {
                return this.overrideDisplayItems;
            }

            return this.items;
        },
    },
    props: {
        ordered: {
            default: false,
        },
        moveable: {
            default: false,
        },
        items: {
            required: true,
        },
        overrideDisplayItems: {
            required: false,
        },
        boldName: {
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

.Items {
    border: 1px solid $hugr-colours-grey;
    padding-left: 20px;
    &_Item {
      padding: 4px;
      border-bottom: 1px solid $hugr-colours-grey;
      &:last-child {
        border-bottom: none;
      }
      position: relative;
      &_Name {
        display: block;

        &_Bold {
            font-weight: bold;
        }
      }
      &_Type {
        display: block;
        font-size: 0.8em;

      }
      &_Actions {
        position: absolute;
        top: 6px;
        right: 6px;
        &_Move {
          &_Up {
            margin-right: 8px;
          }
          &_Down {
            margin-right: 8px;
          }
        }
      }

      &._padded {
            padding: 11px 5px;
      }
    }

    &._unordered {
        list-style: none;
        padding: 0;
    }
  }
</style>
