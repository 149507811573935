<template>
  <div role="progressbar" :class="['StepProgress']" :aria-valuenow="currentStep" :aria-valueMin="1" :aria-valuetext="'Step ' + currentStep + ' of ' + totalSteps" :aria-valuemax="totalSteps" :aria-labelledby="'stepProgressBar'">
    <div class="StepProgress_Steps">
    <template v-for="i in totalSteps" :key="currentStep+'-'+i+'-step'">
        <div class="StepProgress_Steps_Step">
          <div class="StepProgress_Steps_Step_Bubble" v-if="i < currentStep">
            <div class="StepProgress_Steps_Step_Bubble_Inner" >
              <Icon class="StepProgress_Steps_Step_Bubble_Inner_Icon" type="solid" icon="check" />
            </div>
          </div>
          <div v-else :class="['StepProgress_Steps_Step_Bubble', i==currentStep?'_Current':'_Future']">

          </div>
          <div v-if="i < totalSteps" :class="['StepProgress_Steps_Step_Breaker', i >= currentStep?'_Future':'']">

          </div>
        </div>
    </template>
    </div>
  </div>
</template>

<script setup>
  import { toRefs } from 'vue';

  const props = defineProps( {
    id: String,
    totalSteps: {
      type: Number,
      default: 3,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  } );

  const { id, totalSteps, currentStep } = toRefs( props );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .StepProgress {
    margin-bottom: 8px;
    margin-top: 8px;
    &_Steps {
      display: flex;
      flex-direction: row;

      &_Step {
        display: flex;
        flex-direction: row;
        align-items: center;

        &_Bubble {
          display: inline-block;

          height: 24px;
          width: 24px;
          max-height: 24px;
          max-width: 24px;

          border-radius: 16px;
          background-color: #6bbc61;

          &_Inner {
            width: 24px;
            height: 24px;
            display: flex;

            align-items: center;
            justify-content: center;
            background-color: transparent;

            &_Icon {
              color: white;
            }
          }

          &._Current {
            background-color: #024d78;
          }
          &._Future {
            background-color: #d9d9d9;
          }
        }
        &_Breaker {
          display: inline-block;
          margin-right: 8px;
          margin-left: 8px;

          height: 2px;
          width: 18px;
          background-color: #024d78;

          &._Future {
            background-color: #d9d9d9;
          }
        }
      }
    }
  }

  ._darkMode {
    .ProgressBar {

    }
  }
</style>
