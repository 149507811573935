<template>
  <Body :title="$gettext('Issues')">

    <p v-translate>This is a catalogue of all issues that can be used in reporting. Our common issues are there for you to use and cover most aspects of accessibility testing.</p>
    <p v-translate>These are organised by references such as:</p>
    <ul>
      <li v-translate>{{ $hugrConfig.mainShortName.toUpperCase() }}xxx for desktop accessibility issues</li>
      <li v-translate>MOBxxx for mobile accessibility issues</li>
    </ul>
    <p v-translate>You or your team can also add your own issues just for you.</p>

    <div class="Issues_Actions __grid">
      <Button class="__grid_column_2" type="secondary" :icon="['solid', 'plus']" @click="$refs.templatemodal.show()" v-show="hasPermission('IssueTemplates', 'Create')">{{$gettext('Add a template')}}</Button>
      <Search :class="[hasPermission('IssueTemplates', 'Create')?'__grid_column_10':'__grid_column_12']" :label="$gettext('Search all issue templates')" v-model="search" :inUse="search!=''" :results="searchTemplates.length"/>
    </div>
    <TabList
      class="Issues_Tablist"
      :tabs="tabs"
      :key="`tablist`"
    />
    <CardSet v-if="searchTemplates" :title="$gettext('Search Results')" id="TemplatesPanel">
      <Loader v-if="loading"/>
      <DataTable v-else
          class="Reports_Panel_Table" tablekey="issuescat" :customisable="true" @changed="$forceUpdate()"
          :headers="headers"
        >
        <template v-for="(template, i) in searchTemplates" :key="template._id">
          <tr v-if="template.identifier!=''">
            <td v-if="$tableColumnVisible('issuescat', 'identifier')">{{ template.identifier }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'title')">{{ template.title }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'wCAGRef')">{{ template.criteria.map( c => `${c.criterion} (${c.level})` ).join(', ') }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'severity')">{{ ['Advisory', 'Low', 'Medium', 'High', 'Critical'][template.severity] }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'section')">{{ template.section }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'metadata')">
              <span v-if="template.metadata.length > 0 && template.metadata[0] != ''">
                <span v-bind:key="template.identifier+ '-tag-' + key" class="pill" v-for="(tag,key) in template.metadata">
                  {{ template.metadata[key] }}
                </span>
              </span>
              <span v-else v-translate>
                None
              </span>
            </td>
            <td v-if="$tableColumnVisible('issuescat', 'owner')">{{ template.owner?template.owner.name:$gettext('None') }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'team')">{{ template.team?template.team.name:$gettext('None') }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'private?')">{{ template.private?$gettext('Yes'):$gettext('No') }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'locked?')">{{ template.locked?$gettext('Yes'):$gettext('No') }}</td>
            <td v-if="(parseInt(user.aclevel)==0||parseInt(user.aclevel)==1)&&$tableColumnVisible('issuescat', 'used')">{{ template.issueCount }}</td>
            <td>
              <Button size="micro" @click="$refs.issuedetailsmodal.show(template._id)">{{ $gettext('View details') }}</Button>
              &nbsp;
              <Button
                v-if="hasPermission('IssueTemplates', 'Update') || template.canEdit"
                @click="editIssue(template._id, i)"
                size="micro">
                  {{$gettext('Edit')}}
              </Button>
              &nbsp;
              <Button
                v-if="( hasPermission('IssueTemplates', 'Delete') || template.canEdit ) && template.issueCount == 0"
                @click="deleteIssueTemplate(template._id)"
                size="micro"
                type="serious">
                  {{$gettext('Delete')}}
              </Button>
            </td>
          </tr>
        </template>
      </DataTable>
    </CardSet>
    <CardSet v-else :title="$gettext('All Issue templates')">
      <Loader v-if="$apollo.queries.templatePage.loading" />
      <Loader v-else-if="loading"/>
      <DataTable v-else
          class="Reports_Panel_Table" tablekey="issuescat" :customisable="true" @changed="$forceUpdate()"
          :headers="headers"
        >
        <template v-for="(template, i) in templatePage.templates" :key="template._id">
          <tr v-if="template.identifier!=''">
            <td v-if="$tableColumnVisible('issuescat', 'identifier')">{{ template.identifier }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'title')">{{ template.title }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'wCAGRef')">{{ template.criteria.map( c => `${c.criterion} (${c.level})` ).join(', ') }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'severity')">{{ ['Advisory', 'Low', 'Medium', 'High', 'Critical'][template.severity] }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'section')">{{ template.section }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'metadata')">
              <span v-if="template.metadata.length > 0 && template.metadata[0] != ''">
                <span v-bind:key="template.identifier+ '-tag-' + key" class="pill" v-for="(tag,key) in template.metadata">
                  {{ template.metadata[key] }}
                </span>
              </span>
              <span v-else v-translate>
                None
              </span>
            </td>
            <td v-if="$tableColumnVisible('issuescat', 'owner')">{{ template.owner?template.owner.name:$gettext('None') }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'team')">{{ template.team?template.team.name:$gettext('None') }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'private?')">{{ template.private?$gettext('Yes'):$gettext('No') }}</td>
            <td v-if="$tableColumnVisible('issuescat', 'locked?')">{{ template.locked?$gettext('Yes'):$gettext('No') }}</td>
            <td v-if="(parseInt(user.aclevel)==0||parseInt(user.aclevel)==1)&&$tableColumnVisible('issuescat', 'used')">{{ template.issueCount }}</td>
            <td>
              <Button size="micro" @click="$refs.issuedetailsmodal.show(template._id)">{{ $gettext('View details') }}</Button>
              &nbsp;
              <Button
                v-if="hasPermission('IssueTemplates', 'Update') || template.canEdit"
                @click="editIssue(template._id, i)"
                size="micro">
                  {{$gettext('Edit')}}
              </Button>
              &nbsp;
              <Button
                v-if="( hasPermission('IssueTemplates', 'Delete') || template.canEdit ) && template.issueCount == 0"
                @click="deleteIssueTemplate(template._id)"
                size="micro"
                type="serious">
                  {{$gettext('Delete')}}
              </Button>
            </td>
          </tr>
        </template>
      </DataTable>
      <DataTablePaginator :currentPage="page" :pages="templatePage.pages" @select="(p) => { page = p } " />
      <DataTablePageSize :tableName="'IssueTemplates'" @selected="(p) => { size = p; page = 0; } " />
    </CardSet>
    <IssueTemplateModal ref="templatemodal" @success="$apollo.queries.templatePage.refetch()"/>
    <EditIssueTemplateModal ref="edittemplatemodal" @success="replaceIssue"/>
    <IssueDetailsModal ref="issuedetailsmodal"/>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';

import IssueTemplateModal from '@/modals/IssueTemplate/Create';
import EditIssueTemplateModal from '@/modals/IssueTemplate/Edit';
import IssueDetailsModal from '@/modals/IssueTemplate/Details';

import UIDataTablePaginator from '@/components/UI/DataTablePaginator';
import UIDataTablePageSize from '@/components/UI/DataTablePageSize';

export default {
  name: 'IssuesView',
  data() {
    const tabs = [];

    tabs.push( {
      text: `${this.$hugrConfig.mainShortName} ${this.$gettext( "templates" )}`,
      controls: "TemplatesPanel",
      action: () => {
        this.method = 'dig';
      },
      pill: () => {
        return [];
      },
    } );

    tabs.push( {
      text: `${this.$hugrConfig.mainShortName} ${this.$gettext( "mobile templates" )}`,
      controls: "TemplatesPanel",
      action: () => {
        this.method = 'digmob';
      },
      pill: () => {
        return [];
      },
    } );

    tabs.push( {
      text: `${this.$hugrConfig.mainShortName} ${this.$gettext( "other templates" )}`,
      controls: "TemplatesPanel",
      action: () => {
         this.method = 'digother';
      },
      pill: () => {
        return [];
      },
    } );

    tabs.push( {
      text: this.$gettext( "My templates" ),
      controls: "TemplatesPanel",
      action: () => {
         this.method = 'mine';
      },
      pill: () => {
        return [];
      },
    } );

    tabs.push( {
      text: this.$gettext( "Templates on my teams" ),
      controls: "TemplatesPanel",
      action: () => {
         this.method = 'team';
      },
      pill: () => {
        return [];
      },
    } );

    tabs.push( {
      text: this.$gettext( "Community templates" ),
      controls: "TemplatesPanel",
      action: () => {
         this.method = 'community';
      },
      pill: () => {
        return [];
      },
    } );

    return {

      templatePage: false,
      searchTemplates: false,
      key: 1,
      editedissueIndex: false,
      search: '',

      page: 0,
      size: 25,

      loading: false,

      method: 'dig',
      tabs,
    };
  },
  watch: {
    search() {
      this.loading = true;
      clearTimeout( this.typingTimeout );
      if( this.search == '' ) {
        this.loading = false;
        this.searchTemplates = false;
      } else {
        this.typingTimeout = setTimeout( () => {
        this.$apollo.query( {
          query: gql`
            query SearchIssueTemplates($term: String!) {
              templates: SearchIssueTemplates(term: $term) {
                _id,
                identifier,
                title,
                metadata,
                section,

                owner {
                  _id,
                  name
                }
                team {
                  _id
                  name
                }
                criteria {
                  _id
                  criterion
                  level
                }
                private
                locked
                canEdit
                hasIssues
                issueCount
                severity
              }
            }
          `,
          variables: {
            term: this.search,
          },
        } ).then( res => {
          this.loading = false;
          this.searchTemplates = Object.assign( [], res.data.templates );
        } ).catch( () => {
          this.$alerts.error( 'Something went wrong!', 'search went bad' ); //TODO
        } );
        }, 1000 );
      }
    },
  },
  apollo: {
    templatePage: {
      query: gql`
        query IssueTemplatesPage($method: String, $page: Int!, $size: Int!) {
          templatePage: IssueTemplatesPage(method: $method, page: $page, size: $size) {
            templates {
              _id,
              identifier,
              title,
              metadata,
              section,

              owner {
                _id,
                name
              }
              team {
                _id
                name
              }
              criteria {
                _id
                criterion
                level
              }
              private
              locked
              canEdit
              hasIssues,
              issueCount
              severity
            },
            hasMore,
            pages
          }
        }
      `,
      variables() {
        return {
          method: this.method,
          page: this.page,
          size: this.size,
        };
      },
      update( data ) {
        return { ...data.templatePage };
      },
    },
  },
  methods: {
    editIssue( id, index ) {
      this.editedissueIndex = index;
      this.$refs.edittemplatemodal.show( id );
    },
    deleteIssueTemplate( id ) {
      this.$confirm.simple( 'This cannot be undone' ).then( result => {
        if( result ) {
          this.doDeleteIssueTemplate( id );
        }
      } );
    },
    doDeleteIssueTemplate( id ) {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!) {
          removeIssueTemplate(id: $id)
        }`,
        variables: {
          id,
        },
      } ).then( () => {
        this.$alerts.success( 'Issue template deleted', 'Issue Template was deleted successfully.' );
        this.$apollo.queries.templatePage.refetch();
      } ).catch( error => {
        this.$alerts.error( 'Issue template not deleted', 'Issue Template could not be deleted.', '', error );
      } );
    },
    replaceIssue( identifier ) {
      this.$apollo.query( {
        query: gql`
          query IssueTemplate($id: ObjectID) {
            template: IssueTemplate(id: $id) {
              _id,
              identifier,
              title,
              description,
              severity,
              affected,
              impact,
              extra,
              metadata,
              criteria {
                _id,
                criterion,
                title,
                level,
                identifier
              },
              solutions {
                _id,
                identifier,
                title
              },
              section,
              reasonTemplates

              owner {
                _id,
                name
              }
              team {
                _id
                name
              }
              private
              locked

              canEdit
              hasIssues
              issueCount
            }
          }
        `,
        variables: {
          id: identifier,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        if ( this.search == '' ) {
          this.templatePage.templates = Object.assign( [], this.templatePage.templates );
          this.templatePage.templates[this.editedissueIndex] = res.data.template;
        } else {
          this.searchTemplates[this.editedissueIndex] = res.data.template;
        }
        this.$forceUpdate();
      } );
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    ...mapState( [ 'user' ] ),
    headers() {
      //TODO: gettext
      const headers = [
        'Identifier',
        'Title',
        'WCAG ref',
        'Severity',
        { name: 'Section', default: false },
        { name: 'Metadata', default: false },
        { name: 'Owner', default: false },
        { name: 'Team', default: false },
        { name: 'Private?', default: false },
        { name: 'Locked?', default: false },
      ];
      if( parseInt( this.user.aclevel ) == 0 || parseInt( this.user.aclevel ) == 1 ) {
        headers.push( 'Used' );
      }
      headers.push( { name: '', freeze: true } );

      return headers;
    },
  },
  components: {
    IssueTemplateModal,
    EditIssueTemplateModal,
    IssueDetailsModal,
    DataTablePaginator: UIDataTablePaginator,
    DataTablePageSize: UIDataTablePageSize,
  },
};
</script>

<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';

  .pill {
    background-color: $hugr-colours-secondary;
    border-radius: 16px;
    padding-right: 4px;
    padding-left: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    font-size: 0.8em;
    margin-right: 5px;
    color:black;
  }

 .search {
   padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #b3b3b3;
    border-radius: 2px;
 }

 .Issues {
   &_Tablist {
     margin-top: 50px;
     padding-bottom: 0 !important;
   }
 }
</style>
