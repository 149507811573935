<template>
  <li class="AccordionItem" tabindex="0" :aria-expanded="open" @click="toggle()">
    <slot></slot>
    <Icon v-if="open" class="AccordionItem_Icon" type="solid" icon="chevron-up"/>
    <Icon v-else class="AccordionItem_Icon" type="solid" icon="chevron-down"/>
  </li>
</template>

<script >
export default {
  name: 'UIAccordionLi',
  data() {
    return {
        open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
      if( this.open ) {
        this.$emit( 'open' );
      } else {
        this.$emit( 'close' );
      }

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';

.AccordionItem {
  border: 1px solid $hugr-colours-grey;
  padding: 15px 10px;
  margin-bottom: -1px;
  position: relative;

  transition: all .5s ease-in-out 0s;
  cursor: pointer;

  &:focus, &:hover, &.focus {
    background: lighten($hugr-colours-grey, 10%);
  }

  &_Icon {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}
</style>
