/***
 * Backend setup
 */

import config from '../../config';
// import { makePromise, fromPromise, GraphQLRequest } from 'apollo-link';

import { ApolloClient, createHttpLink, InMemoryCache, split, ApolloLink, concat, execute, fromPromise } from '@apollo/client/core';
import { getMainDefinition } from "@apollo/client/utilities";
import { createApolloProvider } from '@vue/apollo-option';
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
// import { onError } from 'apollo-link-error';
// import gql from 'graphql-tag'

// const isProduction = process.env.NODE_ENV === 'production';

const wsUri = `${config.graphqlSocketServerProtocol}://${config.graphqlServerHost}:${config.graphqlServerPort}/subscriptions`;
const wsLink = new WebSocketLink( {
  uri: wsUri,
  options: {
    reconnect: true,
  },
} );

// HTTP connection to the API
const httpUri = `${config.graphqlServerProtocol}://${config.graphqlServerHost}:${config.graphqlServerPort}/graphql`;
const httpLink = createHttpLink( {
  // You should use an absolute URL here
  credentials: 'same-origin',
  uri: httpUri,
} );

const authMiddleware = new ApolloLink( ( operation, forward ) => {
  // add the authorization to the headers
  const accessToken = localStorage.getItem( 'HUGR_ACCESS_TOKEN' ) || false;
  const refreshToken = localStorage.getItem( 'HUGR_REFRESH_TOKEN' ) || false;
  if( accessToken && refreshToken ) {
    operation.setContext( {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'hugr-refresh-token': refreshToken,
      },
    } );
  }

  return forward( operation );
} );

const errorMiddleware = onError(
  ( { graphQLErrors, networkError, operation, forward } ) => {
    if ( graphQLErrors ) {
      for ( const err of graphQLErrors ) {
        if(
          err.extensions?.code == "UNAUTHENTICATED"
          && localStorage.hasOwnProperty( 'HUGR_ACCESS_TOKEN' )
          && localStorage.hasOwnProperty( 'HUGR_REFRESH_TOKEN' )
        ) {
          if( localStorage.hasOwnProperty( 'IS_RESETTING' ) && localStorage.getItem( 'IS_RESETTING' ) == 'true' ) {
            // forward(operation);
          } else {
            const refreshRequest = {
              grant_type: "refresh_token",
              refresh_token: localStorage.getItem( 'HUGR_REFRESH_TOKEN' ),
              valid_for: 900,
            };

            const doReset = fetch( `${config.authUrl}/token`, {
              method: "POST",
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
              },
              body: `grant_type=${refreshRequest.grant_type}&refresh_token=${refreshRequest.refresh_token}&valid_for=${refreshRequest.valid_for}`,
            } ).then( res => {
              if ( res.ok ) {
                return res.json();
              }

            } ).then( json => {
              if( json.message
                  && (
                    json.message == 'Token Expired'
                    || json.message == 'Invalid Token'
                    || json.message == 'Invalid Session'
                  )
                ) {
                console.log('Refresh token not successful', err); //eslint-disable-line
                localStorage.clear();
                window.location.reload();
              } else {
                localStorage.setItem( 'HUGR_ACCESS_TOKEN', json.access_token );
              }
            } ).catch( err => {
              //give up and log out
              console.log('Refresh token not successful', err); //eslint-disable-line
              localStorage.clear();
              window.location.reload();
            } );

            return fromPromise( doReset ).flatMap( () => forward( operation ) );
          }
        }
      }
    }
  },
);

const link = split(
  ( { query } ) => {
    const definition = getMainDefinition( query );

    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  concat( errorMiddleware, concat( authMiddleware, httpLink ) ),
  // concat(authMiddleware, httpLink)
);
// Create the apollo client
import possibleTypes from '../possibleTypes.json';
const apolloClient = new ApolloClient( {
  link,
  cache: new InMemoryCache( {
    possibleTypes,
  } ),
  connectToDevTools: true,
} );

export default createApolloProvider( {
  defaultClient: apolloClient,
} );
