<template>
  <Modal
    ref="modal"
    :title="$gettext(`Edit Customer:`) + ` ${customer.name}`"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submitCustomer"

    @close="reset()"
    :ready="customer">
    <FormInput ref="name" idRoot="customer_" :label="$gettext('Name')" v-model="customer.name" :validation="['not-empty']"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';

export default {
  name: 'EditCustomerModal',
  data() {
    return {
      customer: false,
      submitting: false,
    };
  },
  methods: {
    submitCustomer() {
      const validations = [ 'name' ];

      this.$hugrvalidate( validations, this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation updateCustomer($id: ObjectID!, $customer: CustomerInput!) {
                customer: updateCustomer(id: $id, customer: $customer) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              id: this.customer._id,
              customer: {
                name: this.customer.name,
              },
            },
          } ).then( res => {
            this.submitting = false;
            this.reset();
            this.$alerts.success( 'Customer updated!', `Customer ${res.data.customer.name} has been updated successfully` );
            this.$emit( 'success', res.data.customer._id );
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.coded( 'E054', 'F3101', '', error ); //see notifications spreadsheet
          } );
        }
      } );
    },

    show( id ) {
      this.$apollo.query( {
        query: gql`
          query Customer($id: ObjectID!) {
            customer: Customer(id: $id) {
              _id,
              name
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.customer = { ...res.data.customer };
        this.$refs.modal.show();
      } ).catch( () => {
        this.$alerts.coded( 'E055', 'F3102' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.customer = false;
      this.$refs.modal.reset();
    },
  },
  props: {
  },
  components: {
    FormInput,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
