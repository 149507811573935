<template>
  <Body :title="$gettext(`Spreadsheet Template:`) + ` ${template.name}`">
    <form @submit.prevent="updateTemplate" class="Settings_Body_Form">
      <FormInput idRoot="sstemplate_" label="Name" v-model="template.name"/>
      <FormInput idRoot="sstemplate_" label="Description" v-model="template.description"/>

      <input type="checkbox" id="published" v-model="template.published"><label for="published">{{$gettext('Published')}}</label>
      <input type="checkbox" id="promoted" v-model="template.promoted"><label for="promoted">{{$gettext('Promoted')}}</label>
      <input type="checkbox" id="sortByReference" v-model="template.sortByReference"><label for="sortByReference">{{$gettext('Sort by reference')}}</label>

      <ButtonSet class="">
        <Button type="primary" htype="submit">{{$gettext('Save')}}</Button>
      </ButtonSet>
      <!-- <div class="Report__Body_All_Actions __grid">
        <FormInput class="__grid_column_10" idRoot="sstemplate_" label="Name" v-model="template.name"/>
        <Button class="__grid_column_2" type="primary" @click="rename">save</Button>
      </div> -->
    </form>
    <CardSet title="rows" :button="hasPermission( 'SpreadsheetTemplates', 'Update' )?{icon:['solid', 'plus', 'after'], text: $gettext('Add a row')}:false" @buttonClick="$refs.rowmodal.show()">
      <DataTable :manualHead="true">
        <thead>
          <tr>
            <th v-translate>Section</th>
            <th v-translate>Reference</th>
            <th v-translate>Header</th>
            <th v-translate>Details</th>
            <th v-translate>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in template.rows" v-bind:key="'row-' + row._id">
            <td>{{row.section}}</td>
            <td>{{row.ref}}</td>
            <td>{{row.header}}</td>
            <td>{{row.details}}</td>
            <td><Button v-if="hasPermission( 'SpreadsheetTemplates', 'Update' )" size="micro" @click="$refs.editrowmodal.show(row._id)">{{$gettext('Edit')}}</Button></td>
          </tr>
        </tbody>
      </DataTable>
    </CardSet>
    <SpreadsheetTemplateRowModal ref="rowmodal" :template="template._id" @success="$apollo.queries.atemplate.refetch()"/>
    <EditSpreadsheetTemplateRowModal ref="editrowmodal" @success="$apollo.queries.atemplate.refetch()"/>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

import SpreadsheetTemplateRowModal from '@/modals/SpreadsheetTemplate/CreateRow';
import EditSpreadsheetTemplateRowModal from '@/modals/SpreadsheetTemplate/EditRow';

import FormInput from '@/components/Form/Input';

export default {
  name: 'TemplatesSpreadsheetView',
  data() {
    return {
      template: false,
      tid: this.$route.params.id,
    };
  },
  apollo: {
    atemplate: {
      query: gql`
        query SpreadsheetTemplate($id: ObjectID!) {
          atemplate: SpreadsheetTemplate(id: $id) {
            _id,
            name,
            description,
            published,
            promoted,
            sortByReference

            rows {
              _id,
              section,
              ref,
              header,
              details,
              #issues {}
            }
          }
        }
      `,
      variables() {
        return {
          id: this.tid,
        };
      },
    },
  },
  watch: {
    $route( to ) {
      if( this.$route.name == 'TemplatesSpreadsheet' ) {
        this.tid = to.params.id;
        this.$apollo.queries.atemplate.refetch();
      }
    },
    atemplate() {
      this.template = { ...this.atemplate };
    },
  },
  methods: {
    updateTemplate() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateSpreadsheetTemplate($id: ObjectID!, $name: String!, $description: String, $published: Boolean, $promoted: Boolean, $sortByReference: Boolean ) {
            ss: updateSpreadsheetTemplate(id: $id, name: $name, description: $description, published: $published, promoted: $promoted, sortByReference: $sortByReference) {
              _id
            }
          }
        `,
        variables: {
          id: this.tid,
          name: this.template.name,
          description: this.template.description,
          published: this.template.published,
          promoted: this.template.promoted,
          sortByReference: this.template.sortByReference,
        },
      } ).then( () => {
        this.$alerts.success( 'Spreadsheet template updated', `The spreadsheet template has been updated.` );
      } ).catch( error => {
        this.$alerts.error( `Sorry, something went wrong`, `We couldn't update the spreadsheet template.`, '', error );
      } );
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
  },
  components: {
    SpreadsheetTemplateRowModal,
    EditSpreadsheetTemplateRowModal,

    FormInput,
  },
};
</script>
