<template>
  <div
    :class="['Gallery', editable?'_editable':'']"
    :tabindex="editable?0:-1"

    @focus="focussed = ediable?true:false"
    @focusin="focussed = ediable?true:false"
    @focusout="focussed = false"

    @dragenter.prevent="startDrag"
    @dragover.prevent="startDrag"
    @dragleave.prevent="stopDrag"
    @dragend.prevent="stopDrag"
    @drop.prevent="drop"

    @paste="paste"
    >
    <div class="Gallery_Inner" v-if="modelValue[0]">
      <div class="Gallery_Inner_Primary">
        <AuthImg class="Gallery_Inner_Primary_Img"
          :src="instance?`${$hugrConfig.assetsUrl}/evidence/${selectedImg}?${new Date().getTime()}`:selectedImg"
          :key="selectedImg"
          alt="Screenshot" />
      </div>
      <ul class="Gallery_Inner_Others">
        <template v-for="(img, i) of modelValue" :key="`${img}-${key}`">

          <li :class="['Gallery_Inner_Others_Box', img==selectedImg?'_selected':'']"
              role="button"
              tabindex="0"
              :aria-current="img==selectedImg"
              aria-label="Show image"
              @click="selectedImg = img"
              @keyup.space.prevent="selectedImg = img"
              @keyup.enter="selectedImg = img"
            >
              <div class="Gallery_Inner_Others_Box_Inner">
                <AuthImg class="Gallery_Inner_Others_Box_Inner_Img"
                :src="instance?`${$hugrConfig.assetsUrl}/evidence/${img}?${new Date().getTime()}`:img"
                :alt="`Screenshot ${i+1}`"
                :key="`img-${img}-${key}`"
                />
              </div>
          </li>
          <span class="Gallery_Inner_Others_Move">
            <button v-if="editable&&i!=0" class="Gallery_Inner_Others_Move_Button __left" @click.prevent="move(i, -1)"><Icon type="solid" icon="chevron-left" :aria-label="`move image ${i+1} to the left`"/></button>
            <button v-if="editable&&i!=modelValue.length-1" class="Gallery_Inner_Others_Move_Button __right" @click.prevent="move(i, 1)"><Icon type="solid" icon="chevron-right" :aria-label="`move image ${i+1} to the right`"/></button>
          </span>
          <button v-if="editable" class="Gallery_Inner_Others_Remove" @click.prevent="remove(i)"><Icon type="solid" icon="close" :aria-label="`remove image ${i+1}`"/></button>
        </template>
        <li class="Gallery_Inner_Others_Box _addOne"
            v-if="editable"
            role="button"
            tabindex="0"
            @click="triggerPick()"
            @keyup.space.prevent="triggerPick()"
            @keyup.enter="triggerPick()"
          >
          <div class="Gallery_Inner_Others_Box_Inner">
            <Icon class="Gallery_Inner_Others_Box_Inner_Icon" type="solid" icon="image"/>
            <span class="Gallery_Inner_Others_Box_Inner_IconText">Add image</span>
          </div>
        </li>
      </ul>
    </div>
    <template v-else>
      <Empty v-if="!editable" :text="$gettext('No images')"/>
      <Empty  v-else :text="$gettext('No images yet. Drag and drop, paste or add one here.')"
              :icon="['regular', 'image']"
              :or="true"
              :button="{size: 'small', type: 'border', text: $gettext('Choose a file to upload')}"
              @buttonClick="triggerPick()" />
    </template>
    <div class="Gallery_DropArea" v-show="dragging">
      <p class="Gallery_DropArea_Txt">Drop here</p>
    </div>
    <div class="Gallery_DropArea" v-show="focussed&&!dragging">
      <p class="Gallery_DropArea_Txt">Paste a file</p>
    </div>
    <input class="Gallery_Input" type="file" multiple name="fileSelect" id="fileSelect" ref="fileselect" @change="pick">
  </div>
</template>

<script>

  import { toRefs, ref, inject, watch, getCurrentInstance } from 'vue';
  import gql from "graphql-tag";
  import { useMutation } from "@vue/apollo-composable";

  import AuthImg from '../Helpers/AuthImg.vue';

  export default {
    name: 'UIGallery',
    setup( props ) {
      const confirm = inject( 'confirm' );

      const { modelValue, instance, editable } = toRefs( props );
      const dragging = ref( false );
      const focussed = ref( false );
      const selectedImg = ref( modelValue.value[0] );

      const key = ref( 0 );

      // eslint-disable-next-line prefer-destructuring
      watch( modelValue, () => { selectedImg.value = modelValue.value[0] } );

      const { mutate: removeEvidence } = useMutation(
        gql`
        mutation removeEvidence($issueInstance: ObjectID!, $evidence: Int!) {
          instance: removeEvidence(issueInstance: $issueInstance, evidence: $evidence){
            _id
            evidence
          }
        }
      ` );

      const remove = i => {
        if( instance.value ) {
          confirm.simple( 'Cannot be reversed' ).then( result => {
            if( result ) {
              removeEvidence( {
                issueInstance: instance.value,
                evidence: i,
              } ).then( () => {
                modelValue.value.splice( i, 1 );
                if( modelValue.value.indexOf( selectedImg.value ) == -1 ) {
                  [ selectedImg.value ] = modelValue.value;
                }
              } );
            }
          } );
        } else {
          modelValue.value.splice( i, 1 );
          if( modelValue.value.indexOf( selectedImg.value ) == -1 ) {
            [ selectedImg.value ] = modelValue.value;
          }
        }
      };

      const { mutate: addEvidence } = useMutation(
        gql`
        mutation addEvidence($issueInstance: ObjectID!, $evidence: String!) {
          instance: addEvidence(issueInstance: $issueInstance, evidence: $evidence){
            _id
            evidence
          }
        }
      ` );

      const add = file => {
        if( file.type.match( 'image/*' ) ) {
          const reader = new FileReader();
          reader.addEventListener( "load", () => {
            if( instance.value ) {
              addEvidence( {
                issueInstance: instance.value,
                evidence: reader.result,
              } ).then( ( { data } ) => {
                setTimeout( () => {
                  for( const item of data.instance.evidence ) {
                    if( modelValue.value.indexOf( item ) < 0 ) modelValue.value.push( item );
                  }
                  if( !selectedImg.value ) {
                    [ selectedImg.value ] = modelValue.value;
                  }
                }, 100 );
              } );
            } else {
              modelValue.value.push( reader.result );
              if( !selectedImg.value ) {
                [ selectedImg.value ] = modelValue.value;
              }
            }
          }, false );
          reader.readAsDataURL( file );
        }
      };

      const paste = evt => {
        if( !editable.value ) return;

        for( const item of evt.clipboardData.items ) {
          const file = item.getAsFile();
          if( file ) {
            add( file );
          }
        }
      };

      let dragTimer;
      const startDrag = () => {
        if( editable.value ) {
          clearTimeout( dragTimer );
          dragging.value = true;
        }
      };
      const stopDrag = () => {
        dragTimer = setTimeout( () => {
          dragging.value = false;
        }, 100 );
      };
      const drop = e => {
        if( !editable.value ) return;

        dragging.value = false;
        for( const file of e.dataTransfer.files ) {
          add( file );
        }
      };

      const fileselect = ref( null );
      const pick = () => {
        if( !editable.value ) return;

        for( const file of fileselect.value.files ) {
          add( file );
        }
      };
      const triggerPick = () => {
        fileselect.value.click();
      };

      const { mutate: moveEvidence } = useMutation(
        gql`
        mutation moveEvidence($issueInstance: ObjectID!, $index: Int!, $direction: Int!) {
          instance: moveEvidence(issueInstance: $issueInstance, index: $index, direction: $direction){
            _id
            evidence
          }
        }
      ` );

      const vueInstance = getCurrentInstance();

      const move = ( index, direction ) => {
        if( !( direction == -1 || direction == 1 ) ) throw new Error( 'direction incorrect' );

        moveEvidence( {
          issueInstance: instance.value,
          index,
          direction,
        } ).then( () => {
          key.value = key.value++;
          vueInstance?.proxy?.$forceUpdate();
        } );
      };

      return {
        selectedImg,
        dragging,
        focussed,
        paste,

        fileselect,
        pick,
        triggerPick,

        startDrag,
        stopDrag,
        drop,

        remove,

        move,
      };
    },
    props: {
      modelValue: [ String ],
      instance: String,
      editable: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      AuthImg,
    },
    emits: [ 'update:modelValue' ],
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_buttons.scss';
  .Gallery {
    border: 1px solid lighten( $hugr-colours-primary, 50% );
    background: lighten( $hugr-colours-grey, 15% );
    padding: 8px;
    position: relative;

    &._editable {
      background: $hugr-colours-input-surface;
      border: none;
      border-bottom: 3px solid $hugr-colours-input-border;
      margin-bottom: 16px;
      &:focus {
        // border: 1px dashed $hugr-colours-primary;
        outline: auto;
      }
    }

    &_Inner {
      &_Primary {
        max-height: 650px;
        &_Img {
          max-width: 100%;
          max-height: 650px;
          display: block;
          margin: auto;
        }
      }
      &_Others {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 16px;

        &_Remove {
          margin-right: -20px;
          cursor: pointer;
          top: -70px;
          position: relative;
          right: 26px;

          border-radius: 50%;
          height: 20px;
          width: 20px;
          padding: 3px;
          border: none;
          color: $hugr-button-secondary-text;
          background-color: $hugr-button-secondary-bg;
          &:focus, &:hover, &.focus, &.hover {
            // color: $hugr-button-secondary-text-focus;
            background-color: darken($hugr-button-secondary-bg, 20%);
          }
        }

        &_Move {
          width: 0;
          position: relative;
          right: 26px;
          display: inline-block;
          bottom: -3px;

          &_Button {
            cursor: pointer;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            padding: 3px;
            border: none;
            color: $hugr-button-secondary-text;
            background-color: $hugr-button-secondary-bg;
            &:focus, &:hover, &.focus, &.hover {
              // color: $hugr-button-secondary-text-focus;
              background-color: darken($hugr-button-secondary-bg, 20%);
            }
          }
        }

        &_Box {
          display: inline-block;
          width: 75px;
          height: 75px;
          background: #cbd2da;
          margin-right: 16px;
          margin-top: 16px;
          padding: 2px;

          transition: background-color .5s ease-in-out 0s,
              color .5s ease-in-out 0s,
              border-radius 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;

          &._addOne {
            color: $hugr-button-secondary-text;
            background-color: $hugr-button-secondary-bg;
            &:focus, &:hover, &.focus, &.hover {
              // color: $hugr-button-secondary-text-focus;
              background-color: darken($hugr-button-secondary-bg, 20%);
            }
          }

          &_Inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
            text-align: center;

            transition: background-color .5s ease-in-out 0s,
              color .5s ease-in-out 0s,
              border-radius 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;

            &_Img {
              max-width: 400%;
              max-height: 400%;
            }
            &_Icon {
              font-size: 2em;
              display: block;
              margin: auto;
              margin-top: 12px;
            }
            &_IconText {
              font-size:0.9em;
              display: block;
            }
          }

          &:hover, &:focus {
            border-radius: 5px;
            cursor: pointer;

            > .Gallery_Inner_Others_Box_Inner {
              border-radius: 5px;
            }
          }

          &._selected {
            background: darken(#cbd2da, 20%);
          }
        }
      }
    }

    &_DropArea {
      position: absolute;
      top: 0;
      left: 0;
      background: #000000cc;
      color: #fff;
      width: 100%;
      height: 100%;
      text-align: center;
      &_Txt {
        padding: 100px;
        font-size: 2em;
      }
    }

    &_Input {
      position: absolute !important;
      height: 1px; width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
    }
  }

  ._darkMode .Gallery {
    color: $hugr-colours-grey;
    background: $hugr-colours-dark-input-surface;
    border-bottom: 3px solid $hugr-colours-dark-input-border;
  }

  ._preload {
    display: none;
  }
</style>
