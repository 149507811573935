import md5 from 'md5';

const shortHash = ( value, chars = 6 ) => {
  return md5( value ).toUpperCase().slice( 0, chars );
};
const getVideoCardInfo = () => {
  const gl = document.createElement( 'canvas' ).getContext( 'webgl' );
  if ( !gl ) {
    return {
      error: "no webgl",
    };
  }
  const debugInfo = gl.getExtension( 'WEBGL_debug_renderer_info' );

  return debugInfo ? {
    vendor: gl.getParameter( debugInfo.UNMASKED_VENDOR_WEBGL ),
    renderer:  gl.getParameter( debugInfo.UNMASKED_RENDERER_WEBGL ),
  } : {
    error: "no WEBGL_debug_renderer_info",
  };
};

const getFonts = async () => {
  const fontCheck = new Set( [
    // Windows 10
  'Arial', 'Arial Black', 'Bahnschrift', 'Calibri', 'Cambria', 'Cambria Math', 'Candara', 'Comic Sans MS', 'Consolas', 'Constantia', 'Corbel', 'Courier New', 'Ebrima', 'Franklin Gothic Medium', 'Gabriola', 'Gadugi', 'Georgia', 'HoloLens MDL2 Assets', 'Impact', 'Ink Free', 'Javanese Text', 'Leelawadee UI', 'Lucida Console', 'Lucida Sans Unicode', 'Malgun Gothic', 'Marlett', 'Microsoft Himalaya', 'Microsoft JhengHei', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le', 'Microsoft YaHei', 'Microsoft Yi Baiti', 'MingLiU-ExtB', 'Mongolian Baiti', 'MS Gothic', 'MV Boli', 'Myanmar Text', 'Nirmala UI', 'Palatino Linotype', 'Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Historic', 'Segoe UI Emoji', 'Segoe UI Symbol', 'SimSun', 'Sitka', 'Sylfaen', 'Symbol', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Webdings', 'Wingdings', 'Yu Gothic',
    // macOS
    'American Typewriter', 'Andale Mono', 'Arial', 'Arial Black', 'Arial Narrow', 'Arial Rounded MT Bold', 'Arial Unicode MS', 'Avenir', 'Avenir Next', 'Avenir Next Condensed', 'Baskerville', 'Big Caslon', 'Bodoni 72', 'Bodoni 72 Oldstyle', 'Bodoni 72 Smallcaps', 'Bradley Hand', 'Brush Script MT', 'Chalkboard', 'Chalkboard SE', 'Chalkduster', 'Charter', 'Cochin', 'Comic Sans MS', 'Copperplate', 'Courier', 'Courier New', 'Didot', 'DIN Alternate', 'DIN Condensed', 'Futura', 'Geneva', 'Georgia', 'Gill Sans', 'Helvetica', 'Helvetica Neue', 'Herculanum', 'Hoefler Text', 'Impact', 'Lucida Grande', 'Luminari', 'Marker Felt', 'Menlo', 'Microsoft Sans Serif', 'Monaco', 'Noteworthy', 'Optima', 'Palatino', 'Papyrus', 'Phosphate', 'Rockwell', 'Savoye LET', 'SignPainter', 'Skia', 'Snell Roundhand', 'Tahoma', 'Times', 'Times New Roman', 'Trattatello', 'Trebuchet MS', 'Verdana', 'Zapfino',
  ].sort() );

  const { fonts } = document;
  await fonts.ready;

  const fontAvailable = [];

  for ( const font of fontCheck.values() ) {
    if ( fonts.check( `12px "${font}"` ) ) {
      fontAvailable.push( font );
    }
  }

  return fontAvailable;
};

const getPlugins = () => {
  const { plugins } = window.navigator;
  const res = [];
  for ( let i = 0; i < plugins.length; i++ ) {
    if( plugins.item( i ) !== null ) {
      res.push( plugins.item( i ).filename );
    }
  }

  return res;
};

const compileHardware = () => {
  const { hardwareConcurrency, deviceMemory } = window.navigator;

  return {
    hardwareConcurrency,
    deviceMemory,
  };
};

const compileOS = ( async = false ) => {
  const { platform } = window.navigator;
  const { colorDepth, availWidth, availHeight } = window.screen;
  const touchSupport = 'ontouchstart' in window;
  const webgl = getVideoCardInfo();

  const res = {
    platform,
    colorDepth,
    availWidth,
    availHeight,
    touchSupport,
    webgl,
  };

  if( async ) {
    return ( async () => {
      const fonts = await getFonts();

      return {
        ...res,
        fonts,
      };
    } )();
  }

  return res;
};

const compileBrowser = () => {
  const { userAgent, vendor, languages } = window.navigator;
  const plugins = getPlugins();

  return {
    userAgent,
    vendor,
    languages,
    plugins,
  };
};

const compileLocale = ( ip = false ) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneOffset = new Date().getTimezoneOffset();

  return {
    timezone,
    timezoneOffset,
    ip,
  };
};

const g = t => {
  return shortHash( JSON.stringify( t ) );
};

const generate = ( async = false, ip = false ) => {
  if( async ) {
    return ( async () => {
      return `${g( compileHardware() )}_${g( await compileOS( true ) )}_${g( compileBrowser() )}_${g( compileLocale( ip ) )}`;
    } )();
  }

    return `${g( compileHardware() )}_${g( compileOS() )}_${g( compileBrowser() )}_${g( compileLocale( ip ) )}`;

};
const compare = ( f1, f2 ) => {
  const one = f1.split( '_' );
  const two = f2.split( '_' );

  const res = {
    trust: true,
    msg: [],
  };

  if( one[0] != two[0] ) {
    res.trust = false;
    res.msg.push( 'Hardware different' );
  }
  if( one[1] != two[1] ) {
    res.trust = false;
    res.msg.push( 'OS different' );
  }
  if( one[2] != two[2] && one[3] != two[3] ) {
    res.trust = false;
    res.msg.push( 'Browser and locale different' );
  }

  return res;
};

export {
  generate,
  compare,
};
