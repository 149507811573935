<template>
  <span :class="['SelectInput', inline?'_inline':'', hasError?'_haasEror':'']">
    <label :class="['SelectInput_Label', !this.labelVisible?'a11yhide':'']" :for="id">
      <span :class="['SelectInput_Label_Main']">{{ label }}</span>
      <span v-if="required" class="SelectInput_Label_Required">Required</span>
    </label>

    <select v-if="!multiple" ref="mainInput" :id="id" :class="['SelectInput_Input', hasError?'_hasError':'']" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" @change="$emit('change', $event)" :aria-describedby="hintText ? id+'-hint' : ''" :disabled="disabled" tabindex="0">
      <option v-for="(display, value) in options" :value="value" :key="value">{{ display }}</option>
    </select>
    <select v-else ref="mainInput" :id="id" :class="['SelectInput_Input', hasError?'_hasError':'']" v-model="dvalue" :aria-describedby="hintText ? id+'-hint' : ''" :disabled="disabled" tabindex="0" multiple>
      <option v-for="(display, v) in options" :value="v" :key="v">{{ display }}</option>
    </select>

    <DescribedBy v-if="hintText" :id="id+'-hint'" type="hint">{{ hintText }}</DescribedBy>
    <DescribedBy v-if="hasError" :id="id+'-error'" type="error">{{ errorMessage }}</DescribedBy>

  </span>
</template>

<script>
import mixin from './Mixin';
export default {
  mixins: [ mixin ],
  name: 'FormSelect',
  data() {
    return {
      uid: this.$uuid.generate(),

      dvalue: [],
    };
  },
  watch: {
    dvalue( to, from ) {
      this.$emit( 'update:modelValue', to );
    },
  },
  methods: {
    focus() {
      this.$refs['mainInput'].focus();
    },
    reset() {
      
    },
  },
  computed: {
    id() {
      return `${this.idRoot + this.uid}-${this.label.replace( / /g, '' ).toLowerCase().trim()}`;
    },
    required() {
      return ( this.validation && this.validation.indexOf( 'not-empty' ) >= 0 );
    },
  },
  props: {
    idRoot: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: false,
    },
    labelVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    options: {
      required: true,
    },
    hintText: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .SelectInput {
    display: block;
    margin-bottom: 16px;

    &_Label {
      display: block;
      position: relative;
      width: 100%;
      font-size: 0.9em;
      padding-bottom: 4px;
      &_Main {
        font-weight: bold;
      }
      &_Required {
        position: absolute;
        right: 0;
      }
    }

    &_Input {
      padding: 10px;
      background: $hugr-colours-input-surface;
      border: none;
      border-bottom: 3px solid $hugr-colours-input-border;
      width: 100%;
      &._number {
        width: 42px;
      }

      &._hasError {
        border-bottom: 3px solid $hugr-colours-red;
      }
    }

    &._inline {
      white-space: nowrap;
      display: inline-block;
      label {
        display: inline;
        margin-right: 8px;
      }
      select {
        display: inline-block;
        padding: 2px;
      }
    }
  }

  ._darkMode .SelectInput {
    &_Label {
      color: $hugr-colours-grey;
    }
    &_Input {
      color: $hugr-colours-grey;
      background: $hugr-colours-dark-input-surface;
      border-bottom: 3px solid $hugr-colours-dark-input-border;
      &:disabled {
        background: darken($hugr-colours-primary, 10%);
        color: lighten($hugr-colours-grey, 10%);
      }

      &._hasError {
        border-bottom: 3px solid lighten( $hugr-colours-red, 20% );
      }
    }
  }
</style>
