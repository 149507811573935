<template>
  <Body :title="$gettext('Report Types')" class="ReportTypes">

    <div class="ReportTypes_Actions __grid">
      <Button
        v-if="this.hasPermission( 'ReportTypes', 'Create' )"
        class="__grid_column_2"
        type="secondary"
        @click="$refs.reporttypemodal.show()"
        >{{$gettext('Add a report type')}}</Button
      >
      <!-- <Search
        class="__grid_column_10"
        :label="$gettext('Search teams')"
        v-model="teamSearch"
        :inUse="teamSearch!=''"
      /> -->
    </div>
    <div>
      <h3>All Report Types</h3>
      <DataTable class="ReportTypes_Table" :headers="[ 'Identifier', 'Actions' ]">
        <tr v-for="(type,index) in reportTypes" v-bind:key="'rtype-' + index">
          <td>{{type.identifier}}</td>
          <td>
            <LinkButton type="primary" size="micro" :to="`/templates/report-type/${type.identifier}`" v-if="this.hasPermission( 'ReportTypes', 'Update' )">{{$gettext('Edit')}}</LinkButton>
            &nbsp;
            <Button type="serious" size="micro" v-if="this.hasPermission( 'ReportTypes', 'Delete' )" @click="deleteType( type._id )">{{$gettext('Delete')}}</Button>
          </td>
        </tr>
      </DataTable>
    </div>
    <ReportTypeModal ref="reporttypemodal" @success="$apollo.queries.reportTypes.refetch()"/>

    <br />
    <table>
        <caption v-translate>Key</caption>
        <thead>
          <th v-translate>variable</th>
          <th v-translate>output</th>
          <th v-translate>description</th>
        </thead>
        <tbody>
          <tr>
            <td>issues.all</td>
            <td v-translate>number</td>
            <td v-translate>Total number of issues in report</td>
          </tr>
          <tr>
            <td>issues.advisory</td>
            <td v-translate>number</td>
            <td v-translate>number of advisory issues in report</td>
          </tr>
          <tr>
            <td>issues.low</td>
            <td v-translate>number</td>
            <td v-translate>number of low severity issues in report</td>
          </tr>
          <tr>
            <td>issues.medium</td>
            <td v-translate>number</td>
            <td v-translate>number of medium severity issues in report</td>
          </tr>
          <tr>
            <td>issues.high</td>
            <td v-translate>number</td>
            <td v-translate>number of high severity issues in report</td>
          </tr>
          <tr>
            <td>issues.critical</td>
            <td v-translate>number</td>
            <td v-translate>number of critical issues in report</td>
          </tr>
          <tr>
            <td>issues.biggestGroup</td>
            <td v-translate>string</td>
            <td v-translate>Severity group with the largest amount of issues</td>
          </tr>

          <tr>
            <td>instances.all</td>
            <td v-translate>number</td>
            <td v-translate>Total number of instances in report</td>
          </tr>
          <tr>
            <td>instances.advisory</td>
            <td v-translate>number</td>
            <td v-translate>number of advisory instances in report</td>
          </tr>
          <tr>
            <td>instances.low</td>
            <td v-translate>number</td>
            <td v-translate>number of low severity instances in report</td>
          </tr>
          <tr>
            <td>instances.medium</td>
            <td v-translate>number</td>
            <td v-translate>number of medium severity instances in report</td>
          </tr>
          <tr>
            <td>instances.high</td>
            <td v-translate>number</td>
            <td v-translate>number of high severity instances in report</td>
          </tr>
          <tr>
            <td>instances.critical</td>
            <td v-translate>number</td>
            <td v-translate>number of critical instances in report</td>
          </tr>
          <tr>
            <td>instances.biggestGroup</td>
            <td v-translate>string</td>
            <td v-translate>Severity group with the largest amount of instances</td>
          </tr>
          <tr>
            <td>compliance.status</td>
            <td>string</td>
            <td>whether the report is compliant or not</td>
          </tr>
          <tr>
            <td>progress</td>
            <td>number</td>
            <td>progress of the report, percent</td>
          </tr>
          <tr>
            <td>started</td>
            <td>boolean</td>
            <td>whether the report is started</td>
          </tr>
          <tr>
            <td>completed</td>
            <td>boolean</td>
            <td>whether the report is completed</td>
          </tr>
        </tbody>
      </table>
    <p v-translate>If you want any other variables just let me know</p>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

import ReportTypeModal from '@/modals/ReportType/Create';

export default {
  name: 'TemplateReportTypesView',
  data() {
    return {
      reportTypes: false,
    };
  },
  apollo: {
    reportTypes: gql`
      {
        reportTypes: ReportTypes {
          _id,
          identifier
        }
      }
    `,
  },
  methods: {
    deleteType( rid ) {
      this.$confirm.simple( 'Cannot be reversed' ).then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation removeReportType($id: ObjectID!) {
                removed: removeReportType(id: $id)
              }
            `,
            variables: {
              id: rid,
            },
          } ).then( () => {
            this.$alerts.success( 'The report type has been removed' );
            this.$apollo.queries.reportTypes.refetch();
          } ).catch( error => {
            this.$alerts.coded( 'E090', 'F7001', '', error ); //see notifications spreadsheet
          } );
        }
      } );
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
  },
  components: {
    ReportTypeModal,
  },
};
</script>

<style lang="scss" scoped>
  .ReportTypes {
    &_Table {
      width: 100%;
    }
  }
</style>
