<template>
  <div class="Avatar">
    <span :class="['Avatar_Actual', `_size-${size}`, `_style-${type}`]"
          :tabindex="details?0:-1"
          @mouseenter="showDetails()"
          @mouseleave="hideDetails()"
          @focus="showDetails( true, e )"
          @blur="hideDetails( true, e )">{{ override!='OVERRIDE'? getInitials(override) : value }}</span>
    <div class="Avatar_Details" v-show="details&&detailsShowing">
      <h4 class="Avatar_Details_Name">{{ user.name }}</h4>
      <p class="Avatar_Details_Email">{{  user.email }}</p>
      <p v-if="lastEngagement" class="Avatar_Details_Interaction">Last interaction {{ moment(lastEngagement.timestamp).fromNow() }}</p>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment';

export default { //TODO this should cache
  name: 'Avatar',
  data() {
    return {
      value: 'NA',
      user: {
        _id: false,
        name: 'Not Applicable',
      },
      detailsShowing: false,
    };
  },
  watch: {
    user() {
      let initials = this.user.name.match( /\b\w/g ) || [];
      initials = ( ( initials.shift() || '' ) + ( initials.pop() || '' ) ).toUpperCase();
      this.value = initials;
    },
  },
  methods: {
    moment,
    getInitials( input ) {
      let initials = input.match( /\b\w/g ) || [];
      initials = ( ( initials.shift() || '' ) + ( initials.pop() || '' ) ).toUpperCase();

      return initials;
    },
    showDetails( emitFocus, e ) {
      if( !this.details ) return;

      if( emitFocus ) this.$emit( 'focus', e );

      this.detailsShowing = true;
    },
    hideDetails( emitFocus, e ) {
      if( !this.details ) return;

      if( emitFocus ) this.$emit( 'blur', e );

      this.detailsShowing = false;
    },
  },
  apollo: {
    user: {
      query: gql`
        query User($id: ObjectID!) {
          user: User(id: $id) {
            _id,
            name,
            email
          }
        }
      `,
      variables() {
        return {
          id: this.uid,
        };
      },
    },
    lastEngagement: {
      query: gql`
        query LastEngagement($user: ObjectID!, $report: ObjectID!) {
          lastEngagement: LastEngagement(user: $user, report: $report) {
            timestamp
          }
        }
      `,
      variables() {
        return {
          user: this.user._id,
          report: this.report,
        };
      },
      skip() { return !this.report || !this.user._id },
    },
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'regular',
    },
    type: {
      type: String,
      default: 'boxish',
    },
    details: {
      type: Boolean,
      default: false,
    },
    override: {
      type: String,
      default: 'OVERRIDE',
    },
    report: {
      type: String || Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_buttons.scss';
  @import '@/assets/styles/variables/_fonts.scss';

  .Avatar {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: default;
    &_Actual {
      display: inline-block;
      width: 18px;
      height: 18px;
      padding: 9px 10px 9px 8px;
      background: #f8b23f;

      &._size-small {
        height: 14px;
        width: 14px;
        font-size: 0.9em;
      }

      &._size-medium {
        height: 34px;
        width: 34px;
        font-size: 1.2em;
        text-align: center;
        line-height: 32px;
      }

      &._size-giant {
        width: 64px;
        height: 52px;
        font-size: 3em;
        padding: 26px 20px 26px 20px;
      }

      &._style-boxish {
        border-radius: 10px;
      }

      &._style-round {
        border-radius: 100px;
      }
    }

    &_Details {
      position: absolute;
      background: lighten( $hugr-colours-grey, 10% );
      padding: 8px;
      z-index: 999;
      top: 45px;
      left: -40px;
      box-shadow: 20px 20px 25px -25px rgba(0,0,0,0.75);
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid lighten( $hugr-colours-grey, 10% );
        position:absolute;
        top: -10px;
        left: 46px;
      }
      &_Name {
        margin: 0;
        font-size: 1em;
        white-space: nowrap;
        font-family: $hugr-fonts-body;
        font-weight: bold;
      }
      &_Email {
        margin: 0;
        font-size: 0.9em;
        white-space: nowrap;
      }
      &_Interaction {
        margin: 0;
        font-size: 0.8em;
        margin-top: 4px;
        white-space: nowrap;
      }
    }
  }

  ._darkMode .Avatar {
    color: $hugr-colours-primary;
    &_Details {
      color: #000;
      &_Name {
        color: #000 !important;
      }
    }
  }
</style>
