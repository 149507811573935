<template>
  <form @submit.prevent="submitCustomer">
    <FormFieldSet :legend="$gettext('New Customer')">
      <FormInput idRoot="customer_" :label="$gettext('Name')" v-model="name" />
      <Button id="customer" type="primary" htype="submit">{{$gettext('Add Customer')}}</Button>
    </FormFieldSet>
  </form>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';

export default {
  name: 'CustomerForm',
  data() {
    return {
      name: '',
    };
  },
  methods: {
    submitCustomer() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation addCustomer($customer: CustomerInput!) {
            customer: addCustomer(customer: $customer) {
              _id
            }
          }
        `,
        variables: {
          customer: {
            name: this.name,
          },
        },
      } ).then( () => {
        this.$emit( 'success' );
        this.name = '';
      } ).catch( error => {
        this.$alerts.coded( 'E028', 'F1301', '', error ); //see notifications spreadsheet
      } );
    },
  },
  components: {
    FormInput,
    FormFieldSet,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
