<template>
  <div class="card">
    <Screenshot v-if="screenshot!=''" :sID="screenshot" class="screenshot" alt=""/>
    <div class="container">
      <div class="inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Screenshot from '@/components/Helpers/Screenshot';

export default {
  name: 'UICard',
  props: {
    screenshot: {
      type: String,
      default: '',
    },
  },
  components: {
    Screenshot,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .card {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    min-width: 240px;
    background: #262E37;
    height: 340px;
    color: #FFF;
    margin-right: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    .screenshot {
      width: 100%;
      position: relative;
      position: absolute;
    }
    .container {
      padding: 20px;
      background: linear-gradient(to bottom, #262d36a6, #262E37);
      height: calc(100% - 40px);
      width: calc(100% - 40px);
      position: absolute;
      .inner {
        height: 100%;
        position: relative;
        h2, h3, h4, h5, h6 {
          margin: 0;
        }
        a {
          color: white;
          overflow-wrap: break-word;
          word-wrap: break-word;
        }
        dl {
          dt {

          }
          dd {
            margin-inline-start: 5px;
            font-size: 11pt;
            margin-bottom: 10px;
          }
        }
        .buttonset {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          .button {
            width: 100%;
          }
        }
      }
    }
  }
</style>
