<template>
  <div :class="['DataTable', noMinHeight?'_noMinHeight':'', noScroll?'_noScroll':'']">
    <div class="DataTable_Sorting">
      <div v-if="filter" class="DataTable_Sorting_Filter" ref="filters">
        <Button class="DataTable_Sorting_Filter_Btn" type="primary" size="micro" :icon="['solid', 'filter']" @click="toggleFilterExpanded" :aria-expanded="showFilter" v-click-away="filterClickAway">Filter <Icon type="solid" :icon="showFilter?'chevron-up':'chevron-down'" /></Button>
        <div v-if="showFilter" class="DataTable_Sorting_Filter_Container">
          <div class="DataTable_Sorting_Filter_Container_Head">
            <h5>Quick filters</h5>
            <Button type="border" size="micro" @click="() => { resetFilters(); changed() }">Clear all</Button>
          </div>
          <fieldset v-for="filterer of Object.keys( filter )" :key="filterer" class="DataTable_Sorting_Filter_Container_Group">
            <legend>{{ filterer }}</legend>
            <ul>
              <li v-for="filteritem of filter[filterer]" :key="filteritem">
                <input type="checkbox" :id="`${tablekey}-${filterer}-${filteritem}`" v-model="filterData[filterer][filteritem]" @change="changed"/>
                <label :for="`${tablekey}-${filterer}-${filteritem}`">{{ filteritem }}</label>
              </li>
            </ul>
          </fieldset>
        </div>
      </div>
      <div v-if="customisable" class="DataTable_Sorting_Edit" ref="edits">
        <Button class="DataTable_Sorting_Edit_Btn" type="primary" size="micro" :icon="['regular', 'eye-slash']" @click="toggleEditExpanded" :aria-expanded="showEdit" v-click-away="editClickAway">Edit columns <Icon type="solid" :icon="showEdit?'chevron-up':'chevron-down'" /></Button>
        <ul v-if="showEdit" class="DataTable_Sorting_Edit_List">
          <li v-for="item of Object.keys(showData)" :key="item" class="DataTable_Sorting_Edit_List_Item">
            <input type="checkbox" :id="`${tablekey}-${item}-toggle`" v-model="showData[item]" @change="changed"/>
            <label :for="`${tablekey}-${item}-toggle`"><span class="a11yhide">{{ showData[item] ? 'Hide' : 'Show' }}</span>{{  startCase( item ) }}</label>
          </li>
        </ul>
      </div>
    </div>
    <table v-if="manualHead">
      <slot></slot>
    </table>
    <table v-else>
      <thead class="DataTable_Head">
        <tr>
          <template v-for="(header) of headers" :key="header">
            <th v-if="isVisible( header )">
              <template v-if="header=='' || header.name == ''">&nbsp;<span class="a11yhide">Actions</span></template>
              <template v-else-if="header.name">{{header.name}}</template>
              <template v-else>{{header}}</template>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
import { startCase } from 'lodash';
const camelise = str => {
  return str.replace( /(?:^\w|[A-Z]|\b\w)/g, ( word, index ) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  } ).replace( /\s+/g, '' );
};

export default {
  name: 'UIDataTable',
  props: {
    headers: {
      type: Array,
      default: () => { return [] },
    },
    manualHead: {
      type: Boolean,
      default: false,
    },
    noMinHeight: {
      type: Boolean,
      default: false,
    },
    tablekey: String,
    customisable: {
      type: Boolean,
      default: () => false,
    },
    filter: {
      type: Object,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const showData = JSON.parse( localStorage.getItem( `table-${this.tablekey}` ) );
    const newShowData = {};

    for( const header of this.headers ) {
      let name = header;
      if( typeof header.name != 'undefined' ) name = header.name;

      if( !showData || typeof showData[ camelise( name ) ] == 'undefined' ) {
        if( !header.freeze ) newShowData[camelise( name )] = true;
        if( typeof header.default != 'undefined' ) newShowData[camelise( name )] = header.default;
      } else {
        newShowData[ camelise( name ) ] = showData[ camelise( name ) ];
      }
    }
    localStorage.setItem( `table-${this.tablekey}`, JSON.stringify( newShowData ) );

    return {
      showData: newShowData,
      showEdit: false,

      filterData: null,
      showFilter: false,
    };
  },
  mounted() {
    this.resetFilters();
  },
  methods: {
    startCase,
    resetFilters() {
      const filterData = {};
      if( this.filter ) {
        for( const filterer of Object.keys( this.filter ) ) {
          filterData[filterer] = {};
          for( const filteritem of this.filter[filterer] ) {
            filterData[filterer][filteritem] = false;
          }
        }
      }

      this.filterData = filterData;
    },
    isVisible( header ) {
      let name = header;
      if( typeof header.name != 'undefined' ) name = header.name;
      
      if( typeof this.showData[camelise( name )] == 'undefined' ) return true;
      
      return this.showData[camelise( name )];
    },
    changed() {
      localStorage.setItem( `table-${this.tablekey}`, JSON.stringify( this.showData ) );
      this.$emit( 'changed' );
      this.$forceUpdate();
    },
    editClickAway( e ) {
      const parentEl = this.$refs.edits;
      const isChild = parentEl.contains( e.srcElement );
      if( !isChild ) this.closeEdit();
    },
    toggleEditExpanded() {
      this.showEdit = !this.showEdit;
      if( this.showEdit ) {
        document.addEventListener( 'keyup', this.editClickAway );
      }
    },
    closeEdit() {
      this.showEdit = false;
      document.removeEventListener( 'keyup', this.editClickAway );
    },

    filterClickAway( e ) {
      const parentEl = this.$refs.filters;
      const isChild = parentEl.contains( e.srcElement );
      if( !isChild ) this.closeFilter();
    },
    toggleFilterExpanded() {
      this.showFilter = !this.showFilter;
      if( this.showFilter ) {
        document.addEventListener( 'keyup', this.filterClickAway );
      }
    },
    closeFilter() {
      this.showFilter = false;
      document.removeEventListener( 'keyup', this.filterClickAway );
    },

    getFilters() {
      return this.filterData;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  @import '@/assets/styles/variables/_fonts.scss';
  @import '@/assets/styles/variables/_colours.scss';

  .DataTable {
    position: relative;
    overflow-x: auto;
    min-height: 400px;

    &._noScroll {
      overflow-x: unset;
    }

    &._noMinHeight {
      min-height: 40px;
    }

    &_Head {
      border-bottom: 4px solid $dig-blue;
    }

    &_Sorting {
      position: absolute;
      right: 0;
      top: 6px;

      &_Edit {
        display: inline-block;
        vertical-align: top;
        margin-left: 4px;
        &_List {
          border: 1px solid $hugr-colours-grey;
          border-radius: 4px;
          background: #FFF;
          list-style: none;
          padding: 8px;
          margin: 0;
          position: absolute;
          min-width: 120px;
          right: 0;
          z-index: 999;
          margin-top: 4px;
          
          input {

          }
          label {
            display: inline-block;
            font-size: 0.8em;
            vertical-align: top;
            margin: 2px 0;
          }
        }
      }

      &_Filter {
        display: inline-block;
        vertical-align: top;
        margin-left: 4px;
        &_Container {
          border: 1px solid $hugr-colours-grey;
          border-radius: 4px;
          background: #FFF;
          padding: 8px;
          margin: 0;
          position: absolute;
          min-width: 30vw;
          right: 0;
          z-index: 999;
          margin-top: 4px;
          height: 200px;

          &_Head {
            h5 { margin: 6px 0; }
            button { float: right; margin-top: -27px; }
          }

          &_Group {
            display: inline-block;
            vertical-align: top;
            border: none;
            border-right: 1px solid $hugr-colours-grey;
            height: calc(100% - 60px);
            overflow-y: auto;
            padding: 16px;
            margin: 0;
            legend {
              display: block;
              position: relative;
              top: 16px;
              margin-bottom: 16px;
            }
            ul {
              list-style: none;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;
      min-width: 700px;
      thead {
        th {
          font-family: $hugr-fonts-logo;
          text-align: left;
          padding: 8px;
          padding-left: 0;
          padding-right: 16px;
        }
      }
      tbody {
        // font-size: 16px;
        tr {
          &._dimmed {
            background: $hugr-colours-grey;
          }
          td {
            border-top: 1px solid $dig-blue;
            padding: 0.8em;
            padding-left: 0;
            padding-right: 1.6em;
            // font-size: 16px;
            font-size: 0.8em;
            a {
              // color: $hugr-colours-new-link;
            }
          }
        }
      }
    }
  }

  ._darkMode .DataTable {
    .DataTable {
      &_Sorting {
        &_Edit {
          &_List {
            background: $hugr-colours-primary;
          }
        }

        &_Filter {
          &_Container {
            background: $hugr-colours-primary;
          }
        }
      }
    }

    table {
      tbody {
        tr {
          &._dimmed {
            background: darken($hugr-colours-primary, 10%)
          }
        }
      }
    }
  }
</style>
