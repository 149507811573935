<template>
  <Body :title="$gettext(`Edit`) + ` ${section.shortName}`">
    <form @submit.prevent="updateReportSection">
      <FormFieldSet :legend="$gettext('Edit')">
        <FormInput idRoot="section_" :label="$gettext('Short name')" v-model="section.shortName" />
        <FormInput idRoot="section_" :label="$gettext('Full name')" v-model="section.fullName" />
        <FormTextArea idRoot="section_" :label="$gettext('Summary')" v-model="section.summary" />
        <FormInput idRoot="section_" type="number" :label="$gettext('Order')" v-model="section.order" />
        <Button type="primary" htype="submit">{{$gettext('Save')}}</Button>
      </FormFieldSet>
    </form>
  </Body>
</template>

<script>
import gql from 'graphql-tag';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import Body from '@/components/UI/Body.vue';

export default {
  name: 'TemplatesEditReportSectionView',
  data() {
    return {
      section: false,
      id: this.$route.params.id,
    };
  },
  apollo: {
    asection: {
      query: gql`
        query ReportSection($id: ObjectID!) {
          asection: ReportSection(id: $id) {
            _id
            shortName
            fullName
            summary
            order
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  watch: {
    $route( to ) {
      if( this.$route.name == 'EditReportSection' ) {
        this.id = to.params.id;
        this.$apollo.queries.asection.refetch();
      }
    },
    asection() {
      this.section = { ...this.asection };
    },
  },
  methods: {
    updateReportSection() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateReportSection($id: ObjectID!, $reportSection: ReportSectionInput!) {
            rs: updateReportSection(id: $id, reportSection: $reportSection) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          reportSection: {
            shortName: this.section.shortName,
            fullName: this.section.fullName,
            summary: this.section.summary,
            order: Number( this.section.order ),
          },
        },
      } ).then( () => {
        this.$alerts.success( 'Report section updated', `The report section has been updated successfully.` );
        this.$router.push( { path: '/templates/report-sections' } );
      } ).catch( error => {
        this.$alerts.generic( error );
      } );
    },
  },
  components: {
    FormInput,
    FormFieldSet,
    FormTextArea,
    Body,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
