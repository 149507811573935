<template>
  <Modal
    ref="modal"
    :title="$gettext('New Report Type')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Report Type..."

    :submitText="$gettext('Add report type')"
    @submit="submitTemplate"

    @close="reset()">
      <FormInput ref="identifier" idRoot="template_" :label="$gettext('Identifier')" v-model="identifier" :validation="['not-empty']"/>
      <FormTextArea idRoot="template_" :label="$gettext('Executive Summary Template')" v-model="execSummary" />
</Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';

export default {
  name: 'ReportTypeForm',
  data() {
    return {
      identifier: '',
      execSummary: '',
      submitting: false,
    };
  },
  methods: {
    submitTemplate() {
      this.$hugrvalidate( [ 'identifier' ], this ).then( ( { success, errors } ) => {
        if ( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
            mutation addReportType($identifier: String!, $execSummary: String) {
              ss: addReportType(identifier: $identifier, execSummary: $execSummary) {
                _id
              }
            }
            `,
            variables: {
              identifier: this.identifier,
              execSummary: this.execSummary,
            },
          } ).then( res => {
            this.submitting = false;
            this.$emit( 'success', res.data.ss._id );
            this.execSummary = '';
            this.identifier = '';
            this.$alerts.success( 'Report type added' );
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.coded( 'E039', 'F2001', '', error ); //see notifications spreadsheet
          } );
        }
      } );
    },
    show( ) {
      this.$refs.modal.show();
    },
    reset() {
      this.identifier = '';
      this.execSummary = '';

      this.$refs.modal.reset();
    },
  },
  components: {
    FormInput,
    FormTextArea,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
