<template>
  <form @submit.prevent="submit">
    <FormFieldSet :legend="$gettext('Change my password')">
      <FormInput idRoot="resetpwd_" :label="$gettext('Current Password')" v-model="currentPassword" type="password"/>
      <FormInput idRoot="resetpwd_" :label="$gettext('New Password')" v-model="newPassword" type="password"/>
      <FormInput idRoot="resetpwd_" :label="$gettext('Confirm Password')" v-model="confPassword" ref="conf" type="password"/>
      <Button type="border" htype="submit">{{$gettext('Change')}}</Button>
    </FormFieldSet>
  </form>
</template>

<script>
import gql from 'graphql-tag';

import { mapState, mapActions } from 'vuex';
import md5 from 'md5';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';

import AriaSearchSelect from '@/components/Aria/SearchSelect';

export default {
  name: 'AdminPasswordResetForm',
  data() {
    return {
      user: '',
      currentPassword: '',
      newPassword: '',
      confPassword: '',
    };
  },
  methods: {
    submit() {
      if( this.newPassword === this.confPassword ) {
        this.$apollo.mutate( {
          mutation: gql`
            mutation resetPassword($current: String!, $new: String!) {
              done: resetPassword(current: $current, new: $new)
            }
          `,
          variables: {
            current: md5( `${this.currentPassword}:alienFORGERY` ),
            new: md5( `${this.newPassword}:alienFORGERY` ),
          },
        } ).then( res => {
          if( res.data.done ) {
            this.$alerts.success( 'Password changed!', `Your password has been changed successfully` );
            this.currentPassword = '';
            this.newPassword = '';
            this.confPassword = '';
          } else {
            this.$alerts.error( 'Password not changed', `Please check your current password and try again` );
          }
        } ).catch( () => {
          this.$alerts.coded( 'E035', 'F1701' ); //see notifications spreadsheet
        } );
      } else {
        this.$refs.conf.addError( `passwords don't match` );
        this.$refs.conf.focus();
      }
    },
  },
  components: {
    FormInput,
    FormFieldSet,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
