<template>
  <div class="Paginator">
    <ul>
      <li v-for="(i, index) in pages" :key="`teampagelist${index}`"><button :aria-selected="chosen==index" :class="[chosen==index?'_selected':'']" @click="selectPage(index)" :aria-label="`page ${i}`">{{ i }}</button></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UIDataTablePaginator',
  data() {
    return {
      chosen: this.currentPage,
    };
  },
  watch: {
    currentPage() {
      this.chosen = this.currentPage;
    },
  },
  props: {
    pages: {
        type: Number,
        required: true,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    selectPage( page ) {
      this.chosen = page;
      this.$emit( 'select', page );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  @import '@/assets/styles/variables/_fonts.scss';
  @import '@/assets/styles/variables/_colours.scss';

  .Paginator {
    border-top: 1px solid $dig-blue;
    position: relative;
    padding: 8px;
    ul {
      position: absolute;
      right: 8px;
      margin: 0;
      padding: 0;
      list-style: none;
      max-width: 75%;
      li {
        display: inline-block;
        button {
          background: none;
          border: none;
          cursor: pointer;

          &:hover, &:focus, &._selected {
            color: darken($hugr-colours-secondary, 20%);
          }
        }
      }
    }
  }

  ._darkMode .Paginator {
    ul {
      li {
        button {
          color: $hugr-colours-grey;

          &:hover, &:focus, &._selected {
            color: $hugr-colours-secondary;
          }
        }
      }
    }
  }
</style>
