<template>
  <div :class="['Empty', `_${type}`]">
    <Icon v-if="icon.length" class="Empty_Icon" :type="icon[0]" :icon="icon[1]" />
    <p v-if="text!=''" class="Empty_Text">{{ text }}</p>
    <p v-if="or" class="Empty_Or">Or</p>
    <Button v-if="button&&showButton" class="cardSetButton" :type="buttonProps.type" :size="buttonProps.size" :icon="buttonProps.icon" @click="buttonClick">{{buttonProps.text}}</Button>
  </div>
</template>

<script>
export default {
  name: 'UIEmpty',
  methods: {
    buttonClick( e ) {
      this.$emit( 'buttonClick', e );
    },
  },
  computed: {
    buttonProps() {
      const { button } = this;
      if( typeof button.type == 'undefined' ) {
        button.type = 'primary';
      }
      if( typeof button.size == 'undefined' ) {
        button.size = 'regular';
      }
      if( typeof button.icon == 'undefined' ) {
        button.icon = [];
      }

      return button;
    },
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    button: {
      type: [ Object, Boolean ],
      default: () => {
        return false;
      },
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'normal',
    },
    icon: {
      type: Array,
      default() {
        return [];
      },
    },
    or: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Empty {
    text-align: center;
    padding: 25px;

    &_Icon {
      font-size: 4em;
      color: $dig-blue;
      margin-bottom: 8px;
    }

    &_Text {
      font-size: 14pt;
      margin: 8px 0;
    }

    &_Or {
      margin: 8px 0;
    }

    &._small {
      padding: 0;
      .Empty_Text {
        font-size: 12pt;
      }
    }
  }

  ._darkMode .Empty {
    &_Icon {
      color: $dig-orange;
    }
  }
</style>
