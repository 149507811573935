<template>
  <div class="separator">
    <span>{{text}}</span>
  </div>
</template>

<script>
  export default {
    name: 'UISeparator',
    props: {
      text: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .separator {
    width: 100%;
    text-align: center;
    position: relative;
    margin: 20px 0;
    height: 20px;
    span {
      background: white;
      position: absolute;
    }
    &:before {
      content: '';
      border-bottom: 1px solid;
      display: block;
      position: absolute;
      width: 100%;
      top: 10px;
    }
  }
</style>
