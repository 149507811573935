/**
 * Highlight setup
 */
import Hljs from 'highlight.js';
import 'highlight.js/styles/a11y-light.css';
import 'github-markdown-css/github-markdown.css';
export default {
    install( Vue ) { //todo
      Vue.directive( 'highlight', el => {
          const blocks = el.querySelectorAll( 'pre code' );
          blocks.forEach( block => {
              Hljs.highlightBlock( block );
          } );
      } );
    },
  };
