<template>
  <Modal
    ref="modal"
    :title="$gettext('Update Row')"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submitRow"

    @close="reset()">
    <FormInput idRoot="row_" :label="$gettext('Section')" v-model="row.section" />
    <FormInput idRoot="row_" :label="$gettext('Reference')" v-model="row.ref" />
    <FormInput idRoot="row_" :label="$gettext('Header')" v-model="row.header" />
    <FormTextArea idRoot="row_" :label="$gettext('Details')" v-model="row.details"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';

export default {
  name: 'EditSpreadsheetTemplateRowModal',
  data() {
    return {
      row: false,
    };
  },
  methods: {
    submitRow() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`mutation updateSpreadsheetTemplateRow($id: ObjectID!, $row: SpreadsheetTemplateRowInput!) {
          row: updateSpreadsheetTemplateRow(id: $id, row: $row) {
            _id
          }
        }`,
        variables: {
          id: this.row._id,
          row: {
            template: this.row.template._id,
            section: this.row.section,
            ref: this.row.ref,
            header: this.row.header,
            details: this.row.details,
          },
        },
      } ).then( res => {
        this.submitting = false;
        this.$emit( 'success', res.data.row._id );
        this.reset();
      } ).catch( error => {
        this.submitting = false;
        this.$alerts.coded( 'E065', 'F3701', '', error ); //see notifications spreadsheet
      } );
    },

    show( id ) {
      this.$apollo.query( {
        query: gql`
          query SpreadsheetTemplateRow($id: ObjectID!) {
            row: SpreadsheetTemplateRow(id: $id) {
              _id
              template {
                _id
              }
              section,
              ref,
              header,
              details
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.row = { ...res.data.row };
        this.$refs.modal.show();
      } ).catch( () => {
        this.$alerts.coded( 'E066', 'F3702' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.row = false;

      this.$refs.modal.reset();
    },
  },
  components: {
    FormInput,
    FormTextArea,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .get-title {
    width: auto;
    float: right;
  }
</style>
