<template>
  <Body :title="$gettext('Spreadsheet Templates')" class="SSTemplates">
    <div class="ReportTypes_Actions __grid">
      <Button
        v-if="this.hasPermission( 'SpreadsheetTemplates', 'Create' )"
        class="__grid_column_4"
        type="secondary"
        @click="$refs.spreadsheettemplatemodal.show()"
        >{{$gettext('Add a Spreadsheet Template')}}</Button
      >
      <!-- <Search
        class="__grid_column_10"
        :label="$gettext('Search teams')"
        v-model="teamSearch"
        :inUse="teamSearch!=''"
      /> -->
    </div>
    <h3 v-translate>Templates</h3>
    <div>
      <DataTable class="SSTemplates_Table" :manualHead="true">
        <thead>
          <tr>
            <th v-translate>Name</th>
            <th v-translate>Description</th>
            <th v-translate># Rows</th>
            <th v-translate>Published</th>
            <th v-translate>Promoted</th>
            <th v-translate>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(template,index) in templates" v-bind:key="'template-' + index">
            <td>{{template.name}}</td>
            <td>{{template.description}}</td>
            <td>{{template.rows.length}}</td>
            <td>{{template.published?$gettext('Yes'):$gettext('No')}}</td>
            <td>{{template.promoted?$gettext('Yes'):$gettext('No')}}</td>
            <td><LinkButton v-if="this.hasPermission( 'SpreadsheetTemplates', 'Update' )" type="primary" size="micro" :to="`/templates/spreadsheet/${template._id}`">{{$gettext('Edit')}}</LinkButton></td>
          </tr>
        </tbody>
      </DataTable>
    </div>
    <SpreadsheetTemplateModal ref="spreadsheettemplatemodal" @success="$apollo.queries.templates.refetch()"/>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

import SpreadsheetTemplateModal from '@/modals/SpreadsheetTemplate/Create';

export default {
  name: 'TemplatesSpreadsheetsView',
  data() {
    return {
      templates: false,
    };
  },
  apollo: {
    templates: gql`
      {
        templates: SpreadsheetTemplates {
          _id,
          name,
          description
          published,
          promoted,

          rows {
            _id
          }
        }
      }
    `,
  },
  methods: {
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
  },
  components: {
    SpreadsheetTemplateModal,
  },
};
</script>

<style lang="scss" scoped>
  .SSTemplates {
    &_Table {
      width: 100%;
    }
  }
</style>
