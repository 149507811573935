<template>
    <div class="FourOhFour">Page cannot be found</div>
</template>

<script>

export default {
    name: "FourOhFourView",
    setup() {

    },
};
</script>

<style lang="scss" scoped>
    .FourOhFour {
        font-weight: bold;
        font-size: 1.2em;
        text-align: center;
        margin: 100px 0;
    }
</style>
