<template>
  <Modal
    ref="modal"
    :title="$gettext('New Solution')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Solution..."

    :submitText="$gettext('Add solution')"
    @submit="submit"

    @close="reset()">
    <FormInput ref="identifier" idRoot="solution_" :label="$gettext('Identifier')" v-model="identifier" :validation="['not-empty']"/>
    <FormInput ref="title" idRoot="solution_" :label="$gettext('Title')" v-model="title" :validation="['not-empty']"/>

    <FormTextArea ref="overview" idRoot="solution_" :label="$gettext('Overview')" v-model="overview" :validation="['not-empty']"/>
    <FormInput ref="tags" idRoot="solution_" :label="$gettext('Metadata tags')" :hintText='$gettext(`Comma separated e.g: "image,input,custom"`)' v-model="tags"/>

    <FormTextArea ref="code" idRoot="solution_" :label="$gettext('Incorrect code example')" v-model="code"/>
    <FormTextArea ref="fix" idRoot="solution_" :label="$gettext('Suggested code example')" v-model="fix"/>

    <FormSelect idRoot="report_" :label="$gettext('Team')" v-model="team" :options="{
        '0': $gettext('Just me'),
        ...teams
      }"/>

    <div>
      <input type="checkbox" id="private" v-model="priv"><label for="private">{{$gettext('Private')}}</label>
    </div>
    <div>
      <input type="checkbox" id="locked" v-model="locked"><label for="locked">{{$gettext('Locked')}}</label>
    </div>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import FormSelect from '@/components/Form/Select';

export default {
  name: 'SolutionModal',
  data() {
    return {
      identifier: '',
      title: '',
      overview: '',
      code: '',
      fix: '',
      tags: '',
      team: '0',
      priv: false,
      locked: false,
      submitting: false,
    };
  },
  apollo: {
    teams: {
      query: gql`
        {
          teams: Teams {
            _id
            name
          }
        }
      `,
      update: data => {
        const opts = {};
        for( const team of data.teams ) {
          opts[team._id] = team.name;
        }

        return opts;
      },
    },
  },
  methods: {
    submit() {
      //initial checks
      this.$hugrvalidate( [ 'identifier', 'title', 'overview', 'code', 'fix' ], this ).then( ( { success, errors } ) => {
      if ( success ) {
      if( this.user.aclevel >= 2 && this.identifier.indexOf( 'AP' ) == 0 ) {
        this.$alerts.error( 'Reserved identifier', `Sorry, we need identifiers beginning with 'AP' for our solutions` );
      } else {
        this.submitting = true;
        this.$apollo.mutate( {
          mutation: gql`
            mutation addSolution($solution: SolutionInput!) {
              solution: addSolution(solution: $solution) {
                _id
                identifier
              }
            }
          `,
          variables: {
            solution: {
              identifier: this.identifier,
              title: this.title,
              overview: this.overview,
              code: this.code,
              fix: this.fix,
              metadata: this.tags.split( ',' ).map( t => t.trim() ),
              team: this.team == '0' ? null : this.team,
              private: this.priv,
              locked: this.locked,
            },
          },
        } ).then( res => {
          this.submitting = false;
          this.$emit( 'success', res.data.solution._id );
          this.$alerts.success( 'Solution created!', `The solution ${res.data.solution.identifier} has been created successfully` );
          this.reset();
        } ).catch( error => {
          this.submitting = false;
          this.$alerts.error( 'Solution not created', `Ouch. You've found a bug that we need to look at.`, '', error );
        } );
      }
      }
      } );
    },

    show() {
      this.$refs.modal.show();
    },
    reset() {
      this.identifier = '';
      this.title = '';
      this.overview = '';
      this.code = '';
      this.fix = '';
      this.tags = '';
      this.team = '0';
      this.priv = false;
      this.locked = false;

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  components: {
    FormInput,
    FormTextArea,
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
