const tablesPlugin = {
  install( app ) {
    const tableColumnVisible = ( key, column ) => {
      const tableDetails = JSON.parse( localStorage.getItem( `table-${key}` ) );

      if( !tableDetails ) return true;
      if( typeof tableDetails[ column ] == 'undefined' ) return true;

      return tableDetails[ column ];
    };

    app.config.globalProperties.$tableColumnVisible = tableColumnVisible;
    app.provide( 'tableColumnVisible', tableColumnVisible );
  },
};

export default tablesPlugin;
