<template>
  <Modal
    ref="modal"
    :title="$gettext('Detail overrides')"

    :showActions="true"

    :loading="submitting || loading"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submit()"

    @close="reset()"
    :ready="data">

    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>

    <div v-if="qualityAssuranceVersion">
      <FormInput id="statementLocationOverride" idRoot="overrides_" :label="$gettext('Statement location')" v-model="statementOverrides.statementURL"/>
      <FormDate id="lastReviewDateOverride" :hasPicker="true" :clearDateAction="true" idRoot="overrides_" :hintText="'dd/mm/yyyy'" :label="$gettext('Last reviewed date stated')" v-model="statementOverrides.lastReviewDate"/>
      <FormSelect id="complianceStatusOverride" idRoot="overrides_" :label="$gettext('Assessment') + ' compliance stated'" v-model="statementOverrides.complianceStatus"
        :options="{
          '': $gettext('No data'),
          'Not compliant': $gettext('Not compliant'),
          'Partially compliant': $gettext('Partially compliant'),
          'Fully compliant': $gettext('Fully compliant'),
      }"/>
      <FormSelect v-if="hasPermission('AccessibilityStatementOverrides', 'CheckLegalWording')" id="legalStatusOverride" idRoot="overrides_" :label="$gettext('Legal wording')" v-model="statementOverrides.legalStatus"
        :options="{
          '': $gettext('No data'),
          'Incorrect wording': $gettext('Incorrect wording'),
          'Correct wording': $gettext('Correct wording'),
      }"/>
      <FormDate v-if="hasPermission('AccessibilityStatementOverrides', 'AssureStatements')" id="lastAssuredDateOverride" idRoot="overrides_" :hasPicker="true" :useTodayAction="true" :clearDateAction="true" :hintText="'dd/mm/yyyy'" :label="$gettext('Last assured')" v-model="statementOverrides.lastAssuredDate"/>
      <FormTextArea ref="assuranceNoteOverride" idRoot="overrides_" :label="$gettext('Add a note')" v-model="statementOverrides.assuranceNote" />
    </div>

    <div v-if="!qualityAssuranceVersion">

    <FormInput id="dateCreatedOverride" idRoot="overrides_" :label="$gettext('Date Created')" v-model="createdOverride"/>
    <FormInput id="datePublishedOverride" idRoot="overrides_" :label="$gettext('Date Published')" v-model="publishedOverride"/>

    <FormFieldSet legend="Key issue instances breakdown" decoration="divide">
      <li v-for="(issue, key) in processedKeyIssueInstances" class="spacing" v-bind:key="key">
        <span v-if="issue[1] == 1">
          There is <strong>{{ issue[1] }}</strong> issue marked as a key issue for <strong>{{ issue[0] }}</strong>
        </span>
        <span v-else>
          There are <strong>{{ issue[1] }}</strong> issues marked as key issues for <strong>{{ issue[0] }}</strong>
        </span>
        <Button class="removeButton" type="border" size="micro" @click.prevent="populateIssue(issue[0], issue[1])">
          <span v-if="processedDisplayIssues.map(i => i.name).indexOf(issue[0])>=0 || keyIssueTitle==issue[0]" v-translate>Use again</span>
          <span v-else v-translate>Populate</span>
        </Button>
      </li>
    </FormFieldSet>

    <FormFieldSet legend="Add a key issue" decoration="divide">
      <form @submit.prevent="addIssue">
        <FormInput ref="keyissuetitle" idRoot="issue_" :label="$gettext('Key Issue Title')" v-model="keyIssueTitle"/>
        <FormTextArea ref="keyissuebody" idRoot="issue_" :label="$gettext('Key Issue Description')" v-model="keyIssueBody" />
        <Button type="border" size="small" @click.prevent="addIssue()">{{$gettext('Add issue')}}</Button>
      </form>
    </FormFieldSet>

    <FormFieldSet legend="Key issues" decoration="divide">
      <AriaSearchSelectList v-if="keyIssues.length" v-model:items="keyIssues" :overrideDisplayItems="processedDisplayIssues" :ordered="true" :moveable="true" :boldName="true"/>
    </FormFieldSet>

    </div>

    <div v-if="extended && !qualityAssuranceVersion">
      <FormInput id="mainURLOverride" idRoot="overrides_" :label="$gettext('Main URL')" v-model="statementOverrides.mainURL"/>
      <FormInput id="purposeOverride" idRoot="overrides_" :label="$gettext('Service purpose')" v-model="statementOverrides.purpose"/>
      <FormInput id="platformOverride" idRoot="overrides_" :label="$gettext('Platform type')" v-model="statementOverrides.platform"/>
      <FormInput id="reportURLOverride" idRoot="overrides_" :label="$gettext('Report URL')" v-model="statementOverrides.reportURL"/>
      <FormInput id="customerOverride" idRoot="overrides_" :label="$gettext('Customer')" v-model="statementOverrides.customer"/>
      <FormInput id="reportNameOverride" idRoot="overrides_" :label="$gettext('Alternative name')" v-model="statementOverrides.reportName"/>
      <FormInput id="wcagVersionOverride" idRoot="overrides_" :label="$gettext('WCAG version')" v-model="statementOverrides.wcagVersion"/>
      <FormInput id="wcagStandardOverride" idRoot="overrides_" :label="$gettext('WCAG standard')" v-model="statementOverrides.wcagStandard"/>
      <FormInput id="contactEmailOverride" idRoot="overrides_" :label="$gettext('Contact Email')" v-model="statementOverrides.contactEmail"/>
      <FormInput id="contactPhoneOverride" idRoot="overrides_" :label="$gettext('Contact Phone')" v-model="statementOverrides.contactPhone"/>
      <FormSelect id="complianceStatusOverride" idRoot="overrides_" :label="'Stated compliance'" v-model="statementOverrides.complianceStatus"
        :options="{
          '': $gettext('No data'),
          'Not compliant': $gettext('Not compliant'),
          'Partially compliant': $gettext('Partially compliant'),
          'Fully compliant': $gettext('Fully compliant'),
      }"/>
      <FormDate id="statementDateOverride" :hasPicker="true" :clearDateAction="true" idRoot="overrides_" :hintText="'dd/mm/yyyy'" :label="$gettext('Statement Date')" v-model="statementOverrides.statementDate"/>
      <FormDate id="lastReviewDateOverride" :hasPicker="true" :clearDateAction="true" idRoot="overrides_" :hintText="'dd/mm/yyyy'" :label="$gettext('Last reviewed date stated')" v-model="statementOverrides.lastReviewDate"/>
      <FormDate id="latestTestDateOverride" :hasPicker="true" :clearDateAction="true" idRoot="overrides_" :hintText="'dd/mm/yyyy'" :label="$gettext('Latest test date')" v-model="statementOverrides.latestTestDate"/>
      <FormInput id="testConductorOverride" idRoot="overrides_" :label="$gettext('Latest Tester Name')" v-model="statementOverrides.testConductor"/>
      <FormTextArea id="disproportionateBurdenOverride" idRoot="overrides_" :label="$gettext('Disproportionate Burden Claim')" v-model="statementOverrides.disproportionateBurden"/>
    </div>
  </div>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions, mapGetters } from 'vuex';

import AriaSearchSelectList from '@/components/Aria/SearchSelectList';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import FormDate from '@/components/Form/Date';
import FormSelect from '@/components/Form/Select';

export default {
  name: 'EditRenderDetailsModal',
  data() {
    return {
      keyIssueTitle: '',
      keyIssueBody: '',
      originalCreatedOverride: '',
      createdOverride: '',
      originalPublishedOverride: '',
      publishedOverride: '',
      originalKeyIssues: [],
      keyIssues: [],
      keyIssueInstances: [],
      reportVersionID: false,
      originalStatementOverrides: {},
      statementOverrides: {},
      extended: false,
      qualityAssuranceVersion: false,
      submitting: false,
      loading: false,
    };
  },
  methods: {
    submit() {
      this.submitting = true;

      let variableObject = {
          id: this.reportVersionID,
      };

      const toSendOverrides = {};

      for ( const [ key, value ] of Object.entries( this.statementOverrides ) ) {
        if ( this.originalStatementOverrides[ key ] != value ) {
          toSendOverrides[key] = value;
        }
        
      }

      if ( Object.keys( toSendOverrides ).length > 0 ) {
        variableObject = { ...variableObject, statementOverrides: { ...toSendOverrides } };
      }

      if ( this.createdOverride && this.originalCreatedOverride != this.createdOverride ) {
        variableObject = { ...variableObject, createdOverride: this.createdOverride };
      }

      if ( this.publishedOverride && this.originalPublishedOverride != this.publishedOverride ) {
        variableObject = { ...variableObject, publishedOverride: this.publishedOverride };
      }

      this.$apollo.mutate( {
        mutation: gql`
          mutation updateReportVersionOverrides($id: ObjectID!, $createdOverride: String, $publishedOverride: String,  $keyIssues: [String], $statementOverrides: accessibilityStatementOverridesInput ) {
            result: updateReportVersionOverrides(id: $id, createdOverride: $createdOverride, publishedOverride: $publishedOverride, keyIssues: $keyIssues, statementOverrides: $statementOverrides)
          }
        `,
        variables: variableObject,
      } ).then( () => {
        this.submitting = false;
        this.$alerts.success( this.$gettext( '%{projectString} details updated!', { projectString: this.$hugrConfig.projectStringCap } ), this.$gettext( 'The %{projectString} details have been updated successfully', { projectString: this.$hugrConfig.projectString } ) );
        this.$emit( 'success', { created: this.createdOverride, published: this.publishedOverride, issues: this.keyIssues } );
        this.reset();
      } ).catch( () => {
        this.submitting = false;
        this.$alerts.coded( 'E063', 'F3501' ); //see notifications spreadsheet
      } );
    },
    submitExtended() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateReportVersionOverrides($id: ObjectID!, $createdOverride: String, $publishedOverride: String,  $keyIssues: [String], $statementOverrides: accessibilityStatementOverridesInput ) {
            result: updateReportVersionOverrides(id: $id, createdOverride: $createdOverride, publishedOverride: $publishedOverride, keyIssues: $keyIssues, statementOverrides: $statementOverrides)
          }
        `,
        variables: {
          id: this.reportVersionID,
          createdOverride: this.createdOverride,
          publishedOverride: this.publishedOverride,
          keyIssues: this.keyIssues,
          statementOverrides: this.statementOverrides,
        },
      } ).then( res => {
        this.$alerts.success( 'Details updated!', `The override details have been updated successfully` );
        this.$emit( 'success', { created: this.createdOverride, published: this.publishedOverride, issues: this.keyIssues, statementoverrides: this.statementOverrides } );
        this.reset();
      } ).catch( () => {
        this.$alerts.coded( 'E063', 'F3501' ); //see notifications spreadsheet
      } );
    },
    show( reportVersionID, created, published, issues, keyIssueInstances ) {
      this.reportVersionID = reportVersionID;
      this.createdOverride = created;
      this.publishedOverride = published;
      this.originalKeyIssues = Object.assign( [], issues );
      this.keyIssues = Object.assign( [], issues );
      this.keyIssueInstances = Object.assign( [], keyIssueInstances );
      this.$refs.modal.show();
    },
    showExtended( reportVersionID, created, published, issues, keyIssueInstances, statementOverrides, focusID ) {
      this.extended = true;
      this.reportVersionID = reportVersionID;
      this.createdOverride = created;
      this.publishedOverride = published;
      this.originalKeyIssues = Object.assign( [], issues );
      this.keyIssues = Object.assign( [], issues );
      this.keyIssueInstances = Object.assign( [], keyIssueInstances );
      this.statementOverrides = { ...statementOverrides } || {};
      delete this.statementOverrides.__typename;
      this.$refs.modal.show();
      setTimeout( () => {
        const el = document.getElementById( focusID );

        if ( el ) {
            el.children[1].focus();
        }
      }, 200 );
    },
    fetchLatestOverrides( reportID ) {
      this.$apollo.query( {
        query: gql`
          query Report($id: ObjectID) {
            report: Report(id: $id) {
              _id,
            identifier,
            currentVersion {
              _id
              published,
              publishedDate,
              firstPublishDate,
              createdOverride,
              publishedOverride,
              accessibilityStatementOverrides {
                mainURL,
                purpose,
                platform,
                customer,
                reportName,
                wcagVersion,
                wcagStandard,
                contactEmail,
                contactPhone,
                contactPerson,
                complianceStatus,
                statementDate,
                statementURL,
                lastReviewDate,
                legalStatus,
                lastAssuredDate,
                assuranceNote,
                latestTestDate,
                testConductor,
                reportURL,
                disproportionateBurden
              }
            }
            }
          }
        `,
        variables: {
          id: reportID,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.loading = false;
        this.reportVersionID = res.data.report.currentVersion._id;
        this.createdOverride = res.data.report.currentVersion.createdOverride;
        this.publishedOverride = res.data.report.currentVersion.publishedOverride;
        this.originalStatementOverrides = { ...res.data.report.currentVersion.accessibilityStatementOverrides } || {};
        this.statementOverrides = { ...res.data.report.currentVersion.accessibilityStatementOverrides } || {};
        delete this.statementOverrides.__typename;
      } ).catch( () => {
        this.$alerts.coded( 'E063', 'F3501' ); //see notifications spreadsheet
      } );
    },
    showQualityAssurance( reportID ) {
      this.loading = true;
      this.qualityAssuranceVersion = true;
      this.fetchLatestOverrides( reportID );
      this.$refs.modal.show();
    },
    addIssue() {
      if ( this.keyIssueTitle != '' ) {
        if ( this.keyIssueBody != '' ) {
          this.keyIssues.push( `${this.keyIssueTitle}<keyIssueBodySeparator>${this.keyIssueBody}` );
        } else {
          this.keyIssues.push( this.keyIssueTitle );
        }
        this.keyIssueTitle = '';
        this.keyIssueBody = '';
      }
    },
    removeIssue( issueKey ) {
      this.keyIssues.splice( issueKey, 1 );
    },
    moveIssueUp( issueKey ) {
      if( issueKey > 0 ) {
        const temp = this.keyIssues[issueKey - 1];
        this.keyIssues[issueKey - 1] = this.keyIssues[issueKey];
        this.keyIssues[issueKey] = temp;
      }
    },
    moveIssueDown( issueKey ) {
      if( issueKey < this.keyIssues.length - 1 ) {
        const temp = this.keyIssues[issueKey + 1];
        this.keyIssues[issueKey + 1] = this.keyIssues[issueKey];
        this.keyIssues[issueKey] = temp;
      }
    },
    populateIssue( section, number ) {
      this.keyIssueTitle = section;
      this.keyIssueBody = `There was 1 issue marked as a key issue for the ${section} category.`;
      if ( number > 1 ) {
        this.keyIssueBody = `There were ${number} issues marked as key issues for the ${section} category.`;
      }
      this.$refs.keyissuetitle.focus();
    },
    reset() {
      this.extended = false;
      this.qualityAssuranceVersion = false;
      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    processedKeyIssueInstances() {
      const ret = new Map();
      for ( const keyIssue of this.keyIssueInstances ) {
        const split = keyIssue.section ? keyIssue.section.split( " : " ) : keyIssue.template.section.split( " : " );
        let section = split[0];

        if ( split.length > 0 ) {
          // eslint-disable-next-line prefer-destructuring
          section = split[1];
        }

        // const section = `${keyIssue.template.identifier}: ${keyIssue.template.title}`;

        if ( !ret.has( section ) ) {
          ret.set( section, 1 );
        } else {
          ret.set( section, ret.get( section ) + 1 );
        }
      }

      return ret;
    },
    processedKeyIssues() {
      const ret = [];
      for ( const keyIssue of this.keyIssues ) {
        const split = keyIssue.split( '<keyIssueBodySeparator>' );
        ret.push( split );
      }

      return ret;
    },
    processedDisplayIssues() {
      const ret = [];
      for ( const keyIssue of this.processedKeyIssues ) {

        let newObject = {
          name: keyIssue[0],
          type: "",
        };

        if( keyIssue.length > 1 ) {
          newObject = {
            name: keyIssue[0],
            type: keyIssue[1],
          };
        }

        ret.push( newObject );
      }

      return ret;
    },
  },
  components: {
    FormFieldSet,
    FormInput,
    FormDate,
    FormSelect,
    FormTextArea,
    AriaSearchSelectList,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .removeButton {
    margin-left: 5px;
  }
  .spacing {
    margin-top: 5px;
  }
</style>
