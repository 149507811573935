<template>
  <Body title="System" class="System">
    <dl>
      <div>
        <dt>Frontend Version:</dt>
        <dd>{{ $hugrConfig.version }}</dd>
      </div>
      <div>
        <dt>Backend Version:</dt>
        <dd>{{ backendVersion }}</dd>
      </div>
      <div>
        <dt>Backend host:</dt>
        <dd>{{ backendHost }}</dd>
      </div>
    </dl>
  </Body>
</template>

<script setup>
  import { ref } from 'vue';
  import { useQuery } from '@vue/apollo-composable';
  import gql from 'graphql-tag';

  const { onResult, refetch } = useQuery( gql`
    query {
      System {
        hostname
        version
      }
    }
  `,
  {
    fetchPolicy: 'no-cache',
  } );

  const backendVersion = ref( false );
  const backendHost = ref( false );

  onResult( ( { data } ) => {
    if( typeof data.System == 'undefined' ) return;
    console.log( 'System:', data.System );
    backendHost.value = data.System.hostname;
    backendVersion.value = data.System.version;
  } );
</script>

<style lang="scss" scoped>

</style>
