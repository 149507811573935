<template>
  <ul class="ClearList">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'UIClearList',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.ClearList {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
