<template>
  <form @submit.prevent="submitReportSection">
    <FormFieldSet :legend="$gettext('New Report Section')">
      <FormInput idRoot="section_" :label="$gettext('Short name')" v-model="shortName" />
      <FormInput idRoot="section_" :label="$gettext('Full name')" v-model="fullName" />
      <FormTextArea idRoot="section_" :label="$gettext('Summary')" v-model="summary" />
      <FormInput idRoot="section_" type="number" :label="$gettext('Order')" v-model="order" />
      <Button type="primary" htype="submit">{{$gettext('Add Report Section')}}</Button>
    </FormFieldSet>
  </form>
</template>

<script>
import gql from 'graphql-tag';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';

export default {
  name: 'ReportSectionForm',
  data() {
    return {
      shortName: '',
      fullName: '',
      summary: '',
      order: 0,
    };
  },
  methods: {
    submitReportSection() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation addReportSection($reportSection: ReportSectionInput!) {
            rs: addReportSection(reportSection: $reportSection) {
              _id
            }
          }
        `,
        variables: {
          reportSection: {
            shortName: this.shortName,
            fullName: this.fullName,
            summary: this.summary,
            order: Number( this.order ),
          },
        },
      } ).then( res => {
        this.$emit( 'success', res.data.rs._id );
        this.summary = '';
        this.fullName = '';
        this.shortName = '';
        this.order = 0;
      } ).catch( error => {
        this.$alerts.coded( 'E038', 'F1901', '', error ); //see notifications spreadsheet
      } );
    },
  },
  components: {
    FormInput,
    FormFieldSet,
    FormTextArea,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
