<template>
  <div class="details">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UIDetails',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  div.details {
    background: #5e646a;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    border-top: 1px solid #262e37;
    border-bottom: 1px solid #262e37;
    padding: 10px;
    color: #FFF;
    box-sizing: border-box;
    dl {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      dt {
        display: inline-block;
        width: 100px;
      }
      dd {
        display: inline-block;
      }
    }
    .buttonset {
      display: inline-block;
      vertical-align: top;
      position:absolute;
      right: 6px;
      top: 6px;
    }
  }
</style>
