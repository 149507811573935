<template>
  <form @submit.prevent="submit">
    <!-- <h3 v-translate>Details</h3>
    <FormInput v-bind:idRoot="rule._id" :label="$gettext('Type')" v-model="rule.type" />
    <FormInput v-bind:idRoot="rule._id" :label="$gettext('Issue')" v-model="rule.issue" />
    <FormInput v-bind:idRoot="rule._id" :label="$gettext('Confidence')" v-model="rule.confidence" />
    <FormInput v-bind:idRoot="rule._id" :label="$gettext('Reason')" v-model="rule.reason" />
    <FormSelect v-bind:idRoot="rule._id" :label="$gettext('Logic')" v-model="rule.logic" v-bind:options="{and: 'and', or: 'or'}"/>
    <h3 v-translate>Comparisons</h3>
    <ul>
      <li v-for="(comparison, index) in rule.comparisons" v-bind:key="index">
        <FormInput v-bind:idRoot="rule._id+comparison[0]" :label="$gettext('Parameter')" v-model="comparison[0]" v-bind:label-visible="false" />
        <FormSelect v-bind:idRoot="rule._id+comparison[0]" :label="$gettext('Comparator')" v-model="comparison[1]" v-bind:label-visible="false" v-bind:options="{
          '==': $gettext('Is exactly (==)'),
          '!=': $gettext('Is not (!=)'),
          '<': $gettext('Less than (&lt;)'),
          '>': $gettext('Greater than (&gt;)'),
          '<=': $gettext('Less than or equal to (&lt;=)'),
          '>=': $gettext('Greater than or equal to (&gt;=)'),
          'similar': $gettext('Similar to'),
          'cont': $gettext('Contains'),
          'start': $gettext('Starts with'),
          'ends': $gettext('Ends with'),
          'regex': $gettext('RegEx')}"/>
        <FormInput v-bind:idRoot="rule._id+comparison[0]" label="Test" v-model="comparison[2]" v-bind:label-visible="false" />
        <button @click.prevent="removeComparison(index)">{{$gettext('Remove')}}</button>
      </li>
      <li><button @click.prevent="addOne">{{$gettext('Add One')}}</button></li>
    </ul>
    <button>
      <span v-if="!rule._id">{{$gettext('Save')}}</span>
      <span v-else>{{$gettext('Update')}}</span>
    </button>
    <button @click.prevent="remove">{{$gettext('Delete')}}</button> -->
  </form>
</template>

<script>
// import { mapState, mapActions } from 'vuex';
// import FormInput from '@/components/Form/Input';
// import FormSelect from '@/components/Form/Select';

export default {
  name: 'Rule',
  // methods: {
  //   ...mapActions(['jwtemit']),
  //   submit: function() {
  //     if(!this.rule._id) {
  //       this.jwtemit(['PUT_RULE', this.rule]);
  //     } else {
  //       this.jwtemit(['UPDATE_RULE', this.rule]);
  //     }
  //   },
  //   remove: function() {
  //     this.jwtemit(['RM_RULE', this.rule._id]);
  //   },
  //   addOne: function(rule) {
  //     this.rule.comparisons.push(['', '', '']);
  //   },
  //   removeComparison: function(index) {
  //     this.rule.comparisons.splice(index, 1);
  //   }
  // },
  props: {
    rule: {
      type: Object,
    },
  },
  // components: {
  //   FormInput,
  //   FormSelect
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 table, tr, th, td{
   border: 1px solid;
 }
</style>
