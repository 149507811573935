<template>
  <Modal ref="modal" title="Page compliance report" @close="reset()">
    <div v-if="pages">
      <Table class="PageCompliance">
        <thead class="PageCompliance_Head">
          <tr>
            <th v-translate>Page</th>
            <th class="PageCompliance_Head_Compliance" v-translate>Compliance</th>
          </tr>
        </thead>
        <tbody class="PageCompliance_Body">
          <tr v-for="page of pages" :key="page.name" class="PageCompliance_Body_Row">
            <td class="PageCompliance_Body_Row_Page">
              <p class="PageCompliance_Body_Row_Page_Name">{{ page.name }}</p>
              <p v-if="page.title!=''||page.url!=''" class="PageCompliance_Body_Row_Page_Details">
                <span v-if="page.title!=''">{{ page.title}}&nbsp;</span>
                <span v-if="page.url!=''"><a :href="page.url" target="_blank">{{ page.url }}</a></span>
              </p>
            </td>
            <td :class="['PageCompliance_Body_Row_Compliance', page.compliance=='Fail'||page.componentCompliance=='Fail'?'_red':'_green']">
              <p class="PageCompliance_Body_Row_Compliance_Result">
                <span v-if="page.componentCompliance!='Pass'&&page.compliance=='Pass'">{{ page.componentCompliance }}</span>
                <span v-else>{{ page.compliance }}</span>
              </p>
              <template v-if="!hideComplianceSubText">
                <p v-if="page.compliance=='Pass'&&page.componentCompliance!='Pass'" class="PageCompliance_Body_Row_Compliance_Details">Issues on components only</p>
                <p v-else-if="page.componentCompliance!='Pass'" class="PageCompliance_Body_Row_Compliance_Details">Issues on page and components</p>
              </template>
            </td>
          </tr>
        </tbody>
      </Table>
      <label><input type="checkbox" v-model="hideComplianceSubText"/>Hide compliance subtext</label>
    </div>
    <Empty v-else :text="$gettext('There are not pages on this report')" />
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import UITable from '@/components/UI/Table';

export default {
  name: 'PageComplianceModal',
  data() {
    return {
      pages: false,
      hideComplianceSubText: false,
    };
  },
  methods: {
    show( list ) {
      this.pages = list;
      this.$refs.modal.show();
    },
    reset() {
      this.$refs.modal.reset();
    },
  },
  components: {
    Table: UITable,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .PageCompliance {
    // margin: 18px;
    // width: calc(100% - 32px);
    table-layout: fixed;
    &_Head {
      &_Compliance {
        width: 150px;
      }
    }

    &_Body {
      &_Row {
        &_Page {
          &_Name {
            margin: 2px 0;
          }
          &_Details {
            font-size: 0.8em;
            margin-top: 0;
            margin-bottom: 2px;

            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        &_Compliance {
          &._red {
            background: lighten($hugr-colours-red, 50%);
          }
          &._green {
            background: lighten($hugr-colours-green, 50%);
          }

          &_Result {
            margin: 2px 0;
          }

          &_Details {
            font-size: 0.8em;
            margin-top: 0;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  ._darkMode .PageCompliance {
    &_Body {
      &_Row {
        &_Compliance {
          &._red {
            background: darken($hugr-colours-red, 20%);
          }
          &._green {
            background: darken($hugr-colours-green, 20%);
          }
        }
      }
    }
  }
</style>
