<template>
  <div :class="['Search', settings?.visibleSearchLabels?'_pad':'']">
    <form>
      <label v-if="settings?.visibleSearchLabels" class="Search_VisibleLabel" for="searchInput">{{ label }}</label>
      <div class="Search_Box">
        <input id="searchInput" class="Search_Box_Input" :aria-label="label" :placeholder="settings?.visibleSearchLabels?'':`${label}...`" v-model="value" @keyup.esc.prevent="clear()" @keydown.enter.prevent="doANothingSearch()" @keyup.enter.prevent="doANothingSearch()" />
      </div>
      <Button type="Search_Button primary" :icon="['solid', 'search']" aria-label="Search"></Button>
    </form>
    <span aria-live="polite" class="a11yhide">
      <template v-if="inUse">
        <span v-if="results==false">Showing search results</span>
        <span v-else-if="results==0">No search results</span>
        <span v-else>Showing {{ results }} search results</span>
      </template>
    </span>
  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'UISearch',
  data() {
    return {
      value: this.modelValue,
    };
  },
  apollo: {
    settings: gql`
      {
        settings: Settings {
          id
          visibleSearchLabels,
        }
      }
    `,
  },
  watch: {
    value() {
      this.$emit( 'update:modelValue', this.value );
    },
  },
  methods: {
    clear() {
      this.value = "";
    },
    doANothingSearch() {
    },
  },
  props: {
    label: {
      type: String,
      default: 'Search',
    },
    inUse: {
      type: Boolean,
      default: false,
    },
    results: {
      type: Boolean || Number,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';
@import '@/assets/styles/variables/_buttons.scss';

  .Search {
    position: relative;
    &._pad {
      margin-top: 22px;
    }

    &_VisibleLabel {
      position: absolute;
      top: -22px;
    }

    &_Box {
      border: 1px solid $hugr-button-border-border;
      border-right: none;

      width: calc(100% - 57px);
      display: inline-block;
      vertical-align: top;
      border-radius: 3px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      transition: border .5s ease-in-out 0s,
                  color .5s ease-in-out 0s,
                  border-radius 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;

      &:focus-within {
        border: 1px solid $hugr-colours-primary;
        border-right: none;
        border-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        outline: 2px solid black;
      }
      &:hover {
        border-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid darken($hugr-colours-grey, 10%);
        border-right: none;
      }

      &_Input {
        width: 100%;
        border: none;
        padding: 17px 20px;
        box-sizing: border-box;
        font-size: 0.9em;
        height: 50px; //make sure all browsers are the same
        border-radius: 10px;
        &:focus {
          outline: none;
        }
      }
    }
    &_Button {
      display: inline-block;
      vertical-align: top;

      width: 55px;
      height: 52px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:hover, &:focus, &.hover, &.focus {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  ._darkMode .Search {
    &_Box {
      &_Input {
        background-color: $hugr-colours-primary;
        color: #FFF;
        &::placeholder {
          color: $hugr-colours-grey;
        }
      }
    }
  }
</style>
