<template>
  <div :class="['SearchSelect', hasError?'with-error':'']">
    <div class="SearchSelect_Inner" v-on:keyup.esc.prevent="doClose" v-on:keydown.down.prevent="down" v-on:keydown.up.prevent="up" v-on:keydown.tab="tabAway">
      <label :for="`${uid}-input`" class="SearchSelect_Inner_Label">
        <Icon v-if="icon.length" :type="icon[0]" :icon="icon[1]" />
        <span class="SearchSelect_Inner_Label_Main">{{ label }}</span>
        <span v-if="required" class="SearchSelect_Inner_Label_Required">Required</span>
      </label>
      <div :class="['SearchSelect_Inner_Body', results.length?'_open':'_closed', hasError?'_hasError':'']" :ref="`${uid}combo`" role="combobox" :aria-owns="`${uid}-listbox`" :aria-expanded="results.length?'true':'false'">
        <template v-if="multiple">
          <span v-for="item in selected" :key="`pill-${item}`" class="SearchSelect_Inner_Body_Pill">
            {{ oOptions.filter( i => i.value == item )[0].display }}
            <button class="SearchSelect_Inner_Body_Pill_Close" :aria-label="`remove ${oOptions.filter( i => i.value == item )[0].display}`" @click.prevent="doSelect(item, '')"><Icon type="solid" icon="times"/></button>
          </span>
        </template>
        <input  :id="`${uid}-input`"
                class="SearchSelect_Inner_Body_Input"
                :aria-describedby="[hintText ? uid+'-hint' : '', hasError ? uid+'-error' : ''].join(' ')"
                ref="mainInput"
                v-model="value"
                @input="update"
                @focus="toggle"
                @click="toggle"
                :aria-controls="uid-listbox"/>
        <Icon v-if="dropdown" class="SearchSelect_Inner_Body_DropdownArrow" type="solid" :icon="results.length?'chevron-up':'chevron-down'" @click="$refs.mainInput.focus()"/>
      </div>
      <ul v-if="results.length" ref="dropdown" class="SearchSelect_Inner_DropDown" :id="`${uid}-listbox`" role="listbox" :key="`${value}-${results.length}`">
        <li v-for="result in results" :key="result.value" :class="['SearchSelect_Inner_DropDown_Item', (result.priority?'_priority':''), (selected.indexOf( result.value ) >= 0)?'_selected':'']" tabindex="-1" role="option" @click.prevent="doSelect(result.value, result.display)" @keyup.enter="doSelect(result.value, result.display)" @keyup.space.prevent="doSelect(result.value, result.display)">
          <span v-if="result.priority" class="SearchSelect_Inner_DropDown_Item_Pre">Suggested:</span>
          <span>{{ result.display }}</span>
          <Icon v-if="multiple" class="SearchSelect_Inner_DropDown_Item_Checkbox" type="regular" :icon="selected.indexOf( result.value ) >= 0?'square-check':'square'" />
          <Icon v-if="!multiple&&selected.indexOf( result.value ) >= 0" class="SearchSelect_Inner_DropDown_Item_Checkbox" type="regular" icon="circle-check" />
        </li>
        <li v-if="oOptions.length > cOptions.length" class="SearchSelect_Inner_DropDown_Item _showMore" tabindex="-1" role="option" @click.prevent="showAll()" @keyup.enter="showAll()" @keyup.space.prevent="showAll()">
          Show more
        </li>
        <li v-else-if="sOptions.length < oOptions.length" class="SearchSelect_Inner_DropDown_Item _showMore" tabindex="-1" role="option" @click.prevent="showLess()" @keyup.enter="showLess()" @keyup.space.prevent="showLess()">
          Only show suggested
        </li>
      </ul>
    </div>
    <DescribedBy v-if="hintText" :hide="results.length>0" :id="`${uid}-hint`" type="hint">{{ hintText }}</DescribedBy>
    <DescribedBy v-if="hasError" :hide="results.length>0" :id="`${uid}-error`" type="error">{{errorMessage}}</DescribedBy>
  </div>
</template>

<script>
import gql from 'graphql-tag';
// import { mapState, mapActions } from 'vuex';

import mixin from '@/components/Form/Mixin';
import DescribedBy from '../Form/DescribedBy.vue';

export default {
  mixins: [ mixin ],
  name: 'AriaSearchSelect',
  created() {
    this.getOpts();
  },
  data() {
    return {
      uid: this.$uuid.generate(),

      cOptions: this.options,
      oOptions: this.options,
      sOptions: this.options,

      id: null,
      value: '',
      results: [],

      selected: [],

      fixedPriority: [],
    };
  },
  watch: {
    priority() {
      this.setOptions( this.oOptions );
    },
  },
  methods: {
    refetch() { //refetch the gql opts
      this.getOpts();
    },
    focus() { //focus the input
      this.$refs['mainInput'].focus();
    },
    setOptions( options ) {
      //check priorities exist first
      this.fixedPriority = this.priority.filter( p => options.map( o => o.value ).indexOf( p ) >= 0 );

      if( this.fixedPriority.length ) this.cOptions = options.filter( o => !o.hidden ).filter( o => !this.fixedPriority || this.fixedPriority.indexOf( o.value ) >= 0 );
      if( !this.fixedPriority.length ) this.cOptions = options.filter( o => !o.hidden );

      this.oOptions = options.filter( o => !o.hidden );
      this.sOptions = this.cOptions;
      // this.aOptions = res.data.options.filter( o => o.hidden );

      if( typeof this.$refs.mainInput != 'undefined' && this.default != '' ) {
        this.selected = [];
        let defaultV = [ this.default ];
        if( this.multiple ) defaultV = this.default.split( ',' );
        for( const option of options ) {
          if( defaultV.indexOf( option.value ) >= 0 || defaultV.map( d => parseInt( d ) ).indexOf( option.value ) >= 0 ) {
            this.selected.push( option.value );
            if( !this.multiple ) {
              this.$refs.mainInput.value = option.display;
              this.$emit( 'selected', option.value, option.display, this );
            }
          }
        }
        if( this.selected.length && this.multiple ) {
          this.$emit( 'selected', this.selected, this );
        }
      }
    },
    getOpts() {
      if ( this.gqlOptions.length == 0 ) {
        setTimeout( () => {
          this.setOptions( this.options );
        }, 100 );
      } else {
        this.$apollo.query( {
          query: gql`query Options($func: String!, $param: String) {
            options: Options(function: $func, param: $param) {
              value,
              display,
              search,
              hidden
            }
          }`,
          variables: {
            func: this.gqlOptions[0],
            param: ( this.gqlOptions.length > 1 ) ? this.gqlOptions[1] : null,
          },
          fetchPolicy: 'no-cache',
        } ).then( res => {
          this.setOptions( res.data.options );
        } ).catch( () => {
          this.$alerts.coded( 'E016', 'F601' ); //see notifications spreadsheet
        } );
      }
    },
    showAll() {
      this.cOptions = this.oOptions;
      setTimeout( () => {
        // this.ignoreToggle = true;
        // setTimeout( () => { this.ignoreToggle = false }, 100 );
        this.$refs.mainInput.focus();
        this.open();
      }, 100 );
    },
    showLess() {
      this.cOptions = this.sOptions;
      for( const item of this.selected ) {
        if( this.cOptions.map( o => o.value ).indexOf( item ) < 0 ) this.cOptions.push( this.oOptions.filter( o => o.value == item )[0] );
      }
      setTimeout( () => {
        this.$refs.mainInput.focus();
        this.open();
      }, 100 );
    },
    validate( value ) {
      let success = true;
      const messages = [];
      for( const validation of this.validation ) {
        if( typeof validation == 'object' && validation.regex ) {
          //todo
          if( !validation.regex.test( this.value ) ) {
            this.success = false;
            messages.push( validation.message );
            this.addError( validation.message );
          }
        } else {
          switch( validation ) {
            case 'not-empty': {
              if( this.selected.length == 0 ) {
                success = false;
                messages.push( 'This cannot be empty' );
                this.addError( 'This cannot be empty' );
              }

              break;
            }
            case 'valid-option': {
              if( this.cOptions.map( o => o.value ).indexOf( value ) < 0 ) {
                success = false;
                messages.push( 'Option is not valid, please make sure one is selected from the dropdown' );
                this.addError( 'Option is not valid, please make sure one is selected from the dropdown' );
              }

              break;
            }
            case 'option-matches': {
              if( this.value != '' && value != 0 && this.cOptions.map( o => o.value ).indexOf( value ) >= 0 ) if( this.cOptions[this.cOptions.map( o => o.value ).indexOf( value )].display != this.value ) {
                success = false;
                messages.push( `Option selected doesn't match, please reselect from the dropdown` );
                this.addError( `Option selected doesn't match, please reselect from the dropdown` );
              }

              break;
            }
            default: {
              throw `Validation method doesn't exist`;
            }
          }
        }
      }

      return {
        success,
        messages,
      };
    },
    update() {
      this.results = [];
      if( this.fixedPriority.length ) {
        for( const opt of this.cOptions ) {
          if( this.fixedPriority.indexOf( opt.value ) >= 0 ) {//&& this.results.indexOf(opt)==-1) {
            this.results.push( {
              ...opt,
              priority: true,
            } );
          }
        }
      }
      for( const opt of this.cOptions ) {
        if(
            (
              opt.display.toLowerCase().indexOf( this.value.toLowerCase() ) >= 0
              || opt.search?.toLowerCase().indexOf( this.value.toLowerCase() ) >= 0
            )
            && this.results.map( result => result.value ).indexOf( opt.value ) == -1
          ) {
          // let opt2 = opt;
          // opt2.priority = false;
          this.results.push( {
            ...opt,
            priority: false,
          } );
        }
      }
      if( this.results.length ) {
        document.addEventListener( 'click', this.clickOutside );
        document.addEventListener( 'touchstart', this.clickOutside );
      }
    },
    open() {
      if( ( this.value == '' || this.dropdown ) && this.results.length == 0 ) {
        const priority = [];
        const other = [];
        for( const opt of this.cOptions ) {
          if( this.fixedPriority.indexOf( opt.value ) >= 0 ) { //&& this.results.indexOf(opt)==-1) {
            priority.push( {
              ...opt,
              priority: true,
            } );
          } else {
            other.push( {
              ...opt,
              priority: false,
            } );
          }
        }
        if( this.dropdown ) {
          this.results = priority.concat( other );
        }
        if( this.results.length ) {
          document.addEventListener( 'click', this.clickOutside );
          document.addEventListener( 'touchstart', this.clickOutside );
        }
      }
    },
    toggle() {
      if( this.ignoreToggle ) return;

      if( !this.results.length ) {
        this.open();
      } else {
        this.close();
      }
      this.ignoreToggle = true;
      setTimeout( () => { this.ignoreToggle = false }, 100 );
    },
    clickOutside( evt ) {
      // let target = evt.target;
      const combo = this.$refs[ `${this.uid}combo` ];
      const parent1 = evt.target.parentNode && evt.target.parentNode == combo;
      const parent2 = evt.target.parentNode && evt.target.parentNode.parentNode && evt.target.parentNode.parentNode == combo;

      let stopClose = false;

      if( this.multiple ) {
        const list = this.$refs[ `dropdown` ];
        const listParent1 = evt.target.parentNode && evt.target.parentNode == list;
        const listParent2 = evt.target.parentNode && evt.target.parentNode.parentNode && evt.target.parentNode.parentNode == list;

        if( listParent1 || listParent2 ) stopClose = true;
      }

      if( !parent1 && !parent2 && !stopClose ) this.close();
    },
    doSelect( value, display ) {
      if( this.multiple ) {
        if( this.selected.indexOf( value ) >= 0 ) {
          this.selected = this.selected.filter( i => i != value );
        } else {
          this.selected.push( value );
        }
        this.$emit( 'selected', this.selected, this );
      } else {
        this.selected = [ value ];
        this.$emit( 'selected', value, display, this );
      }
    },
    doClose() { //do a close inside input
      this.close();
      //return focus to input
      this.$refs.mainInput.focus();
    },
    close() { //do a close overall
      if( this.results.length ) {
        document.removeEventListener( 'click', this.clickOutside );
        document.removeEventListener( 'touchstart', this.clickOutside );
      }
      //close list
      this.results = [];
      this.$forceUpdate();

      //reset input
      if( this.multiple || this.selected.length == 0 ) this.value = '';
      if( !this.multiple && this.selected.length > 0 ) this.value = this.oOptions.filter( i => i.value == this.selected[0] )[0].display;
    },
    tabAway() {
      this.close();
    },
    down( event ) {
      event.preventDefault();
      if( document.activeElement == this.$refs.mainInput && this.results.length ) { //is on input and open go to first
        this.$refs.dropdown.children[0].focus();
      } else if( this.results.length && document.activeElement.nextElementSibling != null ) { //go to next opt
          document.activeElement.nextElementSibling.focus();
      } else { //failsafe back to input
        this.$refs.mainInput.focus();
      }
    },
    up( event ) {
      event.preventDefault();
      if( this.results.length && document.activeElement.previousElementSibling != null ) { //go to prev opt
          document.activeElement.previousElementSibling.focus();
      } else { //failsafe back to input
        this.$refs.mainInput.focus();
      }
    },
    clear() {
      this.close();
      this.value = '';
    },
    select( id, display ) { //display is optional
      this.$refs.mainInput.focus();
      this.selectWithoutFocus( id, display );
    },
    selectWithoutFocus( id, display ) {
      if( display ) {
        this.value = display;
      } else {
        for( const option of this.cOptions ) {
          if( option.value == id ) {
            this.value = option.display;
          }
        }
      }
      this.results = [];
    },
  },
  computed: {
    required() {
      return this.validation.indexOf( 'not-empty' ) >= 0;
    },
  },
  components: {
    DescribedBy,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    idRoot: {
      type: String,
      default: 'missing',
      // required: true
    },
    gqlOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    default: {
      type: String,
      default: '',
    },
    priority: {
      type: Array,
      default: () => {
        return [];
      },
    },
    icon: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hintText: {
      type: String,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .SearchSelect {
    margin-bottom: 16px;
    &_Inner {
      position: relative;
      &_Label {
        display: block;
        position: relative;
        width: 100%;
        font-size: 0.9em;
        padding-bottom: 4px;
        &_Main {
          font-weight: bold;
        }
        &_Required {
          position: absolute;
          right: 0;
        }
      }

      &_Body {
        background: $hugr-colours-input-surface;
        padding: 4px;
        border-bottom: 3px solid $hugr-colours-input-border;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 4px;

        &._open {
          border-bottom: 3px solid $hugr-colours-input-surface;
        }
        &._hasError {
          border-bottom: 3px solid $hugr-colours-red;
        }

        &_Pill {
          background: $hugr-colours-primary;
          color: $dig-orange;
          font-size: 0.8em;
          padding: 4px 6px;
          border-radius: 8px;

          &_Close {
            background: transparent;
            border: none;
            color: $dig-orange;

            padding: 2px 3px 1px 3px;
            font-size: 0.9em;
            border-radius: 8px;

            &:hover, &:focus {
              background: $dig-orange;
              color: $hugr-colours-primary;
              cursor: pointer;
            }
          }
        }

        &_Input {
          background: transparent;
          border: none;
          // width: calc( 100% - 24px );
          flex-grow: 1;
          &:focus {
            outline: none;
          }
        }

        &:focus-within {
          // outline: black;
          outline-style: auto;
          outline-width: 2px;
        }
      }

      &_DropDown {
        position: absolute;
        background: $hugr-colours-input-surface;
        // top: 56px;
        width: 100%;
        list-style: none;
        margin: 0;
        margin-top: 4px;
        padding: 0;
        max-height: 200px;
        overflow-y: auto;
        z-index: 999;
        border-bottom: 3px solid $hugr-colours-input-border;
        box-shadow: 0px 2px 6px -2px $hugr-colours-primary;

        &_Item {
          position: relative;
          padding: 8px;
          cursor: pointer;
          &._priority {
            background: lighten( $dig-blue, 60% );
          }
          &:focus, &:hover {
            background: lighten( $dig-orange, 15% );
          }
          &._selected {
            background: $dig-orange;
          }
          &._showMore {
            text-decoration: underline;
            font-weight: 600;
            font-size: 0.8em;
          }

          &_Pre {
            font-weight: bold;
            font-size: 0.8em;
            display: block;
            margin-bottom: 4px;
          }
          &_Checkbox {
            position: absolute;
            right: 16px;
          }
        }
      }
    }
  }

  ._darkMode .SearchSelect {
    &_Inner {
      &_Body {
        color: $hugr-colours-grey;
        background: $hugr-colours-dark-input-surface;
        border-bottom: 3px solid $hugr-colours-dark-input-border;
        
        &_Input {
          color: $hugr-colours-grey;
        }

        &._hasError {
          border-bottom: 3px solid lighten( $hugr-colours-red, 20% );
        }
      }

      &_DropDown {
        color: $hugr-colours-grey;
        background: $hugr-colours-dark-input-surface;

        &_Item {
          &._priority {
            background: darken( $dig-blue, 10% );
          }
          &:focus, &:hover {
            background: darken( $dig-orange, 60% );
          }
          &._selected {
            background: darken( $dig-orange, 40% );
          }
        }
      }
    }
  }
</style>
