<template>
  <div>
    <Body v-if="issue" :title="issue.identifier+': '+issue.title">
      <Table>
        <tr>
          <th v-translate>Issue</th>
          <td>
            <vue-markdown :key="issue.identifier+'-description'" v-highlight :html="false">{{ issue.description }}</vue-markdown>
          </td>
        </tr>
        <tr>
          <th v-translate>Severity</th>
          <td>
            <span v-if="issue.severity==0" v-translate>Advisory</span>
            <span v-else-if="issue.severity==1" v-translate>Low</span>
            <span v-else-if="issue.severity==2" v-translate>Medium</span>
            <span v-else-if="issue.severity==3" v-translate>High</span>
            <span v-else-if="issue.severity==4" v-translate>Critical</span>
            <span v-else v-translate>Unknown</span>
          </td>
        </tr>
        <tr>
          <th v-translate>WCAG Ref(s)</th>
          <td>
            <ul>
              <li v-for="criterion in issue.criteria" v-bind:key="criterion.identifier">{{criterion.criterion}} {{criterion.title}} (Level {{criterion.level}}) [{{criterion.identifier}}]</li>
            </ul>
          </td>
        </tr>
        <tr>
          <th v-translate>Affected users</th>
          <td>
            <ul>
              <li v-for="group in issue.affected" v-bind:key="group">
                <span v-if="group=='blind'" v-translate>Blind</span>
                <span v-else-if="group=='low-vision'" v-translate>Low Vision</span>
                <span v-else-if="group=='cognitively-impaired'" v-translate>Cognitively Impaired</span>
                <span v-else-if="group=='deaf'" v-translate>Deaf</span>
                <span v-else-if="group=='motor-impaired'" v-translate>Motor Impaired</span>
                <span v-else>{{group}}</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th v-translate>Impact on users</th>
          <td>
            <vue-markdown :key="issue.identifier+'-impact'" v-highlight :html="false">{{ issue.impact }}</vue-markdown>
          </td>
        </tr>
        <tr>
          <th v-translate>Related Solutions</th>
          <td>
            <ul>
              <li v-for="solution in issue.solutions" v-bind:key="solution.identifier">{{solution.identifier}}: {{solution.title}}</li>
            </ul>
          </td>
        </tr>
      </Table>
      <h3 v-translate>Instances</h3>
      <InstanceList :instances="instances" />
    </Body>
    <div v-else v-translate>Loading...</div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';
import UIBody from '@/components/UI/Body';
import UITable from '@/components/UI/Table';

import InstanceList from '@/components/InstanceList';

export default {
  name: 'IssueViewView',
  // created() {
  //   this.jwtemit(['GET_REPORT']);
  // },
  data() {
    return {
      reportIdent: this.$route.params.report.replace( '%20', ' ' ),
      version: this.$route.params.version,
      template: this.$route.params.template,

      issue: false,
      instances: [],
    };
  },
  mounted() {
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Issue View',
      },
    } );
  },
  watch: {
    $route( to, from ) {
      this.reportIdent = this.$route.params.report.replace( '%20', ' ' );
      this.version = this.$route.params.version;
      this.template = this.$route.params.template;

      this.$apollo.queries.issue.refetch();
      this.$apollo.queries.instances.refetch();
      //TODO
    },
  },
  apollo: {
    issue: {
      query: gql`
        query IssueTemplate($ident: String!) {
          issue: IssueTemplate(identifier: $ident) {
            _id,
            identifier,
            title,
            description,
            severity,
            affected,
            impact
            #extra
            criteria {
              identifier,
              criterion,
              title,
              level
            }
            solutions {
              identifier,
              title
            }
          }
        }
      `,
      variables() {
        return {
          ident: this.template,
        };
      },
    },
    instances: {
      query: gql`
        query IssuesFromReport($ident: String!, $version: String, $tmp: String!) {
          instances: IssuesFromReport(identifier: $ident, version: $version, template: $tmp) {
            _id,
            identifier,
            reason,
            evidence,
            status,
            primary,
            parent {
              _id
            }
            others {
              _id
            }
          }
        }
      `,
      variables() {
        return {
          ident: this.reportIdent,
          tmp: this.template,
          version: this.version,
        };
      },
    },
  },
  // sockets: {
  //   STATUS_REPORT: function(report) {
  //     this.report = report
  //     let tid = this.$route.params.pathMatch.toUpperCase();
  //     this.jwtemit(['GET_ISSUETEMPLATE', tid]); //to ensure order, doesn't actually need to be here otherwise
  //   },
  //   RETURN_ISSUETEMPLATE: function(issue) {
  //     let tid = this.$route.params.pathMatch.toUpperCase();
  //     this.issue = issue;
  //     this.jwtemit(['GET_ISSUEINSTANCES_BY_TEMPLATE', {report: this.report.currentVersion, template: tid}]);
  //   },
  //   RETURN_ISSUEINSTANCES_BY_TEMPLATE: function(instances) {
  //     this.instances = instances;
  //   },
  //   RM_ISSUEINSTANCE_RESULT: function(data) {
  //     if(data.success) {
  //       let tid = this.$route.params.pathMatch.toUpperCase();
  //       this.jwtemit(['GET_ISSUEINSTANCES_BY_TEMPLATE', {report: this.report.currentVersion, template: tid}]);
  //     } else {
  //
  //     }
  //   }
  // },
  methods: {
    ...mapActions( [ 'jwtemit' ] ),
    // duplicate: function(id) {
    //   for(let instance of this.instances) {
    //     if(instance._id==id) {
    //       instance.duplicate = true;
    //     }
    //   }
    //   this.$forceUpdate();
    // },
    countGroup( group ) {
      // groups[group].contains
      return 0; //TODO
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  components: {
    Body: UIBody,
    /* CardSet: UICardSet,
    Card: UICard,
    ButtonSet: UIButtonSet,
    Details: UIDetails, */
    Table: UITable,

    InstanceList,
  },
};
</script>

<style lang="scss" scoped>

</style>
