<template>
  <Modal
    ref="modal"
    :title="$gettext('New Page')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Page..."

    submitText="Add page"
    @submit="submitPage"
    @close="reset()"
    class="PageModal">
    <FormInput ref="name" idRoot="page_" :label="$gettext('Unique name')" v-model="name" :validation="['not-empty', 'max-70-chars']"/>

    <template v-if="simplified">
      <FormInput idRoot="page_" :label="$gettext('Page URL')" v-model="combineUrl" ref="pageurl" @blur="doSplitUrl"/>
    </template>
    <template v-else>
      <FormInput idRoot="page_" :label="$gettext('Host URL')" v-model="host" :hintText="$gettext('example: https://diginclusion.com')"/>
      <FormInput idRoot="page_" :label="$gettext('Path')" v-model="path" :hintText="$gettext('example: /contact')"/>
    </template>

    <Notice v-if="outsideBase" type="alert" size="micro">This doesn't match the URL of the product, make sure this is correct</Notice>

    <Notice v-if="this.title!==this.actualTitle" type="alert" size="micro">Page has changed, update page title? <button size="micro" @click="title=actualTitle">Yes, update page title</button></Notice>
    <Notice v-if="isLoginPage&&!isLoginAcknowleged&&this.title!==this.actualTitle" type="alert" size="micro">This page appears to require login. Is this supposed to be a login page? <Button size="micro" @click="acknowlegeLoginPage()">Yes, this is a login page</Button></Notice>
    
    <FormInput idRoot="page_" :label="$gettext('Title')" v-model="title"  />
    <FormImage ref="ssImage" idRoot="page_" label="Screenshot" v-model="screenshot"/>
    <Notice class="PageModal_ComponentNotice" size="small" type="alert" v-if="componentsWithFuturePageFlags.length > 0">
      <h3 class="PageModal_ComponentNotice_Title">Add page to components</h3>
      <ul>
        <li v-for="(component, i) in componentsWithFuturePageFlags" :key="i">
          <input type="checkbox" v-model="componentsToAddTo" :value="component._id" :id="`comp-${component._id}`"/>
          <label :for="`comp-${component._id}`">{{component.identifier}}</label>
        </li>
      </ul>
      <span v-if="componentsWithFuturePageFlags.length != 1">There are {{componentsWithFuturePageFlags.length}} components that will add this page, untick the pages if they shouldn't be added.</span>
      <span v-else>There is 1 component that will add this page, untick the page if it shouldn't be added.</span>
    </Notice>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import FormImage from '@/components/Form/Image';
import Notice from '@/components/UI/Notice';

export default {
  name: 'PageModal',
  data() {
    return {
      combineUrl: '',
      host: '',
      path: '',
      name: '',
      title: '',
      screenshot: '',
      componentsToAddTo: [],
      submitting: false,

      simplified: true,
      outsideBase: false,
      actualTitle: '',
      isLoginPage: false,
      isSinglePage: false,

      isLoginAcknowleged: false,
    };
  },
  apollo: {
    myReport: {
      query: gql`
        query ReportFromVersion($id: ObjectID!) {
          myReport: ReportFromVersion(id: $id) {
            _id,
            productUrl
          }
        }
      `,
      variables() {
        return {
          id: this.report,
        };
      },
      skip() { return this.report === '0' },
    },
  },
  watch: {
    report() {
      if( this.host === '' ) this.host = this.myReport.productUrl;
      this.doCombineUrl();
    },
  },
  methods: {
    doCombineUrl() {
      this.combineUrl = `${this.host}${this.path}`;
    },
    doSplitUrl() {
      const regex = /([A-Za-z]*:\/\/)?([A-Za-z0-9\-.]*\.[A-Za-z0-9\-.]*)?([A-Za-z0-9%/#?=_\-&. ]*)/.exec( this.combineUrl );
      const protocol = regex[1] || 'https://';
      const domain = regex[2];
      const path = regex[3][0] === '/' ? regex[3] : `/${regex[3]}`;

      this.host = typeof domain === 'undefined' || domain === '' ? this.myReport.productUrl : `${protocol}${domain}`;
      this.path = path;

      this.doCombineUrl();

      this.outsideBase = this.host != this.myReport.productUrl;

      this.parsePage();
    },
    submitPage() {
      this.$hugrvalidate( [ 'name' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`mutation addPage( $page: PageInput!, $componentsToAddTo: [ObjectID]) {
              page: addPage(page: $page, componentsToAddTo: $componentsToAddTo) {
                _id,
                host,
                path,
                name,
                title
              }
            }`,
            variables: {
              page: {
                reportVersion: this.report,
                host: this.host,
                path: this.path,
                name: this.name,
                title: this.title,
                screenshot: this.screenshot,
              },
              componentsToAddTo: this.componentsToAddTo,
            },
          } ).then( res => {
            this.host = this.myReport.productUrl || '';
            this.path = '';
            this.doCombineUrl();
            this.name = '';
            this.title = '';
            this.componentsToAddTo = this.componentsWithFuturePageFlags.map( comp => comp._id );
            this.screenshot = '';
            this.$refs.ssImage.clear();
            this.submitting = false;

            this.outsideBase = false;
            this.actualTitle = '';
            this.isLoginPage = false;
            this.isSinglePage = false;
            this.isLoginAcknowleged = false;

            this.$emit( 'added', res.data.page );
            this.$alerts.success( 'Page added' );
          } ).catch( err => {
            this.submitting = false;

            if( err.message == 'page name is not unique' ) {
              this.$refs.name.addError( 'Page name must be unique' );
            } else {
              this.$alerts.coded( 'E076', 'F4501' ); //see notifications spreadsheet
            }
          } );
        }
      } );
    },
    getTitle( body ) {
      const title = body.match( /<title[^>]*>([^<]+)<\/title>/ )[1];
      //parse title
      const el = document.createElement( 'div' );
      el.innerHTML = title;

      return el.innerText;
    },
    isLogin( body ) {
      return /type=["']{0,1}password["']{0,1}/.test( body );
    },
    parsePage() {
      this.outsideBase = false;
      this.actualTitle = '';
      this.isLoginPage = false;
      this.isSinglePage = false;
      this.isLoginAcknowleged = false;

      if( this.host == '' ) return;

      window.fetch( `https://cors.diginclusion.com/${this.host}${this.path}` ).then( res => res.text() ).then( body => {
        this.isLoginPage = this.isLogin( body );

        this.actualTitle = this.getTitle( body );

        if( !this.isLoginPage && this.title === '' ) this.title = this.actualTitle;
      } ).catch( () => {
        this.$alerts.error( 'Something went wrong!', `Couldn't parse the page from that URL, you will need to enter some information manually.` );
      } );
    },
    acknowlegeLoginPage() {
      this.isLoginAcknowleged = true;
      if( this.title === '' ) this.title = this.actualTitle;
    },
    show() {
      this.componentsToAddTo = this.componentsWithFuturePageFlags.map( comp => comp._id );
      this.$refs.modal.show();
    },
    reset() {
      this.$refs.modal.reset();
    },
  },
  computed: {
    componentsWithFuturePageFlags() {
      const result = [];
      if( this.components ) {
        this.components.forEach( component => {
          if ( component.futurePagesAdded ) {
            result.push( component );
          }
        } );
      }

      return result;
    },
  },
  props: {
    report: {
      type: String,
      required: true,
    },
    components: {
      required: false,
    },
  },
  components: {
    FormInput,
    FormImage,
    Notice,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .get-title {
    width: auto;
    position: absolute;
    right: 20px;
  }

  .PageModal {
    &_ComponentNotice {
      margin: 18px 0 !important;

      &_Title {
        margin: 0;
      }

      ul {
        list-style: none;
        padding: 0;
      }
    }
  }
</style>
