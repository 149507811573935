<template>
  <Modal
    v-show="!hideModal"
    ref="modal"
    :title="$gettext('Add existing issue')"
    :subtitle="$gettext('Select existing issue(s) relevant to this check:')"

    :showActions="true"
    :noSubmit="true"
    
    @close="reset()"
    class="AddExisting">
    <ul class="AddExisting_Items" v-if="items.length">
      <li v-for="item in items" :key="item.text" :class="['AddExisting_Items_Item', expanded.indexOf( item.identifier )>=0?'_expanded':'']">
        <dl class="AddExisting_Items_Item_Overview">
          <dt>Issue:</dt>
          <dd>{{ item.identifier }}</dd>

          <dt>From:</dt>
          <dd class="AddExisting_Items_Item_Overview_Pages" :title="`${item.page.name}${item.others.length?' and more':''}`">
            <span>{{ item.page.name }}</span>
            <span v-if="item.others.length"> and {{ item.others.length }} more</span>
          </dd>
        </dl>
        <div class="AddExisting_Items_Item_Actions">
          <Button size="micro" type="transparent" :aria-label="`Add ${item.instance}`" @click="expandItem( item.identifier )" :aria-expanded="expanded.indexOf( item.identifier )>=0?'true':'false'">
            <template v-if="expanded.indexOf( item.identifier )<0">Show Details</template>
            <template v-else>Hide Details</template>
          </Button>
          <Button size="micro" type="secondary" :aria-label="`Add ${item.instance}`" @click="addItem( item.identifier )">Add</Button>
        </div>
        <div class="AddExisting_Items_Item_Details" v-if="expanded.indexOf( item.identifier ) >= 0">
          <dl class="AddExisting_Items_Item_Details_Inner">
            <div class="AddExisting_Items_Item_Details_Inner_Row">
              <dt>Instance</dt>
              <dd><router-link :to="`/issue/${item.identifier}`" target="_blank">{{ item.identifier }}</router-link></dd>
            </div>
            <div class="AddExisting_Items_Item_Details_Inner_Row">
              <dt>Issue Template</dt>
              <dd>{{ item.template.identifier }}: {{ item.template.title }}</dd>
            </div>
            <div class="AddExisting_Items_Item_Details_Inner_Row">
              <dt>Reason</dt>
              <dd>{{ item.reason }}</dd>
            </div>
            <div class="AddExisting_Items_Item_Details_Inner_Row">
              <dt>Solution</dt>
              <dd>
                <template v-if="item.solutions.length">
                  <ul>
                    <li v-for="solution of item.solutions" :key="solution._id">
                      {{ solution.identifier }}: {{ solution.title }}
                    </li>
                  </ul>
                </template>
                <template v-else>None given</template>
              </dd>
            </div>
          </dl>
        </div>
      </li>
    </ul>
    <div class="AddExisting_Empty" v-else>
      <Icon class="AddExisting_Empty_Icon" type="solid" icon="question" />
      <div class="AddExisting_Empty_Text">
        <p class="AddExisting_Empty_Text_First">None</p>
        <p class="AddExisting_Empty_Text_Second">You have not created any issues elsewhere that apply to this check</p>
      </div>
    </div>
  </Modal>
</template>

<script setup>
  import { ref, inject } from 'vue';
  import { useLazyQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  const modal = ref( null );
  const row = ref( null );
  const currentColumn = ref( null );
  const items = ref( [] );

  const hideModal = ref( false );
  const enabled = ref( false );

  const confirm = inject( 'confirm' );

  const expanded = ref( [] );

  const { load, refetch, onResult } = useLazyQuery( gql`
    query CheckItemsFromOtherPages($row: ObjectID!, $currentColumn: ObjectID!) {
      items: CheckItemsFromOtherPages(row: $row, currentColumn: $currentColumn) {
        _id
        identifier
        reason
        template {
          _id
          identifier
          title
        }
        page {
          _id
          name
        }
        others {
          _id
          page {
            _id
          }
        }
        solutions {
          _id
          identifier
          title
        }
      }
    }
  `,
  {
    row,
    currentColumn,
  },
  {
    fetchPolicy: 'no-cache',
    enabled,
  } );

  onResult( ( { data } ) => {
    items.value = data.items;
  } );

  const show = ( sRow, sCurrentColumn ) => {
    row.value = sRow;
    currentColumn.value = sCurrentColumn;
    enabled.value = true;
    // eslint-disable-next-line no-unused-expressions
    load() || refetch();
    hideModal.value = false;
    modal.value.show();
  };

  const hide = () => {
    hideModal.value = true;
  };

  const reset = () => {
    enabled.value = false;
    items.value = [];
    row.value = null;
    currentColumn.value = null;
    modal.value.reset();
  };

  const emit = defineEmits( [ 'addItem' ] );

  const addItem = identifier => {
    confirm.simple().then( result => {
      if( result ) {
        emit( 'addItem', identifier );
        reset();
      }
    } );
  };

  const expandItem = identifier => {
    if( expanded.value.indexOf( identifier ) >= 0 ) {
      expanded.value.splice( expanded.value.indexOf( identifier ), 1 );
    } else {
      expanded.value.push( identifier );
    }
  };

  defineExpose( {
    show,
    reset,
  } );
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

  .AddExisting {
    &_Items {
      list-style: none;
      margin: 0;
      padding: 0;
      &_Item {
        // padding: 8px;
        border-left: 5px solid $hugr-colours-grey;
        background: lighten( $hugr-colours-grey, 13% );
        margin: 16px 0;
        position: relative;

        &._expanded {
          border: 2px solid $hugr-colours-grey;
          border-left: 5px solid $hugr-colours-grey;
        }

        &_Overview {
          padding: 8px;
          font-size: 0.9em;
          display: inline-block;
          dt {
            margin-left: 16px;
            font-weight: bold;
            display: inline-block;
            vertical-align: middle;
          }
          dd {
            display: inline-block;
            vertical-align: middle;
            margin-left: 8px;
          }

          &_Pages {
            max-width: 216px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        &_Actions {
          // padding: 8px;
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 16px;
          top: 16px;

          button {
            margin-left: 8px;
          }
        }

        &_Details {
          background: #FFF;
          width: 100%;
          padding: 8px;
          box-sizing: border-box;

          &_Inner {
            border: 1px solid $hugr-colours-grey;
            margin: 0;

            &_Row {
              border-bottom: 1px solid $hugr-colours-grey;
              font-size: 0.9em;
              &:last-child {
                border-bottom: none;
              }
              dt {
                // border-right: 2px solid;
                display:inline-block;
                vertical-align: middle;
                width: 120px;
                padding: 4px;
              }
              dd {
                border-left: 1px solid $hugr-colours-grey;
                display:inline-block;
                vertical-align: middle;
                padding: 4px;
                max-width: calc( 100% - 140px );
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    &_Empty {
      &_Icon {
        font-size: 3em;
        color: darken( $hugr-colours-grey, 40% );
      }
      &_Text {
        display: inline-block;
        margin-left: 16px;
        &_First {
          font-size: 1.3em;
          margin: 0;
        }
        &_Second {
          color: darken( $hugr-colours-grey, 40% );
          margin: 0;
        }
      }
    }
  }

  ._darkMode .AddExisting {
    &_Items {
      &_Item {
        background: darken( $hugr-colours-primary , 10%);
      }
    }
  }
</style>
