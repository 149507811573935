<template>
  <Modal
    ref="modal"
    :title="$gettext('Change issue template')"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="save"

    @close="reset()">
    <AriaSearchSelect ref="issueTemplateSelect" :label="$gettext('Issue Template')" :dropdown="true" :gqlOptions="['ISSUETEMPLATES_BY_REPORT', report]" @selected="setIssueTemplate" :validation="['not-empty']"/>
    <FormInput idRoot="issue_" :label="$gettext('Instance Identifier')" v-model="identifier" disabled/>

    <Notice size="micro">{{$gettext('All issue details will be copied to a new issue template, the existing issue will be marked as closed.')}}</Notice>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';

import FormInput from '@/components/Form/Input';

export default {
  name: 'MutateIssueModal',
  data() {
    return {
      identifier: '',
      issueTemplate: '',
      submitting: false,
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    reset() {
      this.identifier = '';
      this.$refs.modal.reset();
    },
    setIssueTemplate( issueTemplate, display ) {
      this.$refs.issueTemplateSelect.select( issueTemplate, display );
      this.issueTemplate = issueTemplate;

      this.$apollo.query( {
        query: gql`
          query newInstanceIdentifier($rv: ObjectID!, $tmp: ObjectID!) {
            identifier: newInstanceIdentifier(reportVersion: $rv, template: $tmp)
          }
        `,
        variables: {
          rv: this.report,
          tmp: issueTemplate,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.identifier = res.data.identifier;
      } ).catch( error => {
        this.$alerts.coded( 'E072', 'F4401', '', error ); //see notifications spreadsheet
      } );
    },
    save() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation mutateIssue($issue: ObjectID!, $template: ObjectID!, $identifier: String!) {
            instance: mutateIssue(issue: $issue, template: $template, identifier: $identifier) {
              _id,
              identifier
            }
          }
        `,
        variables: {
          issue: this.id,
          template: this.issueTemplate,
          identifier: this.identifier,
        },
      } ).then( res => {
        this.submitting = false;
        this.reset();
        this.$router.push( `/issue/${res.data.instance.identifier}` );
      } ).catch( () => {
        this.submitting = false;
        this.$alerts.coded( 'E075', 'F4402' ); //see notifications spreadsheet
      } );
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    report: {
      type: String,
      required: true,
    },
  },
  components: {
    AriaSearchSelect,
    FormInput,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
