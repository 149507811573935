<template>
  <Modal
    ref="modal"
    :title="$gettext('New Issue Template')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Issue Template..."

    :submitText="$gettext('Add issue template')"
    @submit="submit"

    @close="reset()">
    <FormInput idRoot="template_" :label="$gettext('Identifier')" v-model="identifier"/>
    <FormInput idRoot="template_" :label="$gettext('Title')" v-model="title"/>
    <FormTextArea ref="description" idRoot="template_" :label="$gettext('Description')" v-model="description"/>
    <FormInput idRoot="template_" :label="$gettext('Metadata tags')" :hintText='$gettext(`Comma separated e.g: "image,input,custom"`)' v-model="tags"/>
    <FormInput idRoot="template_" :label="$gettext('Section')" v-model="section" :hintText="$gettext('Section : Subsection')"/>
    <FormSelect idRoot="template_" :label="$gettext('Severity')" v-model="severity" :options="{
      0: $gettext('Advisory'),
      1: $gettext('Low'),
      2: $gettext('Medium'),
      3: $gettext('High'),
      4: $gettext('Critical')
      }" />
    <FormCheckboxSet idRoot="template" :label="$gettext('Affected users')" v-model="affected" :options="{
        'blind': $gettext('Blind'),
        'low-vision': $gettext('Low Vision'),
        'cognitively-impaired': $gettext('Cognitively Impaired'),
        'deaf': $gettext('Deaf'),
        'motor-impaired': $gettext('Motor Impaired')
      }" />
    <FormTextArea ref="impact" idRoot="template_" :label="$gettext('Impact')" v-model="impact"/>

    <ul v-if="criteria.length" aria-live="polite">
      <li v-for="criterion in criteria" v-bind:key="criterion.identifier">
        {{criterion.criterion}} {{criterion.title}} ({{$gettext('Level')}} {{criterion.level}}) [{{criterion.identifier}}]
        &nbsp;
        <button @click.prevent="rmCriterion(criterion)">{{$gettext('Remove')}}</button>
      </li>
    </ul>
    <p v-else v-translate>No criteria, add one</p>
    <AriaSearchSelect :label="$gettext('Add a criterion')" :dropdown="true" idRoot="criteria" :gqlOptions="['CRITERIA']" @selected="addCriterion"/>

    <ul v-if="solutions.length" aria-live="polite">
      <li v-for="solution in solutions" v-bind:key="solution.identifier">
        {{solution.identifier}}: {{solution.title}}
        &nbsp;
        <button @click.prevent="rmSolution(solution)">{{$gettext('Remove')}}</button>
      </li>
    </ul>
    <p v-else v-translate>No solutions, add one</p>
    <AriaSearchSelect :label="$gettext('Add a solution')" :dropdown="true" idRoot="solution" :gqlOptions="['SOLUTIONS']" @selected="addSolution"/>

    <fieldset>
      <legend>{{$gettext('Reason Templates')}}</legend>
      <div v-for="(reasonTemplate, i) in reasonTemplates" v-bind:key="'reasonTemplate-' + i">
        <textarea v-model="reasonTemplates[i]"></textarea>
        <button @click.prevent="reasonTemplates.splice(i, 1)">{{$gettext('remove')}}</button>
      </div>
      <button @click.prevent="reasonTemplates.push('')" type="button">{{$gettext('Add one')}}</button>
    </fieldset>

    <fieldset>
      <legend v-translate>For report type</legend>
      <div>
        <input type="checkbox" v-model="reportTypes" value="default" id="default"/>
        <label for="default">Accessibility - Web WCAG 2.1 AA</label>
      </div>
      <div>
        <input type="checkbox" v-model="reportTypes" value="aaa" id="aaa"/>
        <label for="aaa">Accessibility - Web WCAG 2.1 AAA</label>
      </div>
      <div>
        <input type="checkbox" v-model="reportTypes" value="mobios" id="mobios"/>
        <label for="mobios">Accessibility - Mobile - iOS</label>
      </div>
      <div>
        <input type="checkbox" v-model="reportTypes" value="mobandroid" id="mobandroid"/>
        <label for="mobandroid">Accessibility - Mobile - Android</label>
      </div>
      <div>
        <input type="checkbox" v-model="reportTypes" value="mobweb" id="mobweb"/>
        <label for="mobweb">Accessibility - Mobile - Web</label>
      </div>
      <div>
        <input type="checkbox" v-model="reportTypes" value="mobmixed" id="mobmixed"/>
        <label for="mobmixed">Accessibility - Mobile - Mixed</label>
      </div>

      <div>
        <input type="checkbox" v-model="reportTypes" value="uxdesktop" id="uxdesktop"/>
        <label for="uxdesktop">UX - Desktop</label>
      </div>
      <div>
        <input type="checkbox" v-model="reportTypes" value="uxmob" id="uxmob"/>
        <label for="uxdesktop">UX - Mobile</label>
      </div>

      <div>
        <input type="checkbox" v-model="reportTypes" value="other" id="other"/>
        <label for="other">Other</label>
      </div>
    </fieldset>

    <FormSelect idRoot="report_" :label="$gettext('Team')" v-model="team" :options="{
        '0': $gettext('Just me'),
        ...teams
      }"/>

    <div>
      <input type="checkbox" id="private" v-model="priv"><label for="private">{{$gettext('Private')}}</label>
    </div>
    <div>
      <input type="checkbox" id="locked" v-model="locked"><label for="locked">{{$gettext('Locked')}}</label>
    </div>
    <div>
      <input type="checkbox" id="retired" v-model="retired"><label for="retired">{{$gettext('Retired')}}</label>
    </div>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';
import FormImage from '@/components/Form/Image';
import FormTextArea from '@/components/Form/TextArea';
import FormCheckboxSet from '@/components/Form/CheckboxSet';

export default {
  name: 'IssueTemplateModal',
  data() {
    return {
      identifier: '',
      title: '',
      description: '',
      section: '',
      severity: 0,
      affected: [],
      impact: '',
      extra: '',
      tags: '',
      criteria: [],
      solutions: [],
      reasonTemplates: [],

      reportTypes: [],

      team: '0',
      priv: true,
      locked: false,
      retired: false,

      submitting: false,
    };
  },
  apollo: {
    teams: {
      query: gql`
        {
          teams: Teams {
            _id
            name
          }
        }
      `,
      update: data => {
        const opts = {};
        for( const team of data.teams ) {
          opts[team._id] = team.name;
        }

        return opts;
      },
    },
  },
  methods: {
    submit() {
      //inititial checks

      if( this.user.aclevel >= 2 && this.identifier.indexOf( 'DIG' ) == 0 ) { //can't start with DIG
        this.$alerts.error( 'Reserved identifier', `Sorry, we need identifiers beginning with 'DIG', these are for our templates` );
      } else if( this.user.aclevel >= 2 && this.identifier.indexOf( 'MOB' ) == 0 ) { //can't start with MOB
        this.$alerts.error( 'Reserved identifier', `Sorry, we need identifiers beginning with 'MOB', these are for our templates` );
      } else {
        this.submitting = true;
        this.$apollo.mutate( {
          mutation: gql`
            mutation addIssueTemplate($template: IssueTemplateInput!) {
              template: addIssueTemplate(template: $template) {
                _id
                identifier
              }
            }
          `,
          variables: {
            template: {
              identifier: this.identifier,
              title: this.title,
              description: this.description,
              section: this.section,
              severity: parseInt( this.severity ),
              affected: this.affected,
              impact: this.impact,
              extra: this.extra,
              metadata: this.tags.split( ',' ).map( t => t.trim() ),
              criteria: this.criteria.map( criterion => criterion._id ),
              solutions: this.solutions.map( solution => solution._id ),
              reasonTemplates: this.reasonTemplates,

              reportTypes: this.reportTypes,

              team: this.team == 0 ? null : this.team,
              private: this.priv,
              locked: this.locked,
              retired: this.retired,
            },
          },
        } ).then( res => {
          this.submitting = false;
          this.$emit( 'success', res.data.template._id );
          this.$alerts.success( 'Issue template created!', `The issue template ${res.data.template.identifier} has been created successfully` );
          this.reset();
        } ).catch( error => {
          this.submitting = false;
          this.$alerts.coded( 'E074', 'F4301', '', error ); //see notifications spreadsheet
        } );
      }
    },
    addCriterion( criterion ) {
      this.$apollo.query( {
        query: gql`
          query Criterion($id: ObjectID!) {
            criterion: Criterion(id: $id) {
              _id,
              criterion,
              title,
              level,
              identifier
            }
          }
        `,
        variables: {
          id: criterion,
        },
      } ).then( res => {
        this.criteria.push( res.data.criterion );
      } ).catch( () => {
        this.$alerts.coded( 'E057', 'F4302' ); //see notifications spreadsheet
      } );
    },
    rmCriterion( criterion ) {
      this.criteria.remove( criterion );
    },

    addSolution( solution ) {
      this.$apollo.query( {
        query: gql`
          query Solution($id: ObjectID!) {
            solution: Solution(id: $id) {
              _id,
              identifier,
              title
            }
          }
        `,
        variables: {
          id: solution,
        },
      } ).then( res => {
        this.solutions.push( res.data.solution );
      } ).catch( () => {
        this.$alerts.coded( 'E058', 'F4303' ); //see notifications spreadsheet
      } );
    },
    rmSolution( solution ) {
      this.solutions.remove( solution );
    },

    show() {
      this.$refs.modal.show();
    },
    reset() {
      this.identifier = '';
      this.title = '';
      this.description = '';
      this.section = '';
      this.severity = 0;
      this.affected = [];
      this.impact = '';
      this.extra = '';
      this.tags = '';
      this.criteria = [];
      this.solutions = [];
      this.reasonTemplates = [];

      this.team = 0;
      this.priv = true;
      this.locked = false;
      this.retired = false;

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  props: {
    success: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    FormInput,
    FormSelect,
    FormTextArea,
    FormCheckboxSet,
    AriaSearchSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.ButtonSet_Inner {
  button {
    margin: 0 0.5rem;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
