<template>
  <Modal
    ref="modal"
    :title="$gettext('Reset Password')"

    :showActions="true"

    :loading="submitting"
    loadingText="Requesting change..."

    :submitText="$gettext('Reset')"
    @submit="submit"

    @close="reset()">
    <FormInput idRoot="resetpwd_" :label="$gettext('Current Password')" v-model="currentPassword" type="password"/>
    <FormInput idRoot="resetpwd_" :label="$gettext('New Password')" v-model="newPassword" type="password"/>
    <FormInput idRoot="resetpwd_" :label="$gettext('Confirm Password')" v-model="confPassword" ref="confirmation" type="password"/>
  </Modal>
</template>

<script>
  export default {
    name: "PasswordResetModal",
  };
</script>

<script setup>
  import { ref, inject, defineExpose } from 'vue';
  import { useMutation } from '@vue/apollo-composable';
  import gql from 'graphql-tag';
  import md5 from 'md5';

  import FormInput from '@/components/Form/Input';

  const alerts = inject( 'alerts' );

  const modal = ref( null );
  const confirmation = ref( null );

  const currentPassword = ref( '' );
  const newPassword = ref( '' );
  const confPassword = ref( '' );

  const submitting = ref( false );

  const show = () => {
    modal.value.show();
  };

  const reset = () => {
    confirmation.value.reset();
    currentPassword.value = '';
    newPassword.value = '';
    confPassword.value = '';

    modal.value.reset();
  };

  const { mutate: resetPassword, onError, onDone } = useMutation( gql`
    mutation resetPassword($current: String!, $new: String!) {
      done: resetPassword(current: $current, new: $new)
    }
  ` );

  onDone( result => {
    submitting.value = false;
    if( result.data.done ) {
      alerts.success( 'Password changed!', `Your password has been changed successfully` );
      reset();
    } else {
      alerts.error( 'Password not changed', `Please check your current password and try again` );
    }
  } );

  onError( () => {
    alerts.coded( 'E035', 'FTODO' ); //XXX
  } );

  const submit = () => {
    submitting.value = true;
    confirmation.value.reset();
    if( newPassword.value !== confPassword.value ) {
      confirmation.value.addError( `passwords don't match` );
      confirmation.value.focus();
      
      return;
    }

    resetPassword( {
      current: md5( `${currentPassword.value}:alienFORGERY` ),
      new: md5( `${newPassword.value}:alienFORGERY` ),
    } );
  };

  defineExpose( {
    show,
    reset,
  } );
</script>

<style lang="scss" scoped>

</style>
