<template>
  <Modal
    ref="modal"
    :title="$gettext('New Row')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Row..."

    :submitText="$gettext('Add row')"
    @submit="submitRow"

    @close="reset()">
    <FormInput idRoot="row_" :label="$gettext('Section')" v-model="section" />
    <FormInput idRoot="row_" :label="$gettext('Reference')" v-model="ref" />
    <FormInput idRoot="row_" :label="$gettext('Header')" v-model="header" />
    <FormTextArea idRoot="row_" label="Details" v-model="details"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';

export default {
  name: 'SpreadsheetTemplateRowModal',
  data() {
    return {
      section: '',
      ref: '',
      header: '',
      details: '',
      submitting: false,
    };
  },
  methods: {
    submitRow() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`mutation addSpreadsheetTemplateRow($row: SpreadsheetTemplateRowInput!) {
          row: addSpreadsheetTemplateRow(row: $row) {
            _id
          }
        }`,
        variables: {
          row: {
            template: this.template,
            section: this.section,
            ref: this.ref,
            header: this.header,
            details: this.details,
          },
        },
      } ).then( res => {
        this.submitting = false;
        this.header = '';
        this.details = '';
        this.$emit( 'success', res.data.row._id );
      } ).catch( error => {
        this.submitting = false;
        this.$alerts.generic( error );
      } );
    },

    show() {
      this.$refs.modal.show();
    },
    reset() {
      this.section = '';
      this.ref = '';
      this.header = '';
      this.details = '';

      this.$refs.modal.reset();
    },
  },
  props: {
    template: {
      type: String,
      required: true,
    },
  },
  components: {
    FormInput,
    FormTextArea,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .get-title {
    width: auto;
    float: right;
  }
</style>
