<template>
  <div class="PageSizeContainer">
      <FormSelect class="PageSizeContainer_Select" v-if="parseInt(user.aclevel)==0 || hasPermission('QualityOfLife','ExtendedTablePageSize')" idRoot="pagesize_" :inline="true" :bold="true" :label="$gettext('Show') + ':'" v-model="chosen" :options="adminChoices" @change="updateChosen()" />
      <FormSelect class="PageSizeContainer_Select" v-else idRoot="pagesize_" :inline="true" :bold="true" :label="$gettext('Show') + ':'" v-model="chosen" :options="userChoices" @change="updateChosen()" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FormSelect from '@/components/Form/Select';
import gql from 'graphql-tag';

export default {
  name: 'UIDataTablePageSize',
  data() {
    return {
      chosen: this.pageSize,
      userChoices: { 10: 10, 25: 25, 50: 50 },
      adminChoices: { 10: 10, 25: 25, 50: 50, 75: 75, 100: 100, 200: 200, 500: 500, 1000: 1000 },
    };
  },
  mounted() {
    if( localStorage.getItem( `TablePageSize_${this.tableName}` ) ) this.selectPageSize( localStorage.getItem( `TablePageSize_${this.tableName}` ) );
    if( this.getSettingValue( `TablePageSize_${this.tableName}` ) ) {
      const pageSizeValue = this.getSettingValue( `TablePageSize_${this.tableName}` );
      this.selectPageSize( pageSizeValue );
      localStorage.setItem( localStorage.getItem( `TablePageSize_${this.tableName}` ), pageSizeValue );
    }
  },
  props: {
    pageSize: {
      type: Number,
      default: 25,
    },
    tableName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission', 'getSettingValue' ] ),
  },
  methods: {
    ...mapActions( [ 'setSettings' ] ),
    updateChosen() {
      this.saveChoice();
      this.$emit( 'selected', parseInt( this.chosen ) );
    },
    resetStoreSettings() {
      this.$apollo.query( {
        query: gql`
          query GetUserSettings($id: ObjectID!) {
            settings: GetUserSettings(id: $id){
              key,
              value
            }
          }`,
          variables: {
            id: this.user.id,
          },
        fetchPolicy: 'no-cache',
      },
      ).then( data => {
        const { settings } = data.data;
        this.setSettings( settings );
      } );
    },
    saveChoice() {
      if( this.tableName != '' ) {
        localStorage.setItem( `TablePageSize_${this.tableName}`, this.chosen );
        this.$apollo.mutate( {
          mutation: gql`mutation SetUserSetting($setUserSettingId: ObjectID!, $key: String!, $value: String!) {
              result: setUserSetting(id: $setUserSettingId, key: $key, value: $value)
          }
          `,
          variables: {
            setUserSettingId: this.user.id,
            key: `TablePageSize_${this.tableName}`,
            value: this.chosen,
          },
          fetchPolicy: 'no-cache',
        } ).then( () => {
          this.resetStoreSettings();
        } ).catch( () => {
          this.$alerts.coded( "E085","F5001" );
        } );
      }
    },
    selectPageSize( size ) {
      this.chosen = size;
      this.$emit( 'selected', parseInt( size ) );
    },
  },
  components: {
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  @import '@/assets/styles/variables/_fonts.scss';
  @import '@/assets/styles/variables/_colours.scss';

  .PageSizeContainer {
    position: relative;
    padding: 8px;
    max-width: 200px;
    margin-top: -20px;
  }

</style>
