<template>
  <div class="LightBoxBack" v-if="expanded" @click="toggleLightbox()"></div>
  <div :class="['Screenshot', expanded?'_expanded':'']" @click="toggleLightbox()">
    <AuthImg ref="img"
      :class="['Screenshot_Img', lightbox?'_lightbox':'', expanded?'_expanded':'']"
      :key="sID" :src="`${$hugrConfig.assetsUrl}/screenshot/${sID}`"
      :alt="renderAlt"
      :tabindex="lightbox?0:-1"
      @click.prevent.stop="toggleLightbox()"
      @keyup.enter="toggleLightbox()"
      @keyup.space="toggleLightbox()"
      @keyup.esc="toggleLightbox()" />
  </div>
</template>

<script>
import AuthImg from './AuthImg.vue';

export default {
  name: 'Screenshot',
  data() {
    return {
      value: '',
      options: false,
      expanded: false,
      showing: false,
    };
  },
  methods: {
    toggleLightbox() {
      if( this.lightbox ) this.expanded = !this.expanded;
    },
  },
  computed: {
    renderAlt() {
      if( this.lightbox && this.expanded ) return `${this.alt} (Click to close)`;
      if( this.lightbox && !this.expanded ) return `${this.alt} (Click to expand)`;

      return this.alt;
    },
  },
  props: {
    sID: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: 'Screenshot',
    },
    lightbox: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AuthImg,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .Screenshot {
    width: 100%;
    height: 100%;
    &._expanded {
      background: white;
      width: 86vw;
      height: 86vh;
      position: fixed;
      left: 6vw;
      top: 5vh;
      padding: 32px;
      z-index: 9999999999999;
    }
    &_Img {
      max-width: 100%;
      max-height: 100%;
      &._preload {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;
      }

      &._lightbox {
        cursor: pointer;
      }

      &._expanded {
        width: unset;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .LightBoxBack {
    position: fixed;
    left: -500px;
    top: -500px;
    background: black;
    opacity: 0.8;
    width: 200vw;
    height: 200vh;
    z-index: 999999999999;
  }
</style>
