<template>
  <table :class="[forceLight?'_forceLight':'', 'Table']">
    <slot></slot>
  </table>
</template>

<script>
export default {
  name: 'UITable',
  props: {
    forceLight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/styles/variables/_colours.scss';
  .Table {
    width: 100%;
    border: 1px solid #b3b3b3;
    background: #f6f8fa;
    border-radius: 2px;
    margin: 10px 0;
    border-collapse: collapse;
    max-width: 900px;
    tr, th, td {
      border: 1px solid #b3b3b3;
    }
    th, td {
      padding: 10px;
    }
    img {
      width: 100%;
    }
  }

  ._darkMode {
    .Table {
      background: darken($hugr-colours-primary, 10%);
    }
  }

  .Table._forceLight {
    background: #FFF;
    color: #000;
  }
</style>
