<template>
  <div class="TabPanel" tabindex="-1" v-if="isOpen">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UITabPanel',
  created() {
    if( this.default == 'open' ) {
      this.isOpen = true;
    }
  },
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    open() {
      this.isOpen = true;

    },
    close() {
      this.isOpen = false;
    },
  },
  props: [ 'default' ],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  // @import '@/assets/styles/variables/_fonts.scss';
  // @import '@/assets/styles/variables/_colours.scss';

  .TabPanel {
  }
</style>
