<template>
  <div class="Loader" v-html="message" aria-live="polite"></div>
</template>

<script>
let interval;
export default {
  name: 'Loader',
  data() {
    return {
      message: this.$gettext( 'Loading...' ),
      stage: 0,
    };
  },
  mounted() {
    this.reset();
  },
  watch: {
    stage() {
      switch( this.stage ) {
        case 0: {
          this.message = this.$gettext( 'Loading...' );

          break;
        }
        case 1: {
          this.message = this.$gettext( 'Sorry, this is taking a while...' );

          break;
        }
        case 2: {
          this.message = this.$gettext( 'Sorry, we\'re still working on fetching things...' );

          break;
        }
        case 3: {
          this.message = `${this.$gettext( 'This is taking a bit too long, try refreshing the page or ' )} <a href="mailto:hello@diginclusion.com">${this.$gettext( 'send us an email' )}</a>.`;

          break;
        }
        default: {
          this.message = `${this.$gettext( 'This is taking a bit too long, try refreshing the page or ' )} <a href="mailto:hello@diginclusion.com">${this.$gettext( 'send us an email' )}</a>.`;

          break;
        }
      }
    },
  },
  methods: {
    reset() {
      this.stage = 0;
      clearInterval( interval );
      interval = setInterval( () => {
        this.stage++;
      }, 10000 );
    },
  },
  beforeUnmount() {
    clearInterval( interval );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .Loader {
   font-weight: bold;
   font-size: 1.2em;
   text-align: center;
   margin: 100px 0;
 }
</style>
