<template>
  <div class="Notifications" v-if="open">
    <div :class="['Notifications_Inner', notifications.length==0?'_empty':'']" tabindex="-1" ref="inner" v-click-away="hide" @keyup.esc="hide">
      <template v-if="notifications.length">
        <h4 class="Notifications_Inner_Title" v-translate>Latest Notifications</h4>
        <Button type="transparent" size="micro" class="Notifications_Inner_MarkRead" @click="markAllRead">{{$gettext('Mark all as read')}}</Button>
        <ClearList class="Notifications_Inner_List">
          <li v-for="notification in notifications" :key="notification.timestamp">
            <a :href="`/#${notification.link}`" :class="['Notifications_Inner_List_Item', notification.read?'_read':'_unread']" @click="(e) => { dismiss(notification._id, notification.link, e); }">
              <h5 :class="'a11yhide'">
                {{ notification.title.replace("/namesplit/", " ") }}
              </h5>
              <span :class="['Notifications_Inner_List_Item_Indicator', notification.read?'_read':'_unread']" :aria-label="notification.read?$gettext('( Read, mark as unread )'):$gettext('( Unread, mark as read )')" role="button" tabindex="0" @click.prevent="toggleRead(notification)" @keyup.space.prevent="toggleRead(notification)" @keyup.enter.prevent="toggleRead(notification)"></span>
              <div :class="'Notification_Head'">
                <div :class="'Notification_Head_Left'" :aria-hidden="true">
                  <p :class="'Notification_Head_Left_Bold'">
                    {{ notification.title.split("/namesplit/")[0] }}
                  </p>
                  <p :class="'Notification_Head_Left_Text'" v-if="notification.title.split('/namesplit/')[1]">
                    {{ notification.title.split("/namesplit/")[1] }}
                  </p>
                </div>
                <div :class="'Notification_Head_Bold'">
                  <time :datetime="notification.timestamp" class="Notifications_Inner_List_Item_Timestamp">{{moment(notification.timestamp).fromNow()}}</time>
                </div>
              </div>
              <div :class="'Notification_Body'">
                <p v-html="notification.body"></p>
              </div>
            </a>
          </li>
        </ClearList>
        <LinkButton class="Notifications_Inner_AllButton" type="transparent" size="small" :to="`/notifications`">{{$gettext('All Notifications')}}</LinkButton>
      </template>
      <p v-else class="Notifications_Inner_Empty" v-translate>Nothing here!</p>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import moment from 'moment';

export default {
  name: 'Notifications',
  data() {
    return {
      open: false,

      notifications: [],
    };
  },
  apollo: {
    notifications: {
      query: gql`
        query ($limit: Int) {
          notifications: Notifications(limit: $limit) {
            _id
            title
            body
            timestamp
            read
            link
          }
        }
      `,
      variables() {
        return {
          limit: 5,
        };
      },
    },
  },
  methods: {
    show() {
      this.$apollo.queries.notifications.refetch();
      this.open = true;
      setTimeout( () => {
        this.$refs.inner.focus();
        document.addEventListener( 'keyup', this.tab );
        this.$emit( 'open' );
      }, 100 );
    },
    hide() {
      this.open = false;
      document.removeEventListener( 'keyup', this.tab );
      this.$emit( 'close' );
    },
    toggle() {
      if( this.open ) {
        this.hide();
      } else {
        this.show();
      }
    },
    isOpen() {
      return this.open;
    },
    dismiss( id, to, e ) {
      e.preventDefault();
      if( !e.srcElement.classList.contains( 'Notifications_Inner_List_Item_Indicator' ) ) {
        this.$apollo.mutate( {
          mutation: gql`
            mutation ($id: ObjectID) {
              dismissNotification(id: $id)
            }
          `,
          variables: {
            id,
          },
        } ).catch( () => {
          this.$alerts.coded( 'E008', 'F201' ); //see notifications spreadsheet
        } );
        this.$router.push( to );
        this.hide();
      }
    },
    markAllRead() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation ($user: ObjectID) {
            dismissAllNotifications(user: $user)
          }
        `,
        variables: {
          user: this.user._id,
        },
      } ).then( () => {
        this.$apollo.queries.notifications.refetch();
      } ).catch( () => {
        this.$alerts.coded( 'E009', 'F202' ); //see notifications spreadsheet
      } );
    },
    tab( e ) { //IT'S A TARP
      if( e.keyCode == 9 ) {
        e.preventDefault();
        if( !this.$refs.inner.querySelectorAll( ":focus" ).length && document.activeElement != this.$refs.modalwindow ) {
          this.$refs.inner.focus();
        }
      }
    },
    toggleRead( notification ) {
      if( notification.read ) {
        this.$apollo.mutate( {
          mutation: gql`
            mutation ($id: ObjectID) {
              unDismissNotification(id: $id)
            }
          `,
          variables: {
            id: notification._id,
          },
        } ).then( () => {
          this.$apollo.queries.notifications.refetch();
        } ).catch( () => {
          this.$alerts.coded( 'E010', 'F203' ); //see notifications spreadsheet
        } );
      } else {
        this.$apollo.mutate( {
          mutation: gql`
            mutation ($id: ObjectID) {
              dismissNotification(id: $id)
            }
          `,
          variables: {
            id: notification._id,
          },
        } ).then( () => {
          this.$apollo.queries.notifications.refetch();
        } ).catch( () => {
          this.$alerts.coded( 'E008', 'F204' ); //see notifications spreadsheet
        } );
      }
    },
    moment,
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_fonts.scss';
@import '@/assets/styles/variables/_colours.scss';
@import '@/assets/styles/variables/_header.scss';
.a11yhide {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.Notification {
  &_Head {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    // font-size: 16px !important;
    font-size: 0.9em;
    margin-bottom: 8px;
    width: calc(100% - 16px);
    margin-top: -19px;

    &_Left {
      // font-size: 16px !important;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      &_Bold {
        font-weight: 600 !important;
      }
      &_Text {
        font-weight: 400 !important;
      }
    }

    &_Bold {
        font-weight: 600 !important;
    }
  }

  &_Body {
    background: lighten($dig-blue, 70%);
    padding: 8px;
    border-radius: 4px;
    width: 75%;
    font-size: 0.8em;
  }
}

.Notifications {
  z-index: 99999;
  position: absolute;
  right: 5px;
  top: 50px;

  &_Inner {
    float: right;

    width: 25%;
    min-width: 400px;

    max-height: 75%;

    background: $hugr-colours-white;
    border: 1px solid $hugr-colours-grey;
    border-radius: 5px;

    box-shadow: $hugr-header-regular-shadow;

    // &._empty {
    //   height: auto !important;
    //   min-height: auto !important;
    // }

    &_Empty {
      font-weight: bold;
      text-align: center;
      padding: 50px;
    }

    &_Title {
      padding: 0 5px;
      margin: 10px 0;
      font-weight: bold;
    }

    &_MarkRead {
      position: absolute;
      right: 5px;
      top: 7px;
    }

    &_List {
      &_Item {
        display: block;
        color: $hugr-colours-primary;
        text-decoration: none;
        padding: 5px 10px;

        border-bottom: 1px solid $hugr-colours-grey;
        // border-left: 5px solid $hugr-colours-tertiary;

        transition: background-color .5s ease-in-out 0s;

        &_Indicator {
          background: $hugr-colours-tertiary;
          display: inline-block;
          width: 8px;
          height: 8px;
          position: relative;
          right: -97%;
          z-index: 2;
          border-radius: 10px;
          border: 2px solid white;

          &:focus, &:hover {
            border: 2px solid $hugr-colours-primary;
          }

          &._read {
            background: $hugr-colours-grey;

            &:focus, &:hover {
              border: 2px solid $hugr-colours-tertiary;
            }
          }
        }

        &_Timestamp {
          font-size: 0.6em;
        }

        &:focus, &:hover {
          background: lighten($hugr-colours-grey, 10%);
        }

        h5 {
          font-weight: bold;
          margin: 0;
          position: relative;
        }
        p {
          // font-size: 16px;
          font-weight: normal;
          margin: 0;
        }
      }
    }

    &_AllButton {
      width: 100%;
    }
  }
}

._darkMode .Notifications {
  &_Inner {
    background: $hugr-colours-primary;

    &_List {
      &_Item {
        color: #FFF;

        &:focus, &:hover {
          background: darken($hugr-colours-primary, 10%);
        }
      }
    }
  }
}

._darkMode .Notification {
  &_Body {
    color: #000;
  }
}

@media (max-width: 1000px) {
  .Notifications {
    top: 66px;
    left: 0;
    right: unset;
    width: 100%;
    &_Inner {
      float: unset;
      width: calc( 100% - 2px);
      min-width: unset;
      height: calc( 100vh - 68px);
      overflow: scroll;
    }
  }
}
</style>
