<template>
  <span v-if="format==''">{{renderTimestamp}}</span>
  <span v-if="format=='fromNow'" :title="renderTimestamp">{{renderFromNow}}</span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DateTime',
  computed: {
    renderTimestamp() {
      return new Date( this.timestamp ).toLocaleString();
    },
    renderFromNow() {
      return moment( this.timestamp ).fromNow();
    },
  },
  methods: {
    moment,
  },
  props: {
    timestamp: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: '',
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
