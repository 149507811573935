<template>
  <Modal
    ref="modal"
    :title="$gettext('Add a contact')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Contact..."

    :submitText="$gettext('Add contact')"
    @submit="submitMutation"
    
    @close="reset()"
    @keydown.stop>
    <FormInput ref="name" idRoot="contact_" :label="$gettext('Name')" v-model="name" :validation="['not-empty']"/>
    <FormInput ref="email" idRoot="contact_" :label="$gettext('Email')" v-model="email" :validation="['not-empty', 'email']"/>
    <FormInput idRoot="contact_" :label="$gettext('Phone number')" v-model="phoneNumber" />

    <FormSelect v-show="hasPermission('SetUserPermissionGroup', 'Any') || ( hasPermission('SetUserPermissionGroup', 'WithinGroupCategories') && Object.keys(niceList).length > 0 )" :key="'permission-key'" idRoot="permissiongroup_" :label="$gettext('Permission Group')" v-model="selectedGroup" :options="niceList" hintText="This will become their permission group if an account is made using this email."/>
    <FormTextArea idRoot="contact_" :label="$gettext('Public Notes')" v-model="notes" />
  </Modal>
</template>

<script>

import { mapGetters, mapState } from 'vuex';

import gql from 'graphql-tag';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import FormSelect from '@/components/Form/Select';

export default {
  name: 'ContactModal',
  data() {
    return {
      email: '',
      name: '',
      phoneNumber: '',
      notes: '',
      role: '',
      permissionGroups: false,
      selectedGroup: false,
      submitting: false,
    };
  },
  apollo: {
    permissionGroups: {
      query: gql`
        query permissionGroups {
          permissionGroups: PermissionGroups {
            _id,
            name,
            categories {
              category {
                _id
              },
              hierarchy
            }
          }
        }
      `,
    },
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  methods: {
    submitMutation() {
      this.$hugrvalidate( [ 'email', 'name' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation ($team: ObjectID!, $contact: ContactInput!) {
                addContact(team: $team, contact: $contact) {
                  _id
                }
              }
            `,
            variables: {
              team: this.team._id,
              contact: {
                email: this.email,
                name: this.name,
                phoneNumber: this.phoneNumber,
                notes: this.notes,
                role: this.role,
                permissionGroup: this.selectedGroup != false ? this.selectedGroup : null,
              },
            },
          } ).then( res => {
            this.submitting = false;
            if ( res.data.addContact._id ) {
              this.reset();
              this.$alerts.success( 'Contact added!', `Contact has been added to team.` );
              this.$emit( 'success', res.data.addContact._id );
            }
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.coded( 'E049', 'F2601', '', error ); //see notifications spreadsheet
          } );
        }
      } );
    },
    show( name = '' ) {
      this.name = name;
      this.$refs.modal.show();
    },
    reset() {
      this.name = '';
      this.email = '';
      this.phoneNumber = '';
      this.notes = '';
      this.selectedGroup = false;

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission' ] ),
    niceList() {
      const list = {};
      this.permissionGroups.forEach( group => {
        let hierarchyMatch = false;
        group.categories.forEach( pairTwo => {
          this.user.permissionGroup?.categories.forEach( pair => {
            if ( ( pair.category._id == pairTwo.category._id && pair.hierarchy > pairTwo.hierarchy ) ) {
              hierarchyMatch = true;
            }
        } );
      } );

      if ( this.hasPermission( 'SetUserPermissionGroup', 'Any' ) || ( this.hasPermission( 'SetUserPermissionGroup', 'WithinGroupCategories' ) && hierarchyMatch ) ) {
        list[group._id] = group.name;
      }

      } );
      
      return list;
    },
    chosenGroup() {
      return this.permissionGroups.filter( group => {
        return ( ( group._id == this.selectedGroup ) );
      } )[0];
    },
  },
  components: {
    FormInput,
    FormTextArea,
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
