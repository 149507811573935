<template>
  <div class="cardset">
    <div class="inner">
      <h3 class="card-head">{{ title }}</h3>
      <div class="cardSetButtons">
      <Button v-if="button" class="cardSetButton" :type="buttonProps.type" :size="buttonProps.size" :icon="buttonProps.icon" @click="buttonClick">{{buttonProps.text}}</Button>
      <Button v-if="button2" class="cardSetButton" :type="button2Props.type" :size="button2Props.size" :icon="button2Props.icon" @click="button2Click">{{button2Props.text}}</Button>
      </div>
      <div class="contents">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UICardSet',
  methods: {
    buttonClick( e ) {
      this.$emit( 'buttonClick', e );
    },
    button2Click( e ) {
      this.$emit( 'button2Click', e );
    },
  },
  computed: {
    buttonProps() {
      const { button } = this;
      if( typeof button.type == 'undefined' ) {
        button.type = 'border';
      }
      if( typeof button.size == 'undefined' ) {
        button.size = 'small';
      }
      if( typeof button.icon == 'undefined' ) {
        button.icon = [];
      }

      return button;
    },
    button2Props() {
      const button = this.button2;
      if( typeof button.type == 'undefined' ) {
        button.type = 'border';
      }
      if( typeof button.size == 'undefined' ) {
        button.size = 'small';
      }
      if( typeof button.icon == 'undefined' ) {
        button.icon = [];
      }

      return button;
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    hlevel: {
      type: [ String, Number ], //NUmber?
      default: 3, //TODO make this bit work
    },
    button: {
      type: [ Object, Boolean ],
      default: () => {
        return false;
      },
    },
    button2: {
      type: [ Object, Boolean ],
      default: () => {
        return false;
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .cardset {
    .inner {
      position: relative;
      margin-top: 60px;
      .card-head {
        margin-top: 0;
        padding-top: 8px;
      }
      .cardSetButtons {
        position: absolute;
        right: 0;
        top: 0;
      }
      .cardSetButton {
        margin-left: 5px;
      }
      .filters {
        padding: 0;
        list-style: none;
        margin: 8px 5px;
        display: inline-block;
        li {
          display: inline-block;
          margin-right: 10px;
          color: grey;
          cursor: pointer;
          span {
            &[aria-checked=true] {
              color: black;
            }
            // &:hover, &:focus {
            //   outline: default;
            // }
          }
        }
      }
      .contents {
        margin-top: 25px;
        // background: #e5ebf2;
        // border: 1px solid #dce4ee;
      }
    }
  }
</style>
