<template>
  <Fragment ref="fragment" type="page" :title="!loading&&page?page.name:'Loading'" :btnIcon="['regular', 'edit']" btnLabel="Edit Page" :btnAction="fireEditModal" :showBtn="!page||!page.reportVersion.published" @closed="reset(false)">
    <div v-if="page" class="PageFragment">
      <!-- <div class="PageFragment_Compliance">
        <div class="PageFragment_Compliance_DeleteBtn" v-if="hasPermission( 'Pages', 'CanDelete' )&&!page.reportVersion.published">
          <Button v-if="page.canRemove" type="serious" size="micro" @click="deletePage">Delete page</Button>
          <Button v-else-if="hasPermission( 'Issues', 'CanDelete' )" type="serious" size="micro" @click="removePageModal.show( page._id )">Delete page</Button>
          <template v-else>
            <Button type="serious" size="micro" disabled="true"
              @mouseenter="tooltipEl?.showTooltip()"
              @mouseleave="tooltipEl?.hideTooltip()"
              @focus="tooltipEl?.showTooltip()"
              @blur="tooltipEl?.hideTooltip()">Delete page</Button>
            <Tooltip ref="tooltipEl" text="Cannot remove page, remove all issues and this page from components and user journeys in order to delete it." />
          </template>
        </div>
        Page compliance:&nbsp;
        <CompliancePill :progress="page.progress" :isCompliant="page.isCompliant" :issues="page.issues.length"/>
      </div> -->

      <div class="PageFragment_Grid">
        <div class="PageFragment_Grid_Checks">
          <!-- view by -->
          
          <ColumnChecks v-if="page&&page.reportVersion&&page.reportVersion._id" :editable="!page.reportVersion.published" :reportVersion="page.reportVersion._id" :column="page._id" type="page" :progress="page.progress" @changed="loadProgress() || updateProgress()" @guidance="showGuidance" @goToIssue="goToIssue"/>
          <Loader v-else />
        </div>

        <div class="PageFragment_Grid_Details">
          <GroupSummary type="page"
          :itemId="page._id"
          :loading="loading"
          :offsetUp="64"
          :wcagVersion="WCAGVersion"
          :details="{
            'Page Title': page.title ? page.title : 'Untitled',
            URL: page.host || page.path ? `${page.host}${page.path}` : 'No URL',
            Created: page.created ? moment( page.created ).format( 'DD/MM/YYYY @ HH:mm' ) : 'Unknown',
            'Created By': page.user ? page.user.name : 'Unknown',
          }"

          :contextItems="[
                {
                  name: $gettext('Delete page'),
                  emit: 'deletePage',
                  icon: ['solid', 'trash'],
                  enabled: page.canRemove && hasPermission( 'Pages', 'CanDelete' ),
                },
                {
                  name: $gettext('Delete page'),
                  emit: 'deletePageComplex',
                  icon: ['solid', 'trash'],
                  enabled: !page.canRemove && hasPermission( 'Issues', 'CanDelete' ) && hasPermission( 'Pages', 'CanDelete'),
                },
          ]"

          @deletePage="deletePage"
          @deletePageComplex="removePageModal.show( page._id )"

          :screenshot="page.screenshot"
          :progress="page.progress" :isCompliant="page.isCompliant" :issues="page.issues"

          :reportVersion="page.reportVersion"

          :components="page.components"
          :userJourneys="page.userJourneys"

          @refetch="refetch"
          />
        </div>
      </div>
    </div>
    <Loader v-else />
  </Fragment>
  <EditPageModal ref="editpagemodal" @edited="refetch"/>
  <UserJourneyModal v-if="page&&page.reportVersion._id" :reportVersion="page.reportVersion._id" ref="userjourneymodal" @success="refetch" @updateJourney="triggerUpdateJourney"/>
  <EditUserJourneyModal ref="edituserjourneymodal" @success="refetch" />
  <ComponentModal v-if="page&&page.reportVersion._id" :report="page.reportVersion._id" ref="componentmodal" @added="refetch"/>
  <RemovePageModal ref="removePageModal" @doReset="doDeleteReset"/>
</template>

<script>
  export default {
    name: "PageFragment",
  };
</script>

<script setup>
  import { ref, inject, effect, computed, onMounted } from 'vue';
  import { useQuery, useLazyQuery, useMutation } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import moment from 'moment';
  import { useStore } from 'vuex';

  import EditPageModal from '@/modals/Page/Edit';
  import UserJourneyModal from '@/modals/UserJourney/Create';
  import EditUserJourneyModal from '@/modals/UserJourney/Edit';
  import ComponentModal from '@/modals/Component/Create';
  import RemovePageModal from '@/modals/Page/Remove';

  import ColumnChecks from '@/components/ColumnChecks.vue';
  import GroupSummary from '@/components/UI/GroupSummary.vue';

  const emit = defineEmits( [ 'closed', 'goToComponent', 'goToUserJourney' ] );
  const confirm = inject( 'confirm' );

  const store = useStore();
  const hasPermission = computed( () => store.getters.hasPermission );

  const editpagemodal = ref( null );
  const userjourneymodal = ref( null );
  const edituserjourneymodal = ref( null );
  const componentmodal = ref( null );
  const removePageModal = ref( null );

  const fragment = ref( null );
  const doQuery = ref( false );
  const pageId = ref( null );
  const reportVersion = ref( null );

  onMounted( () => {
    const { mutate: sendNavigation } = useMutation(
      gql`
        mutation sendNavigation ($page: String) {
          sendNavigation(page: $page)
        }
      `, {
        variables: {
          page: 'Page Fragment',
        },
    } );
    
    sendNavigation();
  } );

  const { loading, onResult, refetch } = useQuery( gql`
    query Page($id: ObjectID!) {
      page: Page(id: $id) {
        _id
        title
        name
        host
        path
        progress
        isCompliant
        canRemove
        screenshot {
          _id
        }

        user {
          _id
          name
        }

        created

        issues {
          _id
          identifier
          status
          primary

          template {
            _id
            severity
            section
            identifier
            title
          }
        }
        components {
          _id,
          identifier
          progress
          isCompliant
          issues {
            _id
          }
        }
        userJourneys {
          _id,
          title
          progress
          isCompliant
          issues {
            _id
          }
        }

        reportVersion {
          _id
          published
          version
          report {
            _id
            identifier
            template
          }
        }
      }
    }
  `,
  {
    id: pageId,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doQuery,
  } );

  const { load: loadProgress, refetch: updateProgress, onResult: pageProgressResult } = useLazyQuery( gql`
    query Page($id: ObjectID!) {
      page: Page(id: $id) {
        _id
        progress
        isCompliant
      }
    }
  `,
  {
    id: pageId,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doQuery,
  } );

  const page = ref( null );

  const fireEditModal = () => {
    editpagemodal.value.show( page.value._id );
  };

  const show = id => {
    pageId.value = id;
    doQuery.value = true;
    if( page.value && page.value._id == id ) refetch();
    onResult( pageResult => {
      page.value = pageResult.data.page;
      reportVersion.value = pageResult.data.page.reportVersion._id;
    } );
    fragment.value.show();
  };

  pageProgressResult( ( { data } ) => {
    page.value.progress = data.page.progress;
    page.value.isCompliant = data.page.isCompliant;
  } );

  const reset = ( triggerParent = true ) => {
    emit( 'closed', true );
    if( triggerParent ) fragment.value.reset();
    doQuery.value = false;
  };

  const guidanceShowing = ref( false );
  const guidanceType = ref( null );
  const guidance = ref( '' );

  const WCAGVersion = computed( () => {
    let wcag = '2.2';
    if( reportVersion.value && reportVersion.value.report ) {
      switch( reportVersion.value.report.template ) {
        case 'aaa':
        case 'default': {
          wcag = '2.1';
          
          break;
        }
        case 'wcag22aa':
        case 'wcag22aaa':
        default: {
          wcag = '2.2';

          break;
        }
      }
    }

    return wcag;
  } );

  const showGuidance = details => {
    const regex = /\[.*\]\((.*)\)/;
    const urlCheck = regex.exec( details );
    if( urlCheck && ( urlCheck[1].indexOf( 'https://docs.hugr.app/' ) == 0 || urlCheck[1].indexOf( 'https://design.homeoffice.gov.uk/' ) == 0 ) ) {
      guidanceType.value = 'iframe';
      // eslint-disable-next-line prefer-destructuring
      guidance.value = `https://cors.diginclusion.com/${urlCheck[1]}`;
    } else {
      guidanceType.value = 'markdown';
      guidance.value = details;
    }
    guidanceShowing.value = true;
  };

  const addComponent = () => {
    componentmodal.value.show();
    componentmodal.value.setAddToExisting( page.value._id );
    componentmodal.value.addPage( page.value._id );
  };

  const addUserJourney = () => {
    userjourneymodal.value.show();
    userjourneymodal.value.setAddToExisting( page.value._id, 'page' );
    userjourneymodal.value.addPage( page.value._id );
  };

  const triggerUpdateJourney = ( journey, pageToAdd ) => {
    edituserjourneymodal.value.show( journey );
    edituserjourneymodal.value.preAddPage( pageToAdd );
  };

  const { mutate: removePage } = useMutation(
    gql`
    mutation removePage($id: ObjectID!) {
      removed: removePage(id: $id)
    }
  ` );

  const doDeleteReset = () => {
    doQuery.value = false;
    reset();
  };

  const deletePage = () => {
    confirm.simple( 'This cannot be undone' ).then( result => {
      if( result ) {
        removePage( {
          id: page.value._id,
        } );
        doDeleteReset();
      }
    } );
  };

  const isShowing = () => ( fragment.value ? fragment.value.isShowing() : false );

  defineExpose( {
    show,
    reset,
    isShowing,
  } );

  const goToComponent = ( e, component ) => {
    e.preventDefault();
    reset();
    emit( 'goToComponent', component );
  };

  const goToUserJourney = ( e, journey ) => {
    e.preventDefault();
    reset();
    emit( 'goToUserJourney', journey );
  };

  const goToIssue = identifier => {
    reset();
    emit( 'goToIssue', identifier );
  };
</script>

<style lang="scss">
  @import '@/assets/styles/variables/_header.scss';
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_sizes.scss';

  .PageFragment {
    position: relative;
    height: 95%;

    &_Compliance {
      position: absolute;
      top: -56px;
      right: 0;

      &_DeleteBtn {
        display: inline-block;
        vertical-align: middle;
        margin-right: 18px;
      }
    }

    &_Grid {
      height: 100%;
      display: flex;
      gap: 16px;
      flex-wrap: 0;

      &_Checks {
        position: relative;
        height: 95%;
        width: 800px;
        flex-grow: 1;
      }

      &_Details {
        width: $groupSummary-width;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
  }

  @media (max-width: 1400px) {
    .PageFragment_Grid {
      flex-wrap: wrap-reverse;
      &_Details {
        flex-grow: 1;
      }
    }
  }
</style>
