<template>
  <Modal
    ref="modal"
    :title="$gettext(`Edit Team:`) + ` ${team.name}`"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submitTeam"

    @close="reset()"
    :ready="team">
    <label for="mfaEnabled">MFA Enabled</label>
    <input id="mfaEnabled" name="mfaEnabled" type="checkbox" v-model="team.mfaEnabled" />
    <FormInput ref="name" idRoot="team_" :label="$gettext('Domain Restriction for Team Members')" v-model="team.domainrestriction" :hintText="$gettext(`Note: You can still invite a member outside of the domain as an exception, but you will be prompted for confirmation if you do so. If they change their email and it is not to the restricted domain, they'll be kicked out.`)"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';

import FormInput from '@/components/Form/Input';

export default {
  name: 'TeamSettings',
  data() {
    return {
      team: false,
      submitting: false,
    };
  },
  methods: {
    submitTeam() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateTeamSettings($id: ObjectID!, $settings: TeamSettingsInput!) {
            team: updateTeamSettings(id: $id, settings: $settings) {
              _id,
              name
            }
          }
        `,
        variables: {
          id: this.team._id,
          settings: {
            domainrestriction: this.team.domainrestriction,
            mfaEnabled: this.team.mfaEnabled,
          },
        },
      } ).then( res => {
        this.submitting = false;
        this.reset();
        this.$alerts.success( 'Team settings updated!', `Team ${res.data.team.name} has been updated successfully` );
        this.$emit( 'success' );
      } ).catch( error => {
        this.submitting = false;
        this.$alerts.generic( error );
      } );
    },
    show( id ) {
      this.$apollo.query( {
        query: gql`
          query Team($id: ObjectID) {
            team: Team(id: $id) {
              _id,
              identifier,
              name,
              mfaEnabled,
              domainrestriction
            }
          }
        `,
        variables: {
          id,
        },
      } ).then( res => {
        this.team = { ...res.data.team };
        setTimeout( () => {
          this.$refs.modal.show();
        } );
      } );
    },
    reset() {
      this.team = false;
      this.$refs.modal.reset();
    },
  },
  props: {
  },
  components: {
    FormInput,
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
