<template>
  <Modal
    ref="modal"
    :title="$gettext('Edit Solution')"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submit"

    @close="reset()">
    <FormInput idRoot="solution_" :label="$gettext('Identifier')" v-model="solution.identifier"/>
    <FormInput idRoot="solution_" :label="$gettext('Title')" v-model="solution.title"/>

    <FormTextArea ref="overview" idRoot="solution_" :label="$gettext('Overview')" v-model="solution.overview"/>
    <FormInput ref="tags" idRoot="solution_" :label="$gettext('Metadata tags')" :hintText='$gettext(`Comma separated e.g: "image,input,custom"`)' v-model="tags"/>

    <FormTextArea ref="code" idRoot="solution_" :label="$gettext('Incorrect code example')" v-model="solution.code"/>
    <FormTextArea ref="fix" idRoot="solution_" :label="$gettext('Suggested code example')" v-model="solution.fix"/>

    <FormSelect idRoot="report_" :label="$gettext('Team')" v-model="solution.team" :options="{
        '0': $gettext('Just me'),
        ...teams
      }"/>

    <div>
      <input type="checkbox" id="private" v-model="solution.private"><label for="private">{{$gettext('Private')}}</label>
    </div>
    <div>
      <input type="checkbox" id="locked" v-model="solution.locked"><label for="locked">{{$gettext('Locked')}}</label>
    </div>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import FormSelect from '@/components/Form/Select';

export default {
  name: 'SolutionModal',
  data() {
    return {
      solution: false,
      tags: '',
      submitting: false,
    };
  },
  apollo: {
    teams: {
      query: gql`
        {
          teams: Teams {
            _id
            name
          }
        }
      `,
      update: data => {
        const opts = {};
        for( const team of data.teams ) {
          opts[team._id] = team.name;
        }

        return opts;
      },
    },
  },
  methods: {
    submit() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateSolution($id: ObjectID!, $solution: SolutionInput!) {
            solution: updateSolution(id: $id, solution: $solution) {
              _id,
              identifier
            }
          }
        `,
        variables: {
          id: this.solution._id,
          solution: {
            identifier: this.solution.identifier,
            title: this.solution.title,
            overview: this.solution.overview,
            code: this.solution.code,
            fix: this.solution.fix,
            metadata: this.tags.split( ',' ).map( t => t.trim() ),
            private: this.solution.private,
            locked: this.solution.locked,

            team: this.solution.team == '0' ? null : this.solution.team,
          },
        },
      } ).then( res => {
        this.submitting = false;
        this.$emit( 'success', res.data.solution._id );
        this.$alerts.success( 'Solution updated!', `The solution ${res.data.solution.identifier} has been updated successfully` );
        this.reset();
      } ).catch( error => {
        this.submitting = false;
        this.$alerts.coded( 'E064', 'F3601', '', error ); //see notifications spreadsheet
      } );
    },

    show( solution ) {
      this.$apollo.query( {
        query: gql`
          query Solution($id: ObjectID!) {
            solution: Solution(id: $id) {
              _id,
              identifier,
              title,
              overview,
              code,
              fix,
              metadata,

              locked,
              private,

              team {
                _id
              }
            }
          }
        `,
        variables: {
          id: solution,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.solution = { ...res.data.solution };
        this.tags = this.solution.metadata.join();
        if( this.solution.team ) {
          this.solution.team = this.solution.team._id;
        } else {
          this.solution.team = '0';
        }
        setTimeout( () => {
          this.$refs.modal.show();
        }, 100 );
      } ).catch( () => {
        this.$alerts.coded( 'E058', 'F3602' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  components: {
    FormInput,
    FormTextArea,
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
