<template>
  <li v-if="title=='Loading'" :class="['Issue', '_loading']">
    Loading...
  </li>
  <li v-else :class="['Issue']">
    <router-link :to="to" :class="['Issue_Item', `_${barColour}`]">
      <div class="Issue_Item_Main">
        <span class="Issue_Item_Main_Title">{{title}}</span>
        <span class="Issue_Item_Main_Identifier">#{{identifier}}</span>
        <span class="Issue_Item_Main_Section"><span class="_hide">Report Section </span>{{section}}</span>
      </div>
      <div class="Issue_Item_Status"><span class="_hide" v-if="status!=''">Issue status </span>{{status}}</div>
      <div class="Issue_Item_Flag"><span class="_hide" v-if="flag!=''">QA status </span>{{flag}}</div>
      <div class="Issue_Item_Instances">{{instances}} instances</div>
    </router-link>
  </li>
</template>

<script>

export default {
  name: 'UIIssueLi',
  props: {
    title: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: '',
    },
    flag: {
      type: String,
      default: '',
    },
    barColour: {
      type: String,
      default: 'grey',
    },
    instances: {
      type: [ String, Number ],
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';

.Issue {
  padding: 5px 0;

  &._loading {
    height: 50px;
    background: $hugr-colours-grey;
    color: transparent;
    width: 100%;
    margin-bottom: 5px;
  }

  &_Item {
    display: block;
    padding: 10px 0;
    transition: background-color .5s ease-in-out 0s,
                color .5s ease-in-out 0s,
                padding .5s ease-in-out 0s,
                border-radius 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;

    border-left: 5px solid $hugr-colours-grey;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    padding-left: 5px;
    padding-right: 5px;

    &._grey {
      border-left: 5px solid $hugr-colours-grey;
    }
    &._red {
      border-left: 5px solid lighten($hugr-colours-red, 50%);
    }
    &._yellow {
      border-left: 5px solid lighten($hugr-colours-yellow, 25%);
    }
    &._green {
      border-left: 5px solid lighten($hugr-colours-green, 50%);
    }

    &:hover, &:focus, &.focus {
      background: lighten($hugr-colours-grey, 10%);
      border-radius: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding-left: 10px;
      padding-right: 0px;
      // .Issue_Item_Main_Title {
      //   position: absolute;
      //   width: 20000px;
      // }
    }

    ._hide {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    &_Main {
      display: inline-block;
      vertical-align: bottom;
      width: 50%;
      position: relative;

      &_Title {
        display: block;
        font-size: 1.1em;
        color: #000;

        width: 100%;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      &_Identifier, &_Section {
        font-size: 0.8em;
        color: lighten($hugr-colours-primary, 25%);
        margin-right: 25px;
      }
      // &_Section {

      // }
    }

    &_Status, &_Flag, &_Instances {
      display: inline-block;
      vertical-align: bottom;
      text-align: center;
      width: 16%;

      font-size: 0.8em;
      color: lighten($hugr-colours-primary, 25%);
    }

    // &_Instances {
    //   display: inline-block;
    //   vertical-align: bottom;

  // }
  }
}

._darkMode .Issue {
  &_Item {
    &:hover, &:focus, &.focus {
      background: darken($hugr-colours-primary, 10%);
    }
    &_Main {
      &_Title {
        color: #FFF;
      }
      &_Identifier, &_Section {
        color: $hugr-colours-grey;
      }
    }
    &_Status, &_Flag, &_Instances {
      color: $hugr-colours-grey;
    }
  }
}

@media (max-width: 700px) {
    .Issue {
      &_Item {
        text-decoration: none;
        &_Main {
          display: block;
          width: 100%;
          &_Section {
            display: block;
          }
        }
        &_Status {
          text-align: left;
        }
        &_Instances {
          text-align: right;
          position: absolute;
          right: 20px;
          margin-top: 4px;
        }
      }
  }
}
</style>
