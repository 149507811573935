<template>
  <form @submit.prevent="submitStatementSection">
    <FormFieldSet :legend="$gettext('New Accessibility Statement Section')">
      <FormInput ref="title" idRoot="section_" :validation="['not-empty']" :label="$gettext('Title')" v-model="title" />
      <FormTextArea ref="body" idRoot="section_" :validation="['not-empty']" :taller="true" :label="$gettext('Body')" v-model="body" />
      <FormInput ref="order" idRoot="section_" :validation="['positive-integer']" type="number" :label="$gettext('Order')" v-model="order" />
      <Button type="primary" htype="submit">{{$gettext('Add Accessibility Statement Section')}}</Button>
    </FormFieldSet>
  </form>
</template>

<script>
import gql from 'graphql-tag';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';

export default {
  name: 'AccessibilityStatementSectionForm',
  data() {
    return {
      title: '',
      body: '',
      enabled: true,
      order: 0,
    };
  },
  methods: {
    submitStatementSection() {
      const validations = [ 'title', 'body', 'order' ];
      this.$hugrvalidate( validations, this ).then( ( { success, errors } ) => {
        if ( success ) {
          this.$apollo.mutate( {
        mutation: gql`
          mutation addAccessibilityStatementSection($accessibilityStatementSection: AccessibilityStatementSectionInput!) {
            rs: addAccessibilityStatementSection(accessibilityStatementSection: $accessibilityStatementSection) {
              _id
            }
          }
        `,
        variables: {
          accessibilityStatementSection: {
            title: this.title,
            body: this.body,
            enabled: this.enabled,
            order: Number( this.order ),
          },
        },
      } ).then( res => {
        this.$emit( 'success', res.data.rs._id );
        this.title = '';
        this.body = '';
        this.enabled = true;
        this.order = 0;
      } ).catch( error => {
        this.$alerts.coded( 'E038', 'F1901', '', error ); //see notifications spreadsheet
      } );
        }
      } );
    },
  },
  components: {
    FormInput,
    FormFieldSet,
    FormTextArea,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
