<template>
  <div v-if="type=='simple'" :class="['ProgressBar', `_direction-${direction}`]">
    <div :class="'ProgressBar_Obfuscated'" v-if="value == 0">
      <img src="./assets/progress/not-started.svg" alt="">
      <span :class="'ProgressBar_Obfuscated_Bold'" v-translate>Not started</span>
    </div>
    <div :class="'ProgressBar_Obfuscated'" v-else-if="value < 100" :title="`${value}% complete`">
      <img  src="./assets/progress/in-progress.svg" alt="">
      <span :class="'ProgressBar_Obfuscated_Bold'" v-translate>In progress</span>
      <span class="a11yhide">{{ value }}% complete</span>
    </div>
    <div :class="'ProgressBar_Obfuscated'" v-else>
      <img src="./assets/progress/complete.svg" alt="">
      <span :class="'ProgressBar_Obfuscated_Bold'" v-translate>Complete</span>
    </div>
  </div>
  <div v-else-if="type=='count'" class="Count">
    <span v-if="label!=''" :class="['Count_Label', hideLabel?'a11yhide':'']" :for="id">{{ label }}&nbsp;</span>
    <span class="Count_Inner">
      <span class="Count_Inner_Value">{{ value }}</span>
      <span aria-hidden="true" class="Count_Inner_Slash">/</span>
      <span class="a11yhide">of</span>
      <span class="Count_Inner_Total">{{ total }}</span>
    </span>
  </div>
  <div v-else :class="['ProgressBar', `_direction-${direction}`]">
    <label v-if="label!=''" :class="['ProgressBar_Label', hideLabel?'a11yhide':'']" :for="id">{{ label }}&nbsp;</label>
    <progress class="ProgressBar_Bar" :id="id" max="100" :value="value" :title="`${value}%`">{{ value }}%</progress>
    <span :class="['ProgressBar_Number', hideNumber?'a11yhide':'']" >&nbsp;{{ value }}%</span>
  </div>
</template>

<script setup>
  import { toRefs } from 'vue';

  const props = defineProps( {
    id: String,
    value: Number,
    total: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: 'column',
    },
    type: {
      type: String,
      default: 'percent',
    },
    hideLabel: Boolean,
    hideNumber: Boolean,
  } );

  const { id, value, total, label, hideLabel, hideNumber } = toRefs( props );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .ProgressBar {
    width: 100%;
    display: flex;

    &._direction-row {
        flex-direction: row;
    }

    &._direction-column {
        flex-direction: column;
    }

    &_Obfuscated {
      display: flex;
      align-items: center;
      column-gap: 8px;
      &_Bold {
        font-weight: 600;
      }
    }

    &_Label {

    }
    &_Bar {
      appearance: unset;
      width: 100%;
      &::-webkit-progress-bar {
        background-color: $hugr-colours-grey;
      }
      &::-webkit-progress-value {
        background-color: $dig-blue;
      }
      &::-moz-progress-bar {
        background-color: $dig-blue;
      }
    }
    &_Number {

    }
  }

  .Count {
    &_Label {
      font-weight: bold;
    }
    &_Inner {
      background: lighten( $hugr-colours-grey, 10% );
      padding: 4px;
      border-radius: 4px;
    }
  }

  ._darkMode {
    .ProgressBar {
      &_Bar {
        &::-webkit-progress-bar {
          background-color: lighten( $hugr-colours-grey, 20% );
        }
        &::-webkit-progress-value {
          background-color: lighten( $dig-blue, 20% );
        }
        &::-moz-progress-bar {
          background-color: lighten( $dig-blue, 20% );
        }
      }

      &_Obfuscated {
        color: #FFF;
        img {
          filter: invert(1);
        }
      }
    }
  }
</style>
