export default {
  data() {
    return {
      hasError: this.initHasError,
      errorMessage: this.initErrorMessage,
    };
  },
  methods: {
    reset() {
      this.hasError = false;
      this.errorMessage = '';
    },
    validate() {
      let success = true;
      const messages = [];
      for( const validation of this.validation ) {
        if( typeof validation == 'object' && validation.regex ) {
          //todo
          if( !validation.regex.test( this.modelValue ) ) {
            success = false;
            messages.push( validation.message );
            this.addError( validation.message );
          }
        } else {
          switch( validation ) {
            case 'not-empty': {
              if( this.modelValue == '' ) {
                success = false;
                messages.push( 'This cannot be empty' );
                this.addError( 'This cannot be empty' );
              }

              break;
            }
            case 'email': {
              // eslint-disable-next-line
              const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
              if( !regex.test( this.modelValue ) ) {
                success = false;
                messages.push( 'This is not a valid email' );
                this.addError( 'This is not a valid email' );
              }

              break;
            }
            case 'postcode': {
              const pregex = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;
              if( !pregex.test( this.modelValue ) ) {
                success = false;
                messages.push( 'This is not a valid post code' );
                this.addError( 'This is not a valid post code' );
              }

              break;
            }
            case 'positive-integer': {
              const pregex = /^[1-9][0-9]*$/;
              if( !pregex.test( this.modelValue ) ) {
                success = false;
                messages.push( 'This is not a valid positive integer' );
                this.addError( 'This is not a valid positive integer' );
              }

              break;
            }
            // Bit of an arbitrary number... half a tweet should be enough?
            case 'max-70-chars': {
              if ( this.modelValue.length > 70 ) {
                success = false;
                messages.push( 'This cannot be more than 70 characters' );
                this.addError( 'This cannot be more than 70 characters' );
              }

              break;
            }
            case 'at-least-8-chars': {
              if( this.modelValue.length < 8 ) {
                success = false;
                messages.push( 'Password needs to be at least 8 characters long' );
                this.addError( 'Password needs to be at least 8 characters long' );
              }

              break;
            }
            case 'no-special-chars': {
              const regex = /[$&+,:;=?@#|'<>.\-^*()%!]/;
              if( regex.test( this.modelValue ) ) {
                success = false;
                messages.push( 'This cannot contain any special characters' );
                this.addError( 'This cannot contain any special characters' );
              }

              break;
            }
            default: {
              throw `Validation method doesn't exist`;
            }
          }
        }
      }

      return {
        success,
        messages,
      };
    },
    addError( msg ) {
      this.hasError = true;
      this.errorMessage = msg;
    },
  },
  props: {
    validation: {
      type: Array,
      default: () => {
        return [];
      },
    },
    initErrorMessage: {
      type: String,
      required: false,
      default: "",
    },
    initHasError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
