<template>
  <div>
    <Button type="transparent" :toggle="true" size="small" :checked="showAll" @click="showAll=!showAll">{{$gettext('Show all images')}}</Button>
    <ul v-if="cInstances.length" class="instances">
      <template v-for="instance in cInstances">
        <li v-if="instance&&instance.primary" :key="`instance-${instance.identifier}`">
          <div>
            <h4>{{ instance.identifier }}</h4>
            <figure>
              <span v-if="instance.evidence[0]">
                <Button v-if="!showAll&&!instance.showImg" class="showImgBtn" type="transparent" size="micro" :icon="['regular', 'eye']" @click="() => { instance.showImg=true; $forceUpdate() }">{{$gettext('Show image')}}</Button>
                <Button v-if="!showAll&&instance.showImg" class="showImgBtn" type="transparent" size="micro" :icon="['regular', 'eye-slash']" @click="() => { instance.showImg=false; $forceUpdate() }">{{$gettext('Hide image')}}</Button>
                <img v-if="showAll||instance.showImg" :src="$hugrConfig.assetsUrl+'/evidence/'+instance.evidence[0]" alt="Screenshot of issue" />
              </span>
              <span class="no-img" v-else v-translate>No image</span>
              <figcaption>
                <vue-markdown :key="instance.identifier+'-caption'" v-highlight :html="false">{{ instance.identifier }}: {{ instance.reason }}</vue-markdown>
              </figcaption>
              <Notice v-if="instance.others.length" type="info" size="small"><translate :translated-parameters="{group: instance.others.length+1}">This is a group of %{group} instances.</translate></Notice>
              <ButtonSet size="small" class="actions">
                <LinkButton :to="'/issue/'+instance.identifier" type="secondary" size="small" :icon="['solid', 'chevron-right', 'after']">{{$gettext('Go to instance')}}</LinkButton>
              </ButtonSet>
            </figure>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

// import InstanceGroupForm from '@/forms/InstanceGroup';

export default {
  name: 'InstanceList',
  created() {
    this.checkParents();
  },
  data() {
    return {
      cInstances: this.instances,
      showAll: false,
    };
  },
  methods: {
    checkParents() {
      for( const instance of this.cInstances ) {
        if( instance.parent && this.cInstances.filter( instance => !!instance ).map( instance => instance._id ).indexOf( instance.parent._id ) == -1 ) {
          this.cInstances.push( instance.parent );
        }
      }
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  props: {
    instances: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

  ul.instances {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      border-left: 5px solid #b2b3b2;
      padding: 10px;
      margin: 20px 0;
      h4 {
        margin: 0;
      }
      figure {
        border: 1px solid #b2b3b2;
        background: #f6f8fa;
        width: fit-content;
        padding: 10px;
        margin: 0;
        max-width: 900px;
        width: 100%;
        padding-bottom: 55px;
        padding-top: 35px;
        position: relative;
        img {
          max-width: 100%;
          max-height: 300px;
        }
        figcaption {
          margin: 10px 0;
        }
        .showImgBtn {
          position: absolute;
          right: 9px;
          top: 8px;
        }
        .actions {
          float: right;
          margin-top: 10px;
        }
      }
    }
  }

  ._darkMode {
    ul.instances {
      li {
        border-left: 5px solid darken($hugr-colours-primary, 50%);;
        figure {
          border: 1px solid $hugr-colours-primary;
          background: darken($hugr-colours-primary, 20%);
        }
      }
    }
  }
</style>
