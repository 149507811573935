<!-- TODO this is deprecated - to remove -->
<template>
  <Modal ref="modal" :title="issue?issue.identifier+': '+issue.title:'Issue Template Details'" @close="reset()">
    <form>
      <Table v-if="issue">
        <tr>
          <th v-translate>Issue</th>
          <td>
            <vue-markdown :key="issue.identifier+'-description'" v-highlight :html="false">{{ issue.description }}</vue-markdown>
          </td>
        </tr>
        <tr>
          <th v-translate>Severity</th>
          <td>
            <span v-if="issue.severity==0" v-translate>Advisory</span>
            <span v-else-if="issue.severity==1" v-translate>Low</span>
            <span v-else-if="issue.severity==2" v-translate>Medium</span>
            <span v-else-if="issue.severity==3" v-translate>High</span>
            <span v-else-if="issue.severity==4" v-translate>Critical</span>
            <span v-else v-translate>Unknown</span>
          </td>
        </tr>
        <tr>
          <th v-translate>WCAG Ref(s)</th>
          <td>
            <ul>
              <li v-for="criterion in issue.criteria" v-bind:key="criterion.identifier">{{criterion.criterion}} {{criterion.title}} ({{$gettext('Level')}} {{criterion.level}}) [{{criterion.identifier}}]</li>
            </ul>
          </td>
        </tr>
        <tr>
          <th v-translate>Affected users</th>
          <td>
            <ul>
              <li v-for="group in issue.affected" v-bind:key="group">
                <span v-if="group=='blind'" v-translate>Blind</span>
                <span v-else-if="group=='low-vision'" v-translate>Low Vision</span>
                <span v-else-if="group=='cognitively-impaired'" v-translate>Cognitively Impaired</span>
                <span v-else-if="group=='deaf'" v-translate>Deaf</span>
                <span v-else-if="group=='motor-impaired'" v-translate>Motor Impaired</span>
                <span v-else>{{group}}</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th v-translate>Impact on users</th>
          <td>
            <vue-markdown :key="issue.identifier+'-impact'" v-highlight :html="false">{{ issue.impact }}</vue-markdown>
          </td>
        </tr>
        <tr>
          <th v-translate>Related Solutions</th>
          <td>
            <ul>
              <li v-for="solution in issue.solutions" v-bind:key="solution.identifier">{{solution.identifier}}: {{solution.title}}</li>
            </ul>
          </td>
        </tr>
      </Table>

      <div class="actions">
        <Button type="primary" @click.prevent="reset()">{{$gettext('Close')}}</Button>
      </div>
    </form>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import UITable from '@/components/UI/Table';

export default {
  name: 'IssueDetailsModal',
  data() {
    return {
      issue: false,
    };
  },
  methods: {
    show( issue ) {
      this.$apollo.query( {
        query: gql`
          query IssueTemplate($id: ObjectID!) {
            issue: IssueTemplate(id: $id) {
              _id,
              identifier,
              title,
              description,
              severity,
              affected,
              impact
              #extra
              criteria {
                identifier,
                criterion,
                title,
                level
              }
              solutions {
                identifier,
                title
              }
            }
          }
        `,
        variables: {
          id: issue,
        },
      } ).then( res => {
        this.issue = res.data.issue;
        this.$refs.modal.show();
      } ).catch( () => {
        this.$alerts.coded( 'E059', 'F4101' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.$refs.modal.reset();
    },
  },
  components: {
    Table: UITable,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
