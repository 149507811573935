<template>
  <span class="forminput" :class="[hasError?'with-error':'']">
    <!-- <label v-bind:class="isVisible" :for="id">{{ label }}</label>
    <input  :id="id"
            :value="value"
            :placeholder="placeholder"
            ref="mainInput"
            @input="$emit('input', $event.target.value)"
            @keyup="$emit('keyup', $event)"
            @blur="$emit('blur', $event)"
            :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : '']"
            :disabled="disabled"
            tabindex="0"
            :class="[type=='number'?'_number':'']"
          /> -->

    <fieldset :class="['FormDate', hasError?'with-error':'']">
      <legend>{{ label }}</legend>
      <div class="FormDate_FauxInput">
        <input class="FormDate_FauxInput_Input" autocomplete="off" type="number" min="00" max="31" v-model="day" aria-label="Day" @input="change" ref="day" :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : '']"/>/
        <input class="FormDate_FauxInput_Input" autocomplete="off" type="number" min="00" max="12" v-model="month" aria-label="Month" @input="change" ref="month" :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : '']"/>/
        <input class="FormDate_FauxInput_Input _year" autocomplete="off" type="number" min="0" max="3000" v-model="year" aria-label="Year" @input="change" ref="year" :aria-describedby="[hintText ? id+'-hint' : '', hasError ? id+'-error' : '']"/>
        <input class="FormDate_FauxInput_PickerInput" autocomplete="off" type="date" v-model="pickerDate" :aria-hidden="true" @input="pickerChange" @onChange="pickerChange" ref="picker"/>
        <Button class="FormDate_FauxInput_PickerButton" v-if="hasPicker" type="icon" :icon="['regular', 'calendar']" aria-controls="picker" ref="datePickerBtnRef" @click="$refs.picker.showPicker()" @keyup.space.prevent="$refs.picker.showPicker()">{{ $gettext(`Date Picker`) }}</Button>
      </div>
      <!-- <p>Set to: {{val}}</p> -->
    </fieldset>

    <div v-if="hasError" :id="id+'-error'" class="error">{{errorMessage}}</div>
    <div v-if="hintText" :id="id+'-hint'" class="hint">{{ hintText }}</div>
    <div class="actions" v-if="useTodayAction || clearDateAction">
      <Button v-if="useTodayAction" size="micro" type="primary" @click="useTodaysDate" aria-label="Use today's date">Use today's date</Button>
      <Button v-if="clearDateAction" size="micro" type="primary" @click="clearDate" aria-label="Clear date">Clear date</Button>
    </div>
  </span>
</template>

<script>
import mixin from './Mixin';

import moment from 'moment';

export default {
  mixins: [ mixin ],
  name: 'FormDate',
  data() {
      return {
        uid: this.$uuid.generate(),

        val: this.modelValue,
        day: moment( this.modelValue ).format( "DD" ),
        month: moment( this.modelValue ).format( 'MM' ),
        year: moment( this.modelValue ).format( 'yyyy' ),
        pickerDate: false,
      };
  },
  mounted() {
    if ( this.modelValue == '' ) {
      this.clearDate();
    }
    this.change();
  },
  methods: {
    focus() {
      this.$refs['day'].focus();
    },
    useTodaysDate() {
      const momentDate = moment();
      this.month = momentDate.month() + 1; //month 0 indexed
      this.day = momentDate.date();
      this.year = momentDate.year();
      this.change();
    },
    clearDate() {
      this.month = 0;
      this.day = 0;
      this.year = 0;
      this.val = '';
      this.change();
    },
    pickerChange() {
      if ( this.pickerDate == '' ) {
        this.clearDate();
      } else {
        const [ newYear, newMonth, newDay ] = this.pickerDate.split( '-' );
        this.year = newYear;
        this.month = newMonth;
        this.day = newDay;
      }
      this.change();
    },
    change() {

      if( parseInt( this.month ) < 10 ) {
        this.month = `0${parseInt( this.month )}`;
      }

      if( parseInt( this.day ) < 10 ) {
        this.day = `0${parseInt( this.day )}`;
      }

      if ( !moment( `${this.year}-${this.month}-${this.day}T00:00:00.000Z` ).isValid() && !( this.day == '00' && this.month == '00' && this.year == 0 ) ) {
        this.hasError = true;
        this.errorMessage = 'Impossible or invalid date selected.';
      } else {
        this.hasError = false;
        this.errorMessage = '';
        
        if ( !( this.day == '00' && this.month == '00' && this.year == 0 ) ) {
          this.val = `${this.year}-${this.month}-${this.day}T00:00:00.000Z`;
          this.pickerDate = `${this.year}-${this.month}-${this.day}`;
        }
      }

      this.$emit( 'update:modelValue', this.val );
    },
  },
  computed: {
    id() {
      return `${this.idRoot + this.uid}-${this.label.replace( ' ', '' ).toLowerCase().trim()}`;
    },
    isVisible() {
      let ret = '';
      if( !this.labelVisible ) {
        ret = 'a11yhide';
      }

      return ret;
    },
  },
  props: {
    idRoot: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: false,
    },
    labelVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    hintText: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    hasPicker: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    useTodayAction: {
      type: Boolean,
      default: false,
    },
    clearDateAction: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  span.forminput {
    display: block;
    margin-bottom: 10px;

    .hint {
      font-size: 9pt;
      margin-top: 3px;
    }
    .error {
      font-size: 9pt;
      color: red;
    }
    .actions {
      display: flex;
      margin-top: 4px;
      column-gap: 4px;
    }
  }

  .FormDate {
    border: none;
    padding: 0;
    margin: 0;
    legend {
      display: block;
      position: relative;
      width: 100%;
      font-size: 0.9em;
      padding-bottom: 4px;
      font-weight: bold;
    }
    &_FauxInput {
      position: relative;
      padding: 10px;
      background: #F3F0EB;
      border: none;
      border-bottom: 3px solid #908A84;
      width: calc(100% - 20px);
      &_PickerInput {
        visibility: hidden;
        width: 1px;
        height: 1px;
        position: absolute;
        top: 2px;
        right: 5px;
      }
      &_PickerButton {
        position: absolute;
        top: 2px;
        right: 5px;
      }
      &_Input {
        background: #F3F0EB;
        border: none;
        width: 32px;
        &._year {
          width: 50px;
        }
      }
    }

  }
  .with-error.FormDate {
    &_FauxInput {
      border: 1px solid red;
    }
  }

  ._darkMode .FormDate {
    legend {
      color: $hugr-colours-grey;
    }
    &_FauxInput {
      color: $hugr-colours-grey;
      background: $hugr-colours-dark-input-surface;
      border-bottom: 3px solid $hugr-colours-dark-input-border;

      &_Input {
        background: $hugr-colours-dark-input-surface;
        color: $hugr-colours-grey;
      }

      &_PickerButton {
        color: lighten($hugr-colours-grey, 10%) !important;
        &:active, &:hover, &:focus {
          color: $hugr-colours-secondary !important;
        }
      }

      &:disabled {
        background: darken($hugr-colours-primary, 10%);
        color: lighten($hugr-colours-grey, 10%);
      }

      &._hasError {
        border-bottom: 3px solid lighten( $hugr-colours-red, 20% );
      }
    }
  }

  // ._darkMode span.forminput {
  //   label {
  //     color: $hugr-colours-grey;
  //   }
  //   input {
  //     background: $hugr-colours-primary;
  //     color: #FFF;
  //     &:disabled {
  //       background: darken($hugr-colours-primary, 10%);
  //       color: lighten($hugr-colours-grey, 10%);
  //     }
  //   }
  //   .hint {
  //     color: $hugr-colours-grey;
  //   }

  //   &.with-error {
  //     input {
  //       border: 1px solid #f77373;
  //     }

  //     .error {
  //       color: #f77373;
  //     }
  //   }
  // }

</style>
