<template>
  <Body title="Components" class="Components">

    <div class="Components_Fonts">
      <h2>Fonts</h2>
      <p class="Components_Fonts_Logo">Logo (Quicksand)</p>
      <p class="Components_Fonts_Headings">Headings (Quicksand)</p>
      <p class="Components_Fonts_Body">Body (fs-me-regular)</p>
    </div>

    <div class="Components_Colours">
      <h2>Colours</h2>
      <span class="Components_Colours_Swatch _primary">Primary</span>
      <span class="Components_Colours_Swatch _secondary">Secondary</span>
      <span class="Components_Colours_Swatch _tertiary">Tertiary</span>

      <span class="Components_Colours_Swatch _red">Red</span>
      <span class="Components_Colours_Swatch _white">White</span>
    </div>

    <div class="Components_Alerts">
      <h2>Alerts</h2>
      <Button size="micro" @click="fireSuccess">Fire success</Button>
      <Button size="micro" @click="fireError">Fire error</Button>
      <Button size="micro" @click="fireCodedError">Fire coded error</Button>
      <Button size="micro" @click="fireInfo">Fire alert</Button>
      <Button size="micro" @click="fireErrorHOnly">Fire error (Heading only)</Button>
    </div>

    <div class="Components_Pills">
      <h2>Pills</h2>
      <Pill text="plain" />

      <h3>Sizes</h3>
      <span><Pill text="Regular" /></span>
      <span><Pill text="Small" size="small"/></span>
      <!-- <span><Pill text="Micro" size="micro"/></span> -->

      <h3>Outcomes</h3>
      <span><Pill text="Pass" /></span>
      <span><Pill text="Fail" /></span>
      <span><Pill text="Advisory" /></span>
      <span><Pill text="Not Applicable" /></span>

      <h3>Severity</h3>
      <span><Pill text="Advisory" /></span>
      <span><Pill text="Low" /></span>
      <span><Pill text="Medium" /></span>
      <span><Pill text="High" /></span>
      <span><Pill text="Critical" /></span>

      <h3>Priority</h3>
      <span><Pill text="Lowest" /></span>
      <span><Pill text="Low" /></span>
      <span><Pill text="Medium" /></span>
      <span><Pill text="High" /></span>
      <span><Pill text="Highest" /></span>

      <h3>Tooltips</h3>
      <span><Pill text="tooltip" tooltip="This is a tooltip"/></span>
      <span><Pill text="tooltip & title" tooltip="This is a tooltip" tooltipTitle="This is a title"/></span>
      <span><Pill text="tooltip" tooltip="This is a tooltip" size="small"/></span>
      <span><Pill text="tooltip & title" tooltip="This is a tooltip" tooltipTitle="This is a title" size="small"/></span>
    </div>

    <div class="Components_Buttons">
      <h2>Buttons</h2>
      <h3>Sizes</h3>
      <Button type="primary" size="regular" @click="btnLog('regular')">Regular</Button>
      <Button type="primary" size="small" @click="btnLog('small')">Small</Button>
      <Button type="primary" size="micro" @click="btnLog('micro')">Micro</Button>

      <h3>Styles</h3>
      <Button type="primary">Primary</Button>
      <Button type="secondary">Secondary</Button>
      <Button type="serious">Serious</Button>
      <Button type="transparent">Transparent</Button>
      <Button type="border">Border</Button>
      <Button type="icon" :icon="['solid', 'info-circle']" aria-label="Icon"></Button>

      <h3>Hover states</h3>
      <Button class="hover" type="primary">Primary</Button>
      <Button class="hover" type="secondary">Secondary</Button>
      <Button class="hover" type="serious">Serious</Button>
      <Button class="hover" type="transparent">Transparent</Button>
      <Button class="hover" type="border">Border</Button>
      <Button type="icon" :icon="['solid', 'info-circle']" aria-label="Icon" class="focus"></Button>

      <h3>Focus states</h3>
      <Button class="focus" type="primary">Primary</Button>
      <Button class="focus" type="secondary">Secondary</Button>
      <Button class="focus" type="serious">Serious</Button>
      <Button class="focus" type="transparent">Transparent</Button>
      <Button class="focus" type="border">Border</Button>
      <Button type="icon" :icon="['solid', 'info-circle']" aria-label="Icon" class="focus"></Button>

      <h3>Link buttons</h3>
      <LinkButton class="btn" type="primary">Primary</LinkButton>
      <LinkButton class="btn" type="secondary">Secondary</LinkButton>
      <LinkButton class="btn" type="serious">Serious</LinkButton>
      <LinkButton class="btn" type="transparent">Transparent</LinkButton>
      <LinkButton class="btn" type="border">Border</LinkButton>

      <h3>Icons</h3>
      <Button type="primary" :icon="['solid', 'plus']">With Icon</Button>
      <Button type="primary" :icon="['solid', 'plus']" size="small">With Icon</Button>
      <Button type="primary" :icon="['solid', 'plus']" size="micro">With Icon</Button>
      <br />
      <br />
      <Button type="primary" :icon="['solid', 'plus', 'after']">With Icon</Button>
      <Button type="primary" :icon="['solid', 'plus', 'after']" size="small">With Icon</Button>
      <Button type="primary" :icon="['solid', 'plus', 'after']" size="micro">With Icon</Button>

      <h3>Variants</h3>
      <Button type="primary" variant="expand" :icon="['solid', 'plus']">Expand</Button>
      <Button class="focus" type="primary" variant="expand" :icon="['solid', 'plus']">Expand</Button>
      <br/>
      <br/>
      <Button type="border" variant="expand" size="small" :icon="['solid', 'plus']">Expand</Button>
      <Button class="focus" type="border" variant="expand" size="small" :icon="['solid', 'plus']">Expand</Button>
    </div>
    <div class="Components_Tabs">
      <h2>Tabs</h2>
      <TabList :tabs="tabs"/>
      <div class="Components_Tabs_Pane _one" id="tabpane" ref="tabpane">
        <p ref="tabpanetext">Tab: One</p>
      </div>
    </div>
    <div class="Components_Search">
      <h2>Search</h2>
      <Search label="Search something" v-model="searchterm"/>
      <p>Term: {{searchterm}}</p>
    </div>

    <div class="Components_Inputs">
      <h2>Inputs</h2>
      <form>
        <FormInput label="Standard" :hint="inputShowHints?'This is a hint':''"/>

        <FormInput type="password" label="Password" :hint="inputShowHints?'This is a hint':''"/>

        <TextArea label="Text Area" :hint="inputShowHints?'This is a hint':''" />

        <div><input id="devinputhints" type="checkbox" v-model="inputShowHints"><label for="devinputhints">Show hints</label></div>
        <div><input id="devinputerrors" type="checkbox" v-model="inputShowErrors"><label for="devinputerrors">Show errrors</label></div>
      </form>
    </div>

    <div class="Components_Aria">
      <h2>Aria components</h2>

      <h3>Aria listbox</h3>
      <AriaListBox idRoot="instance_" :label="$gettext('Status')" v-model="listBoxStatus"
        :options="{
          'reported': $gettext('Reported'),
          'closed-removed': $gettext('Closed Removed'),
          'closed-fixed': $gettext('Closed Fixed'),
          'in-progress': $gettext('In Progress'),
          'retest': $gettext('To Retest')
        }"
        :extra="{
          'reported': $gettext('Issue is reported, action is required.'),
          'closed-removed': $gettext('Component with issue has been removed or mitigated.'),
          'closed-fixed': $gettext('Issue has been fixed.'),
          'in-progress': $gettext('Fix is in progress.'),
          'retest': $gettext('Issue has been fixed and requires retesting')
        }"
      />

      <h3>Aria Search Select (New)</h3>
      <AriaSearchSelect label="Search select" :items="[
          { value: '1', display: 'One' },
          { value: '2', display: 'Two' },
          { value: '3', display: 'Three' },
          { value: '4', display: 'Four' },
          { value: '5', display: 'Five' },
          { value: '6', display: 'Six' },
          { value: '7', display: 'Seven' },
          { value: '8', display: 'Eight' },
          { value: '9', display: 'Nine' },
          { value: '10', display: 'Ten' },
        ]" :dropdown="true"/>

      <AriaSearchSelect label="Search select" :items="[
          { value: '1', display: 'One' },
          { value: '2', display: 'Two' },
          { value: '3', display: 'Three' },
          { value: '4', display: 'Four' },
          { value: '5', display: 'Five' },
          { value: '6', display: 'Six' },
          { value: '7', display: 'Seven' },
          { value: '8', display: 'Eight' },
          { value: '9', display: 'Nine' },
          { value: '10', display: 'Ten' },
        ]" :dropdown="true" :multiple="true"/>

      <h3>Aria search select (old)</h3>
      <AriaSearchSelectLegacy label="Search select" :items="[
          { value: '1', display: 'One' },
          { value: '2', display: 'Two' },
          { value: '3', display: 'Three' },
          { value: '4', display: 'Four' },
          { value: '5', display: 'Five' },
          { value: '6', display: 'Six' },
          { value: '7', display: 'Seven' },
          { value: '8', display: 'Eight' },
          { value: '9', display: 'Nine' },
          { value: '10', display: 'Ten' },
        ]" :dropdown="true"/>

      <h4>Aria search select list (ordered)</h4>
      <AriaSearchSelectList v-model:items="ssListOrdered" :ordered="true" :moveable="true"/>
      <Button size="micro" @click="() => { ssListOrdered.push({name: `Item ${ssListOrderedNum}`}); ssListOrderedNum++ }">Add an item</Button>

      <h4>Aria search select list (unordered)</h4>
      <AriaSearchSelectList v-model:items="ssListUnordered"/>
      <Button size="micro" @click="() => { ssListUnordered.push({name: `Item ${ssListUnorderedNum}`}); ssListUnorderedNum++ }">Add an item</Button>

    </div>

    <!-- <div class="Components_Modals">
      <Button>New Issue Instance</Button>
    </div> -->

    <div class="Components_Table">
      <h2>Data Table</h2>
      <DataTable :headers="[
        'Header One',
        'Header Two',
        'Header Three',
        'Header Four'
      ]" >
        <tr>
          <td>One</td>
          <td>Twp</td>
          <td>Three</td>
          <td>Four</td>
        </tr>
        <tr>
          <td>One</td>
          <td>Twp</td>
          <td>Three</td>
          <td>Four</td>
        </tr>
        <tr>
          <td>One</td>
          <td>Twp</td>
          <td>Three</td>
          <td>Four</td>
        </tr>
      </DataTable>
    </div>

    <div class="Components_Table">
      <h2>Issue List</h2>
      <ClearList>
        <IssueLi title="An issue title" identifier="DIGxxx" section="Section One > Section Two" status="Reported" instances="16" to="#" />
        <IssueLi title="An second issue title" identifier="DIGxxx" section="Section One > Section Two" status="Done" instances="2" to="#" />
        <IssueLi title="A super super super super super super super loooooooooooooooooooooong title" identifier="DIGxxx" section="Section One > Section Two" status="Reported" instances="2" to="#" />
      </ClearList>
    </div>

    <div class="Components_Loader">
      <h2>Loader</h2>
      <Button size="micro" @click="$refs.loader.reset()">Reset Loader</Button>
      <Loader ref="loader"/>
    </div>

    <div class="Components_Notice">
      <h2>Notice</h2>
      <Notice>
        <h3>Standard Notice</h3>
        <p>lorem ipsum</p>
      </Notice>
      <Notice type="alert">
        <h3>Alert Notice</h3>
        <p>lorem ipsum</p>
      </Notice>
      <Notice type="error">
        <h3>Error Notice</h3>
        <p>lorem ipsum</p>
      </Notice>
      <Notice size="small">
        <h3>Small Notice</h3>
        <p>lorem ipsum</p>
      </Notice>
      <Notice size="micro">
        <h3 style="margin: none">Micro Notice</h3>
      </Notice>
    </div>

    <div class="Components_Notice">
      <h2>Fragment</h2>
      <Button @click="$refs.pagefragment.show('')">Page</Button>
      <PageFragment ref="pagefragment" />
    </div>

  </Body>
</template>

<script>
import UIBody from '@/components/UI/Body';

import Pill from '@/components/UI/Pill';

import AriaListBox from '@/components/Aria/ListBox';
import AriaSearchSelect from '@/components/Aria/SearchSelect3';
import AriaSearchSelectLegacy from '@/components/Aria/SearchSelect2';
import AriaSearchSelectList from '@/components/Aria/SearchSelectList';

import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';

import PageFragment from '@/fragments/Page';

export default {
  name: 'DevComponentsView',
  data() {
    const tabs = [
      {
        text: "One",
        controls: "tabpane",
        action: () => {
          this.$refs.tabpane.classList.remove( '_two' );
          this.$refs.tabpane.classList.remove( '_three' );
          this.$refs.tabpane.classList.add( '_one' );
          this.$refs.tabpanetext.innerText = 'Tab: One';
        },
      },
      {
        text: "Two",
        controls: "tabpane",
        action: () => {
          this.$refs.tabpane.classList.remove( '_one' );
          this.$refs.tabpane.classList.remove( '_three' );
          this.$refs.tabpane.classList.add( '_two' );
          this.$refs.tabpanetext.innerText = 'Tab: Two';
        },
      },
      {
        text: "Three",
        controls: "tabpane",
        pill: () => [ 'pill' ],
        action: () => {
          this.$refs.tabpane.classList.remove( '_one' );
          this.$refs.tabpane.classList.remove( '_two' );
          this.$refs.tabpane.classList.add( '_three' );
          this.$refs.tabpanetext.innerText = 'Tab: Three';
        },
      },
    ];

    return {
      tabs,
      searchterm: "",

      listBoxStatus: "reported",
      ssListOrdered: [],
      ssListUnordered: [],
      ssListOrderedNum: 1,
      ssListUnorderedNum: 1,

      inputShowHints: false,
      inputShowErrors: false,
    };
  },
  methods: {
    fireSuccess() {
      this.$alerts.success( 'This is a success', `This is a success body text and <a href="#">link</a>` );
    },
    fireError() {
      this.$alerts.error( 'This is an error', `This is an error body text and <a href="#">link</a>` );
    },
    fireCodedError() {
      this.$alerts.coded( 'E001', 'F001' );
    },
    fireInfo() {
      this.$alerts.info( 'This is info', `This is an info message` );
    },
    fireErrorHOnly() {
      this.$alerts.error( 'This is an error' );
    },
    btnLog( btn ) {
      console.log( `button ${btn} has been clicked` );
    },
  },
  components: {
    Body: UIBody,
    AriaListBox,
    AriaSearchSelect,
    AriaSearchSelectLegacy,
    AriaSearchSelectList,

    FormInput,
    TextArea: FormTextArea,

    PageFragment,
    
    Pill,
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/variables/_fonts.scss';
@import '@/assets/styles/variables/_colours.scss';

.Components {
  & > * { border-bottom: 1px solid $hugr-colours-grey; }
  &_Fonts {
    &_Logo {
      font-family: $hugr-fonts-logo;
    }
    &_Headings {
      font-family: $hugr-fonts-headings;
    }
    &_Body {
      font-family: $hugr-fonts-body;
    }
  }

  &_Colours {
    &_Swatch {
      display: block;
      &:before {
        content: '';
        display: inline-block;
        background: grey;
        border: 1px solid grey;
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }

      &._primary:before {
        background: $hugr-colours-primary;
      }
      &._secondary:before {
        background: $hugr-colours-secondary;
      }
      &._tertiary:before {
        background: $hugr-colours-tertiary;
      }
      &._red:before {
        background: $hugr-colours-red;
      }
      &._white:before {
        background: $hugr-colours-white;
      }
    }
  }

  &_Pills {
    span {
      display: inline-block;
      margin-right: 8px;
    }
  }

  &_Buttons {
    button, .btn {
      margin-right: 20px;
    }
  }

  &_Tabs {
    &_Pane {
      background: $hugr-colours-grey;
      padding: 50px;
      &._one {
        background: lighten($hugr-colours-primary, 75%);
      }
      &._two {
        background: lighten($hugr-colours-secondary, 50%);
      }
      &._three {
        background: lighten($hugr-colours-tertiary, 50%);
      }
    }
  }
}
</style>
