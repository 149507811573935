<template>
  <span>
    <span role="textbox"
          :aria-labelledby="label"
          ref="textbox"
          contenteditable
          tabindex="0"
          class="textbox"
          v-on:input="inputMask($event); $emit('update:modelValue', $event.target.innerText)"
          v-on:keydown.enter.prevent

          v-on:blur="blur"
          :aria-describedby="(hintText?uid+'-hint':'')"

          @mousedown="(e) => { e.cancelBubble = true; }"
          ></span>
      <span v-if="placeholder&&empty" class="placeholder" v-html="parsePlaceholder"></span>
      <span v-if="hintText" :id="uid+'-hint'" class="hiddenHint">{{hintText}}</span>
  </span>
</template>

<script>
export default {
  name: 'AriaContentEditable',
  data() {
    return {
      uid: this.$uuid.generate(),
    };
  },
  mounted() {
    this.$refs.textbox.innerText = this.$attrs.modelValue;
  },
  methods: {
    focus() {
      this.$refs.textbox.focus();
      document.execCommand( 'selectAll', false, null );
      document.getSelection().collapseToEnd();
    },
    blur() {
      if( typeof this.$attrs.onBlur == 'function' ) {
        this.$attrs.onBlur();
      }
    },
    setText( t ) {
      this.$refs.textbox.innerText = t;
    },
  },
  computed: {
    empty() {
      return this.$attrs.modelValue == '';
    },
    parsePlaceholder() {
      return this.placeholder.replace( '*', '<b>' ).replace( '*', '</b>' );
    },
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    inputMask: {
      type: Function,
      default: () => {},
    },
    hintText: {
      default: false,
    },
    placeholder: {
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '@/assets/styles/variables/_colours.scss';
.textbox {
  display: inline-block;
  min-height: 20px;
  min-width: 20px;
}
.hiddenHint {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.placeholder {
  position: relative;
  float: left;
  text-align: left;
  top: -15px;
  font-size: 9pt;
  color: lighten($hugr-colours-primary, 10%);
  pointer-events: none;
  height: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._darkMode {
  .textbox {
    color: #FFF;
  }
  .placeholder {
    color: darken($hugr-colours-grey, 10%);
  }
}
</style>
