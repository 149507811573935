<template>
  <span>
    <a v-if="!external" :href="href"><slot></slot></a>

    <a v-else :href="href" target="_blank"><Icon v-if="icon" :colour="iconColour" type="solid" icon="link" /><div :class="[iconOnly?'a11yhide':'']"><slot></slot></div><span class="newtab"> (Opens in new tab)</span></a>
  </span>
</template>

<script>
// import SROnly from '@/components/Helpers/SROnly';

export default {
  name: 'Link',
  props: {
    href: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    iconColour: {
      type: String,
      default: '',
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
  // components: {
  //   SROnly
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.a11yhide {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

  a {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    
    .newtab {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0;
    }
    &:focus, &:hover {
      .newtab {
        z-index: 10;
        position: absolute;
        width: auto;
        height: auto;
        clip: unset;
        background: #e5ebf2;
        padding: 5px 10px;
        margin-left: -154px;
        margin-top: 20px;
      }
    }
  }

  ._darkMode .newtab {
    color: black;
  }
</style>
