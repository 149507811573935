<template>
  <ReportInfo :trail="[
      {name: $gettext('Home'), path: '/dashboard'},
      {name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), path: `/${$hugrConfig.reportRouterReplacement}s`},
      {name: 'THIS_REPORT'},
      {name: $gettext('Components'), path: `/${$hugrConfig.reportRouterReplacement}s/${identifier}/${version}/components`}
    ]" />

  <Body :withMenu="true" class="Components" :hideTitle="fragmentOpen" title="Components" ref="body" :titleIcon="['solid','puzzle-piece']" :titleHint="['',$gettext('Components are elements that are duplicated across multiple pages with exactly the same characteristics, code and colours.')]">
    <template v-if="!fragmentOpen">
      <div class="Components_Btns" v-if="!reportLoading">
        <Button v-if="!report.report.currentVersion.published" class="Components_Btns_Add"  :icon="['solid', 'puzzle-piece']" size="small" type="secondary" @click="componentmodal.show()">{{ $gettext('Add a component') }}</Button>
        <LinkButton class="Pages_Btns_Spreadsheet" :icon="['solid', 'table']" size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/${identifier}/${version}/spreadsheet`">Spreadsheet view</LinkButton>
      </div>

      <template v-if="!reportLoading">
        <DataTable
            v-if="report.report.components.length>0"
            class="Components_Table"
            :noScroll="true"
            :headers="headers"
          >
          <tr v-for="component in report.report.components" :key="component._id" class="Components_Table_Row">
            <td class="Components_Table_Row_Name"><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${identifier}/${version}/components/${component._id}`" @click="componentfragment.show( component._id )" @keyup.space.prevent="componentfragment.show( component._id )" :title="component.identifier">{{ component.identifier }}</router-link></td>
            <td class="Components_Table_Row_Description">
              <span v-if="component.description" :title="component.description">{{ component.description }}</span>
              <span v-else>None</span>
            </td>
            <td>{{ component.pages.length }}</td>
            <td v-if="showInProgress"><ProgressBar :id="`componentprogress-${component.name}`" label="Component Progress" :hideLabel="true" :value="component.progress" type="simple"/></td>
            <td>
              <CompliancePill :progress="component.progress" :isCompliant="component.isCompliant" :issues="component.issues.length" size="small"/>
            </td>
            <td>
              <div class="Components_Table_Row_Severity">
                <SeverityLine
                  :type="hasPermission( 'UIAlts', 'IssueBySeverityBar' )?'line':'pills'"
                  :advisory="component.issues.filter( i => i.issueTemplate.severity == 0 ).length"
                  :low="component.issues.filter( i => i.issueTemplate.severity == 1 ).length"
                  :medium="component.issues.filter( i => i.issueTemplate.severity == 2 ).length"
                  :high="component.issues.filter( i => i.issueTemplate.severity == 3 ).length"
                  :critical="component.issues.filter( i => i.issueTemplate.severity == 4 ).length"
                  />
              </div>
            </td>
          </tr>
        </DataTable>
        <template v-else>
          <Empty v-if="!report.report.currentVersion.published" :text="$gettext('No components')"
            :button="{size: 'regular', type: 'secondary', icon: ['solid', 'puzzle-piece'], text: $gettext('Add an component')}"
            @buttonClick="$refs.componentmodal.show()" />
          <Empty v-else :text="$gettext('No components')"/>
        </template>
      </template>
      <Loader v-else />
    </template>
    <PageFragment ref="pagefragment" @closed="closeFragment" @goToComponent="goToComponent" @goToUserJourney="goToUserJourney" @goToIssue="goToIssue"/>
    <ComponentFragment ref="componentfragment" @closed="closeFragment" @goToPage="goToPage" @goToUserJourney="goToUserJourney" @goToIssue="goToIssue"/>
    <UserJourneyFragment ref="userjourneyfragment" @closed="closeFragment" @goToComponent="goToComponent" @goToPage="goToPage" @goToIssue="goToIssue"/>
    <IssueFragment ref="issuefragment" @closed="closeFragment" @goToPage="goToPage" @goToComponent="goToComponent" @goToUserJourney="goToUserJourney"/>
  </Body>
  <ComponentModal v-if="!reportLoading" :report="report.report.currentVersion._id" ref="componentmodal" @added="preRefetchReport"/>
</template>

<script setup>
  import ComponentModal from '@/modals/Component/Create';

  import PageFragment from '@/fragments/Page';
  import ComponentFragment from '@/fragments/Component';
  import UserJourneyFragment from '@/fragments/UserJourney';
  import IssueFragment from '@/fragments/Issue';

  import ProgressBar from '@/components/UI/ProgressBar';
  import CompliancePill from '@/components/CompliancePill';
  import Pill from '@/components/UI/Pill.vue';
  import SeverityLine from '@/components/UI/SeverityLine';

  import { ref, onMounted, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuery, useMutation } from '@vue/apollo-composable';
  import gql from 'graphql-tag';

  import config from '../../../config';

  const router = useRouter();
  const store = useStore();

  const hasPermission = computed( () => store.getters.hasPermission );

  const componentmodal = ref( null );

  const pagefragment = ref( null );
  const componentfragment = ref( null );
  const userjourneyfragment = ref( null );
  const issuefragment = ref( null );

  const identifier = ref( router.currentRoute.value.params.identifier );
  const version = ref( router.currentRoute.value.params.version );
  const urlcomponent = ref( router.currentRoute.value.params.component );

  const body = ref( null );

  const { onResult: settingsResult } = useQuery( gql`
    query Settings {
      settings: Settings {
        id
        showInProgress
      }
    }`,
  );

  const showInProgress = ref( false );

  settingsResult( ( { data } ) => {
    if( !data ) return;

    showInProgress.value = data?.settings.showInProgress;
  } );

  const headers = computed( () => {
    const hs = [];
    hs.push( 'Component name' );
    hs.push( 'Description' );
    hs.push( 'Pages' );
    if( showInProgress.value ) hs.push( 'Test status' );
    hs.push( 'Compliance' );
    hs.push( 'Instances of issues by severity' );

    return hs;
  } );

  const reportVariables = ref( {
    identifier: router.currentRoute.value.params.identifier,
    version: router.currentRoute.value.params.version,
  } );

  onMounted( () => {
    if( urlcomponent.value ) {
      componentfragment.value.show( urlcomponent.value );
    }

    const { mutate: sendNavigation } = useMutation(
      gql`
        mutation sendNavigation ($page: String) {
          sendNavigation(page: $page)
        }
      `, {
        variables: {
          page: 'Assessment Components',
        },
    } );
    
    sendNavigation();
  } );

  const reportFragment = gql`
    fragment reportFragment on Report {
      _id
      identifier
      title

      components {
        _id,
        identifier,
        description,
        progress
        isCompliant

        pages {
          _id
        }

        issues {
          _id
          issueTemplate {
            severity
          }
        }
      }

      currentVersion {
        _id
        published
      }
    }`;

  let reportQuery = gql`
      query Report($identifier: String!) {
        report: Report(identifier: $identifier) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;

  if( version.value ) {
    reportQuery = gql`
      query Report($identifier: String!, $version: String!) {
        report: ReportWithVersion(identifier: $identifier, version: $version) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;
  }

  const { result: report, refetch: refetchReport, loading: reportLoading, onResult: onReport } = useQuery(
    reportQuery,
    reportVariables,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const preRefetchReport = () => {
    refetchReport();
    body.value.reloadMenu();
  };

  onReport( ( { data } ) => {
    if( data?.report?.currentVersion?._id ) {
      // eslint-disable-next-line no-underscore-dangle
      store._actions.setReport[0]( data.report.currentVersion._id );
    }
  } );

  const closeFragment = () => {
    history.pushState(
      {},
      null,
      `/${config.reportRouterReplacement}s/${identifier.value}/${version.value}/components`,
    );
    refetchReport();
  };

  const fragmentOpen = computed( () => ( componentfragment.value ? componentfragment.value.isShowing() : false )
    || ( pagefragment.value ? pagefragment.value.isShowing() : false )
    || ( userjourneyfragment.value ? userjourneyfragment.value.isShowing() : false )
    || ( issuefragment.value ? issuefragment.value.isShowing() : false ) );

  const goToComponent = component => {
    componentfragment.value.show( component );
  };

  const goToPage = page => {
    pagefragment.value.show( page );
  };

  const goToUserJourney = journey => {
    userjourneyfragment.value.show( journey );
  };

  const goToIssue = issue => {
    issuefragment.value.show( issue );
  };

</script>

<style lang="scss" scoped>
  .Components {
    position: relative;

    &_Blurb {
      font-size: 0.9em;
    }

    &_Btns {
      position: absolute;
      right: 50px;
      top: 36px;

      button, a {
        margin-left: 8px;
      }
    }
    
    &_Table {
      margin-top: 32px;
      &_Row {
        &_Name {
          max-width: 10vw;
          a {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &_Description {
          max-width: 20vw;
          span {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &_Severity {
          max-width: 240px;
          display: flex;
          justify-content: flex-start;
          gap: 12px;
        }
      }
    }
  }
</style>
