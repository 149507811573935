<template>
  <form @submit.prevent="submitPermissionGroupCategory">
    <FormFieldSet :legend="$gettext('New Permission Group Category')">
      <FormInput idRoot="Permission Group Category_" :label="$gettext('Name')" v-model="name" />
      <Button id="Permission Group Category" type="primary" htype="submit">{{$gettext('Add Permission Group Category')}}</Button>
    </FormFieldSet>
  </form>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';

export default {
  name: 'PermissionGroupCategoryForm',
  data() {
    return {
      name: '',
    };
  },
  methods: {
    submitPermissionGroupCategory() {
      if( this.name != '' ) {
        this.$apollo.mutate( {
          mutation: gql`
            mutation addPermissionGroupCategory($permissionGroupCategory: PermissionGroupCategoryInput!) {
              permissionGroupCategory: addPermissionGroupCategory(permissionGroupCategory: $permissionGroupCategory) {
                _id,
                name
              }
            }
          `,
          variables: {
            permissionGroupCategory: {
              name: this.name,
            },
          },
        } ).then( () => {
          this.$emit( 'success' );
          this.$alerts.success( 'Success', 'Group category successfully created.' );
          this.name = '';
        } ).catch( () => {
          this.$alerts.coded( 'E036', 'F1801' ); //see notifications spreadsheet
        } );
      } else {
        //give the group a name
        this.$alerts.coded( 'E037', 'F1802' ); //see notifications spreadsheet
      }
    },
  },
  components: {
    FormInput,
    FormFieldSet,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
