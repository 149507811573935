<template>
  <div>
    <div v-if="disabled">
      <p v-translate>This functionality is disabled, please speak to an administrator</p>
    </div>
    <div v-else-if="!hasAccess">
      <p v-translate>You don't have access to this report</p>
    </div>
    <div class="Render" v-else-if="report&&sections">
      <div class="Render_TopBar" v-if="!undecorated">
        <ReportInfo v-if="report.hasWriteAccess" class="Render_TopBar_Info" :reportVersion="report.currentVersion._id" :slim="true" :back="true"/>
        <div v-else class="Render_TopBar_Bar"></div>
        <ButtonSet class="Render_TopBar_Buttons">
          <Button v-if="report.hasWriteAccess" size="small" direction="horizontal" @click="$refs.rendersharemodal.show( report )">{{$gettext('Share')}}</Button>
          <!-- <Button v-if="report.hasWriteAccess" size="small" direction="horizontal" @click="doCopy()">Copy</Button> -->
          <Button v-if="report.hasWriteAccess" size="small" direction="horizontal" @click="$refs.editrenderdetails.show(report.currentVersion._id, report.currentVersion.createdOverride, report.currentVersion.publishedOverride, report.currentVersion.keyIssues, issuesMarkedAsKeyIssues);">{{$gettext('Edit details')}}</Button>
          <Button type="transparent" :toggle="true" size="small" direction="horizontal" :checked="hidePasses" @click="() => { hidePasses=!hidePasses; }">{{$gettext('Hide section without any issues')}}</Button>
          <Button v-if="report.team" type="transparent" :toggle="true" size="small" direction="horizontal" :checked="showAllTeamSections" @click="() => { showAllTeamSections=!showAllTeamSections; $apollo.queries.sections.refetch() }">{{$gettext('Show all team issue template sections')}}</Button>
          <AriaListBox class="Render_TopBar_Buttons_VersionSelect" idRoot="ver_" :label="$gettext('Show version')" labelStyle="small" :labelVisible="true" v-model="showingVersion" @change="changeVersion"
            :options="versionOpts"
            :extra="versionOptsExt"
          />
        </ButtonSet>
      </div>

      <Notice size="small" v-if="hasChangeRequired&&report.hasWriteAccess" type="error">
        {{$gettext('Some issues require changes for QA')}}
      </Notice>
      <Notice size="small" v-else-if="(hasUnchecked||hasToCheck||hasSecondOpinion)&&report.hasWriteAccess" type="alert">
        {{$gettext('Not all issues have been QA approved')}}
      </Notice>

      <Loader v-if="doingLoading" /> <!-- Note: this is a second loader for version changes, main loader at bottom -->
      <div v-else :class="['Report', sidebar&&!undecorated?'_sidebaropen':'']" ref="report" tabindex="-1">
        <div class="Report_SideBar" ref="sidebar" v-if="!undecorated">
          <Button v-show="sidebar" id="closeSidebar" type="icon" :icon="['solid', 'arrow-left']" @click="() => { sidebar = false }">{{$gettext('Hide document overview')}}</Button>
          <Button v-show="!sidebar" id="openSidebar" type="icon" :icon="['solid', 'arrow-right']" @click="() => { sidebar = true }">{{$gettext('Show document overview')}}</Button>
          <ul v-show="sidebar" id="sidebarContents">
            <li>
              <!-- <button @click="() => { sidebarO['title'] = typeof sidebarO['title']=='undefined'?false:!sidebarO['title'] }"><Icon type="solid" icon="minus" :read="`minimise section`"/></button>  -->
              <a href="#title" @focus="(e) => scrollTo(e, 'title')" @click="(e) => moveTo(e, 'title')" class="_anchor _h1">{{report.identifier}}: {{report.title}}</a>
            </li>
            <ul v-show="sidebarO['title']||typeof sidebarO['title']=='undefined'">
              <li><a href="#intro" @focus="(e) => scrollTo(e, 'intro')" @click="(e) => moveTo(e, 'intro')" class="_anchor _h2" v-translate>Introduction</a></li>
              <li><a href="#using" @focus="(e) => scrollTo(e, 'using')" @click="(e) => moveTo(e, 'using')" class="_anchor _h2" v-translate>Using this report</a></li>
              <li><a href="#affected" @focus="(e) => scrollTo(e, 'affected')" @click="(e) => moveTo(e, 'affected')" class="_anchor _h2" v-translate>Who may be affected</a></li>
              <li>
                <a href="#execsummary" @focus="(e) => scrollTo(e, 'execsummary')" @click="(e) => moveTo(e, 'execsummary')" class="_anchor _h2" v-translate>Executive Summary</a>
                <ul>
                  <li><a href="#results" @focus="(e) => scrollTo(e, 'results')" @click="(e) => moveTo(e, 'results')" class="_anchor _h3" v-translate>Results</a></li>
                  <li><a href="#results" @focus="(e) => scrollTo(e, 'pageCompliance')" @click="(e) => moveTo(e, 'pageCompliance')" class="_anchor _h3" v-translate>Compliance by page</a></li>
                  <li><a href="#keyissues" @focus="(e) => scrollTo(e, 'keyissues')" @click="(e) => moveTo(e, 'keyissues')" class="_anchor _h3" v-translate>Key issues</a></li>
                </ul>
              </li>
              <li>
                <a href="#contents" @focus="(e) => scrollTo(e, 'contents')" @click="(e) => moveTo(e, 'contents')" class="_anchor _h2" v-translate>Contents</a>
                <ul>
                  <template v-for="section in sections">
                    <li v-if="!hidePasses||wholeSectionResult(section)!='Pass'" :key="`sidebar-${section.title}`">
                      <a :href="`#section-${section.title}`" @focus="(e) => scrollTo(e, `section-${section.title}`)" @click="(e) => moveTo(e, `section-${section.title}`)" class="_anchor _h3">{{ section.title }}</a>
                      <ul>
                        <template v-for="subsection in section.subsections">
                          <li v-if="!hidePasses||sectionResult(subsection.templates)!='Pass'" :key="`sidebar-${section.title}=${subsection.title}`">
                            <a :href="`#subsection-${subsection.title}`" @focus="(e) => scrollTo(e, `subsection-${subsection.title}`)" @click="(e) => moveTo(e, `subsection-${subsection.title}`)" class="_anchor _h4">{{ subsection.title}}</a>
                            <ul>
                              <template v-for="template in subsection.templates">
                                <li v-if="hasOpenInstances(template.instances)" :key="`issue-${template.template.identifier}`">
                                  <a @focus="(e) => scrollTo(e, `issue-${template.template.identifier}`)" @click="(e) => moveTo(e, `issue-${template.template.identifier}`)" :href="`#issue-${template.template.identifier}`" class="_anchor _h5">{{template.template.identifier}}: {{template.template.title}}</a>
                                </li>
                              </template>
                            </ul>
                          </li>
                        </template>
                      </ul>
                    </li>
                  </template>
                </ul>
              </li>
            </ul>
          </ul>
        </div>

        <Page class="Report_Page Report_Page_FirstPage" ref="titlepage">
          <img class="Report_Page_FirstPage_Logo" src="../../assets/images/dig-inclusion-white.png" alt="Dig Inclusion"/>
          <div class="Report_Page_FirstPage_Title">
            <h1 id="title" tabindex="-1" >{{report.title}}</h1>
            <span>#{{report.identifier}}</span>
          </div>
          <p class="Report_Page_FirstPage_Version">{{$gettext('Version ')}}&nbsp;{{report.currentVersion.version}}</p>
          <p class="Report_Page_FirstPage_Dates">
            <span v-if="report.currentVersion.createdOverride == '' || !report.currentVersion.createdOverride">
              {{createdDate}}
            </span>
            <span v-else>
              {{report.currentVersion.createdOverride}}
            </span> -
            <span v-if="report.currentVersion.publishedOverride == '' || !report.currentVersion.publishedOverride">
              {{publishedDate}}
            </span>
            <span v-else>
              {{report.currentVersion.publishedOverride}}
            </span>
          </p>
          <dl class="Report_Page_FirstPage_Details">
            <div class="Report_Page_FirstPage_Details_Item">
              <dt>Purpose of the product:</dt>
              <dd>{{  report.productDescription }}</dd>
            </div>
            <div class="Report_Page_FirstPage_Details_Item _inline">
              <dt v-translate>Prepared by:</dt>
              <dd>
                <ul class="inline-list">
                  <li>{{report.owner.name}}</li>
                  <li v-for="user in report.collaborators" v-bind:key="'user-' + user.name">{{user.name}}</li>
                </ul>
              </dd>
            </div>
            <div class="Report_Page_FirstPage_Details_Item _inline">
              <dt v-translate>Published:</dt>
              <dd>{{ publishedDate }}</dd>
            </div>
          </dl>
        </Page>
        <Page class="Report_Page Report_Page_Intro">
          <h2 id="intro" tabindex="-1" v-translate>Introduction</h2>
          <p>{{$gettext('Accessibility Standard: Web Content Accessibility Guidelines (WCAG)')}} {{highestWCAG}}</p>
          <p>{{$gettext('The report provides details of any accessibility or design issues that need to be addressed for the site to meet ')}} <a :href="`https://www.w3.org/tr/wcag${highestWCAG.replace('.' , '' )}`" target="_blank">WCAG {{highestWCAG}} {{ highestConformity }} conformance</a>. {{$gettext('The executive summary may also reference additional recommendations for improving the accessibility of the site. Information about applying recommendations, methodology and further information can be found near the end of the report.')}}</p>
          <p>
            <ul class="inline-list">
              <li>{{report.owner.name}}</li>
              <li v-for="user in report.collaborators" :key="user.name">{{user.name}}</li>
            </ul>
          </p>
          <p>Dig Inclusion</p>
        </Page>
        <Page class="Report_Page Report_Page_Usage">
          <h2 id="using" tabindex="-1" v-translate>Using this report</h2>
          <p v-translate>This style of audit report is spread over two documents:</p>
          <ul>
            <li v-translate>This report, which identifies issues found during the audit and offers suggested solutions.</li>
            <li><translate :translate-params="{wcag: highestWCAG, conformity: highestConformity}">A spreadsheet containing the URLs or pages of the application each measured against individual WCAG %{wcag} %{conformity} criterion.</translate></li>
          </ul>
          <p>Each issue in this report is assigned a unique code, such as TEST-DIG001-01. These codes serve as identifiers to help teams pinpoint specific issues for discussion.</p>
          <p>Please note that the codes are not always in numerical order, and some numbers may appear skipped. This is because closed issues are not in the final report, but can still be accessed via our Hugr system. Each code is permanently linked to its corresponding issue.</p>
          <p>If you have any questions about the coding system, please contact your Dig Inclusion representative.</p>
          <translate :translate-params="{wcag: highestWCAG}">A brief explanation of Dig evaluation methodology and WCAG %{wcag} can be found at the end of this report under Standards and methodology. Please remember that we can only carry out a detailed assessment of a sample of pages from any one website, unless the site is very small. So, the examples we provide in the "Detailed analysis" section are just that, examples, and should not be regarded as a definitive list of all instances of a specific accessibility problem. Please use these examples to help you identify and correct all other instances of the issue. While we make every effort to identify all existing accessibility issues, the size of most commercial websites means that it is impossible for us to guarantee that we have found every problem that exists, particularly where issues may only occur on one or two pages deep within a site.</translate>
        </Page>
        <Page class="Report_Page Report_Page_Affected">
          <h2 id="affected" tabindex="-1" v-translate>Who may be affected?</h2>

          <div class="Report_Page_Affected_User">
            <h3 v-translate>People who are blind</h3>
            <img class="Report_Page_Affected_User_Image" src="../../assets/images/render/blind.png" alt="woman who is blind" />
            <div class="Report_Page_Affected_User_Details">
              <p v-translate>Blind people use text-to-speech software called screen readers to listen to the content of the web page or application. Even if they have some vision, it’s difficult or impossible for them to follow a cursor, so they will use a keyboard rather than a mouse to navigate the screen content.</p>
              <p v-translate>It’s critical that all components are built with accessibility in mind so that blind people understand what they are for and how to operate them.</p>
            </div>
          </div>

          <div class="Report_Page_Affected_User">
            <h3 v-translate>People who are partially sighted</h3>
            <img class="Report_Page_Affected_User_Image" src="../../assets/images/render/partially_sighted.png" alt="woman who is partially sighted" />
            <div class="Report_Page_Affected_User_Details">
              <p v-translate>People who are partially sighted may need to adapt the screen content to meet their needs. This typically means increasing the contrast and size of text and interface components so that they are easier to see.</p>
              <p v-translate>People who are partially sighted will often use magnification. Because they may only see a small part of the screen at a time, the positioning of related components e.g. form fields and labels needs to be carefully considered.</p>
            </div>
          </div>

          <div class="Report_Page_Affected_User">
            <h3 v-translate>People who are mobility impaired</h3>
            <img class="Report_Page_Affected_User_Image" src="../../assets/images/render/mobility_impaired.png" alt="man who is mobility impaired" />
            <div class="Report_Page_Affected_User_Details">
              <p v-translate>People who are mobility impaired may not be able to use a mouse. They may not be able to use a physical keyboard but are typically able to use a virtual on-screen keyboard.</p>
              <p v-translate>People with mobility impairments will often have very limited movement and will use specialised hardware to interact with a computer. There are many devices on the market that can help people use computers and mobile phones.</p>
            </div>
          </div>

          <div class="Report_Page_Affected_User">
            <h3 v-translate>People who are deaf</h3>
            <img class="Report_Page_Affected_User_Image" src="../../assets/images/render/deaf.png" alt="people communicating via sign language" />
            <div class="Report_Page_Affected_User_Details">
              <p v-translate>People who are deaf need to be able to access alternatives to audio content. Most commonly this refers to the audio track in a video presentation.</p>
              <p v-translate>Designers and developers must also consider deaf people when using audio prompts such as notification tones and provide a visual alternative.</p>
            </div>
          </div>

          <div class="Report_Page_Affected_User">
            <h3 v-translate>People who are cognitively impaired</h3>
            <img class="Report_Page_Affected_User_Image" src="../../assets/images/render/cognitively_impaired.png" alt="woman on a laptop" />
            <div class="Report_Page_Affected_User_Details">
              <p v-translate>Dyslexia, ADHD, Autism and other cognitive attributes mean that people with these attributes may have difficulty with complex interfaces or interactions. People may also find themselves easily distracted by content that is moving or changing.</p>
              <p v-translate>Providing simple interfaces with little or no movement ensures that cognitive distractions are kept to a minimum.</p>
            </div>
          </div>

        </Page>
        <Page class="Report_Page Report_Page_ExecSummary">
          <h2 id="execsummary" tabindex="-1" v-translate>Executive Summary</h2>
          <h3 id="results" tabindex="-1" v-translate>Results</h3>
          <vue-markdown class="Report_Page_ExecSummary_Main md markdown-body" v-highlight :html="false">{{ report.currentVersion.execSummary }}</vue-markdown>

          <h3 id="pageCompliance" tabindex="-1" v-translate>Compliance by page</h3>
          <Table class="PageCompliance">
            <thead class="PageCompliance_Head">
              <tr>
                <th v-translate>Page</th>
                <th class="PageCompliance_Head_Compliance" v-translate>Compliance</th>
              </tr>
            </thead>
            <tbody class="PageCompliance_Body">
              <tr v-for="page of pageComplianceList" :key="page.name" class="PageCompliance_Body_Row">
                <td class="PageCompliance_Body_Row_Page">
                  <p class="PageCompliance_Body_Row_Page_Name">{{ page.name }}</p>
                  <p v-if="page.title!=''||page.url!=''" class="PageCompliance_Body_Row_Page_Details">
                    <span v-if="page.title!=''">{{ page.title}}&nbsp;</span>
                    <span v-if="page.url!=''"><a :href="page.url" target="_blank">{{ page.url }}</a></span>
                  </p>
                </td>
                <td :class="['PageCompliance_Body_Row_Compliance', page.compliance=='Fail'||page.componentCompliance=='Fail'?'_red':'_green']">
                  <p class="PageCompliance_Body_Row_Compliance_Result">
                    <span v-if="page.componentCompliance!='Pass'&&page.compliance=='Pass'">{{ page.componentCompliance }}</span>
                    <span v-else>{{ page.compliance }}</span>
                  </p>
                  <template><!-- <template v-if="!hideComplianceSubText"> -->
                    <p v-if="page.compliance=='Pass'&&page.componentCompliance!='Pass'" class="PageCompliance_Body_Row_Compliance_Details">Issues on components only</p>
                    <p v-else-if="page.componentCompliance!='Pass'" class="PageCompliance_Body_Row_Compliance_Details">Issues on page and components</p>
                  </template>
                </td>
              </tr>
            </tbody>
          </Table>

          <h3 id="keyissues" tabindex="-1" v-translate>Key Issues</h3>
          <template v-for="(issue, key) in processedKeyIssues" v-bind:key='"key-issue-" + key'>
            <h4>{{issue[0]}}</h4>
            <p v-if="issue.length > 1 && issue[1] != ''">
              {{issue[1]}}
            </p>
          </template>
        </Page>
        <Page class="Report_Page Report_Page_ToC">
          <h2 id="contents" tabindex="-1" v-translate>Contents</h2>
          <ul>
            <template v-for="section in sections">
              <template v-for="subsection in section.subsections">
                <template v-for="template in subsection.templates">
                  <li v-if="hasOpenInstances(template.instances)"  v-bind:key="'contentstemplate-' + template.template.identifier">
                    <a :href="`#${template.template.identifier}`">{{template.template.identifier}}: {{template.template.title}}</a>
                  </li>
                </template>
              </template>
            </template>
          </ul>
        </Page>
        <template v-for="section in sections">
          <template v-for="(subsection, index) in section.subsections">
            <Page v-if="!hidePasses||sectionResult(subsection.templates)!='Pass'" :number="pageNumber" class="Report_Page Report_Page_NewSection" :key="`subsection-${section.title}-${subsection.title}-${index}`">
              <h3 v-if="index==0||!hasBeenRendered(section.title, `section-${section.title}`)" :id="`section-${section.title}`" tabindex="-1">{{ section.title }}</h3>
              <h4 v-if="sectionMeta[subsection.title]" :id="`subsection-${subsection.title}`" tabindex="-1">{{sectionMeta[subsection.title].full}}</h4>
              <h4 v-else :id="`subsection-${subsection.title}`" tabindex="-1">{{subsection.title}}</h4>
              <dl class="Report_Page_NewSection_Details">
                <div>
                  <dt v-translate>Test Result</dt>
                  <dd>{{ sectionResult(subsection.templates) }}</dd>
                </div>
                <div>
                  <dt v-translate>Summary</dt>
                  <dd v-if="sectionMeta[subsection.title]">{{sectionMeta[subsection.title].summary}}</dd>
                  <dd v-else v-translate>Missing</dd>
                </div>
              </dl>
            </Page>

            <template v-for="(template,index2) in subsection.templates">
              <Page v-if="hasOpenInstances(template.instances)" :id="template.template.identifier" :number="pageNumber" :key="`template-${section.title}-${subsection.title}-${index}-${index2}`">
                <h5 :id="`issue-${template.template.identifier}`" tabindex="-1">{{template.template.identifier}}: {{template.template.title}}</h5>
                <Table>
                  <tr>
                    <th v-translate>Issue</th>
                    <td>
                      <vue-markdown :key="template.template.identifier+'-description'" v-highlight :html="false">{{ template.template.description }}</vue-markdown>
                    </td>
                  </tr>
                  <tr>
                    <th v-translate>Severity</th>
                    <td>
                      <span v-if="template.template.severity==0" v-translate>Advisory</span>
                      <span v-else-if="template.template.severity==1" v-translate>Low</span>
                      <span v-else-if="template.template.severity==2" v-translate>Medium</span>
                      <span v-else-if="template.template.severity==3" v-translate>High</span>
                      <span v-else-if="template.template.severity==4" v-translate>Critical</span>
                      <span v-else v-translate>Unknown</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p v-translate>Examples</p>
                      <template v-for="(instance, index) in template.instances">
                        <figure :key="`${template.template.identifier}-figure-${index}`" v-if="instance.primary&&instance.status.indexOf('closed')!=0"> <!-- Double Ensure don't show closed -->
                          <img v-if="instance.evidence[0]" :src="$hugrConfig.assetsUrl+'/evidence/'+instance.evidence[0]" alt="Screenshot of issue" crossorigin="anonymous"/>
                          <figcaption>
                            <vue-markdown :key="instance.identifier+'-caption'" v-highlight :html="false">{{ instance.identifier }}: {{ instance.reason }}</vue-markdown>
                              (
                              <ul class="inline-list">
                                <li v-for="(solution,index) in instance.solutions" v-bind:key="'solution-' + index"><a :href="`${solutionsUri}${solution.identifier}`" target="_blank">{{solution.identifier}}</a></li>
                              </ul>
                              )

                              [
                              <ul class="inline-list">
                                <li v-if="instance.component">{{instance.component.identifier}}</li>
                                <template v-else>
                                  <li>
                                    <span v-if="instance.page">{{instance.page.name}}</span>
                                    <span v-else v-translate>Unknown</span>
                                  </li>
                                  <li v-for="(other,index) in instance.others" v-bind:key="'other-' + index">{{other.page.name}}</li>
                                </template>
                              </ul>
                              ]

                          </figcaption>
                          <router-link v-if="report.hasWriteAccess&&!report.currentVersion.published" :to="`/issue/${instance.identifier}`">{{$gettext('Edit')}} {{ instance.identifier }}</router-link>
                        </figure>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <th v-translate>WCAG Ref(s)</th>
                    <td>
                      <ul>
                        <li v-for="(criterion,index) in template.template.criteria" v-bind:key="'criterion-' + index">{{criterion.criterion}} {{criterion.title}} (Level {{criterion.level}}) [{{criterion.identifier}}]</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th v-translate>Affected users</th>
                    <td>
                      <ul>
                        <li v-for="group in template.template.affected" v-bind:key="group">
                          <span v-if="group=='blind'" v-translate>Blind</span>
                          <span v-else-if="group=='low-vision'" v-translate>Low Vision</span>
                          <span v-else-if="group=='cognitively-impaired'" v-translate>Cognitively Impaired</span>
                          <span v-else-if="group=='deaf'" v-translate>Deaf</span>
                          <span v-else-if="group=='motor-impaired'" v-translate>Motor Impaired</span>
                          <span v-else>{{group}}</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th v-translate>Impact on users</th>
                    <td>
                      <vue-markdown :key="template.template.identifier+'-impact'" v-highlight :html="false">{{ template.template.impact }}</vue-markdown>
                    </td>
                  </tr>
                  <tr>
                    <th v-translate>Related Solutions</th>
                    <td>
                      <ul>
                        <li v-for="(solution,index) in template.template.solutions" v-bind:key="'solution-' + index">
                          <a :href="`${solutionsUri}${solution.identifier}`" target="_blank">{{solution.identifier}}: {{solution.title}}</a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </Table>
              </Page>
            </template>
          </template>
        </template>
      </div>
    </div>
    <Loader v-else />
    <EditRenderDetailsModal v-on:success="$apollo.queries.report.refetch()" ref="editrenderdetails" :data="[]"/>
    <ShareModal v-on:html="downloadHTMLStatement()" v-on:print="doPrint()" :shareType="'report'" ref="rendersharemodal"/>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import AriaListBox from '@/components/Aria/ListBox';

import EditRenderDetailsModal from '@/modals/RenderDetails/Edit';
import ShareModal from '@/modals/Share';

import Page from '@/components/ReportRender/Page';

import * as moment from 'moment';

export default {
  name: 'ReportsRenderView',
  mounted() {
    window.addEventListener( 'scroll', this.scroll );
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            disablePreviewReport
          }
        }
      `,
    } ).then( res => {
      this.disabled = res.data.settings.disablePreviewReport;
    } );
  },
  beforeUnmount() {
    window.removeEventListener( 'scroll', this.scroll );
  },
  data() {
    return {
      disabled: false,
      doingLoading: false,

      identifier: this.$route.params.identifier?.replace( '%20', ' ' ),
      version: this.$route.params.version,
      showingVersion: '0.0',

      shareId: this.$route.params.shareId,

      solutionsUri: 'https://solutions.diginclusion.com/#/solutions/',

      report: false,
      sections: false,

      currentNumber: 0,
      hidePasses: false,
      showAllTeamSections: false,

      sidebar: true,
      sidebarO: [],

      scrollTimeout: false,
      reportSections: false,

      passedIssues: [],
      hasAccess: true,

      undecorated: this.$route.name == 'ReportsRenderUndecorated',
    };
  },
  watch: {
    $route( to, from ) {
      this.identifier = this.$route.params.identifier?.replace( '%20', ' ' );
      this.version = this.$route.params.version;
      this.shareId = this.$route.params.shareId;
      this.$apollo.queries.report.refetch();
    },
    report( to, from ) {
      if( !this.report || typeof this.report == 'undefined' ) {
        this.hasAccess = false;
      } else if( this.report.currentVersion ) {
        this.hasAccess = true;
        this.showingVersion = this.report.currentVersion.version;
        this.$apollo.addSmartQuery( 'sections', {
          query: gql`
            query Sections($version: ObjectID!, $showAllTeamSections: Boolean) {
              sections: Sections(reportVersion: $version, showAllTeamSections: $showAllTeamSections) {
                identifier,
                title,
                subsections {
                  identifier,
                  title,
                  templates {
                    template {
                      _id,
                      identifier,
                      title,
                      description,
                      severity,
                      affected,
                      impact
                      #extra
                      criteria {
                        _id
                        identifier,
                        criterion,
                        title,
                        level
                      }
                      solutions {
                        _id
                        identifier,
                        title
                      }
                    },
                    instances {
                      _id
                      identifier,
                      evidence,
                      reason,
                      primary,
                      status,
                      flag,
                      isAKeyIssueInstance,
                      solutions {
                        _id
                        identifier
                      },
                      page {
                        _id
                        name
                      }
                      component {
                        _id
                        identifier
                      }
                      others {
                        _id
                        page {
                          _id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables() {
            return {
              version: this.report.currentVersion._id,
              showAllTeamSections: this.showAllTeamSections,
            };
          },
        } );
      }
    },
  },
  apollo: {
    report: {
      query() {
        const reportFragment = gql`
          fragment renderReportFragment on Report {
            _id,
            identifier,
            title,
            archived,
            hasWriteAccess,
            productDescription,
            template,
            publicLink,
            linkString,
            spreadsheets {
              template {
                name
              }
            }
            team {
              _id
              name
            }
            owner {
              _id
              name
            }
            collaborators {
              _id
              name
            }
            currentVersion {
              _id
              published,
              publishedDate,
              created,
              version,
              execSummary,
              createdOverride,
              publishedOverride,
              keyIssues
            }
            versions {
              _id,
              version
              published
              publishedDate
            }
            pages {
              _id,
              host,
              path,
              name,
              title,
              issues {
                _id,
                template {
                  severity
                }
              },
              components {
                _id
                issues {
                  _id,
                  template {
                    severity
                  }
                },
              }
            }
          }`;
        if( typeof this.shareId != 'undefined' ) {
          return gql`
            query ReportExt($shareId: String!) {
              report: ReportExt(id: $shareId) {
                ... renderReportFragment
              }
            }
            ${reportFragment}
          `;
        } else if( typeof this.version == 'undefined' ) {
          return gql`
            query Report($ident: String!) {
              report: Report(identifier: $ident) {
                ... renderReportFragment
              }
            }
            ${reportFragment}
          `;
        }

          return gql`
            query Report($ident: String!, $version: String!) {
              report: ReportWithVersion(identifier: $ident, version: $version) {
                ... renderReportFragment
              }
            }
            ${reportFragment}
          `;

      },
      variables() {
        return {
          ident: this.identifier,
          version: this.version,
          shareId: this.shareId,
        };
      },
      fetchPolicy: 'no-cache',
    },
    reportSections: gql`
      {
        reportSections: ReportSections {
          _id
          shortName
          fullName
          summary
          order
        }
      }
    `,
  },
  methods: {
    scroll( e ) {
      clearTimeout( this.scrollTimeout );

      //offset stuff
      const pageOffset = this.$refs.titlepage.$el.getBoundingClientRect().top - 40;
      if( pageOffset > 65 ) {
        this.$refs.sidebar.style.top = `${pageOffset}px`;
      } else {
        this.$refs.sidebar.style.top = `65px`;
      }
      //scrollspy stuff
      this.scrollTimeout = setTimeout( () => {
        const items = document.getElementsByClassName( '_anchor' );
        for( const item of items ) {
          const sItem = document.getElementById( item.getAttribute( 'href' ).replace( '#', '' ) );
          const sItemOffset = sItem.offsetTop + sItem.offsetParent.offsetTop;
          if( sItemOffset > window.scrollY && sItemOffset <= window.scrollY + window.innerHeight ) {
            this.makeCurrent( item );
          }
        }
      }, 100 );
    },
    makeCurrent( item ) {
      const items = document.getElementsByClassName( '_anchor' );
      for( const item of items ) {
        item.classList.remove( '_active' );
        item.removeAttribute( 'aria-current' );
      }
      item.classList.add( '_active' );
      item.setAttribute( 'aria-current', 'location' );
      item.scrollIntoViewIfNeeded();
    },
    scrollTo( e, id ) {
      e.preventDefault();
      const el = document.getElementById( id );
      el.scrollIntoViewIfNeeded();
    },
    moveTo( e, id ) {
      e.preventDefault();
      const el = document.getElementById( id );
      el.focus();
    },
    doPrint() {
      window.print();
    },
    downloadHTMLStatement() {
      let statement = this.$refs.report.outerHTML;
      let allCSS = [ ...document.styleSheets ];

      allCSS = allCSS.map( styleSheet => {
        try {
          return [ ...styleSheet.cssRules ].map( rule => rule.cssText ).join( "" );
        } catch ( e ) {
          console.log( "Access to stylesheet %s is denied. Ignoring…", styleSheet.href );
        }
      } ).filter( Boolean ).join( "\n" );

      [ ...document.images ].forEach( img => {
        if ( statement.indexOf( img.src ) || statement.indexOf( img.getAttribute( 'src' ) ) ) {
          const canvas = document.createElement( 'canvas' );
          canvas.height = img.naturalHeight;
          canvas.width = img.naturalWidth;
          const ctx = canvas.getContext( '2d' );
          
          ctx.drawImage( img, 0, 0, canvas.width, canvas.height );
          const dataUrl = canvas.toDataURL();
          console.log( "created dataurl for: ", img.src, dataUrl );

          statement = statement.replaceAll( img.src, dataUrl );
          statement = statement.replaceAll( img.getAttribute( 'src' ) , dataUrl );
        }
      } );

      statement = statement.replace( `"openSidebar"`, `"openSidebar" onclick="document.getElementById('closeSidebar').style.display = 'inline-block'; document.getElementById('openSidebar').style.display = 'none'; document.getElementById('sidebarContents').style.display = 'block';" ` );
      statement = statement.replace( `"closeSidebar"`, `"closeSidebar" onclick="document.getElementById('openSidebar').style.display = 'inline-block'; document.getElementById('closeSidebar').style.display = 'none'; document.getElementById('sidebarContents').style.display = 'none';" ` );

      const finalHTML = `<!DOCTYPE html><html><head><style>${allCSS}</style></head><body>${statement}</body></html>`;
      const content = `data:text/html;charset=utf-8,${encodeURIComponent( finalHTML )}`;
      const link = document.createElement( 'a' );
      link.setAttribute( 'download', `${this.report.identifier}-export.html` );
      link.setAttribute( 'href', content );
      link.click();
    },
    doCopy() {
      this.$refs.report.focus();
      const el = this.$refs.report;
      const { body } = document;
      let range, sel;
      if ( document.createRange && window.getSelection ) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
              range.selectNodeContents( el );
              sel.addRange( range );
          } catch ( e ) {
              range.selectNode( el );
              sel.addRange( range );
          }
      } else if ( body.createTextRange ) {
          range = body.createTextRange();
          range.moveToElementText( el );
          range.select();
          range.execCommand( "Copy" );
      }
      document.execCommand( 'copy' );
    },
    sectionResult( sections ) {
      let count = 0,
          advisoryCount = 0;
      for( const section of sections ) {
        if( section.template.severity != 0 && this.hasOpenInstances( section.instances ) ) {
          count++;
        } else if( section.template.severity == 0 && this.hasOpenInstances( section.instances ) ) {
          advisoryCount++;
        }
      }

      if( count ) {
        return 'Fail';
      } else if( advisoryCount ) {
        return 'Pass (with advisories)';
      }

        return 'Pass';

    },
    wholeSectionResult( section ) {
      let result = 'Pass';
      for( const subsection of section.subsections ) {
        const r = this.sectionResult( subsection.templates );
        if( r == 'Fail' ) {
          result = r;
        } else if( result != 'Fail' && r == 'Pass (with advisories)' ) {
          result = r;
        }
      }

      return result;
    },
    hasOpenInstances( instances ) { //remove todo's as they don't count unless there's a primary
      const ninst = instances.filter( i => i.identifier.indexOf( 'todo' ) < 0 ).filter( i => i.status.indexOf( 'closed' ) != 0 );

      return ninst.length > 0;
    },
    changeVersion() {
      this.doingLoading = true;
      this.$apollo.query( {
        query: gql`
          query ReportWithVersion($ident: String!, $version: String!) {
            report: ReportWithVersion(identifier: $ident, version: $version) {
              _id,
              currentVersion {
                _id
                published,
                publishedDate,
                created,
                version,
                execSummary,
                createdOverride,
                publishedOverride,
                keyIssues
              }
            }
          }
        `,
        variables: {
          ident: this.report.identifier,
          version: this.showingVersion,
        },
      } ).then( () => {
        this.doingLoading = false;
      } ).catch( () => {
        this.$alerts.error( `Couldn't load version`, `Something went wrong when loading this version, please try again.` );
      } );
    },
    hasBeenRendered( t, id ) {
      const i = document.getElementById( id );
      if( i ) {
        return false;
      }

        return true;

    },
  },
  computed: {
    sectionMeta() {
      if ( !this.reportSections ) return {};

      const meta = {};

      const clone = Array.from( this.reportSections );

      const ordered = clone.sort( ( a, b ) => (
        a.order - b.order
      ) );

      ordered.forEach( section => {
        meta[section.shortName] = {
          full: section.fullName,
          summary: section.summary,
        };
      } );

      return meta;
    },

    highestWCAG() {
      let wcag = '2.2';

      switch( this.report.template ) {
        case 'aaa':
        case 'default': {
          wcag = '2.1';
          
          break;
        }
        case 'wcag22aa':
        case 'wcag22aaa':
        default: {
          wcag = '2.2';

          break;
        }
      }

      return wcag;
    },

    // highestWCAG() {
    //   let main = 2;
    //   let minor = 1;

    //   if ( !this.report.spreadsheets ) return `${main}.${minor}`;

    //   this.report.spreadsheets.forEach( spreadsheet => {
    //     if ( spreadsheet.template.name.toLowerCase().indexOf( "wcag" ) >= 0 ) {
    //       const split = spreadsheet.template.name.toLowerCase().split( "wcag" );
    //       const numberSplit = split[1].split( "." );

    //       if ( main < parseInt( numberSplit[0].trim() ) ) {
    //         main = parseInt( numberSplit[0].trim() );
    //       }

    //       if ( minor < parseInt( numberSplit[1].substring( 0,2 ).trim() ) ) {
    //         minor = parseInt( numberSplit[1].substring( 0,2 ).trim() );
    //       }
    //     }
    //   } );

    //   return `${main}.${minor}`;
    // },

    highestConformity() {
      let conformity = "AA";

      if ( !this.report.spreadsheets ) return conformity;

      this.report.spreadsheets.forEach( spreadsheet => {
        if ( spreadsheet.template.name.indexOf( " AAA" ) >= 0 ) {
          conformity = "AAA";
        }
      } );

      return conformity;
    },

    pageNumber() {
      //TODO make better
      // eslint-disable-next-line
      return this.currentNumber++;
    },
    createdDate() {
      return moment( this.report.currentVersion.created ).format( 'Do MMMM YYYY' );
    },
    publishedDate() {
      if( this.report.currentVersion.publishedDate ) {
        return moment( this.report.currentVersion.publishedDate ).format( 'Do MMMM YYYY' );
      }

        return 'Not published';

    },
    issuesMarkedAsKeyIssues() {
      const ret = [];
      for ( const issue of this.allInstances ) {
        if( issue.isAKeyIssueInstance ) {
          ret.push( issue );
        }
      }

      return ret;
    },
    processedKeyIssues() {
      const ret = [];
      for ( const keyIssue of this.report.currentVersion.keyIssues ) {
        const split = keyIssue.split( '<keyIssueBodySeparator>' );
        ret.push( split );
      }

      return ret;
    },
    allInstances() { //open only, this isn't used here?
      const ret = [];
      if( this.sections ) {
        for( const section of this.sections ) {
          for( const subsection of section.subsections ) {
            for( const template of subsection.templates ) {
              for( const instance of template.instances ) {
                if( instance.primary && ( instance.status.indexOf( 'closed' ) == -1 ) ) {
                  ret.push( {
                    ...instance,
                    section: `${section.title} > ${subsection.title}`,
                  } );
                }
              }
            }
          }
        }
      }

      return ret;
    },

    hasUnchecked() {
      return this.allInstances.filter( i => i.flag == 'unchecked' ).length;
    },
    hasToCheck() {
      return this.allInstances.filter( i => i.flag == 'tocheck' ).length;
    },
    hasChecked() {
      return this.allInstances.filter( i => i.flag == 'checked' ).length;
    },
    hasChangeRequired() {
      return this.allInstances.filter( i => i.flag == 'change-required' ).length;
    },
    hasSecondOpinion() {
      return this.allInstances.filter( i => i.flag == 'second-opinion' ).length;
    },

    versionOpts() {
      const opts = {};
      for( const version of this.report.versions ) {
        opts[version.version] = version.version;
      }

      return opts;
    },
    versionOptsExt() {
      const opts = {};
      for( const version of this.report.versions ) {
        if( version.published ) {
          opts[version.version] = `published ${moment( version.publishedDate ).format( 'Do MMMM YYYY' )}`;
        } else {
          opts[version.version] = 'Not published';
        }
      }

      return opts;
    },
    pageComplianceList() {
      const out = [];
      for( const page of this.report.pages ) {
        let pageCompliance = 'Pass';
        let componentCompliance = 'Pass';
        for( const issue of page.issues ) {
          if( issue.template.severity != '0' ) {
            pageCompliance = 'Fail';
          } else if( pageCompliance == 'Pass' ) {
            pageCompliance = 'Pass with advisories';
          }
        }
        for( const component of page.components ) {
          for( const issue of component.issues ) {
            if( issue.template.severity != '0' ) {
              componentCompliance = 'Fail';
            } else if( componentCompliance == 'Pass' ) {
              componentCompliance = 'Pass with advisories';
            }
          }
        }
        out.push( {
          name: page.name,
          title: page.title,
          url: `${page.host}${page.path}`,
          compliance: pageCompliance,
          componentCompliance,
        } );
      }

      return out;
    },
  },
  components: {
    Page,
    EditRenderDetailsModal,
    ShareModal,
    AriaListBox,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_mixins.scss';

  .Render {
    &_TopBar {
      position: relative;

      &_Info {

      }
      &_Bar {
        background: #FFF;
        width: 100%;
        height: 55px;
        position: relative;
        padding: 0 50px;
      }
      &_Buttons {
        position: absolute;
        right: 50px;
        top: 7px;

        button {
          margin-left: 8px;
        }

        &_VersionSelect {
          display: inline-block;
          width: 200px;
          margin-left: 8px;
          vertical-align: middle;
        }
      }
    }
  }
  .Report {
    background: #e5ebf2;
    padding-top: 20px;
    &_SideBar {
      position: fixed;
      left: 0;
      padding: 16px;
      width: 20%;
      overflow: hidden;
      height: calc(100vh - 100px);
      
      @include vertical-scroll;

      ul {
        list-style: none;
        margin: 0;
        margin-left: 8px;
        padding: 0;
        a {
          color: $hugr-colours-primary;
          text-decoration: none;
          padding: 2px 0px;
          display: block;

          &._active {
            color: $hugr-colours-tertiary;
          }
        }
      }
    }
    &._sidebaropen {
      .page {
        position: relative;
        left: 150px;
      }
    }
    &_Page {
      position: relative;
      &_ExecSummary {
        &_Main {
          margin-top: 20px;
        }
      }
      &_FirstPage {
        background: $dig-blue !important;
        color: #FFFFFF !important;
        position: relative;
        &_Logo {
          width: 40%;
        }
        // &_Project {
        //   display: inline-block;
        //   width: 100%;
        //   font-size: 2em;
        //   // border-bottom: 2px solid;
        //   // margin-top: 50px;
        //   // margin-bottom:0;
        // }
        &_Title {
          margin: 16px 0;
          margin-top: 300px;
          h1 {
            font-size: 3em;
            margin: 0;
          }
          span {
            font-size: 0.8em;
            position: relative;
            top: -10px;
          }
        }
        &_Dates {
          margin-top: 0;
        }

        &_Details {
          &_Item {
            margin: 10px 0;

            &._inline {
              dd, dt {
                display: inline;
                margin: 0;
              }
              dd {
                margin-left: 10px;
              }
            }
          }
        }
      }

      &_Affected {
        &_User {
          &_Image {
            display: inline-block;
            vertical-align: top;
            width: 49%;
            margin-top: 20px;
          }
          &_Details {
            display: inline-block;
            vertical-align: top;
            width: 49%;
            margin-left: 2%;
            margin-top: 20px;
            p {
              margin-top: 0;
            }
          }
        }
      }

      &_NewSection {
        &_Details {
          > div {
            > * {
              display: inline-block;
              vertical-align: top;
            }
          }
        }
      }

      h3 {
        background: #262E37;
        color: #FFF;
        display: inline-block;
        width: 100%;
        padding: 15px 2cm;
        position: relative;
        left: -2cm;

        margin-bottom: 0;
      }

      h4 {
        background: #d3d5d7;
        color: #000;
        display: inline-block;
        width: 100%;
        padding: 5px 2cm;
        position: relative;
        left: -2cm;

        margin-top: 0;
      }
    }

    table {
      max-width:800px !important;
    }

    ul.inline-list {
      padding: 0;
      margin: 0;
      list-style: none;
      display: inline;
      li {
        display: inline;
        &:after {
          content: ', ';
        }
        &:last-child:after {
          content: '';
        }
      }
    }
  }

  ._darkMode {
    .Render {

    }

    .Report {
      background: darken(#e5ebf2, 80%);

      &_SideBar {
        ul {
          a {
            color: $hugr-colours-grey;
            &._active {
              color: $hugr-colours-secondary;
            }
          }
        }
      }
      &_Page {
        background: #000;
      }
    }

    .page {
      background: #000;
    }
  }

  .PageCompliance {
    // margin: 18px;
    // width: calc(100% - 32px);
    table-layout: fixed;
    &_Head {
      &_Compliance {
        width: 200px;
      }
    }

    &_Body {
      &_Row {
        &_Page {
          &_Name {
            margin: 2px 0;
          }
          &_Details {
            font-size: 0.8em;
            margin-top: 0;
            margin-bottom: 2px;

            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        &_Compliance {
          &._red {
            background: lighten($hugr-colours-red, 50%);
          }
          &._green {
            background: lighten($hugr-colours-green, 50%);
          }

          &_Result {
            margin: 2px 0;
          }

          &_Details {
            font-size: 0.8em;
            margin-top: 0;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  ._darkMode .PageCompliance {
    &_Body {
      &_Row {
        &_Compliance {
          &._red {
            background: darken($hugr-colours-red, 20%);
          }
          &._green {
            background: darken($hugr-colours-green, 20%);
          }
        }
      }
    }
  }

  @media print {
    .reportbtns {
      display: none !important;
    }
    .Report {
      position: relative !important;
      background: #FFF !important;

      &_SideBar {
        display: none !important;
      }

      .page {
        position: unset !important;
        left: 0 !important;
        background: #FFF !important;
        color: black;
      }

      &_Page {

        &_FirstPage {
          color: black !important;
        }
      }
      
    }

    .notice {
      display: none !important;
    }
  }
</style>
