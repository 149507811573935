<template>
  <div class="page">
    <slot></slot>
    <div v-if="number" class="page-footer">Page {{number}}</div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    number: Number,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  div.page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 2cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  @media print {
    div.page {
      position: relative !important;

      box-shadow: none !important;
      border: none !important;
      margin: unset !important;
      height: auto !important;
      min-height: unset !important;
      padding: unset !important;
      width: 100% !important;

      page-break-after: always !important;
      page-break-inside: avoid !important;
    }
  }
</style>
