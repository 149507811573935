<template>
  <Body title="Import Users" class="Import">
    <DataTable :headers="[
              $gettext('Num'),
              $gettext('Identifier'),
              $gettext('Name'),
              $gettext('Alias'),
              $gettext('Portfolio'),
              $gettext('Owner'),
              $gettext('Customer'),
              $gettext('URL'),
              $gettext('Compliance Statement URL'),
              $gettext('Compliance'),
              $gettext('Risk'),
              $gettext('Disproportionate Burden'),
              $gettext('Notes'),
              $gettext('Ignore'),
              ''
            ]">
      <tr v-for="(report, i) in parsedData" :key="i">
        <td>{{ i+1 }}</td>
        <td><input aria-label="identifier" v-model="report.identifier" /></td>
        <td>
          {{ report.name }}&nbsp;
          <span v-if="report.reportExists">(Already exists, will add notes)</span>
        </td>
        <td>{{ report.alias }}</td>
        <td :class="!report.portfolioExists?'_red':''">
          {{ report.portfolio }}&nbsp;
          <span v-if="!report.portfolioExists">(add portfolio first)</span>
        </td>
        <td :class="!report.userExists?'_red':''">
          {{ report.owner }}&nbsp;
          <span v-if="!report.userExists">(add user first)</span>&nbsp;
          <span v-else>({{ report.userName }})</span>
        </td>
        <td :class="!report.customerExists?'_red':''">
          {{ report.customer }}&nbsp;
          <span v-if="!report.customerExists">(add customer first)</span>
        </td>
        <td>
          {{ report.URL }}
        </td>
        <td>
          {{ report.statementURL }}
        </td>
        <td>
          {{ report.complianceText }} ({{ report.compliance }})
        </td>
        <td>
          {{ report.riskText }} ({{ report.risk }})
        </td>
        <td>
          {{ report.disproportionateBurdenText }} ({{ report.disproportionateBurden}})
        </td>
        <td>
          {{ report.notes }}&nbsp;
          <span v-if="report.commentPosted">Added</span>
        </td>
        <td><input aria-label="Ignore" type="checkbox" v-model="report.ignore"/></td>
        <td>
          <span v-if="report.made">Created</span>
        </td>
      </tr>
    </DataTable>
    <Button @click="importData()">Import</Button>
    <div class="Import_Settings">
      <div>
        <label for="order">Order</label>&nbsp;
        <input v-model="order" id="order" />
        &nbsp;&nbsp;
        <label for="splitter">Splitter</label>&nbsp;
        <select id="splitter" v-model="splitter">
          <option value="0">Comma</option>
          <option value="1">Tab</option>
        </select>
        &nbsp;&nbsp;
        <label for="owner">Default Owner</label>&nbsp;
        <input v-model="defaultOwner" id="owner" />
      </div>
      <br />
      <div>
        <label for="csv">Data</label>
        <textarea id="csv" v-model="csv"></textarea>
      </div>
      <Button @click="parse()">Parse</Button>
    </div>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

export default {
  name: "ImportReports",
  data() {
    return {
      csv: '',
      splitter: 0,
      splitters: [ ",", "\t" ],
      order: "identifier, name, alias, portfolio, owner, customer, statementExists, URL, statementURL, compliance, risk, disproportionateBurden, notes",
      defaultOwner: '',

      parsedData: false,
    };
  },
  methods: {
    parse() {
      // const lines = this.csv.split( "\n" );
      const lines = this.csv.split( /\n(?=(?:(?:[^"]*"){2})*[^"]*$)/ );
      const ret = [];

      for( const line of lines ) {
        if( line.trim() == "" ) continue;

        // const components = line.split( this.splitters[this.splitter] );
        const regex = new RegExp( `${this.splitters[this.splitter]}(?=(?:(?:[^"]*"){2})*[^"]*$)` );
        const components = line.split( regex );
        
        const newLine = {};
        for( const i in this.parsedOrder ) {
          if( components.hasOwnProperty( i ) ) {
            newLine[this.parsedOrder[i]] = components[i].trim();
          }
        }
        ret.push( newLine );
      }

      const done = [];

      for( const i in ret ) {
        if( ret.hasOwnProperty( i ) ) {
          const item = ret[i];

          //no identifier
          if( item.identifier.trim() == "" ) {
            if( item.alias.trim() != "" && item.alias.trim().indexOf( ' ' ) < 0 ) {
              item.identifier = item.alias.trim();
            } else {
              item.identifier = item.name.split( " " ).map( w => w[0] ).join( "" ).toUpperCase();
            }
          }

          if( item.portfolio.trim().indexOf( ' ' ) >= 0 ) {
            item.portfolio = item.portfolio.split( " " ).map( w => w[0] ).join( "" ).toUpperCase();
          }

          if( item.owner.trim() == '' ) {
            item.owner = this.defaultOwner;
          }

          item.compliance = item.compliance.trim().toLowerCase();
          switch( item.compliance ) {
            case 'partially - mostly unmet': {
              item.compliance = 1;
              item.complianceText = 'partially - mostly unmet';

              break;
            }
            case 'partially - mostly met': {
              item.compliance = 2;
              item.complianceText = 'Partially - mostly met';

              break;
            }
            case 'met': {
              item.compliance = 3;
              item.complianceText = 'Met';

              break;
            }
            case 'exceeded': {
              item.compliance = 4;
              item.complianceText = 'Exceeded';

              break;
            }
            default: {
              item.compliance = 0;
              item.complianceText = 'Unknown';
            }
          }

          item.risk = item.risk.trim().toLowerCase();
          switch( item.risk ) {
            case 'high': {
              item.risk = 2;
              item.riskText = 'High';

              break;
            }
            case 'low': {
              item.risk = 0;
              item.riskText = 'Low';

              break;
            }
            case 'medium':
            default: {
              item.risk = 1;
              item.riskText = 'Medium';
            }
          }

          item.disproportionateBurden = item.disproportionateBurden.trim().toLowerCase();
          switch( item.disproportionateBurden ) {
            case 'being concidered':
            case 'being considered': {
              item.disproportionateBurden = 1;
              item.disproportionateBurdenText = 'Being considered';

              break;
            }
            case 'made': {
              item.disproportionateBurden = 2;
              item.disproportionateBurdenText = 'Made';

              break;
            }
            case 'expired': {
              item.disproportionateBurden = 3;
              item.disproportionateBurdenText = 'Expired';

              break;
            }
            case 'none':
            default: {
              item.disproportionateBurden = 0;
              item.disproportionateBurdenText = 'None';
            }
          }

          if( done.indexOf( item.identifier ) >= 0 ) {
            item.duplicate = true;
            item.ignore = true;
          } else {
            item.duplicate = false;
            item.ignore = false;
          }

          if( item.identifier.trim() == "" ) {
            item.ignore = true;
            item.cantAdd = true;
          } else {
            item.cantAdd = false;
          }

          done.push( item.identifier );

          this.checkReport( item, i );
          this.checkPortfolio( item, i );
          this.checkOwner( item, i );
          this.checkCustomer( item, i );
        }
      }

      this.parsedData = ret;
    },
    checkReport( report, i ) {
      const { identifier } = report;
      this.$apollo.query( {
        query: gql`
          query Report($identifier: String!) {
            report: Report(identifier: $identifier) {
              _id,
              title
            }
          }
        `,
        variables: {
          identifier,
        },
      } ).then( res => {
        if( res.data.report ) {
          this.parsedData[i].reportId = res.data.report._id;
          this.parsedData[i].reportExists = true;
          this.parsedData[i].ignore = true;
        } else {
          this.parsedData[i].reportExists = false;
        }
      } ).catch( err => {
        // this.$catchError( err );
        this.parsedData[i].reportExists = false;
      } );
    },
    checkPortfolio( report, i ) {
      if( report.portfolio ) {
        this.$apollo.query( {
          query: gql`
            query Portfolio($identifier: String!) {
              portfolio: Portfolio(identifier: $identifier) {
                _id,
                name
              }
            }
          `,
          variables: {
            identifier: report.portfolio,
          },
        } ).then( res => {
          if( res.data.portfolio ) {
            this.parsedData[i].portfolioId = res.data.portfolio._id;
            this.parsedData[i].portfolioExists = true;
          } else {
            this.parsedData[i].portfolioExists = false;
            this.parsedData[i].ignore = true;
          }
        } ).catch( err => {
          this.$catchError( err );
          //TODO error
        } );
      }
    },
    checkOwner( report, i ) {
      if( report.owner ) {
        this.$apollo.query( {
          query: gql`
            query UserFromEmail($email: String!) {
              user: UserFromEmail(email: $email) {
                _id,
                name
              }
            }
          `,
          variables: {
            email: report.owner,
          },
        } ).then( res => {
          if( res.data.user ) {
            this.parsedData[i].userId = res.data.user._id;
            this.parsedData[i].userExists = true;
            this.parsedData[i].userName = res.data.user.name;
          } else {
            this.parsedData[i].userExists = false;
            this.parsedData[i].ignore = true;
          }
        } ).catch( err => {
          this.$catchError( err );
          //TODO error
        } );
      }
    },
    checkCustomer( report, i ) {
      if( report.customer ) {
        this.$apollo.query( {
          query: gql`
            query CustomerFromName($name: String!) {
              customer: CustomerFromName(name: $name) {
                _id,
                name
              }
            }
          `,
          variables: {
            name: report.customer,
          },
        } ).then( res => {
          if( res.data.customer ) {
            this.parsedData[i].customerId = res.data.customer._id;
            this.parsedData[i].customerExists = true;
          } else {
            this.parsedData[i].customerExists = false;
            this.parsedData[i].ignore = true;
          }
        } ).catch( err => {
          this.$catchError( err );
          //TODO error
        } );
      }
    },
    importData() {
      this.importReports();
      this.postComments();
    },

    importReports() {
      for( const report of this.parsedData ) {
        if( !report.ignore ) {
          const reportInput = {
            identifier: report.identifier,
            title: report.name,
            template: 'default',
            customer: report.customerId,
            owner: report.userId,
            currentVersion: null,
            archived: false,
            collaborators: [],
            portfolio: report.portfolioId,
            team: null,
            productAliases: report.alias.split( ',' ).map( t => t.trim() ),
            productDescription: '',
            productUrl: report.URL,
            productStatementUrl: report.statementURL,
            productRisk: report.risk.toString(),
            productRiskDescription: '',
            disproportionateBurden: report.disproportionateBurden.toString(),
            disproportionateBurdenDescription: '',
            accessibilityStatement: report.compliance.toString(),
            accessibilityStatementNotes: '',
          };
          this.$apollo.mutate( {
            mutation: gql`
              mutation addReport($report: ReportInput!) {
                report: addReport(report: $report) {
                  _id
                }
              }
            `,
            variables: {
              report: reportInput,
            },
          } ).then( res => {
            report.made = true;
            report.reportId = res.data.report._id;
          } ).catch( err => {
            this.$catchError( err );
            //TODO error
          } );
        }
      }
    },
    postComments() {
      if( this.parsedData.map( r => !!r.reportId ).indexOf( false ) >= 0 ) {
        setTimeout( () => {
          this.postComments();
        }, 100 );
      } else {
        for( const report of this.parsedData ) {
          if( report.notes.trim() != "" ) {
            const commentInput = {
              reference: report.reportId,
              from: report.userId,
              contents: report.notes,
              edited: false,
            };
            this.$apollo.mutate( {
              mutation: gql`
                mutation addComment($comment: CommentInput!) {
                  comment: addComment(comment: $comment) {
                    _id
                  }
                }
              `,
              variables: {
                comment: commentInput,
              },
            } ).then( () => {
              report.commentPosted = true;
            } ).catch( err => {
              this.$catchError( err );
              //TODO
            } );
          }
        }
      }
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    parsedOrder() {
      const ret = [];
      for( const i of this.order.split( ',' ) ) {
        ret.push( i.trim() );
      }

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
  .Import {
    &_Settings {
      textarea {
        width: 100%;
        height: 200px;
      }
    }
  }

  ._red {
    background: red;
  }
</style>
