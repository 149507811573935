<template>
  <img :class="[preload?'_preload':'']"
    :src="src"
    @error="authLoad"
    @load="loaded"
    :alt="alt" />
</template>

<script setup>
  import { ref, toRefs, watch } from 'vue';

  const props = defineProps( {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
  } );

  const {
    src,
    alt,
  } = toRefs( props );

  const preload = ref( true );

  const authLoad = e => {
    fetch( e.target.src,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem( 'HUGR_ACCESS_TOKEN' )}`,
          'hugr-refresh-token': localStorage.getItem( 'HUGR_REFRESH_TOKEN' ),
        },
      } ).then( r => r.blob() ).then( d => {
        e.target.src = window.URL.createObjectURL( d );
        preload.value = false;
      } );
  };

  const loaded = () => {
    preload.value = false;
  };

  watch( src, () => {
    preload.value = true;
  } );
</script>

<style lang="scss" scoped>

</style>
