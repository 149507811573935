<template>
  <Modal ref="modal" :title="solution?solution.identifier+': '+solution.title:'Solution Details'" >
    <form>
      <Table v-if="solution">
        <tr>
          <th v-translate>Overview</th>
          <td>
            <vue-markdown :key="solution.identifier+'-overview'" v-highlight :html="false">{{ solution.overview }}</vue-markdown>
          </td>
        </tr>
        <tr>
          <th v-translate>Incorrect code example</th>
          <td>
            <vue-markdown :key="solution.identifier+'-code'" v-highlight :html="false">{{ solution.code }}</vue-markdown>
          </td>
        </tr>
        <tr>
          <th v-translate>Suggested code example</th>
          <td>
            <vue-markdown :key="solution.identifier+'-fix'" v-highlight :html="false">{{ solution.fix }}</vue-markdown>
          </td>
        </tr>
        <tr v-if="solution.issueTemplates.length">
          <th v-translate>Associated issues</th>
            <td>
              <ul>
                <li v-for="issueTemplate in solution.issueTemplates.slice(0, 3)" :key="issueTemplate._id">[{{ issueTemplate.identifier }}] {{ issueTemplate.title  }}</li>
              </ul>
            </td>
          </tr>
        <tr v-if="solution.metadata.length > 0 && solution.metadata[0]!=''">
          <th v-translate>Associated metadata tags</th>
          <td>
              <span v-bind:key="solution.identifier+ '-tag-' + key" class="pill" v-for="(tag,key) in solution.metadata">
                {{ solution.metadata[key] }}
              </span>
          </td>
        </tr>
      </Table>
    </form>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import UITable from '@/components/UI/Table';

export default {
  name: 'SolutionDetailsModal',
  data() {
    return {
      solution: false,
    };
  },
  methods: {
    show( solution ) {
      this.$apollo.query( {
        query: gql`
          query Solution($id: ObjectID!) {
            solution: Solution(id: $id) {
              _id,
              identifier,
              title,
              overview,
              code,
              fix,
              metadata,
              issueTemplates {
                _id
                identifier
                title
              }
              owner {
                _id,
                name
              }
              team {
                _id
                name
              }
              private
              locked
              canEdit
            }
          }
        `,
        variables: {
          id: solution,
        },
      } ).then( res => {
        this.solution = res.data.solution;
        this.$refs.modal.show();
      } ).catch( () => {
        this.$alerts.coded( 'E084', 'F4901' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.$refs.modal.reset();
    },
  },
  components: {
    Table: UITable,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .pill {
    background-color: $hugr-colours-secondary;
    border-radius: 16px;
    padding-right: 4px;
    padding-left: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    font-size: 0.8em;
    margin-right: 5px;
    color:black;
  }

</style>
