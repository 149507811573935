<template>
  <div :class="[hasError?'with-error':'']">
    <div class="searchselect" :ref="uid+'combo'" role="combobox" :aria-owns="`${uid}-listbox`" :aria-expanded="results.length?'true':'false'" v-on:keyup.esc.prevent="doClose" v-on:keydown.down.prevent="down" v-on:keydown.up.prevent="up" v-on:keydown.tab="tabAway">
      <label :for="`${uid}-input`" :class="[iconOnly?'icon-only':'']">
        <Icon v-if="icon.length" :type="icon[0]" :icon="icon[1]" />
        <span class="label-text">
          {{ label }}
          <Icon class="label-required" v-if="required" type="solid" icon="asterisk" title="required" aria-label="required"/>
        </span>
      </label>
      <input  :id="`${uid}-input`"
              :class="[iconOnly?'icon-only':'']"
              :aria-describedby="[hintText ? uid+'-hint' : '', hasError ? uid+'-error' : ''].join(' ')"
              ref="mainInput"
              v-model="value"
              @input="update"
              @focus="mainFocussed"
              @click="mainFocussed"
              :aria-controls="uid-listbox"/>
      <Icon v-if="dropdown" class="dropdown-arrow" type="solid" icon="chevron-down" @click="$refs.mainInput.focus()"/>
      <ul v-if="results.length" ref="dropdown" class="searchselect-dropdown" :id="`${uid}-listbox`" role="listbox" :key="`${value}-${results.length}`">
        <li v-for="result in results" :key="result.value" :class="[(result.priority?'priority':'')]" tabindex="-1" role="option" @click.prevent="doSelect(result.value, result.display)" v-on:keyup.enter="doSelect(result.value, result.display)">
          {{ result.display }}
        </li>
      </ul>
    </div>
    <div v-if="hasError" :id="`${uid}-error`" class="error">{{errorMessage}}</div>
    <div v-if="hintText" :id="`${uid}-hint`" class="hint">{{ hintText }}</div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
// import { mapState, mapActions } from 'vuex';

import mixin from '@/components/Form/Mixin';
export default {
  mixins: [ mixin ],
  name: 'AriaSearchSelect',
  created() {
    this.getOpts();
  },
  data() {
    return {
      uid: this.$uuid.generate(),

      cOptions: this.options,

      id: null,
      value: '',
      results: [],
    };
  },
  methods: {
    refetch() { //refetch the gql opts
      this.getOpts();
    },
    focus() { //focus the input
      this.$refs['mainInput'].focus();
    },
    getOpts() {
      this.$apollo.query( {
        query: gql`query Options($func: String!, $param: String) {
          options: Options(function: $func, param: $param) {
            value,
            display,
            search
          }
        }`,
        variables: {
          func: this.gqlOptions[0],
          param: ( this.gqlOptions.length > 1 ) ? this.gqlOptions[1] : null,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.cOptions = res.data.options;
        if( typeof this.$refs.mainInput != 'undefined' && this.default != '' ) {
          for( const option of this.cOptions ) {
            if( option.value == this.default ) {
              this.$refs.mainInput.value = option.display;
              this.$emit( 'selected', option.value, option.display, this );
            }
          }
        }
      } ).catch( () => {
        this.$alerts.coded( 'E016', 'F601' ); //see notifications spreadsheet
      } );
    },
    validate( value ) {
      let success = true;
      const messages = [];
      for( const validation of this.validation ) {
        if( typeof validation == 'object' && validation.regex ) {
          //todo
          if( !validation.regex.test( this.value ) ) {
            this.success = false;
            messages.push( validation.message );
            this.addError( validation.message );
          }
        } else {
          switch( validation ) {
            case 'not-empty': {
              if( this.value == '' ) {
                success = false;
                messages.push( 'This cannot be empty' );
                this.addError( 'This cannot be empty' );
              }

              break;
            }
            case 'valid-option': {
              if( this.cOptions.map( o => o.value ).indexOf( value ) < 0 ) {
                success = false;
                messages.push( 'Option is not valid, please make sure one is selected from the dropdown' );
                this.addError( 'Option is not valid, please make sure one is selected from the dropdown' );
              }

              break;
            }
            case 'option-matches': {
              if( this.value != '' && value != 0 && this.cOptions.map( o => o.value ).indexOf( value ) >= 0 ) if( this.cOptions[this.cOptions.map( o => o.value ).indexOf( value )].display != this.value ) {
                success = false;
                messages.push( `Option selected doesn't match, please reselect from the dropdown` );
                this.addError( `Option selected doesn't match, please reselect from the dropdown` );
              }

              break;
            }
            default: {
              throw `Validation method doesn't exist`;
            }
          }
        }
      }

      return {
        success,
        messages,
      };
    },
    update() {
      this.results = [];
      if( this.value != '' ) {
        if( this.priority.length ) {
          for( const opt of this.cOptions ) {
            if( this.priority.indexOf( opt.value ) >= 0 ) {//&& this.results.indexOf(opt)==-1) {
              this.results.push( {
                ...opt,
                priority: true,
              } );
            }
          }
        }
        for( const opt of this.cOptions ) {
          if(
              (
                opt.display.toLowerCase().indexOf( this.value.toLowerCase() ) >= 0
                || opt.search.toLowerCase().indexOf( this.value.toLowerCase() ) >= 0
              )
              && this.results.map( result => result.value ).indexOf( opt.value ) == -1
            ) {
            // let opt2 = opt;
            // opt2.priority = false;
            this.results.push( {
              ...opt,
              priority: false,
            } );
          }
        }
      }
      if( this.results.length ) {
        document.addEventListener( 'click', this.clickOutside );
        document.addEventListener( 'touchstart', this.clickOutside );
      }
    },
    mainFocussed() {
      if( ( this.value == '' || this.dropdown ) && this.results.length == 0 ) {
        const priority = [];
        const other = [];
        for( const opt of this.cOptions ) {
          if( this.priority.indexOf( opt.value ) >= 0 ) { //&& this.results.indexOf(opt)==-1) {
            priority.push( {
              ...opt,
              priority: true,
            } );
          } else {
            other.push( {
              ...opt,
              priority: false,
            } );
          }
        }
        if( this.dropdown ) {
          this.results = priority.concat( other );
        }
        if( this.results.length ) {
          document.addEventListener( 'click', this.clickOutside );
          document.addEventListener( 'touchstart', this.clickOutside );
        }
      }
    },
    clickOutside( evt ) {
      // let target = evt.target;
      const combo = this.$refs[ `${this.uid}combo` ];
      const parent1 = evt.target.parentNode && evt.target.parentNode == combo;
      const parent2 = evt.target.parentNode && evt.target.parentNode.parentNode && evt.target.parentNode.parentNode == combo;
      if( parent1 || parent2 ) {
        //is not outside TODO: reverse this
      } else {
        this.close();
      }
    },
    doSelect( value, display ) {
      // this.callback(value, display, this)
      this.$emit( 'selected', value, display, this );
    },
    doClose() { //do a close inside input
      this.close();
      //return focus to input
      this.$refs.mainInput.focus();
    },
    close() { //do a close overall
      if( this.results.length ) {
        document.removeEventListener( 'click', this.clickOutside );
        document.removeEventListener( 'touchstart', this.clickOutside );
      }
      //close list
      this.results = [];
      this.$forceUpdate();
    },
    tabAway() {
      this.close();
    },
    down( event ) {
      event.preventDefault();
      if( document.activeElement == this.$refs.mainInput && this.results.length ) { //is on input and open go to first
        this.$refs.dropdown.children[0].focus();
      } else if( this.results.length && document.activeElement.nextElementSibling != null ) { //go to next opt
          document.activeElement.nextElementSibling.focus();
      } else { //failsafe back to input
        this.$refs.mainInput.focus();
      }
    },
    up( event ) {
      event.preventDefault();
      if( this.results.length && document.activeElement.previousElementSibling != null ) { //go to prev opt
          document.activeElement.previousElementSibling.focus();
      } else { //failsafe back to input
        this.$refs.mainInput.focus();
      }
    },
    clear() {
      this.close();
      this.value = '';
    },
    select( id, display ) { //display is optional
      this.$refs.mainInput.focus();
      this.selectWithoutFocus( id, display );
    },
    selectWithoutFocus( id, display ) {
      if( display ) {
        this.value = display;
      } else {
        for( const option of this.cOptions ) {
          if( option.value == id ) {
            this.value = option.display;
          }
        }
      }
      this.results = [];
    },
  },
  computed: {
    required() {
      return this.validation.indexOf( 'not-empty' ) >= 0;
    },
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    idRoot: {
      type: String,
      default: 'missing',
      // required: true
    },
    gqlOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    default: {
      type: String,
      default: '',
    },
    priority: {
      type: Array,
      default: () => {
        return [];
      },
    },
    icon: {
      type: Array,
      default: () => {
        return [];
      },
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hintText: {
      type: String,
      required: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_mixins.scss';

  div {
    div.searchselect {
      border: 1px solid #b2b3b2;
      border-radius: 2px;
      background: white;
      position: relative;
      margin-bottom: 10px;
      transition: background-color .5s ease-in-out 0s, color .5s ease-in-out 0s;
      label {
        padding: 5px;
        border-right: 1px solid #b2b3b2;
        display: inline-block;
        width: 140px;
        text-align: center;
        &.icon-only {
          width: 20px;
          span.label-text {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0,0,0,0);
            border: 0;
          }
        }

        .label-required {
          font-size: 0.6em;
          position: relative;
          top: -6px;
          left: -2px;
        }
      }
      input {
        padding: 7px 5px;
        background: transparent;
        border: none;
        display: inline-block;
        width: calc(100% - 161px);
        position: absolute;
        right: 0;
        top: 0;
        &.icon-only {
          width: calc(100% - 41px);
        }
      }
      .dropdown-arrow {
        float: right;
        position: relative;
        top: 8px;
        right: 5px;
        pointer-events: none;
      }
      ul.searchselect-dropdown {
        position: absolute;
        left: -1px;
        top: 30px;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #b2b3b1;
        border-top: none;
        list-style: none;
        margin: 0;
        padding: 0;
        max-height: 200px;
        z-index: 999;

        @include vertical-scroll;

        li {
          padding: 5px;
          cursor: pointer;
          &:focus, &:hover {
            background: lighten( $dig-blue, 70% );
          }
          &.priority {
            background: #f8b14f40;
          }
        }
      }
    }

    &.with-error {
      div.searchselect {
        border: 1px solid red;
      }

      .error {
        font-size: 9pt;
        color: red;
        margin-top: -9px;
        margin-bottom: 8px;
      }
    }
    .hint {
      font-size: 9pt;
      margin-top: -9px;
      margin-bottom: 16px
    }
  }

  ._darkMode div {
    div.searchselect {
      background: $hugr-colours-primary;
      color: $hugr-colours-grey;
      input {
        color: $hugr-colours-grey;
      }
      ul.searchselect-dropdown {
        background: $hugr-colours-primary;
        li:hover, li:focus {
          background: darken($hugr-colours-primary, 5%);
        }
      }
    }
    &.with-error {
      div.searchselect {
        border: 1px solid #f77373;
      }

      .error {
        color: #f77373;
      }
    }
  }
</style>
