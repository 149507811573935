<template>
  <Modal
    ref="modal"
    :title="$gettext('Edit component:') + component.identifier"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submitComponent"

    @close="reset()"
    :ready="component">
    <FormInput ref="identifier" idRoot="component_" :label="$gettext('Unique Name')" v-model="component.identifier" :validation="['not-empty', 'max-70-chars']" />
    <FormInput idRoot="component_" :label="$gettext('Description')" v-model="component.description"/>
    <AriaSearchSelectList v-if="component.pages.length" v-model:items="component.pages"/>
    <p v-else v-translate>No pages</p>
    <AriaSearchSelect ref="pageselect" :label="$gettext('Add a Page')" :dropdown="true" idRoot="page" :gqlOptions="['PAGES_BY_REPORT', report]" @selected="addPage"/>
    <Button v-if="!gotPages || ( gotPages && component.pages.length<numberOfPages )" size="micro" @click="addAllCurrentPages()">{{$gettext('Add all pages to this component')}}</Button><br />
    <template v-if="gotPages || component.futurePagesAdded">
      <input type="checkbox" v-model="component.futurePagesAdded" id="addFuturePages" />&nbsp;<label for="addFuturePages">Add all future pages as they are made</label>
    </template>
    <!-- <Button v-if="!component.futurePagesAdded" class="padded" size="micro" @click="addAllCurrentPages(); component.futurePagesAdded = true">{{$gettext('Affects all current and future pages')}}</Button>
    <Button v-if="!component.futurePagesAdded" class="padded" size="micro" @click="component.futurePagesAdded = true">{{$gettext('Affects all future pages as well')}}</Button>
    <Button v-else class="padded" size="micro" @click="component.futurePagesAdded = false">{{$gettext('Do not include future pages')}}</Button>
    <div class="pageDisclaimer" v-if="!component.futurePagesAdded">{{$gettext('Currently selected pages will be added. Future pages will not be added.')}}</div>
    <div class="pageDisclaimer" v-else>{{$gettext('Currently selected pages will be added. Future pages will also be added to this component.')}}</div> -->
    <FormImage ref="ssImage" idRoot="page_" :label="$gettext('Screenshot')" v-model="component.screenshot"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import FormImage from '@/components/Form/Image';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';
import AriaSearchSelectList from '@/components/Aria/SearchSelectList';

export default {
  name: 'EditComponentModal',
  data() {
    return {
      component: false,

      gotPages: false,
      numberOfPages: 0,

      submitting: false,
    };
  },
  methods: {
    addAllCurrentPages() {
      this.$apollo.query( {
        query: gql`query Options($func: String!, $param: String) {
          options: Options(function: $func, param: $param) {
            value,
            display,
            search
          }
        }`,
        variables: {
          func: 'PAGES_BY_REPORT',
          param: this.report,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        const fetchedpages = res.data.options;
        fetchedpages.forEach( page => {
          this.addPage( page.value );
        } );
        this.numberOfPages = fetchedpages.length;
        this.gotPages = true;
      } ).catch( () => {
        this.$alerts.coded( 'F2904', 'E086' ); //see notifications spreadsheet
      } );
    },
    addPage( page ) {
      if( this.component.pages.map( p => p._id ).indexOf( page ) < 0 ) {
        this.$apollo.query( {
          query: gql`
            query Page($id: ObjectID!) {
              page: Page(id: $id) {
                _id,
                name
              }
            }
          `,
          variables: {
            id: page,
          },
        } ).then( res => {
          this.component.pages.push( res.data.page );
        } ).catch( () => {
          this.$alerts.coded( 'E046', 'F2901' ); //see notifications spreadsheet
        } );
      }
    },
    // rmPage: function (page) {
    //   this.component.pages.remove(page);
    // },
    submitComponent() {
      this.$hugrvalidate( [ 'identifier' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`mutation updateComponent($id: ObjectID!, $component: ComponentInput!) {
              component: updateComponent(id: $id, component: $component) {
                _id,
                identifier,
                description,
                pages {
                  _id,
                  name
                }
              }
            }`,
            variables: {
              id: this.component._id,
              component: {
                identifier: this.component.identifier,
                description: this.component.description,
                pages: this.component.pages.map( page => page._id ),
                // elements: this.component.elements,
                screenshot: this.component.screenshot,
                futurePagesAdded: this.component.futurePagesAdded,
              },
            },
          } ).then( res => {
            this.submitting = false;
            this.$emit( 'edited', res.data.component );
            this.reset();
          } ).catch( err => {
            this.submitting = false;
            if( err.message == 'component name is not unique' ) {
              this.$refs.identifier.addError( 'Component name must be unique' );
            } else {
              this.$alerts.coded( 'E051', 'F2902' ); //see notifications spreadsheet
            }
          } );
        }
      } );
    },

    show( componentid ) {
      this.$apollo.query( {
        query: gql`
          query Component($id: ObjectID!) {
            component: Component(id: $id) {
              _id,
              identifier,
              description,
              futurePagesAdded,
              pages {
                _id,
                name
              }
            }
          }
        `,
        variables: {
          id: componentid,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.component = { ...res.data.component };
        this.$refs.modal.show();
        setTimeout( () => {
          this.$refs['pageselect'].refetch();
        }, 100 );
      } ).catch( () => {
        this.$alerts.coded( 'E052', 'F2903' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.component = false;
      this.gotPages = false;
      this.numberOfPages = 0;
      this.$refs.modal.reset();
    },
  },
  props: {
    report: {
      type: String,
      required: true,
    },
  },
  components: {
    FormInput,
    FormImage,
    AriaSearchSelect,
    AriaSearchSelectList,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .padded {
    margin-left: 5px;
 }
 .pageDisclaimer {
    margin-top: 5px;
    font-weight: bold;
 }
</style>
