<template>
  <div class="Well">
    <h3 v-if="title&&title!=''" class="Well_Title">{{ title }}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UIWell',
};
</script>

<script setup>
  import { toRefs } from 'vue';

  const props = defineProps( {
    title: String,
  } );

  const { title } = toRefs( props );
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Well {
    border: 1px solid #b3b3b3;
    // background: #ececec;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    position: relative;

    &_Title {
      margin-top: 0;
      font-weight: 500;
      font-size: 1.2em;
    }
  }

  ._darkMode .Well {
    // background: darken($hugr-colours-primary, 10%);
  }
</style>
