<template>
  <div>

    <Body :title="$gettext(`My Team`)" :fill="true" v-if="me&&team" class="MyTeam">
      <TabList v-if="tabs.length" :tabs="tabs" :key="`teamtabs-${tabs.length}`"
        :lastIndex="tabs.map(t => t.text).indexOf(team.name)" />
      <div id="team">
        <h3>{{team.name}}</h3>
        <router-link class="MyTeam_AllAssessments" :to="`/${$hugrConfig.reportRouterReplacement}s/find/all/alphabetical/team/${team._id}`">
          {{ $gettext( 'Show all %{projectString}s on this team', { projectString: $hugrConfig.projectString } ) }}
        </router-link>

        <ButtonSet v-show="!(!isManager && hasPermission('Restrictions', 'CanNotQuitTeams') && team._id==me.primaryTeam._id)" class="MyTeam_Opts" label="Options" :dropdown="true" size="small" type="secondary">
          <Button size="small" type="transparent" v-if="team._id!==me.primaryTeam._id "
            :checked="team._id===me.primaryTeam._id" @click="makePrimary">{{$gettext('Make Primary Team')}}</Button>
          <Button v-if="( isManager || hasPermission('Teams', 'Update') ) && (!hasJira) && settings.jiraIntegration" size="small" type="transparent" @click="linkToJira">{{$gettext('Link to Jira')}}</Button>
          <Button v-else-if="( isManager || hasPermission('Teams', 'Update') ) && (hasJira) && settings.jiraIntegration" size="small" type="transparent" @click="unlinkFromJira">{{$gettext('Unlink from Jira')}}</Button>
          <Button v-if="isManager || hasPermission('Teams', 'Update')" size="small" type="transparent" @click="$refs.teamsettingsmodal.show(team._id)">{{$gettext('Edit Team Settings')}}</Button>
          <Button v-if="hasPermission('Exports', 'CanExportSummary')" size="small" type="transparent" @click="$refs.csvsummarymodal.show(team._id)">{{ $gettext('Export Summary') }}</Button>
          <Button v-show="!hasPermission('Restrictions', 'CanNotQuitTeams')" size="small" type="serious" @click="quitTeam">{{$gettext('Quit Team')}}</Button>
        </ButtonSet>
        <!-- <Button size="small" type="secondary" @click="$refs.ticketmodal.show()">New Ticket</Button>
        <Button size="small" type="secondary" @click="$refs.ticketlistmodal.show()">Show Team Tickets</Button> -->
        <CardSet class="MyTeam_Members" :title="$gettext('Team Members (') + team.users.length + '/' + team.userslimit + ')'"
          hlevel="3" :button="isManager?{icon:['solid', 'user-plus', 'after'], text: $gettext('Add a team member')}:null"
          @buttonClick="$refs.teammembermodal.show()">
          <span v-if="team.domainrestriction">({{$gettext('Restricted to')}} {{team.domainrestriction}})</span>
          <DataTable v-if="team.users.length||team.managers.length" class="MyTeam_Members_Table" tablekey="myteam" :customisable="true" @changed="$forceUpdate()" :headers="[
            { name: $gettext('Name'), freeze: true },
            $gettext('Email'),
            $gettext('Role'),
            $gettext('Team Role'),
            $gettext('Last login'),
            { name: $gettext('MFA Enabled'), default: false },
            { name: '', freeze: true }
          ]">
            <tr v-for="user in allUsers" v-bind:key="user.email" :class="[user.disabled?'_dimmed':'']">
              <td>
                <router-link :to="`/userprofile/${user._id}`">{{ user.name }}<template v-if="user.disabled">&nbsp;{{$gettext('(user disabled)')}}</template></router-link>
              </td>
              <td v-if="$tableColumnVisible('myteam', 'email')">{{ user.email }}</td>
              <td v-if="$tableColumnVisible('myteam', 'role')">
                <span v-if="user.permissionGroup">{{ user.permissionGroup.name }}</span>
                <span v-else>None</span>
              </td>
              <td v-if="$tableColumnVisible('myteam', 'teamRole')">{{ user.role }}<template v-if="team.biller&&user._id===team.biller._id">{{$gettext(' (billing)')}}</template></td>
              <td v-if="$tableColumnVisible('myteam', 'lastLogin')">
                <span v-if="user.lastLogin" :title="moment( user.lastLogin ).format( `DD/MM/yyyy @ HH:mm` )">{{ moment( user.lastLogin ).fromNow() }}</span>
                <span v-else>Unknown</span>
              </td>
              <td v-if="$tableColumnVisible('myteam', 'mFAEnabled')">
                <span v-if="user.securityKeys">{{ user.mfaEnabled?`${$gettext(`Yes`)} (${user.securityKeys} ${$gettext(`keys`)})`:$gettext('No') }}</span>
                <span v-else>{{ user.mfaEnabled?$gettext(`Yes`):$gettext('No') }}</span>
              </td>
              <td v-if="isManager || hasPermission('Teams', 'Update')">
                <Button class="buttonChoices" v-if="(user.role=='manager' )&&user._id!=me._id" type="serious" size="micro"
                  @click="rmMngr(user._id)">{{$gettext('Remove as manager')}}</Button>

                <Button class="buttonChoices" v-if="user.role!='manager'" type="serious" size="micro"
                  @click="rmUser(user._id)">{{$gettext('Remove user')}}</Button>
                <Button class="buttonChoices" v-if="user.role!='manager'" type="secondary" size="micro"
                  @click="mkMngr(user._id)">{{$gettext('Make manager')}}</Button>
                <Button class="buttonChoices" v-if="( user.role!='manager' ) && user.name.includes(' (invited)')" type="secondary" size="micro"
                  @click="reinvite(user.email)">{{$gettext('Resend invite')}}</Button>
              </td>
            </tr>
          </DataTable>
          <Empty v-else :text="$gettext('Nothing here!')" :button="{icon:['solid', 'user-plus'], text: $gettext('Add a user')}"
            @buttonClick="$refs.usermodal.show()" />
        </CardSet>

        <!-- ADD GRANULAR PERMISSIONS PLEASE (see button="true ?{icon...}")-->
        <CardSet class="MyTeam_Contacts" :title="$gettext('Contacts (') + relevantContacts().length + ')'" hlevel="3"
          :button2="{icon: ['solid', 'archive', 'after'], text: archiveText}" @button2Click="toggleArchived()"
          :button="hasPermission('Contacts', 'Create') ?{icon:['solid', 'user-plus', 'after'], text: $gettext('Add a contact')}:null"
          @buttonClick="$refs.contactmodal.show()">

          <DataTable v-if="team.users.length||team.managers.length" class="MyTeam_Membersx_Table" :headers="[
            $gettext('Name'),
            $gettext('Email'),
            $gettext('Phone number'),
            $gettext('Notes'),
            ''
          ]">
            <tr v-for="contact in relevantContacts()" v-bind:key="contact.email">
              <td>
                <router-link :to="`/userprofile/${contact._id}`">{{ contact.name }}</router-link>
              </td>
              <td><a :href="`mailto:${contact.email}`">{{ contact.email }}</a></td>
              <td>{{ contact.phoneNumber }}</td>
              <!-- <td>{{ roleFor(contact) }}</td> -->
              <td class="notesColumn">{{ contact.notes }}</td>
              <!-- ADD GRANULAR PERMISSIONS PLEASE -->
              <td v-if="true">
                <Button v-if="hasPermission('Contacts', 'Update')" class="buttonChoices" size="micro" @click="$refs.editcontactmodal.show(contact)">
                  {{$gettext('Edit')}}
                </Button>
                <template v-if="hasPermission('Contacts', 'Archive' )">
                  <Button v-if="!contact.archived" class="buttonChoices" type="serious" size="micro"
                    @click="archiveContact(contact)">{{$gettext('Archive')}}</Button>

                  <Button v-if="contact.archived" class="buttonChoices" type="serious" size="micro"
                    @click="unArchiveContact(contact)">{{$gettext('Unarchive')}}</Button>
                </template>
              </td>
            </tr>
          </DataTable>
          <Empty v-else :text="$gettext('Nothing here!')" :showButton="hasPermission('Contacts', 'Create' )" :button="{icon:['solid', 'user-plus'], text: $gettext('Add a contact')}"
            @buttonClick="$refs.usermodal.show()" />
        </CardSet>

        <CardSet class="MyTeam_Tickets" :title="$gettext('Tickets on team')" hlevel="3"
          :button="{icon:['solid', 'ticket-alt', 'after'], text: $gettext('Add a ticket')}"
          @buttonClick="$refs.ticketmodal.show()">
          <DataTable v-if="Object.keys(tickets).length" class="MyTeam_Tickets_Table" :headers="[
            $gettext('Title'),
            $gettext('Status'),
            $gettext('Type'),
            $gettext('Created by')
          ]">
            <tr v-for="ticket in tickets" v-bind:key="ticket._id">
              <td>
                <router-link :to="`/ticket/${ticket.identifier}`">{{ ticket.title }}</router-link>
              </td>
              <td>{{ matchStatus(ticket.status) }}</td>
              <td>{{ matchType(ticket.ticketType) }}</td>
              <td>{{ ticket.createdBy.name }}</td>
            </tr>
          </DataTable>
          <Empty v-else :text="$gettext('No tickets!')" :button="{icon:['solid', 'ticket-alt'], text: $gettext('Add a ticket')}"
            @buttonClick="$refs.ticketmodal.show()" />
        </CardSet>

        <CardSet v-if="isManager" class="MyTeam_Billing" :title="$gettext('Billing and payments')" hlevel="3">
          <p>
            {{$gettext('Subscription status:')}}
            <template v-if="team.subscription">{{ team.subscription.status }}</template>
            <template v-else>{{ activeString(team) }}</template>
          </p>
          <template v-if="team.subscription">
            <h4 v-translate>Payment methods</h4>

            <PaymentSelect chosenStyle="default" :key="`${team.name}-cards`" :methods="team.subscription.paymentMethods"
              :init="team.subscription.primaryCard.id" @selected="() => {  }" />

            <h4 v-translate>Invoices</h4>
            <ul>
              <li v-for="invoice in team.subscription.invoices" :key="invoice.id">{{ prettyDate(invoice.periodStart) }}
                {{ (invoice.amountDue/100).toFixed(2) }} {{ invoice.currency }} ({{ invoice.status }})</li>
            </ul>
          </template>

        </CardSet>

        <TeamMemberModal ref="teammembermodal" :team="team" @success="$apollo.queries.team.refetch()" />
        <ContactModal ref="contactmodal" :team="team" @success="$apollo.queries.team.refetch()" />
        <EditContactModal ref="editcontactmodal" :team="team" @success="$apollo.queries.team.refetch()" />
        <TicketModal ref="ticketmodal" :defaultTeam="team._id" @success="$apollo.queries.tickets.refetch()" />
        <EditTeamSettingsModal ref="teamsettingsmodal" @success="$apollo.queries.team.refetch()" />
        <CSVSummaryModal ref="csvsummarymodal" />
      </div>
    </Body>

    <Body :title="$gettext(`Start a team`)" :fill="true" v-else-if="tident=='none'&&false" class="NewTeam">
      <div class="NewTeam_Opts">
        <div :class="['NewTeam_Opts_Opt', picked=='standard'?'_selected':'']">
          <div class="NewTeam_Opts_Opt_Info">
            <h3 v-translate>Standard</h3>
            <div class="NewTeam_Opts_Opt_Info_Price">
              <span>£{{ standardPrice }}</span>
              <span v-translate> user / month</span>
            </div>
            <h4 v-translate>Includes:</h4>
            <ul>
              <li>Thing 1</li>
              <li>Thing 2</li>
            </ul>
          </div>
          <Button :type="picked=='standard'?'secondary':'primary'" :toggle="true" :checked="picked=='standard'"
            @click="() => { picked = 'standard'; }">{{$gettext('Use Standard')}}</Button>
        </div>

        <div :class="['NewTeam_Opts_Opt', picked=='enterprise'?'_selected':'']">
          <div class="NewTeam_Opts_Opt_Info">
            <h3 v-translate>Enterprise</h3>
            <div class="NewTeam_Opts_Opt_info_Price">
              <span v-translate>Contact for pricing</span>
            </div>
            <h4 v-translate>Includes:</h4>
            <ul>
              <li>Thing 1</li>
              <li>Thing 2</li>
            </ul>
          </div>
          <Button :type="picked=='enterprise'?'secondary':'primary'" :toggle="true" :checked="picked=='enterprise'"
            @click="() => { picked = 'enterprise'; }">{{$gettext('Use Enterprise')}}</Button>
        </div>
      </div>
      <div class="NewTeam_Content" v-if="picked=='standard'">
        <h3 v-translate>Set up your team</h3>

        <h4 v-translate>Your team details</h4>
        <template v-if="subStage==0">
          <FormInput idRoot="team_" :label="$gettext(`What's your team called?`)" v-model="teamName" :validation="['not-empty']"
            ref="teamname" />

          <FormInput idRoot="team_" :label="$gettext('How many people are in your team?')" v-model="teamNum" type="number" />
        </template>
        <template v-else>
          <dl class="NewTeam_Content_TeamInfo">
            <div>
              <dt v-translate>Your team is called </dt>
              <dd>{{ teamName }}</dd>
            </div>
            <div>
              <dt v-translate>People on your team </dt>
              <dd>{{ teamNum }}</dd>
            </div>
          </dl>
        </template>

        <Notice size="small">{{$gettext('This would be ')}}£<span>{{ (standardPrice*teamNum).toFixed(2) }}</span> {{$gettext('a month')}}</Notice>
        <p v-if="teamNum>bigTeamLimit">{{$gettext('For a team this size we would advise you contact us at ')}}<a
            href="mailto:hello@diginclusion.com">hello@diginclusion.com</a>{{$gettext(' to discuss options')}}</p>

        <Button class="NewTeam_Content_ContBtn" v-if="subStage<1" type="secondary" size="small"
          @click="stageOne()">{{$gettext('Continue')}}</Button>
        <template v-else>
          <h4 v-translate>Your team members</h4>

          <ul class="NewTeam_Content_TeamMembers">
            <li class="NewTeam_Content_TeamMembers_Member">{{$gettext('You')}} <span v-translate>manager</span></li>
            <li class="NewTeam_Content_TeamMembers_Member" v-for="member in teamMembers" :key="member.email">
              {{ member.email }}
              <span v-if="member.manager" v-translate>manager</span>
              <Button size="nano" @click="() => { teamMembers.remove(member); }">{{$gettext('remove')}}</Button>
            </li>
          </ul>

          <div class="NewTeam_Content_MemberForm" v-if="subStage==1">
            <FormInput idRoot="team_" :label="$gettext('Email')" v-model="addMemberEmail" ref="memberemail"
              :validation="['email', 'not-empty']" />
            <label>
              <input type="checkbox" v-model="addMemberManager" /> {{$gettext('This person is a team manager')}}
            </label>
            <Button size="micro" @click="addTeamMember">{{$gettext('Add one')}}</Button>
          </div>

          <Button v-if="subStage<2" size="small" @click="() => { subStage = 0 }">{{$gettext('Go back')}}</Button>
          <Button class="NewTeam_Content_ContBtn" v-if="subStage<2" type="secondary" size="small" @click="stageTwo()">
            <template v-if="teamMembers.length">{{$gettext('Continue')}}</template>
            <template v-else>{{$gettext(`Skip, I'll do this later`)}}</template>
          </Button>

          <template v-else>
            <template v-if="!customer">
              <Notice type="error" size="micro">{{$gettext('Something went wrong')}}</Notice>
            </template>
            <div class="NewTeam_Content_Payment" v-else>
              <h4 id="paymentLabel" v-translate>Select a payment method</h4>
              <PaymentSelect :methods="customer.paymentMethods" @selected="(id) => { selectedPaymentMethod = id; }" />
              <Notice v-if="methodError" type="error" size="micro" aria-live="polite">{{$gettext('Select a payment method!')}}</Notice>

              <Button size="small" @click="() => { subStage = 1; selectedPaymentMethod = false; }">{{$gettext('Go back')}}</Button>
              <div class="NewTeam_Content_Payment_Actions">
                <Button size="small" @click="() => { this.$refs.cardmodal.show() }">{{$gettext('Add a card')}}</Button>
                <Button size="small" type="secondary" @click="subscribe()">{{$gettext('Subscribe')}}</Button>
              </div>
            </div>
          </template>

        </template>
      </div>
      <div class="NewTeam_Content" v-if="picked=='enterprise'">
        <h3 v-translate>Contact us for more</h3>

        <p>{{$gettext('Email ')}}<a href="mailto:hello@diginclusion.com">hello@diginclusion.com</a>{{$gettext(' to discuss enterprise options')}}</p>
      </div>
    </Body>

    <Body :title="`No team`" :fill="true" v-else>
      {{$gettext('You are not on a team.')}}
    </Body>
    <PaymentMethodModal ref="cardmodal" @new="stageTwo()" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';

import TeamMemberModal from '@/modals/User/TeamMember';
import ContactModal from '@/modals/User/Contact';
import EditContactModal from '@/modals/User/EditContact';
import TicketModal from '@/modals/Ticket/Create';
import CSVSummaryModal from '@/modals/CSV/Summary';
import EditTeamSettingsModal from '@/modals/Team/Settings';
import PaymentMethodModal from '@/modals/PaymentMethod.vue';

import FormInput from '@/components/Form/Input';
import UIPaymentSelect from '@/components/UI/PaymentSelect.vue';

import config from '../../config';

import moment from 'moment';

export default {
  name: 'MyTeamView',
  mounted() {
    this.$apollo.queries.me.refetch();

    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'My Team',
      },
    } );
  },
  data() {
    return {
      archiveText: 'Show archived',
      showingArchived: false,
      me: false,
      team: false,
      tident: this.$route.params.identifier.replace( '%20', ' ' ),
      tabs: [],
      tickets: false,
      hasJira: false,

      //team setup only
      price: false,
      picked: 'standard',
      teamName: '',
      teamNum: 5,
      standardPrice: 0,
      bigTeamLimit: 100,
      teamMembers: [],

      addMemberEmail: '',
      addMemberManager: false,

      customer: false,
      selectedPaymentMethod: false,
      methodError: false,

      subStage: 0,
    };
  },
  watch: {
    team() {
      if( this.team ) {
        this.fetchHasJira();
      }
    },
    $route() {
      if( this.$route.name == 'myteam' ) {
        this.tident = this.$route.params.identifier.replace( '%20', ' ' );
        this.$apollo.queries.team.refetch();
        this.fetchHasJira();
      }
    },
    me() {
      this.tabs = [];
      if( this.me.teams.length > 1 && this.tident != 'none' ) {
        this.tabs.push( {
          text: this.me.primaryTeam.name,
          controls: "team",
          action: () => {
            this.$router.push( `/team/${this.me.primaryTeam.identifier}` );
          },
        } );
        for( const team of this.me.teams ) {
          if( team.name && team.identifier && team.name != this.me.primaryTeam.name ) {
            this.tabs.push( {
              text: team.name,
              controls: "team",
              action: () => {
                this.$router.push( `/team/${team.identifier}` );
              },
            } );
          }
        }
      } else if( this.me.teams.length >= 1 && this.tident != 'none' ) {
        this.$router.push( `/team/${this.me.primaryTeam.identifier}` );
      } else {
        this.tident = "none";
        this.team = false;
        this.$router.push( `/team/none` );
      }
    },
    teamNum() {
      if( parseInt( this.teamNum ) < 1 ) {
        this.teamNum = 1;
      }
    },
    price() {
      this.standardPrice = this.price.unitAmount / 100;
    },
  },
  apollo: {
    settings: {
      query: gql`
        {
          settings: Settings {
            id
            jiraIntegration
          }
        }
      `,
    },
    me: {
      query: gql`
        {
          me: WhoAmI {
            _id,
            email,
            name,
            aclevel,
            mfaEnabled,
            teams {
              _id,
              identifier,
              name
            }

            primaryTeam {
              _id,
              identifier
              name
            }
          }
        }
      `,
    },
    team: {
      query: gql`
        query Team($ident: String) {
          team: Team(identifier: $ident) {
            _id,
            identifier,
            name,
            userslimit,
            domainrestriction,
            active,
            subscriptionActive,
            activeIndefinitely,
            activeUntil,
            archivedContacts {
              _id
              name
              email
              phoneNumber
              archived
              notes
              role
            }
            contacts {
              _id
              name
              email
              phoneNumber
              archived
              notes
              role
            }

            users {
              _id,
              name,
              email,
              aclevel,
              mfaEnabled,
              securityKeys
              disabled
              lastLogin
              permissionGroup {
                _id
                name
              }
            }
            managers {
              _id,
              name,
              email,
              aclevel,
              mfaEnabled,
              securityKeys
              disabled
              lastLogin
              permissionGroup {
                _id
                name
              }
            }
            customer {
              _id
              name
            }

            biller {
              _id
            }

            subscription {
              id
              status
              primaryCard {
                id
              }
              paymentMethods {
                id
                card {
                  id
                  lastFour
                }
              }

              invoices {
                id
                amountDue
                amountPayed
                currency
                status
                periodStart,
                periodEnd
              }
            }

          }
        }
      `,
      variables() {
        return {
          ident: this.tident,
        };
      },
      skip() {
        return this.tident == 'none';
      },
      update( data ) {
        return { ...data.team };
      },
    },
    tickets: {
      query: gql`
      query TicketsFromTeam($identifier: String!) {
        tickets: TicketsFromTeam(identifier: $identifier) {
          _id,
          identifier,
          title,
          status,
          createdBy {
            _id
            name
          },
          ticketType
        }
      }
      `,
      variables() {
        return {
          identifier: this.tident,
        };
      },
      skip() {
        return this.tident == 'none';
      },
      update( data ) {
        return { ...data.tickets };
      },
    },
    price: {
      query: gql`
        query SubscriptionPricing {
          price: SubscriptionPricing {
            id
            active
            currency
            unitAmount
          }
        }
      `,
      skip() {
        return this.tident != 'none';
      },
    },
  },
  methods: {
    moment,
    roleFor( contact ) {
      if ( contact.role ) {
        return contact.role.replace( /\w\S*/g, w => ( w.replace( /^\w/, c => c.toUpperCase() ) ) );
      }

      return '(No role)';
    },
    toggleArchived() {
      this.showingArchived = !this.showingArchived;

      if ( this.showingArchived ) {
        this.archiveText = 'Show active';
      } else {
        this.archiveText = 'Show archived';
      }
    },
    relevantContacts() {
      if ( this.showingArchived ) {
        return this.team.archivedContacts;
      }

      return this.team.contacts;
    },
    activeString( team ) {
      if( team.active ) {
        let str = 'Yes';
        if( team.subscriptionActive ) {
          str += ' (subscription)';
        } else if( team.activeIndefinitely ) {
          str += ' (until forever)';
        } else {
          str += ` (until ${moment( team.activeUntil ).format( "DD/MM/yyyy" )})`;
        }

        return str;
      }

        return 'No';

    },
    prettyDate( date ) {
      return moment( date ).format( 'DD/MM/yyyy' );
    },
    fetchHasJira() {
      this.$apollo.query( {
        query: gql`
        query TeamHasJiraSet($id: ObjectID!) {
          hasJira: TeamHasJiraSet(id: $id)
        }
      `,
      variables: {
          id: this.team?._id,
      },
      } ).then( res => {
        this.hasJira = res.data.hasJira;
      } );
    },
    rmMngr( user ) {
      this.$confirm.simple( 'This user will still be a normal team member' ).then( result => {
        if( result ) {
          this.doRmMngr( user );
        }
      } );
    },
    quitTeam() {
      this.$confirm.simple( this.$gettext( 'Are you sure you want to quit this team? You\'ll lose access to its %{project}s. If you are the only manager left, you can not quit the team.', { project: this.$hugrConfig.projectStringCap } ) ).then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation quitTeam($team: ObjectID!){
                result: quitTeam(team: $team)
              }
            `,
            variables: {
              team: this.team._id,
            },
          } ).then( res => {
            if ( res.data.result ) {
              this.$apollo.queries.me.refetch();
              this.$alerts.success( "Success","Successfully quit the team." );
            } else {
              this.$alerts.error( "Error when trying to quit team.", "Are you the only manager left?" );
            }
          } );
        }
      } );
    },
    doRmMngr( user ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation removeTeamManager($user: ObjectID!, $team: ObjectID!) {
            team: removeTeamManager(user: $user, team: $team) {
              managers {
                _id,
                name,
                email,
                aclevel,
                mfaEnabled,
                securityKeys
                disabled
              }
            }
          }
        `,
        variables: {
          user,
          team: this.team._id,
        },
      } ).then( res => {
        this.team.managers = res.data.team.managers;
      } );
    },
    reinvite( email ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation ($email: String!, $team: ObjectID!, $sender: String!) {
            inviteTeamUser(email: $email, team: $team, sender: $sender)
          }
        `,
        variables: {
          email,
          team: this.team._id,
          sender: this.user.name,
        },
      } ).then( res => {
        this.$alerts.success( 'Success!', `User has been sent a new email.` );
      } ).catch( e => {
        this.$alerts.error( `Oops, there's a problem :/`, `This is definitely a bug` );
      } );
    },
    linkToJira() {
      const link = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${config.jiraClientID}&scope=read:jira-work%20write:jira-work%20read:me%20manage:jira-configuration%20manage:jira-project%20read:jira-user%20offline_access&redirect_uri=${config.jiraCallbackRoot}/callback/&state=${this.team?._id}-..-${localStorage.getItem( "HUGR_ACCESS_TOKEN" )}&response_type=code&prompt=consent`;
      window.open( link );
    },
    unlinkFromJira() {
      this.$confirm.simple( 'You will not be able to export to Jira anymore. Continue?' ).then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation setTeamJiraRefresh($team: ObjectID!, $jirarefresh: String!) {
                result: setTeamJiraRefresh(team: $team, jirarefresh: $jirarefresh)
              }
            `,
            variables: {
              team: this.team._id,
              jirarefresh: "",
            },
          } ).then( res => {
            if ( res.data.result ) {
              this.$alerts.success( "Successfully unlinked from Jira." );
              this.fetchHasJira();
            } else {
              this.$alerts.error( "Error during unlink, please try again!" );
            }
          } );
        }
      } );
    },
    mkMngr( user ) {
      this.$confirm.simple( '' ).then( result => {
        if( result ) {
          this.doMkMngr( user );
        }
      } );
    },
    doMkMngr( user ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation makeTeamManager($user: ObjectID!, $team: ObjectID!) {
            team: makeTeamManager(user: $user, team: $team) {
              managers {
                _id,
                name,
                email,
                aclevel,
                mfaEnabled,
                securityKeys
                disabled
              }
            }
          }
        `,
        variables: {
          user,
          team: this.team._id,
        },
      } ).then( res => {
        this.team.managers = res.data.team.managers;
      } );
    },
    rmUser( user ) {
      this.$confirm.simple( 'The user will no longer have access to this teams resources' ).then( result => {
        if( result ) {
          this.doRmUser( user );
        }
      } );
    },
    unArchiveContact( contact ) {
      this.$confirm.simple( 'Are you sure you want to unarchive this contact?' ).then( result => {
        if( result ) {
          this.doUnArchiveContact( contact );
        }
      } );
    },
    doUnArchiveContact( contact ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation unArchiveContact($team: ObjectID!, $contact: ObjectID!) {
            result: unArchiveContact(team: $team, contact: $contact)
          }
        `,
        variables: {
          team: this.team._id,
          contact: contact._id,
        },
      } ).then( res => {
        if ( res.data.result ) {
          this.$alerts.success( "Success", "Successfully archived contact." );
          this.$apollo.queries.team.refetch();
        } else {
          this.$alerts.error( "Error when trying to archive contact." );
        }
      } );
    },
    archiveContact( contact ) {
      this.$confirm.simple( 'Are you sure you want to archive this contact?' ).then( result => {
        if( result ) {
          this.doArchiveContact( contact );
        }
      } );
    },
    doArchiveContact( contact ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation archiveContact($team: ObjectID!, $contact: ObjectID!) {
            result: archiveContact(team: $team, contact: $contact)
          }
        `,
        variables: {
          team: this.team._id,
          contact: contact._id,
        },
      } ).then( res => {
        if ( res.data.result ) {
          this.$alerts.success( "Success","Successfully archived contact." );
          this.$apollo.queries.team.refetch();
        } else {
          this.$alerts.error( "Error when trying to archive contact." );
        }
      } );
    },
    doRmUser( user ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation removeTeamMember($user: ObjectID!, $team: ObjectID!) {
            team: removeTeamMember(user: $user, team: $team) {
              users {
                _id,
                name,
                email,
                aclevel,
                mfaEnabled,
                securityKeys
                disabled
              }
            }
          }
        `,
        variables: {
          user,
          team: this.team._id,
        },
      } ).then( res => {
        this.team.users = res.data.team.users;
      } );
    },
    makePrimary() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation makeTeamPrimary($user: ObjectID!, $team: ObjectID!) {
            user: makeTeamPrimary(user: $user, team: $team) {
              _id,
              email,
              name,
              aclevel,

              teams {
                _id,
                identifier,
                name
              }

              primaryTeam {
                _id,
                identifier
              }
            }
          }
        `,
        variables: {
          user: this.me._id,
          team: this.team._id,
        },
      } ).then( res => {
        this.user = res.data.user;
      } );
    },
    matchType( type ) {
      switch( type ) {
        case( 'testSection' ): return 'Section Testing';
        case( 'testPage' ): return 'Page Testing';
        case( 'helpIssue' ): return 'Issue Help';
        case( 'fixIssue' ): return 'Fix issue';
        case( 'general' ): return 'General Enquiry';
      }
    },

    matchStatus( type ) {
      switch( type ) {
        case( 'open' ): return 'Open';
        case( 'closed' ): return 'Closed';
        case( 'replied' ): return 'Internal Reply';
      }
    },
    //Stripe stuff
    stageOne( passes = true ) {
      this.$refs.teamname.reset();
      const val = this.$refs.teamname.validate();
      if( val.success ) {
        if( ( this.teamMembers.length + 1 ) > parseInt( this.teamNum ) ) {
          this.$confirm.make( `Too many people`, `Number of people on your team can't be lower than the number of people invited, this will be increased. To lower this go remove some then come back.`, 'Okay', 'Cancel' ).then( result => {
            if( result ) {
              this.teamNum = ( this.teamMembers.length + 1 );
              this.subStage = 1;
            }
          } );
        } else {
          this.subStage = 1;
        }
      }
    },
    stageTwo( passes = true ) { //passes is for test failure
      if( !passes ) {
        this.subStage = 2;

        return;
      }
      this.customer = false; //force clear in case of error
      this.$apollo.query( {
        query: gql`
          query SubscriptionCustomer {
            customer: SubscriptionCustomer {
              id
              email,
              paymentMethods {
                id,
                card {
                  id
                  lastFour
                }
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.customer = res.data.customer;
        this.subStage = 2;
        this.$forceUpdate();
      } ).catch( () => {
        this.subStage = 2; //go on, lack of customer will show error
      } );
    },

    subscribe() {
      if( this.selectedPaymentMethod ) {
        this.methodError = false;
        this.$apollo.mutate( {
          mutation: gql`
            mutation subscribe($sub: subscriptionInput!) {
              subscribe(subscription: $sub) {
                _id,
                identifier
              }
            }
          `,
          variables: {
            sub: {
              teamName: this.teamName,
              teamSize: parseInt( this.teamNum ),
              teamMembers: this.teamMembers,
              primaryCard: this.selectedPaymentMethod,
            },
          },
        } ).then( res => {
          this.$router.push( `/team/${res.data.subscribe.identifier}` );
        } ).catch( () => {
          this.$alerts.error( 'Something went wrong', `Something went wrong, please contact hello@diginclusion.com` );
        } );
      } else {
        this.methodError = true;
      }
    },

    addTeamMember() {
      //first check it doesn't already exist
      this.$refs.memberemail.reset();
      const val = this.$refs.memberemail.validate();
      if( !val.success ) {
        return;
      }
      if( this.teamMembers.map( m => m.email ).indexOf( this.addMemberEmail ) >= 0 ) {
        this.$alerts.error( 'Already exists', `You've already added this email address` );
      } else if( ( this.teamMembers.length + 2 ) > parseInt( this.teamNum ) ) { //then check can add
        this.$confirm.simple( 'This will take you over your chosen team size, would you like to up it?' ).then( result => {
          if( result ) {
            this.teamNum++;
            this.doAddTeamMember();
          } else {
            this.addMemberEmail = '';
            this.addMemberManager = false;
          }
        } );
      } else {
        this.doAddTeamMember();
      }
    },
    doAddTeamMember() {
      this.teamMembers.push( {
        email: this.addMemberEmail,
        manager: this.addMemberManager,
      } );
      this.addMemberEmail = '';
      this.addMemberManager = false;
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    allUsers() {
      return [
        ...this.team.managers.map( user => { return { ...user, role: 'manager' } } ),
        ...this.team.users.filter( user => !( this.team.managers.map( user => user._id ).indexOf( user._id ) >= 0 ) ).map( user => { return { ...user, role: 'user' } } ),
      ];
    },
    isManager() {
      return this.team.managers.map( user => user._id ).indexOf( this.me._id ) >= 0;
    },
    isPrimary() {
      return this.me.primaryTeam._id == this.team._id;
    },
    ...mapState( [ 'user' ] ),
  },
  components: {
    TeamMemberModal,
    ContactModal,
    EditContactModal,
    CSVSummaryModal,
    TicketModal,
    EditTeamSettingsModal,
    FormInput,
    PaymentSelect: UIPaymentSelect,

    PaymentMethodModal,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

.notesColumn {
  max-width: 15rem;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.8em 1rem !important;
}

.MyTeam {
  position: relative;
  &_AllAssessments {
    font-size: 9pt;
    position: relative;
    top: -18px;
  }
  &_Opts {
    position: absolute;
    margin-top: -48px;
    right: 50px;
  }
  &_Members {
    &_Table {
      width: 100%;
    }
  }
}

.NewTeam {
  width: 600px;
  margin: auto;
  &_Opts {
    margin: auto;
    text-align: center;
    &_Opt {
      display: inline-block;
      border: 1px solid $hugr-colours-grey;
      border-radius: 5px;
      width: 200px;
      padding: 8px;
      margin: 8px;
      &_Info {
        h3 {
          margin-top: 0;
        }
        text-align: left;
        padding: 8px;
      }
      button {
        width: 100%;
        text-align: center;
      }

      &._selected {
        box-shadow: 0px 0px 5px -2px;
        transition: 0.5s;
      }
    }
  }
  &_Content {
    position: relative;
    &_TeamInfo {
      margin-left: 16px;
      dt {
        display: inline;
        color: $hugr-colours-primary;
      }
      dd {
        display: inline;
        margin-left: 0;
        text-decoration: underline;

      }
    }

    &_ContBtn {
      position: absolute;
      right: 0;
    }
    &_TeamMembers {
      margin-left: 16px;
      padding: 0;
      list-style: none;
      &_Member {
        color: $hugr-colours-primary;
        span {
          color: #000;
          background: $hugr-colours-secondary;
          border-radius: 15px;
          padding: 4px;
          font-size: 0.7em;
          position: relative;
          top: -2px;
        }
        button {
          margin-left: 16px;
        }
      }
    }
    &_MemberForm {
      border: 1px solid $hugr-colours-grey;
      padding: 8px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      position: relative;
      border-radius: 5px;

      button {
        position: absolute;
        right: 8px;
        bottom: 8px;
      }
    }
    .notice {
      margin-bottom: 16px !important;
    }
    &_Payment {
      position: relative;

      &_Actions {
        position: absolute;
        right: 0;
        bottom: 0;
        button {
          margin-left: 8px;
        }
      }
    }
  }
}

.buttonChoices {
  margin-right: 5px;
}

._darkMode .NewTeam {
  &_Opts {
    &_Opt {
      &._selected {
        box-shadow: #000 0px 0px 5px -2px;
      }
    }
  }

  &_Content {
    &_TeamInfo {
      dt {
        color: $hugr-colours-grey;
      }
    }

    &_TeamMembers {
      &_Member {
        color: $hugr-colours-grey;
      }
    }
  }
}
</style>
