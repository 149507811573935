<template>
  <Body
    :title="pageTitle"
    :menuItems="menuItems"
    class="AccountSettings"
    width="narrow"
    >
      <AccountDashboardFragment v-if="!route.params.fragment || route.params.fragment === ''"/>
      <AccountInfoFragment v-if="route.params.fragment === 'personal-info'" />
      <AccountSecurityFragment v-if="route.params.fragment === 'security'" />
      <AccountNotificationsFragment v-if="route.params.fragment === 'notifications'" />
      <AccountDeveloperFragment v-if="route.params.fragment === 'developer'" />
      
  </Body>
</template>

<script>
  export default {
    name: "AccountView",
};
</script>

<script setup>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';

  // import gql from 'graphql-tag';

  import Body from '@/components/UI/Body';

  import AccountDashboardFragment from './AccountDashboardFragment.vue';
  import AccountInfoFragment from './AccountInfoFragment.vue';
  import AccountSecurityFragment from './AccountSecurityFragment.vue';
  import AccountNotificationsFragment from './AccountNotificationsFragment.vue';
  import AccountDeveloperFragment from './AccountDeveloperFragment.vue';
  // import FormSelect from '@/components/Form/Select';

  // import PasswordResetForm from '@/forms/PasswordReset';
  // import DisplayNameForm from '@/forms/DisplayNameChange';

  // import MFAForm from '@/forms/MFAForm';
  // import DevicesForm from '@/forms/DevicesForm';
  // import ChangeEmailForm from '@/forms/ChangeEmailForm';

  const route = useRoute();

  const pageTitle = computed( () => {
    switch( route.params.fragment ) {
      case 'personal-info': return 'Personal Info';
      case 'security': return 'Security and Passwords';
      case 'notifications': return 'Notification preferences';
      case 'developer': return 'Developer Options';
      default: return '';
    }
  } );

  const store = useStore();
  const user = computed( () => store.getters.user );

  const menuItems = computed( () => {
    const options = [
      {
        display: "Account Dashboard",
        icon: [ 'solid', 'house-user' ],
        location: '/account/',
        active: !route.params.fragment || route.params.fragment === '',
      },
      {
        display: "Personal Info",
        icon: [ 'solid', 'id-card' ],
        location: '/account/personal-info',
        active: route.params.fragment === 'personal-info',
      },
      {
        display: "Security and passwords",
        icon: [ 'solid', 'lock' ],
        location: '/account/security',
        active: route.params.fragment === 'security',
      },
      {
        display: "Notifications",
        icon: [ 'solid', 'bell' ],
        location: '/account/notifications',
        active: route.params.fragment === 'notifications',
      },
    ];

    if( ( user.value.aclevel == 0 || user.value.aclevel == 1 ) && store.getters.hasPermission( 'Admin', 'DeveloperOptions' ) ) {
      options.push( {
        display: "Developer options",
        icon: [ 'solid', 'code' ],
        location: '/account/developer',
        active: route.params.fragment === 'developer',
      } );
    }

    return options;
  } );

</script>
