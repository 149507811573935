<template>

  <Body class="StatementSections">
    <div>
      <Loader v-if="$apollo.queries.statementSections.loading"/>
      <DataTable v-else class="StatementSections_Table" :headers="[$gettext('Title'), $gettext('Body'), $gettext('Order'), $gettext('Enabled'), $gettext('Actions')]">
        <tr v-for="section in orderedSections" :key="section._id">
          <td>{{section.title}}</td>
          <td>{{section.body}}</td>
          <td>{{section.order}}</td>
          <td>{{section.enabled}}</td>
          <td class="StatementSections_Actions">
          <LinkButton class="StatementSections_Actions_Button" v-if="hasPermission('AccessibilityStatementSections','Update')" type="primary" size="micro" :to="`/accessibility-statement/statement-sections/${section._id}`">{{$gettext('Edit')}}</LinkButton>
          <Button class="StatementSections_Actions_Button" v-if="hasPermission('AccessibilityStatementSections','ToggleActive')" type="primary" size="micro" @click="toggleSection(section._id)">{{$gettext('Toggle enabled')}}</Button>
          <Button class="StatementSections_Actions_Button" v-if="hasPermission('AccessibilityStatementSections','Delete')" type="serious" size="micro" @click="rmStatementSection(section._id)">{{$gettext('Delete')}}</Button>
          </td>
        </tr>
      </DataTable>
    </div>

    <AccessibilityStatementSectionForm v-if="hasPermission('AccessibilityStatementSections','Create')" @success="$apollo.queries.statementSections.refetch()" />

    <br />
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters } from 'vuex';

import AccessibilityStatementSectionForm from '@/forms/AccessibilityStatementSection';

export default {
  name: 'AccessibilityStatementSectionsView',
  data() {
    return {
      statementSections: [],
    };
  },
  apollo: {
    statementSections: gql`
      {
        statementSections: AccessibilityStatementSections {
          _id
          title
          body
          order
          enabled
        }
      }
    `,
  },
  mounted() {
    this.$apollo.queries.statementSections.refetch();
  },
  methods: {
    toggleSection( id ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation toggleAccessibilityStatementSectionActive($id: ObjectID!) {
            toggleAccessibilityStatementSectionActive(id: $id)
          }
        `,
        variables: {
          id,
        },
      } ).then( () => {
        this.$apollo.queries.statementSections.refetch();
      } );
    },
    rmStatementSection( id ) {
      this.$confirm.simple( this.$gettext( "Are you sure you want to delete this section?" ) ).then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation removeAccessibilityStatementSection($id: ObjectID!) {
                removeAccessibilityStatementSection(id: $id)
              }
            `,
            variables: {
              id,
            },
          } ).then( () => {
            this.$apollo.queries.statementSections.refetch();
          } ).catch( error => {
            this.$alerts.generic( error );
          } );
        }
      } );
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission' ] ),
    orderedSections() {
      const clone = Array.from( this.statementSections );

      return clone.sort( ( a,b ) => (
        a.order - b.order
      ) );
    },
  },
  components: {
    AccessibilityStatementSectionForm,
  },
};
</script>

<style lang="scss" scoped>
  .StatementSections {
    &_Table {
      width: 100%;
    }

    &_Actions {
      display: flex;
      justify-content: space-between;
      &_Button {
        margin-right: 5px;
      }
    }
  }
</style>
