<template>
  <fieldset :class="['FieldSet', `_${decoration}`]">
    <legend :class="['FieldSet_Legend', hideLegend?'_hidden':'']">{{ legend }}</legend>
    <slot></slot>
  </fieldset>
</template>

<script>
export default {
  name: 'FormFieldSet',
  props: {
    legend: {
      type: String,
      required: true,
    },
    decoration: {
      type: String,
      default: 'normal',
    },
    hideLegend: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_fonts.scss';
  @import '@/assets/styles/variables/_colours.scss';

  .FieldSet {
    position: relative;
    border: 1px solid $hugr-colours-grey;
    border-radius: 3px;
    box-shadow: 0px 0px 10px -4px $hugr-colours-grey;
    margin-top: 50px;
    margin-bottom: 50px;
    &_Legend {
      background: #FFF;
      border: 1px solid $hugr-colours-grey;
      color: black;
      padding: 10px 20px;
      border-radius: 3px;
      font-family: "Quicksand", serif;
      font-weight: bold;
      &._hidden {
        position: absolute !important;
        height: 1px; width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
      }
    }

    &._none {
      box-shadow: none;
      border: none;
      margin: 0;
      padding: 0;
    }

    &._divide {
      box-shadow: none;
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0;
      border-top: 1px solid darken($hugr-colours-grey, 20%);
      padding-top: 4px;
      margin-top: 32px;
      .FieldSet_Legend {
        border: 0;
        padding: 0;
        font-size: 0.8em;
        top: -15px;
        position: absolute;
        background: transparent;
        color: darken($hugr-colours-grey, 60%);
      }
    }

    button {
      margin-top: 20px;
      margin-right: 10px;
    }
  }

  ._darkMode .FieldSet {

    box-shadow: 0px 0px 10px -4px darken($hugr-colours-primary, 10%);

    &_Legend {
      background: darken($hugr-colours-primary, 10%);
      color: #FFF;
    }

    &._divide {
      border-top: 1px solid $hugr-colours-grey;
      .FieldSet_Legend {
        color: $hugr-colours-grey;
      }
    }
  }
</style>
