<template>
  <Modal
    v-show="!hideModal"
    ref="modal"
    :title="$gettext('Edit executive summary')"
    
    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submit"
    
    @close="reset()">
    <FormTextArea ref="execinput" idRoot="exec_" :label="$gettext('Executive Summary')" v-model="template" />
    <Button type="error" @click="restoreExecSummary">{{ $gettext('Return to default') }}</Button>
  </Modal>
</template>

<script>
  export default {
    name: "EditExecSummaryModal",
  };
</script>

<script setup>
  import { ref } from 'vue';
  import { useLazyQuery, useMutation } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  import FormTextArea from '@/components/Form/TextArea';

  const emit = defineEmits( [ 'success' ] );

  const modal = ref( null );

  const execinput = ref( null );

  const hideModal = ref( false );
  const id = ref( null );
  const enabled = ref( false );

  const template = ref( '' );

  const { load, refetch, onResult } = useLazyQuery( gql`
    query ReportFromVersion($id: ObjectID!) {
      report: ReportFromVersion(id: $id) {
        _id 
        currentVersion {
          _id
          execSummary
          execSummaryTmp
        }
      }
    }
  `,
  {
    id,
  },
  {
    fetchPolicy: 'no-cache',
    enabled,
  } );

  onResult( ( { data } ) => {
    template.value = data.report.currentVersion.execSummaryTmp;

    hideModal.value = false;
    modal.value.show();
  } );

  const show = reportVersion => {
    id.value = reportVersion;
    enabled.value = true;
    // eslint-disable-next-line no-unused-expressions
    load() || refetch();
  };

  const hide = () => {
    hideModal.value = true;
  };

  const reset = () => {
    id.value = null;
    enabled.value = false;
    hideModal.value = true;

    template.value = '';

    modal.value.reset();
  };

  const { mutate, onError, onDone } = useMutation( gql`
    mutation updateExecSummary($id: ObjectID!, $template: String!) {
      res: updateExecSummary(id: $id, execSummary: $template) {
        _id,
      }
    }
  ` );

  const submit = () => {
    mutate( {
      id: id.value,
      template: template.value,
    } );
  };

  onDone( () => {
    reset();
    emit( 'success' );
  } );

  const { mutate: restore, onDone: onRestored } = useMutation( gql`
    mutation restoreExecSummary($id: ObjectID!) {
      res: restoreExecSummary(id: $id) {
        _id,
        execSummaryTmp
      }
    }
  ` );

  const restoreExecSummary = () => {
    restore( { id: id.value } );
  };

  onRestored( ( { data } ) => {
    template.value = data.res.execSummaryTmp;
    execinput.value.focus();
  } );

  defineExpose( {
    show,
    reset,
  } );
</script>
