<template>
  <span :class="['TooltipIcon', `_${severity}`]">
    <Icon
      @mouseenter="tooltipEl?.showTooltip()"
      @mouseleave="tooltipEl?.hideTooltip()"
      @focus="tooltipEl?.showTooltip()"
      @blur="tooltipEl?.hideTooltip()"
      :type="type" :icon="icon" />
    <Tooltip ref="tooltipEl" :text="text" :wider="true"/>
  </span>
</template>

<script setup>
  import Tooltip from '@/components/UI/Tooltip.vue';
  import { ref, toRefs } from 'vue';

  const tooltipEl = ref( null );

  const props = defineProps( {
    type: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    severity: {
      type: String,
      default: 'info',
    },
  } );

  const { type, icon, text, severity } = toRefs( props );

</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';
  .TooltipIcon {
    position: relative;
    &._info {
      color: $hugr-colours-tertiary;
    }
    &._serious {
      color: $hugr-colours-red;
    }
  }
</style>
