<template>
  <Modal
    ref="modal"
    :title="`${$gettext('Edit Sheet:')} ${sheet.title}`"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save sheet')"
    @submit="submitPage"

    @close="reset()">
    <FormInput idRoot="page_" :label="$gettext('Name')" v-model="title"/>

    <FormSelect idRoot="page_" :label="$gettext('User Journey')" :value="userJourney" v-model="userJourney" :options="journeyOpts" />
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import UIModal from '@/components/UI/Modal';
// import AriaListBox from '@/components/Aria/ListBox';
import FormSelect from '@/components/Form/Select';

export default {
  name: 'SheetSettingsModal',
  data() {
    return {
      sheet: false,
      title: '',
      userJourney: 'none',

      journeyOpts: {
        none: 'None',
      },
      journeyDescs: {
        none: 'Show all pages and components',
      },
      submitting: false,
    };
  },
  methods: {
    submitPage() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`mutation editSpreadsheetPage($id: ObjectID!, $title: String!, $userJourney: ObjectID) {
          page: editSpreadsheetPage(id: $id, title: $title, userJourney: $userJourney) {
            _id,
            title,
            userJourney {
              _id
              title
            }
          }
        }`,
        variables: {
          id: this.sheet._id,
          title: this.title,
          userJourney: this.userJourney == 'none' ? null : this.userJourney,
        },
      } ).then( () => {
        this.submitting = false;
        this.$emit( 'edited', this.sheet.number, this.title );
        this.reset();
      } );
    },
    show( sheet, reportVersion ) {
      this.sheet = sheet;
      this.title = sheet.title;
      if( sheet.userJourney ) {
        this.userJourney = sheet.userJourney._id;
      } else {
        this.userJourney = 'none';
      }
      this.$apollo.query( {
        query: gql`
          query ReportFromVersion($id: ObjectID!) {
            report: ReportFromVersion(id: $id) {
              _id
              currentVersion {
                _id
                userJourneys {
                  _id
                  title
                  description
                }
              }
            }
          }
        `,
        variables: {
          id: reportVersion,
        },
      } ).then( res => {
        for( const journey of res.data.report.currentVersion.userJourneys ) {
          if( journey.title != '_devHide' ) {
            this.journeyOpts[journey._id] = journey.title;
            this.journeyDescs[journey._id] = journey.description;
          }
        }
        this.$refs.modal.show();
      } ).catch( () => {
        this.$alert.error( 'Something went wrong' );
      } );
    },
    reset() {
      this.sheet = false;
      this.$refs.modal.reset();
    },
  },
  components: {
    FormInput,
    Modal: UIModal,
    // AriaListBox,
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
