<template>
  <div v-show="isOpen" ref="context-container" v-click-away="close">
    <ul role="menubar" aria-label="Context menu" ref="context-menubar">
      <slot :signal="prop"></slot>
    </ul>
  </div>
</template>

<script>
// import { mixin as clickaway } from 'vue-clickaway';
import { reactive, provide, ref } from 'vue';

const TAB_KEY = 9;
let activatingButton = false;

export default {
  name: 'UIContextMenu',
  // mixins: [ clickaway ],
  setup() {
    const prop = reactive( {
      value: false,
    } );
    const isOpen = ref( false );
    provide( 'signal', prop );

    return {
      isOpen,
      prop,
    };
  },
  methods: {
    getPosition( e ) {
      const x = e.clientX;
      const y = e.clientY - 60;

      return { x, y };
    },
    positionMenu( e ) {
      const position = this.getPosition( e );
      const menu = this.$refs['context-container'];

      const menuHeight = menu.offsetHeight;

      const windowHeight = window.innerHeight;

      menu.style.left = `${position.x}px`;

      if ( ( windowHeight - position.y ) < ( menuHeight + 100 ) ) {
        menu.style.top = `${position.y - menuHeight}px`;
      } else {
        menu.style.top = `${position.y}px`;
      }
    },
    open( e, prop ) {
      this.isOpen = true;
      this.prop.value = prop;

      this.positionMenu( e );

      activatingButton = document.activeElement;

      setTimeout( () => { //required to ensure context menu is open before focussing
        this.$refs['context-menubar'].children[0].focus();
      }, 100 );

      document.addEventListener( 'keyup', this.esc );
    },
    close() {
      document.removeEventListener( 'keyup', this.esc );

      this.isOpen = false;
      this.prop.value = false;

      if( activatingButton && typeof activatingButton != 'undefined' && "focus" in activatingButton ) {
        activatingButton.focus();
        activatingButton = false;
      }
    },
    esc( e ) {
      if( e.key == "Escape" ) {
        this.close();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  div {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999999999999;
    background: white;
    border: 1px solid #cbd2da;
    box-shadow: 0px 0px 5px -2px #5e646a;
    border-radius: 5px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  ._darkMode div {
    background: $hugr-colours-primary;
  }
</style>
