<template>
  <Body title="Rules">
    <div v-if="rules">
      <Well v-for="(rule,index) in rules" v-bind:key="'rule-' + index">
        <Rule v-bind:rule="rule" />
      </Well>
      <button @click.prevent="addRule">{{$gettext('New Rule')}}</button>
    </div>
    <Loader v-else />
  </Body>
</template>

<script>
import Loader from '@/components/Loader';
import Rule from '@/components/Rule';
import UIWell from '@/components/UI/Well';
import UIBody from '@/components/UI/Body';

export default {
  name: 'RulesView',
  created() {
    this.$socket.emit( 'GET_RULES' );
  },
  data() {
    return {
      rules: false,
    };
  },
  sockets: {
    RETURN_RULES( rules ) {
      this.rules = rules;
    },
  },
  methods: {
    addRule() {
      this.rules.push( {
        comparisons: [],
        confidence: 100,
        issue: '',
        reason: '',
        section: '',
        type: '',
        logic: 'and',
      } );
    },
  },
  components: {
    Loader,
    Body: UIBody,
    Well: UIWell,
    Rule,
  },
};
</script>
