<template>
  <div :class="['Pie', cardMode ? '_card' : '', `_bg-${background}`, `_size-${size}`]">
    <div class="Pie_Container">
      <svg class="Pie_Container_Canvas" height="20" width="20" viewBox="0 0 20 20">
        <circle class="Pie_Container_Canvas_Background" r="10" cx="10" cy="10" fill="#cbd2da" />
        <circle v-for="datum of data" :key="datum.key"
          :id="`pieslice-${datum.key}`"
          class="Pie_Container_Canvas_Segment"
          @mouseenter="focus(datum.key)"
          @mouseleave="unfocus(datum.key)"
          @click="goToIssues( datum.key )"
          r="5" cx="10" cy="10" fill="transparent"
          :stroke="datum.colour"
          stroke-width="10"
          :stroke-dasharray="`calc( ${datum.percentage} * 31.42 / 100 ) 31.42`"
          :transform="`rotate(${datum.rotate})`" />
          <circle class="Pie_Container_Canvas_Center" r="6" cx="6" cy="6" fill="white" transform="translate( 4, 4)"/>
      </svg>
      <div :class="['Pie_Container_Center', cardMode ? '_card' : '', `_bg-${background}`]">
        <span class="Pie_Container_Center_Number">{{ total }}</span>
        <span class="Pie_Container_Center_Text">{{ centerKey }}</span>
      </div>
    </div>
    <ul class="Pie_Key">
      <li v-for="datum of data" :key="datum.key"
          :id="`piekeyitem-${datum.key}`"
          class="Pie_Key_Item"
          tabindex="0"
          @focus="focus(datum.key)"
          @blur="unfocus(datum.key)"
          @mouseenter="focus(datum.key)"
          @mouseleave="unfocus(datum.key)"
          @click="goToIssues( datum.key )">
        <span :class="[ 'Pie_Key_Item_Colour', datum.value==0?'_grey':'']" :style="{ backgroundColor: datum.colour }">{{ datum.value }}</span>
        <span class="Pie_Key_Item_Text">{{ datum.key }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
  import { toRefs, computed, watch } from 'vue';

  const emit = defineEmits( [ 'goToIssues' ] );

  const props = defineProps( {
    data: Object,
    centerKey: {
      type: String,
      default: 'items',
    },
    cardMode: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: 'surface',
    },
    size: {
      type: String,
      default: 'normal',
    },
  } );

  const { data, centerKey, cardMode, background } = toRefs( props );

  const total = computed( () => data.value.map( i => i.value ).reduce( ( a, b ) => a + b, 0 ) );

  const calcData = () => {
    let currRotate = -180;
    //add percentaged and degrees
    for( const datum of data.value ) {
      datum.percentage = ( datum.value / total.value ) * 100;
      datum.degrees = ( datum.percentage / 100 ) * 360;
      datum.rotate = currRotate;
      currRotate += datum.degrees;

    }
  };
  calcData();

  watch( data, () => {
    calcData();
  } );

  const focus = key => {
    const datum = data.value.filter( d => d.key == key )[0];
    const slice = document.getElementById( `pieslice-${key}` );

    slice.setAttribute( 'transform', `rotate(${datum.rotate}) scale( 1.1 )` );

    const keyitem = document.getElementById( `piekeyitem-${key}` );
    keyitem.classList.add( '_focus' );
  };

  const unfocus = key => {
    const datum = data.value.filter( d => d.key == key )[0];
    const slice = document.getElementById( `pieslice-${key}` );

    slice.setAttribute( 'transform', `rotate(${datum.rotate})` );

    const keyitem = document.getElementById( `piekeyitem-${key}` );
    keyitem.classList.remove( '_focus' );
  };

  const goToIssues = key => {
    emit( 'goToIssues', key.toLowerCase() );
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Pie {
    padding: 32px;

    &._size-small {
      padding: 16px;
    }

    &._bg-surface {
      background: $hugr-colours-input-surface;
    }
    &._bg-grey {
      background: $hugr-colours-grey;
    }
    &._bg-lightgrey {
      background: lighten( $hugr-colours-grey, 15% );
    }

    &._card {
      background: $hugr-colours-card-background;
    }

    &_Container {
      position: relative;
      width: 50%;
      max-width: 260px;
      max-height: 260px;
      display: inline-block;

      &_Canvas {
        overflow: visible;
        width: 100%;
        height: 100%;

        &_Segment {
          transform-origin: center;
          transition: transform .2s;
          &:focus {
            outline: none;
          }
        }
      }

      &_Center {
        position: absolute;
        display: block;
        width: 33%;
        height: 33%;
        border-radius: 100px;
        text-align: center;
        padding: 14%;
        top: 19%;
        left: 19%;

        &._bg-surface {
          background: $hugr-colours-input-surface;
        }
        &._bg-grey {
          background: $hugr-colours-grey;
        }
        &._bg-lightgrey {
          background: lighten( $hugr-colours-grey, 15% );
        }

        &._card {
          background: $hugr-colours-card-background;
        }

        &_Number {
          font-size: 2em;
          font-weight: 100;
          display: block;
        }
        &_Text {
          font-size: 0.7em;
        }
      }
    }

    &_Key {
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-left: 10%;
        vertical-align: top;
        &_Item {
          margin-bottom: 4px;
          transition: transform .2s;
          cursor: default;
          &:hover, &:focus, &._focus {
            transform: scale( 1.1 );
          }
          &_Colour {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 12px;
            background: grey;
            vertical-align: middle;
            margin: 4px;
            font-size: 0.9em;
            padding: 3px;
            text-align: center;
            font-weight: bold;

            &._grey {
              background: $hugr-colours-grey !important;
            }
          }
          &_Text {
            display: inline-block;
            vertical-align: middle;
            font-size: 0.9em;
            font-weight: 500;
          }
        }
      }
  }

  ._darkMode .Pie {
    background: darken( $hugr-colours-primary, 10% );

    &._card {
      background: lighten( $hugr-colours-primary, 10% );
    }

    &_Container {
      &_Center {
        background: darken( $hugr-colours-primary, 10% );

        &._card {
          background: lighten( $hugr-colours-primary, 10% );
        }
      }
    }

    &_Key {
      &_Item {
        &_Colour {
          color: black;
        }
      }
    }
  }
</style>
