// import { defineStore } from 'pinia';
import Vuex from 'vuex';
// import { RootState } from '@/types';

import * as getters from './getters';
import mutations from './mutations';
import * as actions from './actions';

export default new Vuex.Store( {
  state: {
    // socket: {
    //   isConnected: false,
    //   message: '',
    //   reconnectError: false,
    // },
    user: {
      loggedIn: false,
      id: '0',
      email: '',
      displayname: '',
      name: '',
      customer: '',
      jwt: '',
      aclevel: 9,
      permissionGroup: { name: '', permissions: [] },
      teams: [],
      settings: [],
      mfaEnabled: false,
    },
    report: false,
    firstUse: false,
    // mapID: {
    //
    // }
  },
  getters,
  mutations,
  actions,
} );
