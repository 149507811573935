export default {
    start: () => {
      if( !localStorage.hasOwnProperty( 'session' ) ) {
        localStorage.setItem( 'session', '{}' );
      }
    },
    destroy: () => {
      if( localStorage.hasOwnProperty( 'session' ) ) {
        localStorage.removeItem( 'session' );
      }
    },
    set: ( key, value ) => {
      if( localStorage.hasOwnProperty( 'session' ) ) {
        const session = JSON.parse( localStorage.getItem( 'session' ) );
        session[key] = value;
        localStorage.setItem( 'session', JSON.stringify( session ) );
      } else {
        console.error( 'session not started' );
      }
    },
    get: key => {
      if( localStorage.hasOwnProperty( 'session' ) ) {
        const session = JSON.parse( localStorage.getItem( 'session' ) );

        return session[key];
      }
        console.error( 'session not started' );

    },
    has: key => {
      if( localStorage.hasOwnProperty( 'session' ) ) {
        const session = JSON.parse( localStorage.getItem( 'session' ) );

        return session.hasOwnProperty( key );
      }
        console.error( 'session not started' );

    },
  };
