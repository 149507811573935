<template>
  <Body title="Accessibility statement for Hugr">
    <p>This accessibility statement applies to <span v-if="$hugrConfig.appId.indexOf('ho') == 0">the Home Office instance of </span>Hugr at {{ $hugrConfig.appId }}.</p>

    <template v-if="$hugrConfig.appId.indexOf('ho') == 0">
      <p>The purpose of the website is to provide a place for Home Office staff, contractors and suppliers to provide and get information about the accessibility of Home Office digital products. It is run by Home Office with support from Dig Inclusion.</p>
      <p>We want as many people as possible to be able to use this website. For example, that means you should be able to:</p>
    </template>
    <p v-else>The statement covers {{ $hugrConfig.appId }}. The purpose of the website is to provide a constantly developing framework for creating accessibility reports. This platform is run by Dig Inclusion, who are committed to making their website as accessible as possible for persons with visual, hearing, cognitive and motor impairments. The users are able to:</p>
    <ul>
      <ul>Change colours, contrast levels and fonts</ul>
      <ul>zoom in up to 400% without the text spilling off the screen</ul>
      <ul>navigate most of the website using just a keyboard</ul>
      <ul>navigate most of the website using speech recognition software</ul>
      <ul>listen to most of the website using a screen reader (including the most recent versions of JAWS, NVDA and VoiceOver)</ul>
    </ul>
    <p><a href="https://mcmw.abilitynet.org.uk/">AbilityNet</a> has advice on making your device easier to use if you have a disability.</p>
  
    <h3>How accessible this website is</h3>
    <p>We have tried hard to make this website as accessible as possible. Our accessibility consultants and testers look out for ways to improve it as they use it.</p>
    <p>Content provided by users, such as in files, text fields and images may not be accessible.</p>
    <p>Any issues we currently know about will be listed here or in the central <a href="https://hugr.app/accessibility-statement/">Hugr accessibility statement</a>.</p>
    
    <h3>Feedback and contact information</h3>
    <p>If you need information on this website in a different format like accessible PDF, large print, easy read, audio recording or braille:</p>
    <ul v-if="$hugrConfig.appId.indexOf('ho') == 0">
      <li>email <a href="mailto:access@digital.homeoffice.gov.uk">access@digital.homeoffice.gov.uk</a></li>
      <li>call 03000 719 809</li>
      <li>Teams message james.buller@homeoffice.gov.uk</li>
    </ul>
    <ul v-else>
      <li>email <a href="mailto:hello@diginclusion.com">hello@diginclusion.com</a></li>
    </ul>
    <p v-if="$hugrConfig.appId.indexOf('ho') == 0">We are happy to use text relay services. Our offices have audio induction loops, or if you contact us before your visit we can arrange a sign language translator or other support to help you talk with us.</p>
    <p>We'll consider your request and get back to you within 10 days.</p>

    <h3>Reporting accessibility problems with this website</h3>
    <p>We're always looking to improve the accessibility of this website. If you find any problems not listed on this page or think we're not meeting accessibility requirements, contact:</p>
    <ul v-if="$hugrConfig.appId.indexOf('ho') == 0">
      <li>email <a href="mailto:access@digital.homeoffice.gov.uk">access@digital.homeoffice.gov.uk</a></li>
      <li>call 03000 719 809</li>
      <li>Teams message james.buller@homeoffice.gov.uk</li>
    </ul>
    <ul v-else>
      <li>email <a href="mailto:hello@diginclusion.com">hello@diginclusion.com</a></li>
    </ul>
    <p><a href="https://www.w3.org/WAI/teach-advocate/contact-inaccessible-websites/">Read tips on contacting organisation about inaccessible websites</a>.</p>

    <h3>Enforcement procedure</h3>
    <p>The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) Accessibility (Amendment) (EU Exit) Regulations 2022 (the 'accessibility regulations'). If you're not happy with how we respond to your complaint, <a href="https://www.equalityadvisoryservice.com/">contact the Equality Advisory and Support Service (EASS)</a>.</p>
    <p>If you are in Northern Ireland and are not happy with how we respond to your complaint you can contact the <a href="https://www.equalityni.org/Home">Equalities Commission for Northern Ireland</a> who are responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) Accessibility (Amendment) (EU Exit) Regulations 2022 (the 'accessibility regulations') in Northern Ireland.</p>
  
    <h3>Technical information about this website's accessibility</h3>
    <p v-if="$hugrConfig.appId.indexOf('ho') == 0">The Home Office is committed to making its website accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) Accessibility (Amendment) (EU Exit) Regulations 2022.</p>
    <p v-else>Dig Inclusion is committed to making its website accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.</p>

    <h3>Compliance status</h3>
    <p>This website is partially compliant with the <a href="https://www.w3.org/TR/WCAG22/">Web Content Accessibility Guidelines version 2.2 AA standard</a>, due to the non-compliances listed below.</p>
  
    <h3>Non-accessible content</h3>
    <p>The content listed below is non-accessible for the following reasons.</p>

    <h4>Non-compliance with the accessibility regulations</h4>
    <p>For issues relating to the core Hugr system see list in the <a href="https://hugr.app/accessibility-statement/">central Hugr accessibility statement</a>.</p>
    <p>Content provided by users such as in files, text fields or images may not be accessible in various ways.</p>
    <p>If you find an issue that we have yet to identify, please contact us using one of the routes described in the 'Reporting accessibility problems with this website' section of this statement.</p>
  
    <h4>Disproportionate burden</h4>
    <p>At this time, we have not made any disproportionate burden claims.</p>

    <h4>Content that's not within the scope of the accessibility regulations</h4>
    <p>At this time, we have not identified any content that is not within scope of the accessibility regulations</p>

    <h3>Preparation of this accessibility statement</h3>
    <p>This statement was prepared on 28 July 2023. It was last reviewed on the same date. For details of the latest testing, see the <a href="https://hugr.app/accessibility-statement/">central Hugr accessibility statement</a>.</p>
    <p v-if="$hugrConfig.appId.indexOf('ho') == 0">Home Office accessibility consultants and testers look out for accessibility issues as they use the site and during the development of new features.</p>
  </Body>
</template>

<script>
export default {
  name: 'A11yStatemnt',
};
</script>
