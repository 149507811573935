<template>
  <Modal
    ref="modal"
    :title="$gettext(`Confirm email change`)"
    
    :showActions="true"

    :loading="submitting"
    loadingText="Confirming..."

    :submitText="$gettext('Confirm')"
    @submit="submitConfirm"
    
    @close="reset()">
    <FormInput ref="name" idRoot="email_" :label="$gettext('New Email')" v-model="email" :hintText="$gettext('Email you are changing to.')"/>
    <FormInput ref="name" idRoot="email_" :label="$gettext('Confirmation code')" v-model="code" :hintText="$gettext('You should have had an email sent to you with a code.')"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';

export default {
  name: 'ConfirmEmailChange',
  data() {
    return {
      email: "",
      code: "",
      submitting: false,
    };
  },
  methods: {
    submitConfirm() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation confirmChangeEmail($email: String!, $code: String!) {
            done: confirmChangeEmail(email: $email, code: $code)
          }
        `,
        variables: {
          code: this.code,
          email: this.email,
        },
      } ).then( res => {
        this.submitting = false;
        if( res.data.done ) {
          this.$alerts.success( 'Request complete!', `Your email has been successfully changed!` );
          this.$emit( 'success', this.email );
          this.reset();
        } else {
          this.$alerts.error( 'Problem confirming', `Have you used an incorrect code or email? Please try again.` );
        }
      } ).catch( () => {
        this.submitting = false;
        this.$alerts.coded( 'E048', 'F2501' ); //see notifications spreadsheet
      } );
    },
    show( email ) {
      this.email = email;
      this.$refs.modal.show();
    },
    reset() {
      this.email = "";
      this.code = "";
      this.submitting = false;
      this.$refs.modal.reset();
    },
  },
  props: {
  },
  components: {
    FormInput,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
