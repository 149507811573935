<template>
  <ul role="radiogroup" aria-labelledby="paymentLabel" class="PaymentSelect" v-if="methods.length">
    <li role="radio"
        :class="['PaymentSelect_Method', selectedPaymentMethod===method.id?'_selected':'']"
        v-for="(method, key) of methods"
        :key="method.id"
        :tabindex="((key==0&&selectedPaymentMethod==false)||selectedPaymentMethod==method.id)?'0':'-1'"
        :aria-checked="selectedPaymentMethod==method.id?'true':'false'"

        @keyup.down="(e) => { next(e, key) }"
        @keyup.up="(e) => { prev(e, key) }"

        @keyup.space="select(method.id)"
        @keyup.enter="select(method.id)"
        @click="select(method.id)">

      <Icon type="regular" icon="credit-card" read="card" />
      <span>**** **** **** {{ method.card.lastFour }}</span>

      <template v-if="selectedPaymentMethod===method.id">
        <span v-if="chosenStyle=='default'" class="_default">default</span>
        <Icon v-else class="selected" type="solid" icon="check" read="selected" />
      </template>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'UIPaymentSelect',
  data() {
    let isSelected = false;

    if( this.init != '' && this.methods.map( m => m.id ).indexOf( this.init ) >= 0 ) {
      isSelected = this.init;
    }

    return {
      selectedPaymentMethod: isSelected,
    };
  },
  props: {
    methods: {
      type: Array,
      required: true,
    },
    init: {
      type: String,
      default: '',
    },
    chosenStyle: {
      type: String,
      default: 'tick',
    },
  },
  methods: {
    select( id ) {
      this.selectedPaymentMethod = id;
      this.$emit( 'selected', id );
    },

    next( e, key ) {
      if( e.target.nextElementSibling ) {
        this.select( this.methods[key + 1].id );
        e.target.nextElementSibling.focus();
      }
    },
    prev( e, key ) {
      if( e.target.previousElementSibling ) {
        this.select( this.methods[key - 1].id );
        e.target.previousElementSibling.focus();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .PaymentSelect {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid $hugr-colours-grey;
    border-radius: 5px;
    margin-bottom: 16px;

    &_Method {
      position: relative;
      padding: 16px;
      border-bottom: 1px solid $hugr-colours-grey;

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: none;
      }

      &._selected {
        background: $hugr-colours-grey;
      }

      svg { //icon
        font-size: 1.4em;
        margin-right: 16px;
        &.selected {
          position: absolute;
          right: 0;
        }
      }
      ._default {
        position: absolute;
        right: 20px;
        top: 19px;
      }
      span {
        color: lighten($hugr-colours-primary, 10%);
      }
    }
  }

  ._darkMode .PaymentSelect {
    &_Method {
      &._selected {
        background: darken($hugr-colours-primary, 10%);
      }

      span {
        color: $hugr-colours-grey;
      }
    }
  }
</style>
