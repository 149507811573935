// import Vue from 'vue';
import * as types from './mutation-types';
// import { UserObject } from '@/types';

// const FIVE_MIN=5*60*1000;
// const TEN_SEC=10*1000;
// var jwtcalls = {}

// @ts-ignore
export const setUser = ( { commit }, payload ) => {
  commit( types.SET_USER, {
    loggedIn: true,
    id: payload.id,
    email: payload.email,
    displayname: payload.displayname,
    name: payload.name,
    aclevel: payload.aclevel,
    customer: payload.customer,
    permissionGroup: payload.permissionGroup,
    jwt: payload.jwt,
    teams: payload.teams,
    primaryTeam: payload.primaryTeam,
    settings: payload.settings,
    mfaEnabled: payload.mfaEnabled,
  } );
};

// @ts-ignore
export const setDisplayname = ( { commit }, payload ) => {
  commit( types.SET_DISPLAYNAME, payload );
};

// @ts-ignore
export const setSettings = ( { commit }, payload ) => {
  commit( types.SET_SETTINGS, payload );
};

// @ts-ignore
export const setReport = ( { commit }, payload ) => {
  commit( types.SET_REPORT, payload );
};

// @ts-ignore
export const setFirstUse = ( { commit }, payload ) => {
  commit( types.SET_FIRST_USE, payload );
};
