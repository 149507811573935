<template>
  <span :key="value">{{ value }}</span>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default { //TODO this should cache
  name: 'MapID',
  created() {
    // console.warn('MapID is deprecated, delete it!');
    if( !this.mapID[this.socketOptions] ) {
      // console.warn('MapID opt '+this.socketOptions+' hasnt been preset and may be slower');
      this.sockets.subscribe( `RETURN_${this.socketOptions}_OPTS`, options => {
        this.updateMapID( {
          reference: this.socketOptions,
          options,
        } );
        this.options = options;
        this.update();
      } );
    }
    this.update();
  },
  data() {
    return {
      value: 'Loading...',
      options: false,

      getCount: 0,
    };
  },
  watch: {
    mID: {
        handler( to, from ) {
          this.update();
        },
        deep: true,
    },
  },
  methods: {
    ...mapActions( [ 'jwtemit', 'jwtemitnodupe', 'updateMapID' ] ),
    update() { //SOCKET IS DONE IN APP.VUE
      if( this.mapID[this.socketOptions] ) {
        this.options = this.mapID[this.socketOptions];
      }
      this.getCount++;
      if( this.options ) {
        let gotValue = false;
        this.options.forEach( el => {
          if( el.value == this.mID ) {
            this.value = el.display;
            gotValue = true;
          }
        } );
        if( !gotValue && this.getCount > 5 ) {
          gotValue = true;
          this.value = 'Error';
        }
        if( gotValue ) {
          this.getCount = 0;
          this.value = this.transform( this.value );
        } else {
          this.jwtemit( [ `GET_${this.socketOptions}_OPTS` ] );
        }
      } else {
        // console.warn('MapID opts '+this.socketOptions+' not initalised, will call numerous times');
        this.jwtemit( [ `GET_${this.socketOptions}_OPTS` ] );
      }
    },
  },
  computed: {
    ...mapState( [ 'mapID' ] ),
  },
  props: {
    mID: {
      type: String,
      required: true,
    },
    socketOptions: {
      type: String,
    },
    transform: {
      type: Function,
      default( a ) { return a },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
