<template>
  <Body title="Import Users" class="Import">
    <DataTable :headers="[
              'Num',
              'Name',
              'Email',
              'AC Level',
              'Password',
              'Team',
              'Ignore',
              ''
            ]">
      <tr v-for="(user, i) in parsedData" :key="i">
        <td>{{ i+1 }}</td>
        <td>
          {{ user.name }}
          <span v-if="user.duplicate || user.exists">
            &nbsp;(
            <span v-if="user.duplicate">duplicate</span>&nbsp;
            <span v-if="user.exists">Already exists</span>
            )
          </span>
        </td>
        <td>{{ user.email }}</td>
        <td>{{ user.aclevel }}</td>
        <td>using provided</td>
        <td>
          {{ user.team }}
          <span v-if="!user.teamExists">Add team first</span>
          <span v-if="user.addedToTeam">Added</span>
        </td>
        <td><input aria-label="Ignore" type="checkbox" v-model="user.ignore"/></td>
        <td>
          <span v-if="user.made">Created</span>
        </td>
      </tr>
    </DataTable>
    <Button @click="importData">Import</Button>
    <div class="Import_Settings">
      <div>
        <label for="order">Order</label>&nbsp;
        <input v-model="order" id="order" />
        &nbsp;&nbsp;
        <label for="splitter">Splitter</label>&nbsp;
        <select id="splitter" v-model="splitter">
          <option value="0">Comma</option>
          <option value="1">Tab</option>
        </select>
      </div>
      <br />
      <div>
        <label for="csv">Data</label>
        <textarea id="csv" v-model="csv"></textarea>
      </div>
      <div>
        <label for="password">Password</label>
        <textarea id="password" v-model="password"></textarea>
      </div>
      <Button @click="parse()">Parse</Button>
    </div>
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import md5 from 'md5';

function generatePassword() {
    const length = 8,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for ( let i = 0, n = charset.length; i < length; ++i ) {
        retVal += charset.charAt( Math.floor( Math.random() * n ) );
    }

    return retVal;
}

export default {
  name: "ImportUsers",
  data() {
    const password = generatePassword();

    return {
      csv: '',
      splitter: 0,
      splitters: [ ",", "\n" ],
      order: "name, email, team",

      password,

      parsedData: false,
    };
  },
  methods: {
    parse() {
      const lines = this.csv.split( "\n" );
      const ret = [];

      for( const line of lines ) {
        if( line.trim() == "" ) continue;

        const components = line.split( this.splitters[this.splitter] );
        const newLine = {};
        for( const i in this.parsedOrder ) {
          if( components.hasOwnProperty( i ) ) {
            newLine[this.parsedOrder[i]] = components[i].trim();
          }
        }
        ret.push( newLine );
      }

      const done = [];

      for( const i in ret ) {
        if( ret.hasOwnProperty( i ) ) {
          const item = ret[i];
          item.email = item.email.replace( / /g, '' ).toLowerCase();
          if( done.indexOf( item.email ) >= 0 ) {
            item.duplicate = true;
            item.ignore = true;
          } else {
            item.duplicate = false;
            item.ignore = false;
          }
          done.push( item.email );

          if( !item.aclevel ) {
            item.aclevel = 1;
          }

          this.checkUser( item, i );
          this.checkTeam( item, i );
        }
      }

      this.parsedData = ret;
    },
    checkUser( user, i ) {
      this.$apollo.query( {
        query: gql`
          query UserFromEmail($email: String!) {
            user: UserFromEmail(email: $email) {
              _id,
              name,
              email
            }
          }
        `,
        variables: {
          email: user.email,
        },
      } ).then( res => {
        if( res.data.user ) {
          this.parsedData[i].exists = true;
          this.parsedData[i].ignore = true;
          this.parsedData[i].userId = res.data.user._id;
        } else {
          this.parsedData[i].exists = false;
        }
      } ).catch( () => {
        //TODO error
      } );
    },
    checkTeam( user, i ) {
      this.$apollo.query( {
        query: gql`
          query Team($identifier: String!) {
            team: Team(identifier: $identifier) {
              _id,
              name,
            }
          }
        `,
        variables: {
          identifier: user.team,
        },
      } ).then( res => {
        if( res.data.team ) {
          this.parsedData[i].teamExists = true;
          this.parsedData[i].teamId = res.data.team._id;
        } else {
          this.parsedData[i].teamExists = false;
        }
      } ).catch( () => {
        //TODO error
      } );
    },
    importData() {
      this.addUsers();
      this.addToTeam();
    },
    addUsers() {
      for( const user of this.parsedData ) {
        if( !user.ignore ) {
          const userInput = {
            name: user.name,
            email: user.email,
            aclevel: parseInt( user.aclevel ),
            password: md5( `${this.password}:alienFORGERY` ),
          };
          this.$apollo.mutate( {
            mutation: gql`
              mutation addUserAdmin($user: UserInput!) {
                user: addUserAdmin(user: $user) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              user: userInput,
            },
          } ).then( res => {
            user.made = true;
            user.userId = res.data.user._id;
          } ).catch( err => {
            this.$catchError( err );
            //TODO
          } );
        }
      }
    },
    addToTeam() {
      if( this.parsedData.map( u => ( u.userId || u.ignore ) ).indexOf( false ) >= 0 ) {
        setTimeout( () => {
          this.addToTeam();
        }, 100 );
      } else {
        const userIds = {};
        for( const user of this.parsedData ) {
          if( !user.teamId ) continue;
          if( user.userId ) {
            userIds[user.email] = user.userId;
          } else {
            user.userId = userIds[user.email];
          }
          const variables = {
            team: user.teamId,
            user: user.userId,
          };
          this.$apollo.mutate( {
            mutation: gql`
              mutation addTeamMember($team: ObjectID!, $user: ObjectID!) {
                team: addTeamMember(team: $team, user: $user) {
                  _id,
                  name
                }
              }
            `,
            variables,
          } ).then( res => {
            user.addedToTeam = true;
          } ).catch( err => {
            this.$catchError( err );
            //TODO
          } );
        }
      }
    },
  },
  computed: {
    parsedOrder() {
      const ret = [];
      for( const i of this.order.split( ',' ) ) {
        ret.push( i.trim() );
      }

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
  .Import {
    &_Settings {
      textarea {
        width: 100%;
        height: 200px;
      }
    }
  }
</style>
