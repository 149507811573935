<template>
  <Body title="Debug">
    <button @click="ping">Ping info alert</button>
    <button @click="pingWarn">Ping warning alert</button>
    <button @click="pingSuccess">Ping success alert</button>
    <button @click="pingError">Ping error alert</button>
    {{ user }}
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapActions } from 'vuex';

import UIBody from '@/components/UI/Body';

export default {
  name: 'DevDebugView',
  created() {

  },
  data() {
    return {
      user: false,
    };
  },
  apollo: {
    user: gql`
      {
        user: WhoAmI {
          name,
          email,
          aclevel
        }
      }
    `,
  },
  methods: {
    ...mapActions( [ 'jwtemit' ] ),
    ping() {
      this.jwtemit( [ 'ALERT_PING' ] );
    },
    pingWarn() {
      this.jwtemit( [ 'ALERT_PING_WARN' ] );
    },
    pingSuccess() {
      this.jwtemit( [ 'ALERT_PING_SUCCESS' ] );
    },
    pingError() {
      this.jwtemit( [ 'ALERT_PING_ERROR' ] );
    },
  },
  sockets: {
  },
  components: {
    Body: UIBody,
  },
};
</script>
