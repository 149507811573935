<template>
  <Body title="Counters" :fill="true" class="Counter">
    <Button @click="refresh">{{$gettext('Refresh Counters')}}</Button>
    <div>
      <div class="Counter_Instance">
        <span class="Counter_Instance_Number">{{teams.length}}</span>
        <span class="Counter_Instance_Item" v-translate>Teams</span>
      </div>
      <div class="Counter_Instance">
        <span class="Counter_Instance_Number">{{users.length}}</span>
        <span class="Counter_Instance_Item" v-translate>Users total</span>
      </div>
      <div class="Counter_Instance">
        <span class="Counter_Instance_Number">{{users.length - 70}}</span>
        <span class="Counter_Instance_Item" v-translate>Users since launch</span>
      </div>
    </div>
    <p>If you would like another counter here put it in Jira</p>
    <p>This is to test!</p>
  </Body>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'CounterView',
  data() {
    return {
      teams: false,
      users: false,
    };
  },
  methods: {
    refresh() {
      this.$apollo.queries.teams.refetch();
      this.$apollo.queries.users.refetch();
    },
  },
  apollo: {
    teams: {
      query: gql`
        {
          teams: Teams {
            _id,
          }
        }
      `,
    },
    users: {
      query: gql`
        {
          users: Users {
            _id,
          }
        }
      `,
    },
  },
};
</script>

<style lang="scss" scoped>
  .Counter {
    &_Instance {
      width: 200px;
      display: inline-block;
      vertical-align: top;
      text-align: center;

      padding: 50px;
      border: 1px solid;
      border-radius: 10px;
      margin: 50px;

      &_Number {
        font-size: 500%;
        font-weight: bold;
        display: block;
      }
      &_Item {

      }
    }
  }
</style>
